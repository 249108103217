import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from "react";
import { LayoutSplashScreen } from "../../../../_metronic/layout/core";
import { AuthModel, UserModel } from "./_models";
import * as authHelper from "./AuthHelpers";
import { getUserByToken, getSuperadminUserByToken } from "./_requests";
import { WithChildren } from "../../../../_metronic/helpers";
import { useMsal } from "@azure/msal-react";
import axios from "axios";
//import {updateAlreadyLoggedInStatus} from '../core/_requests';
type AuthContextProps = {
  auth: AuthModel | undefined;
  saveAuth: (auth: AuthModel | undefined) => void;
  currentUser: UserModel | undefined;
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>;
  logout: () => void;
  /* generalSetting: any;
  setGeneralSetting: any, */
};

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => { },
  currentUser: undefined,
  setCurrentUser: () => { },
  logout: () => { },
  /*  generalSetting: undefined,
   setGeneralSetting: () => { }, */
};


const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth());
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>();
  /* const [generalSetting, setGeneralSetting] = useState<any>(); */
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth);
    if (auth) {
      authHelper.setAuth(auth);
    } else {
      authHelper.removeAuth();
    }
  };
  const { instance } = useMsal();
  //detecing if microsoft logout is needed or not here
  const logout = async() => {
    let loginMode = localStorage.getItem('loginMode');
    let ktAuthKeyValue = localStorage.getItem('kt-auth-react-v');
    let authValue = JSON.parse(ktAuthKeyValue);
    let userID = authValue?.result?._id;
    let navigatingTo = localStorage.getItem('navigatingTo');
    //const updateStatus = await updateAlreadyLoggedInStatus(userID,false); 
    if(currentUser?.data?.user?.organizationId){
    try {
      const request = await axios.get(
        `${process.env.REACT_APP_API_URL}outlook/get-mfa-data/${currentUser?.data?.user?.organizationId}`
      );
      
    const mfo_sync = request.data.data.outlook_sync_is_active_mfo;
    const loginType = localStorage.getItem("loginType");
      if(mfo_sync && loginType !='normallogin' && loginType =='MFT'){
        let tenantId = localStorage.getItem('tenantId');
        // get subdomain from localstorage
        let subdomain = localStorage.getItem('subDomain');

        localStorage.setItem('currentStatusChange','loggedOutByMicrosoft');
        sessionStorage.setItem('currentStatusChange','loggedOutByMicrosoft');  
        let homeAccountId = localStorage.getItem('homeAccountId');
        const currentAccount = instance.getAccountByHomeId(homeAccountId);
        localStorage.removeItem('kt-auth-react-v');
        saveAuth(undefined);
        setCurrentUser(undefined);
        instance.logoutRedirect({
          account: currentAccount,
          // postLogoutRedirectUri: 'http://localhost:3000/auth/loggedout?params=checkorg1408'
          postLogoutRedirectUri: `https://${process.env.REACT_APP_HOST_URL}/auth/loggedout?params=${subdomain}`
        });
      
      }else if(!mfo_sync && loginType =='normallogin'){
        saveAuth(undefined);
        setCurrentUser(undefined);
        localStorage.removeItem('kt-auth-react-v');
        localStorage.setItem('currentStatusChange','loggedOutByMicrosoft');
        localStorage.setItem('justLoggedOut','true');
        sessionStorage.setItem('currentStatusChange','loggedOutByMicrosoft');
        localStorage.setItem('loginType','normallogin');
        sessionStorage.setItem('justLoggedOut','true');
      }else if(loginType=='normallogin' && navigatingTo=='microsoft' && mftLogging==true){
        let homeAccountId = localStorage.getItem('homeAccountId');
        const currentAccount = instance.getAccountByHomeId(homeAccountId);
        // localStorage.removeItem('kt-auth-react-v');
        localStorage.setItem('currentStatusChange','loggedOutByMicrosoft');
        sessionStorage.setItem('currentStatusChange','loggedOutByMicrosoft');
        sessionStorage.setItem('justLoggedOut','true');
        localStorage.setItem('justLoggedOut','true');
        saveAuth(undefined);
        setCurrentUser(undefined);
        instance.logoutRedirect({
          account: currentAccount,
          postLogoutRedirectUri: `https://${process.env.REACT_APP_HOST_URL}/auth/loggedout?params=${subdomain}`
          // postLogoutRedirectUri: 'http://localhost:3000/auth/loggedout?params=checkorg1408'
        });

      }else {
        saveAuth(undefined);
        setCurrentUser(undefined);

        let navigatingTo = localStorage.getItem('navigatingTo');
        if(navigatingTo!='microsoft'){
          localStorage.setItem('loginType','normallogin');
          localStorage.setItem('currentStatusChange','loggedOutbyNormal');
          localStorage.setItem('justLoggedOut','true');
          
          sessionStorage.setItem('loginType','normallogin');
          sessionStorage.setItem('currentStatusChange','loggedOutbyNormal');
          sessionStorage.setItem('justLoggedOut','true');
        }else{
          
          localStorage.setItem('currentStatusChange','loggedOutByMicrosoft');
          localStorage.setItem('justLoggedOut','true');

       
          sessionStorage.setItem('currentStatusChange','loggedOutByMicrosoft');
          sessionStorage.setItem('justLoggedOut','true');
          let tenantId = localStorage.getItem('tenantId');
          // get subdomain from localstorage
          
          let subdomain = localStorage.getItem('subDomain');
        
          // localStorage.removeItem('kt-auth-react-v');
          localStorage.setItem('currentStatusChange','loggedOutByMicrosoft');
          localStorage.setItem('justLoggedOut','true');
          sessionStorage.setItem('currentStatusChange','loggedOutByMicrosoft');
          sessionStorage.setItem('justLoggedOut','true');
          
          saveAuth(undefined);
          setCurrentUser(undefined);
  

        }
       
      }
    
    } catch (error) {
    }
    }else if(loginMode=='superadmin'){
      saveAuth(undefined);
      setCurrentUser(undefined);
      localStorage.clear();
    
      localStorage.setItem('currentStatusChange','loggedOutbyNormal');
      localStorage.setItem('justLoggedOut','true');
    }
    
    /*  setGeneralSetting(null); */
  };

  return (
    <AuthContext.Provider
      value={{ auth, saveAuth, currentUser, setCurrentUser, logout }}
    /* generalSetting, setGeneralSetting */
    >
      {children}
    </AuthContext.Provider>
  );
};

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, logout, setCurrentUser } = useAuth();
  /* setGeneralSetting */
  const didRequest = useRef(false);
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          if (auth?.type === 'superadmin') {
            const { data } = await getSuperadminUserByToken(apiToken);
            if (data) {
              setCurrentUser(data);
            }
          } else {
            const { data } = await getUserByToken(apiToken);
            if (data) {
              setCurrentUser(data);
            }
          }

        }
      } catch (error) {
        console.error(error);
        if (!didRequest.current) {
          logout();
        }
      } finally {
        setShowSplashScreen(false);
      }

      return () => (didRequest.current = true);
    };

    if (auth && auth.token) {
      requestUser(auth.token);
    } else {
      logout();
      setShowSplashScreen(false);
    }
    // eslint-disable-next-line
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};

export { AuthProvider, AuthInit, useAuth };