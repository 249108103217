import { useState, useEffect, FC } from "react";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";

import { alert } from "@mobiscroll/react";
import { SSOData, initalSSOData } from "./_model";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Steps } from "intro.js-react";
import { getTooltipData } from "../../../superadmin/hint-tour/core/_request";
import { useQuery } from "react-query";
import { useAuth } from "../../../auth/core/AuthCognito";
import { getCurrentUserDetails, updateTooltipVisibilityByPage } from "../../common/_requests";

const outlookSchema = Yup.object().shape({
  enableOutLookIntegration: Yup.boolean(),
  clientSecret: Yup.string()
    .when("enableOutLookIntegration", {
      is: true,
      then: Yup.string().required("Client Secret is required").nullable().test(
        "no-leading-or-trailing-space",
        "Blank spaces at the beginning or end are not allowed",
        (value) => {
          if (value) {
            return value.trim() === value;
          }
          return true;
        }
      )
        .matches(/^(\S+$)/g, "Client Secret cannot contain blankspaces")
    }),
  clientId: Yup.string()
    .when("enableOutLookIntegration", {
      is: true,
      then: Yup.string().required("Client ID is required").nullable()
        .test(
          "no-leading-or-trailing-space",
          "Blank spaces at the beginning or end are not allowed",
          (value) => {
            if (value) {
              return value.trim() === value;
            }
            return true;
          }
        )
        .matches(/^(\S+$)/g, "Client Id cannot contain blankspaces")
    }),
  tenentId: Yup.string()
    .when("enableOutLookIntegration", {
      is: true,
      then: Yup.string().required("Tenant Id is required").nullable()
        .test(
          "no-leading-or-trailing-space",
          "Blank spaces at the beginning or end are not allowed",
          (value) => {
            if (value) {
              return value.trim() === value;
            }
            return true;
          }
        )
        .matches(/^(\S+$)/g, "Tenant Id cannot contain blankspaces")
    }),
});

const SSOPage = () => {
  //all states to used here
  const [showClientId, setShowClientId] = useState(true);
  const [showClientSecret, setShowClientSecret] = useState(true);
  const [showTenentId, setShowTenentId] = useState(true);

  const [isClientSecretEmpty, setIsClientSecretEmpty] = useState(false);
  const [isClientIdEmpty, setIsClientIdEmpty] = useState(false);
  const [isTenentIdEmpty, setIsTenentIdEmpty] = useState(false);
  const [userPoolDomain, setUserPoolDomain] = useState('');
  const organizationId = localStorage.getItem("organizationID")


  const formik = useFormik<SSOData>({
    initialValues: initalSSOData,
    validationSchema: outlookSchema,
    onSubmit: (values, { resetForm }) => {

      if (values.enableOutLookIntegration == true) {
        let loginType = localStorage.getItem('loginType');
        let navigatingTo = localStorage.getItem('navigatingTo');
        if (loginType != 'MFT' && navigatingTo != 'microsoft') {
          localStorage.setItem('loginType', 'normallogin');
          localStorage.setItem('navigatingTo', 'normallogin');
        }
      }
      handleDataPosting(values);

    },
    enableReinitialize: true,
  });

  const [isInitialDataLoaded, setIsInitialDataLoaded] = useState(false);

  // fetches alredy present data if it is there , if not then it will show empty fields
  useEffect(() => {
    const fetchTokenDataIfExist = async () => {
      try {
        const request = await axios.get(
          `${process.env.REACT_APP_API_URL}outlook/get-mfa-data/${organizationId}`
        );
        if (request.data.data) {
          if (request.data.data && !isInitialDataLoaded) {
            const newData = {
              enableOutLookIntegration: request.data.data.outlook_sync_is_active_mfo,
              enableCognitoIntegration: request.data.data?.cognito?.enabled,
              clientId: request.data.data.outlook_client_id_mfo,
              clientSecret: request.data.data.outlook_client_secret_mfo,
              tenentId: request.data.data.outlook_tenant_id_mfo,
            };
            setIsTenentIdEmpty(false);
            setIsInitialDataLoaded(true);
            setShowClientId(false);
            setShowTenentId(false);
            setShowClientSecret(false);
            setUserPoolDomain('https://' + request.data.data?.cognito?.userPoolUri + '/oauth2/idpresponse');
            formik.resetForm({ values: newData });
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    //call fetchData
    fetchTokenDataIfExist();
  }, [formik]);

  //saves the data to the api and update the user
  const handleDataPosting = async (values: any) => {
    try {
      const request = await axios.post(
        `${process.env.REACT_APP_API_URL}outlook/create-mfo`,
        {
          outlook_sync_is_active_mfo: values.enableOutLookIntegration,
          outlook_client_id_mfo: values.clientId,
          outlook_client_secret_mfo: values.clientSecret,
          outlook_tenant_id_mfo: values.tenentId,
          cognito_enable: values.enableCognitoIntegration,
          cognito_provider_type: values.identityProviderType,
          organizationId: organizationId,
        }
      );
      if (request.status == 200) {
        let msg;
        if (values.enableOutLookIntegration == true) {
          msg = `Single Sign-On (SSO) has been successfully enabled!`;
        } else {
          // msg = `Please turn on the 'Outlook integration' toggle.`;
          msg = `Changes saved successfully!`;
        }
        alert({
          message: msg,
          callback: function () {
            window.location.reload();
          },
        });
      }
    } catch (error: any) {
      console.log(error);
      alert({
        message: "Invalid Credentials",
        callback: function () {
          window.location.reload();
        },
      });
    }
  };

  const { data: tooltipData } = useQuery(
    "tooltip-Data",
    async () => {
      return await getTooltipData();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );
  const { data: loggedUser } = useQuery(
    'logged-user',
    async () => {
      return await getCurrentUserDetails(_id)
    },
    {
      cacheTime: 0, keepPreviousData: false, refetchOnWindowFocus: false
    }
  )
  const steps = tooltipData?.filter(
    (v: any) => v.type === "tour" && v.page === "Settings-SSO"
  );
  const { currentUser } = useAuth()
  const  _id  = currentUser?.data?.user?._id
  const [enabled, setEnabled] = useState<boolean>(true);
  const [initialStep] = useState<number>(0);
  
  const tooltipVisibility = {
    showTooltipVisibility: {
      SettingsSSO: false,
    },
  };
  const onExit = async () => {
    console.log('onExit sso');
    setEnabled(false);
    await updateTooltipVisibilityByPage(_id, tooltipVisibility);
  };
  const renderSingleSignInSSOSettingsTooltip = (
    <Popover id="renderSingleSignInSSOSettingsTooltip">
      <div className="p-5 bg-white card">
        <div>
        Configure your Microsoft SSO by entering your credentials below.
          <a className="text-decoration-underline ms-2"
            href="https://knowledgebase.proxuma.io/article/microsoft-sso-mfa-set-up"
            target="_blank"
          >
            Click here to learn more about setting up your SSO credentials.
          </a>
        </div>
      </div>
    </Popover>
  )
  return (
    // console.log(loggedUser?.showTooltipVisibility),
    <>
    {/* {loggedUser && 
       loggedUser?.showTooltipVisibility?.SettingsSSO !== false &&
       (
        <Steps
          enabled={enabled}
          initialStep={initialStep}
          steps={steps?.length > 0 ? steps : []}
          onExit={onExit}
          options={{
            showStepNumbers: true,
            showButtons: true,
            hidePrev: true,
            scrollToElement: true,
            showBullets: true,
            showProgress: true,
            exitOnOverlayClick: false,
            disableInteraction: true,
            exitOnEsc: false,
            // keyboardNavigation: false,
          }}
        />
      )} */}
    <div
      id="kt_content_container"
      className={
        "container container-fluid position-relative px-0 overflow-auto"
      }
    >
      <div id="kt_general_setting_details" className={"px-4 py-6"}>
        <form className="form" onSubmit={formik.handleSubmit}>
          <div className="row mx-0">
            <div className="col-12 mb-3">
              {/* Card  starts here */}
              <div className="card h-100" id="single-sign-sso-settings">
                {/* Card header starts here */}
                <div
                  className="card-header"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div className="card-title">
                    <h3 className="text-dark fw-bold fs-18 m-0">
                      Single Sign-On (SSO) Settings
                    </h3>
                    <div className="h-24px d-flex align-items-center">
                      <OverlayTrigger
                        trigger="click"
                        rootClose
                        placement="right"
                        overlay={renderSingleSignInSSOSettingsTooltip}
                      >
                        <button className="btn p-0 text-center rounded-3px border lh-1 border-primary text-primary fs-6 w-10px color-white infoBtn ms-2" type="button">i</button>
                      </OverlayTrigger>
                    </div>
                  </div>
                  <div className="card-toolbar">
                    <a href="https://knowledgebase.proxuma.io/article/sso-set-up" target="_blank" className="btn btn-primary mr-10">Help </a> &nbsp; &nbsp;
                    <button type="submit" className="btn btn-primary">
                      Save Changes
                    </button>
                  </div>

                </div>
                {/* Card header ends here */}
                {/* Card body starts here  */}
                <div className="card-body  border-top py-5 overflow-auto mh-100">
                  <div className="row mb-6">
                    <label className="col-form-label fw-bold fs-16 text-dark mw-300px mw-xxl-400px">
                      Enable MFA
                    </label>
                    <div className="d-flex fv-row fv-row col-md-8">
                      <div className="col-lg-8 d-flex align-items-center">
                        <div className="form-check form-check-dark form-switch fv-row">
                          <input
                            className="form-check-input w-45px h-30px"
                            type="checkbox"
                            {...formik.getFieldProps(
                              "enableCognitoIntegration"
                            )}
                            checked={formik.values.enableCognitoIntegration}
                          ></input>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-6">
                    <label className="col-form-label fw-bold fs-16 text-dark mw-300px mw-xxl-400px">
                      Enable SSO Login
                    </label>
                    <div className="d-flex fv-row fv-row col-md-8">
                      <div className="col-lg-8 d-flex align-items-center">
                        <div className="form-check form-check-dark form-switch fv-row">
                          <input
                            className="form-check-input w-45px h-30px"
                            type="checkbox"
                            {...formik.getFieldProps(
                              "enableOutLookIntegration"
                            )}
                            checked={formik.values.enableOutLookIntegration}
                          ></input>
                        </div>
                      </div>
                    </div>
                  </div>
                  {formik.values.enableOutLookIntegration && (<><div className="row mb-6">
                    <label className="col-form-label required fw-bold fs-16 text-dark mw-300px mw-xxl-400px">
                      Provider type
                    </label>
                    <div className="fv-row col-md-8">
                      <div className="position-relative identifier">
                        <select
                          className="form-select disabled fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                          disabled
                          {...formik.getFieldProps("identityProviderType")}
                        >
                          {/* <option >Select provider type</option> */}
                          <option value="Microsoft" selected>Microsoft</option>
                        </select>
                        {formik.touched.identityProviderType && formik.errors.identityProviderType && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block fs-8">
                              {formik.errors.identityProviderType}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                    <div className="row mb-6">
                      <label className="col-form-label required fw-bold fs-16 text-dark mw-300px mw-xxl-400px">
                        Client ID{" "}
                      </label>

                      <div className="fv-row col-md-8">
                        <div className="position-relative">
                          <input
                            type={showClientId ? "text" : "password"}
                            className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                            placeholder="Enter Client ID"
                            style={{ width: "100%" }}
                            {...formik.getFieldProps("clientId")}
                          />
                          {showClientId ? (
                            <i
                              className="bi bi-eye-slash-fill fs-2x position-absolute top-50 translate-middle me-4 end-0"
                              onClick={() => setShowClientId(!showClientId)}
                            ></i>
                          ) : (
                            <i
                              className="bi bi-eye fs-2x bi bi-eye-slash-fill fs-2x position-absolute top-50 translate-middle me-4 end-0"
                              onClick={() => setShowClientId(!showClientId)}
                            ></i>
                          )}
                        </div>
                        {formik.touched.clientId && formik.errors.clientId && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block fs-8">
                              {formik.errors.clientId}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row mb-6">
                      <label className="col-form-label required fw-bold fs-16 text-dark mw-300px mw-xxl-400px">
                        Tenant ID
                      </label>

                      <div className="fv-row col-md-8">
                        <div className="position-relative">
                          <input
                            type={showTenentId ? "text" : "password"}
                            className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                            placeholder="Enter Tenant ID"
                            style={{ width: "100%" }}
                            {...formik.getFieldProps("tenentId")}
                          />

                          {showTenentId ? (
                            <i
                              className="bi bi-eye-slash-fill fs-2x position-absolute top-50 translate-middle me-4 end-0"
                              onClick={() => setShowTenentId(!showTenentId)}
                            ></i>
                          ) : (
                            <i
                              className="bi bi-eye fs-2x position-absolute top-50 translate-middle me-4 end-0"
                              onClick={() => setShowTenentId(!showTenentId)}
                            ></i>
                          )}
                        </div>

                        {formik.touched.tenentId && formik.errors.tenentId && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block fs-8">
                              {formik.errors.tenentId}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row mb-6">
                      <label className="col-form-label required fw-bold fs-16 text-dark mw-300px mw-xxl-400px">
                        Client Secret{" "}
                      </label>

                      <div className="fv-row col-md-8">
                        <div className="position-relative">
                          <input
                            type={showClientSecret ? "text" : "password"}
                            className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                            placeholder="Enter Client Secret"
                            style={{
                              width: "100%",
                            }}
                            {...formik.getFieldProps("clientSecret")}
                          />
                          {showClientSecret ? (
                            <i
                              className="bi bi-eye-slash-fill fs-2x position-absolute top-50 translate-middle me-4 end-0"
                              onClick={() =>
                                setShowClientSecret(!showClientSecret)
                              }
                            ></i>
                          ) : (
                            <i
                              className="bi bi-eye fs-2x position-absolute top-50 translate-middle me-3 end-0"
                              onClick={() =>
                                setShowClientSecret(!showClientSecret)
                              }
                            ></i>
                          )}
                        </div>
                        {formik.touched.clientSecret &&
                          formik.errors.clientSecret && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block fs-8">
                                {formik.errors.clientSecret}
                              </div>
                            </div>
                          )}
                      </div>
                    </div><div className="row mb-6">
                      <label className="col-form-label fw-bold fs-16 text-dark mw-300px mw-xxl-400px">
                        Provider Redirect URI
                      </label>

                      <div className="fv-row col-md-8">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                            placeholder="Enter Client Secret"
                            style={{
                              width: "100%",
                            }}
                            disabled
                            value={userPoolDomain}
                          />
                        </div>

                      </div>
                    </div></>)}

                  <div className="row mb-6">

                  </div>
                  {/* <div className="row mb-6">
                    <label className="col-form-label fw-bold fs-16 text-dark mw-300px mw-xxl-400px">
                      Enable 1 Login Per 24 hrs
                    </label>
                    <div className="d-flex fv-row fv-row col-md-8">
                      <div className="col-lg-8 d-flex align-items-center">
                        <div className="form-check form-check-dark form-switch fv-row">
                          <input
                            className="form-check-input w-45px h-30px"
                            type="checkbox"
                            {...formik.getFieldProps(
                              "enableOutLookIntegration"
                            )}
                            checked={formik.values.enableOutLookIntegration}
                          ></input>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                {/* Card body ends here  */}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    </>
  );
};

export default SSOPage;