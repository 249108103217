import { MbscCalendarEvent, MbscCalendarEventData, MbscResource } from '../../shared/calendar-view/calendar-view.types';
import { isInvalid } from '../../util/date-validation';
import {
  addDays,
  checkDateRangeOverlap,
  createDate,
  formatDate,
  getDateOnly,
  getDateStr,
  getEndDate,
  IDatetimeProps,
  isSameDay,
  makeDate,
} from '../../util/datetime';
import { MbscDateType } from '../../util/datetime.types.public';
import { getTextColor } from '../../util/dom';
import { UNDEFINED } from '../../util/misc';
import { getEventMap, getTzOpt } from '../../util/recurrence';
import { MbscEventcalendarOptions } from './eventcalendar.types';

// tslint:disable no-non-null-assertion

let uid = 1;

/** @hidden */
export function getDataInRange(
  data: any[],
  s: MbscEventcalendarOptions,
  firstDay: Date,
  lastDay: Date,
  start?: MbscDateType,
  end?: MbscDateType,
): MbscCalendarEvent[] {
  let startDate = firstDay;
  let endDate = lastDay;
  const map = new Map<MbscCalendarEvent, boolean>();
  const dataInRange: MbscCalendarEvent[] = [];

  if (start) {
    startDate = makeDate(start, s);
  }

  if (end) {
    endDate = makeDate(end, s);
  } else if (start) {
    endDate = addDays(startDate, 1);
  }

  const events = getEventMap(data, startDate, endDate, s);

  for (const date in events) {
    if (date) {
      for (const event of events[date]) {
        if (!event.start) {
          // Single date only (in case of invalids)
          dataInRange.push(event);
        } else if (!map.has(event)) {
          let eventStart = makeDate(event.start, s);
          let eventEnd = makeDate(event.end, s) || eventStart;

          if (event.allDay) {
            eventStart = createDate(s, eventStart.getFullYear(), eventStart.getMonth(), eventStart.getDate());
            eventEnd = getEndDate(s, true, eventStart, eventEnd);
            eventEnd = createDate(s, eventEnd.getFullYear(), eventEnd.getMonth(), eventEnd.getDate(), 23, 59, 59, 999);
          }

          if (checkDateRangeOverlap(startDate, endDate, eventStart, eventEnd)) {
            const eventCopy = { ...event };
            if (s.dataTimezone || s.displayTimezone) {
              eventCopy.start = eventStart.toISOString();
              eventCopy.end = eventEnd.toISOString();
            }
            map.set(event, true);
            dataInRange.push(eventCopy);
          }
        }
      }
    }
  }
  return dataInRange;
}

/** @hidden */
export function getEventId() {
  return `mbsc_${uid++}`;
}

/** @hidden */
export function getEventData(
  s: IDatetimeProps,
  event: MbscCalendarEvent,
  eventDay: Date,
  colorEvent: boolean,
  resource?: MbscResource,
  isList?: boolean,
  isMultipart?: boolean,
  isDailyResolution?: boolean,
  skipLabels?: boolean,
  fullDates?: boolean,
): MbscCalendarEventData {
  const color = event.color || (resource && resource.color);
  const st = event.start || event.date;
  const origStart = event.recurring ? event.original!.start : event.start;
  const allDay = event.allDay || !origStart;
  const tzOpt = getTzOpt(s, event);
  const start = st ? makeDate(st, tzOpt) : null;
  const end = event.end ? makeDate(event.end, tzOpt) : null;
  const endDate = getEndDate(s, event.allDay, start, end, isList);
  const bufferStart = event.bufferBefore ? makeDate(+start - event.bufferBefore * 60000, tzOpt) : null;
  const bufferEnd = event.bufferAfter ? makeDate(+endDate + event.bufferAfter * 60000, tzOpt) : null;
  const isMultiDay = start && endDate && !isSameDay(start, endDate);
  const isFirstDay = isMultiDay ? isSameDay(start, eventDay) : true;
  const isLastDay = isMultiDay ? isSameDay(endDate, eventDay) : true;
  const fillsAllDay = !fullDates && (allDay || (isMultipart && isMultiDay && !isFirstDay && !isLastDay));
  let startTime = '';
  let endTime = '';
  if (!skipLabels) {
    if (fullDates) {
      const dateTimeFormat = s.dateFormat! + (allDay ? '' : s.separator! + s.timeFormat!);
      startTime = start ? formatDate(dateTimeFormat, start, s) : '';
      endTime = end ? formatDate(dateTimeFormat, allDay ? endDate : end, s) : '';
    } else if (!isMultipart && !isDailyResolution) {
      startTime = start ? formatDate(s.dateFormat!, start, s) : '';
      endTime = end ? formatDate(s.dateFormat!, endDate, s) : '';
    } else if (!allDay) {
      startTime = start ? formatDate(s.timeFormat!, start, s) : '';
      endTime = end ? formatDate(s.timeFormat!, end, s) : '';
    }
  }
  const eventStart = !fillsAllDay && (isFirstDay || !isMultipart || fullDates) ? startTime : '';
  const eventEnd = !fillsAllDay && (isLastDay || !isMultipart || fullDates) ? endTime : '';
  const html = event.title || event.text || '';
  const title = html; // htmlToText(html);
  const tooltip = title + (fillsAllDay ? '' : ', ' + eventStart + ' - ' + eventEnd);
  const format = s.dateFormatFull!;
  const startStr = !skipLabels && start ? ', ' + s.fromText + ': ' + formatDate(format, start, s) + (allDay ? '' : ', ' + startTime) : '';
  const endStr = !skipLabels && end ? ', ' + s.toText + ': ' + formatDate(format, end, s) + (allDay ? '' : ', ' + endTime) : '';
  const resourceStr = resource && resource.name ? ', ' + resource.name : '';

  return {
    allDay,
    allDayText: fillsAllDay ? s.allDayText : '',
    ariaLabel: title + resourceStr + startStr + endStr,
    bufferEnd,
    bufferStart,
    color,
    currentResource: resource,
    date: +eventDay,
    end: eventEnd,
    endDate: end ? end : start ? new Date(start) : null,
    html,
    id: event.id,
    isMultiDay,
    lastDay: !fillsAllDay && isMultiDay && isLastDay ? s.toText : '',
    original: event,
    position: {},
    resource: event.resource,
    slot: event.slot,
    start: eventStart,
    startDate: start,
    style: {
      background: color,
      color: colorEvent && color ? getTextColor(color) : '',
    },
    title,
    tooltip: s.showEventTooltip ? event.tooltip || tooltip : UNDEFINED,
    // uid will contain the start date as well in case of recurring events
    uid: event.occurrenceId ? event.occurrenceId : event.id,
  };
}

/** @hidden */
export function prepareEvents(events?: MbscCalendarEvent[]): MbscCalendarEvent[] {
  const data = [];
  if (events) {
    for (const event of events) {
      if (event.id === UNDEFINED) {
        event.id = getEventId();
      }
      data.push(event);
    }
  }
  return data;
}

/** @hidden */
export function checkInvalidCollision(
  s: any,
  invalids: { [key: string]: MbscCalendarEvent[] } | undefined,
  valids: { [key: string]: MbscCalendarEvent[] } | undefined,
  start: Date,
  end: Date,
  min: number,
  max: number,
  invalidateEvent: 'start-end' | 'strict' | undefined,
  exclusiveEndDates?: boolean,
): MbscCalendarEvent | boolean {
  if (invalidateEvent === 'start-end') {
    const invalidStart = isInvalid(s, start, invalids, valids, min, max);
    const invalidEnd = isInvalid(s, end, invalids, valids, min, max);
    if (invalidStart) {
      return invalidStart;
    }

    if (invalidEnd) {
      return invalidEnd;
    }
  } else {
    const until = exclusiveEndDates ? end : getDateOnly(addDays(end, 1));
    for (const d = getDateOnly(start); d < until; d.setDate(d.getDate() + 1)) {
      const invalid = isInvalid(s, d, invalids, valids, min, max);
      if (invalid) {
        return invalid;
      }
    }
  }
  return false;
}

/** @hidden */
export function checkOverlap(
  event: MbscCalendarEvent,
  start: Date,
  end: Date,
  eventMap: { [key: string]: MbscCalendarEvent[] },
  s: MbscEventcalendarOptions,
): MbscCalendarEvent | boolean {
  const until = s.exclusiveEndDates ? end : getDateOnly(addDays(end, 1));
  for (const d = getDateOnly(start); d < until; d.setDate(d.getDate() + 1)) {
    const dayEvents = (eventMap[getDateStr(d)] || []).filter((e) => e.id !== event.id);
    if (dayEvents.length) {
      // If overlap is not allowed globally or on the created/updated event, return the first event on the day
      if (s.eventOverlap === false || event.overlap === false) {
        return dayEvents[0];
      }
      // Otherwise check every event for the day, and return the first one with disabled overlap
      for (const dayEvent of dayEvents) {
        if (dayEvent.overlap === false) {
          return dayEvent;
        }
      }
    }
  }
  return false;
}
