import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import GeneralSettingView from "./general/generalSettingView";
import OutlookSync from "./outlooksync/OutlookSync";
import SSOPage from "./sso/SSOPage";
import { useQuery } from "react-query";
import { getTooltipData } from "../../superadmin/hint-tour/core/_request";
import { getCurrentUserDetails, updateTooltipVisibilityByPage } from "../common/_requests";
import { useAuth } from "../../auth/core/AuthCognito";
import { useState } from "react";
import { Steps } from "intro.js-react";

const SettingPage = () => {
  const { data: tooltipData } = useQuery(
    "tooltip-Data",
    async () => {
      return await getTooltipData();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );
  const { data: loggedUser } = useQuery(
    'logged-user-settings',
    async () => {
      return await getCurrentUserDetails(_id)
    },
    {
      cacheTime: 0, keepPreviousData: false, refetchOnWindowFocus: false
    }
  )
  const stepsGeneral = tooltipData?.filter(
    (v: any) => v.type === "tour" && v.page === "Settings-General"
  );
  const stepsEmail = tooltipData?.filter(
    (v: any) => v.type === "tour" && v.page === "Settings-Emails"
  );
  const stepsSSO = tooltipData?.filter(
    (v: any) => v.type === "tour" && v.page === "Settings-SSO"
  );
  const { currentUser, setCurrentUser } = useAuth()
  const _id = currentUser?.data?.user?._id
  const [enabledSettingGeneral, setEnabledSettingGeneral] = useState<boolean>(true);
  const [enabledSettingEmail, setEnabledSettingEmail] = useState<boolean>(true);
  const [enabledSettingSSO, setEnabledSettingSSO] = useState<boolean>(true);
  const [initialStep] = useState<number>(0);
  const [data, setData] = useState(currentUser)

  
  const tooltipVisibilityGeneral = {
    showTooltipVisibility: {
      SettingsGeneral: false,
    },
  };
  const tooltipVisibilityEmail = {
    showTooltipVisibility: {
      SettingsEmails: false,
    },
  };
  const tooltipVisibilitySSO = {
    showTooltipVisibility: {
      SettingsSSO: false,
    },
  };
  const onExitSettingsGeneral = async () => {
    setEnabledSettingGeneral(false);
    await updateTooltipVisibilityByPage(_id, tooltipVisibilityGeneral);
  };
  const onExitSettingsEmail = async () => {
    console.log('SettingsEmails');
    // setData((prevState) => ({
    //   ...prevState, // Spread the previous state
    //   data: {
    //     ...prevState.data, // Spread the data object
    //     user: {
    //       ...prevState.data.user, // Spread the user object
    //       showTooltipVisibility: {
    //         ...prevState.data.user.showTooltipVisibility, // Spread the showTooltipVisibility object
    //         SettingsEmails: false // Update the email key
    //       }
    //     }
    //   }
    // }));
    setEnabledSettingEmail(false);
    await updateTooltipVisibilityByPage(_id, tooltipVisibilityEmail);
  };
  const onExitSettingsSSO = async () => {
    console.log('SettingsSSO');
    setEnabledSettingSSO(false);
    await updateTooltipVisibilityByPage(_id, tooltipVisibilitySSO);
  };
  const URL = window.location.href
  return (
    console.log(currentUser),
    <>
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="general"
          element={
            <>
              {loggedUser && loggedUser?.showTooltipVisibility?.SettingsGeneral !== false && 
              URL.includes('general') &&
              (
                <Steps
                  enabled={enabledSettingGeneral}
                  initialStep={initialStep}
                  steps={stepsGeneral?.length > 0 ? stepsGeneral : []}
                  onExit={onExitSettingsGeneral}
                  options={{
                    showStepNumbers: true,
                    showButtons: true,
                    hidePrev: true,
                    scrollToElement: true,
                    showBullets: true,
                    showProgress: true,
                    exitOnOverlayClick: false,
                    disableInteraction: true,
                  }}
                />
              )}
              <GeneralSettingView />
            </>
          }
        />
      </Route>
      {/* <Route element={<Outlet />}>
          <Route
            path="resources"
            element={ 
              <>
                  <div className='card h-100 py-6 px-7 bg-transparent notifyHeight'>
                    <ResourceSettingListWrapper />
                  </div>
              </>
            }
          />
        </Route> */}
      <Route element={<Outlet />}>
        <Route
          path="/outlooksync"
          element={
            <>
              {loggedUser && loggedUser?.showTooltipVisibility?.SettingsEmails !== false && 
              URL.includes('outlooksync') &&
              (
                <Steps
                  enabled={enabledSettingEmail}
                  initialStep={initialStep}
                  steps={stepsEmail?.length > 0 ? stepsEmail : []}
                  onExit={onExitSettingsEmail}
                  options={{
                    showStepNumbers: true,
                    showButtons: true,
                    hidePrev: true,
                    scrollToElement: true,
                    showBullets: true,
                    showProgress: true,
                    exitOnOverlayClick: false,
                    disableInteraction: true,
                  }}
                />
              )}
              <OutlookSync />
            </>
          }
        ></Route>
      </Route>
      {/* for sso path  */}
      <Route element={<Outlet />}>
        <Route path="/sso" element={
          <>
            {loggedUser && loggedUser?.showTooltipVisibility?.SettingsSSO !== false && 
            URL.includes('sso') &&
            (
              <Steps
                enabled={enabledSettingSSO}
                initialStep={initialStep}
                steps={stepsSSO?.length > 0 ? stepsSSO : []}
                onExit={onExitSettingsSSO}
                options={{
                  showStepNumbers: true,
                  showButtons: true,
                  hidePrev: true,
                  scrollToElement: true,
                  showBullets: true,
                  showProgress: true,
                  exitOnOverlayClick: false,
                  disableInteraction: true,
                }}
              />
            )}
            <SSOPage />
          </>
        }>

        </Route>

      </Route>
      <Route index element={<Navigate to="/" />} />
    </Routes>
    </>
  );
};

export default SettingPage;
