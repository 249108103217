/* eslint-disable jsx-a11y/anchor-is-valid */
import { PublicClientApplication } from "@azure/msal-browser";
 
import { useMsal } from "@azure/msal-react";
import { useState , useEffect } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { useFormik } from "formik";
import { getUserByToken, login } from "../core/_requests";
import { useAuth } from "../core/Auth";
import '../../../../_metronic/assets/sass/login.scss';
import { Loading } from "../../../components/loading/Loading";
import { Link, useNavigate } from "react-router-dom";
import { getGeneralSetting } from "../../../../_metronic/requests/GeneralSettingRequest";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { encryptData, generateEncryptionKey } from "./encryptionUtils";
import axios from "axios";
import { LayoutProvider, LayoutSplashScreen } from "../../../../_metronic/layout/core";
import { useSubdomain } from '../../../context/SubdomainContext';
import { KTSVG } from "../../../../_metronic/helpers";
import {mftLogin} from '../core/_requests';
import { create } from "domain";
import { set } from "lodash";
const APP_URL:any = process.env.REACT_APP_HOST_URL;

export function LoggedOut() {
  
  localStorage.clear();
    let urlParams = new URLSearchParams(window.location.search);
    //  href="`http://${}.localhost/3000/auth`"
  
    let mainUrl = APP_URL.split('www.');
    useEffect(() => {
    // window.location.href = `http://${urlParams.get('params')}.localhost:3000/auth`;
     window.location.href = `https://${urlParams.get('params')}.${mainUrl[1]}/auth`;
    }, []);
    
    
  

return (
  <>
     <div className="w-100 w-sm-400px">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
    </div>
  </>
);



}