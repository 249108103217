/** @jsxRuntime classic */
/** @jsx createElement */
import { createElement } from '../../../react/renderer';
import { ListHeaderBase, MbscListHeaderOptions } from './list-header';

export function template(s: MbscListHeaderOptions, inst: ListHeaderBase, content: any): any {
  return (
    <div ref={inst._setEl} className={inst._cssClass}>
      {content}
    </div>
  );
}

/**
 * The ListItem component
 */
export class ListHeader extends ListHeaderBase {
  protected _template(s: MbscListHeaderOptions): any {
    return template(s, this, s.children);
  }
}
