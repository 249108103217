import {useQuery} from 'react-query'
import {FC} from 'react'
import { getIntroById } from './core/_request'
import { IntrojsEditModalForm } from './IntrojsEditModalForm'
import { isNotEmpty, QUERIES } from '../../../../_metronic/helpers'
import { useListView } from './core/ListViewProvider'
import React from 'react'

type Props = {
  openUserModel: (state: boolean) => any
}

const IntrojsEditModalFormWrapper: FC<Props> = ({openUserModel}) => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: introData,
    error,
  } = useQuery(
    `${QUERIES.USERS_LIST}-user-${itemIdForUpdate}`,
    () => {
      return getIntroById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )
  console.log(introData);

  if (!itemIdForUpdate) {
    return (
      <IntrojsEditModalForm
        openUserModal={openUserModel} 
        isUserLoading={isLoading}
        tooltips={{_id: undefined}}
      />
    )
  }

  if (!isLoading && !error && introData) {
    return <IntrojsEditModalForm openUserModal={openUserModel}
     isUserLoading={isLoading} 
     tooltips={introData} 
     />
  }
  return null
}

export {IntrojsEditModalFormWrapper}
