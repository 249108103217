import { useState, FC, useEffect, useCallback, useMemo } from "react";
import * as Yup from "yup";
import { Loading } from "../../../components/loading/Loading";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { isNotEmpty } from "../../../../_metronic/helpers";
import { MyOrganisation, initialMyOrganisation } from "./_model";
import { useFormik } from "formik";
import { Popup, alert } from "@mobiscroll/react";
import {
  createOrganisation,
  getOrganisationById,
  passwordVerify,
  updateOrganisation,
} from "../../superadmin/organisations/organisation-list/core/_requests";
import { useAuth } from "../../auth/core/AuthCognito";
import clsx from "clsx";
import { useLayout } from "../../../../_metronic/layout/core";
import { checkValidCredentials } from "../../../../_metronic/requests/GeneralSettingRequest";
import axios from "axios";
import { Alert } from "../../../mobiscroll/react/bundle-datepicker";
import { OverlayTrigger, Popover } from "react-bootstrap";

const EUcountries = [
  { name: "Austria", value: "AT" },
  { name: "Belgium", value: "BE" },
  { name: "Bulgaria", value: "BG" },
  { name: "Croatia", value: "HR" },
  { name: "Cyprus", value: "CY" },
  { name: "Czechia", value: "CZ" },
  { name: "Denmark", value: "DK" },
  { name: "Estonia", value: "EE" },
  { name: "Finland", value: "FI" },
  { name: "France", value: "FR" },
  { name: "Germany", value: "DE" },
  { name: "Greece", value: "GR" },
  { name: "Hungary", value: "HU" },
  { name: "Ireland", value: "IE" },
  { name: "Italy", value: "IT" },
  { name: "Latvia", value: "LV" },
  { name: "Lithuania", value: "LT" },
  { name: "Luxembourg", value: "LU" },
  { name: "Malta", value: "MT" },
  { name: "Netherlands", value: "NL" },
  { name: "Poland", value: "PL" },
  { name: "Portugal", value: "PT" },
  { name: "Romania", value: "RO" },
  { name: "Slovakia", value: "SK" },
  { name: "Slovenia", value: "SI" },
  { name: "Spain", value: "ES" },
  { name: "Sweden", value: "SE" }
  // Add more countries as needed
];


const myOrganisationSchema = Yup.object().shape({
  // address: Yup.string().required("Address is required").trim(),

//   vat_number: Yup.string()
//     .required("vat number  name is required")
//     .trim()
// ,
   company_name: Yup.string()
    .required("Company name is required")
    .trim()
    .max(50, "Maximum 50 characters are required in company name"),

  invoice_email: Yup.string()
    .required("Invoice e-mail address is required")
    .email("Please enter a valid email address")
    .min(3, "Minimum 3 symbols are required")
    .max(50, "Maximum 50 symbols are required")
    .matches(/^(\S+$)/g, "Invoice e-mail address cannot contain blank spaces"),

  first_name: Yup.string()
    .required("First name is required")
    .trim()
    .max(15, "Maximum 15 characters are required in first name"),

  last_name: Yup.string()
    .required("Last name is required")
    .trim()
    .max(15, "Maximum 15 characters are required in last name"),

  email: Yup.string()
    .email("Please enter a valid email address")
    .min(3, "Minimum 3 symbols are required")
    .max(50, "Maximum 50 symbols are required")
    .required("Email address is required")
    .matches(/^(\S+$)/g, "Invoice email cannot contain blankspaces"),

  zipcode: Yup.string().required("Zipcode is required").trim(),

  city: Yup.string()
    .required("City is required")
    .trim()
    .max(10, "Maximum 10 characters are required in city"),

  street: Yup.string().required("Address is required").trim(),

  user_name: Yup.string()
    .required("Autotask client id is required")
    .matches(/^(\S+$)/g, "Autotask client id cannot contain blankspaces")
    .trim(),

  secret: Yup.string().required("Autotask client secret is required").trim(),
  country: Yup.string().required("country is required").trim(),
  default_billing_address: Yup.boolean(),
  billing_name: Yup.string().when("default_billing_address", {
    is: false,
    then: Yup.string()
      .required("Billing Name is required")
      .trim()
      .max(50, "Maximum 50 characters are required in Billing Name"),
  }),

  billing_address: Yup.string().when("default_billing_address", {
    is: false,
    then: Yup.string()
      .required("Billing Address is required")
      .trim()
      .max(50, "Maximum 50 characters are required in Billing Address"),
  }),
  billing_zipcode: Yup.string().when("default_billing_address", {
    is: false,
    then: Yup.string().required("Billing zipcode is required").trim(),
  }),
  // vat_number: Yup.string().when('country', {  
  //   is: (country:any , vat_number:any) => {
  //   console.log("vat_number: ", vat_number);


  //     return EUcountries.some(euCountry => euCountry.value === country) && country !== 'NL';
  // } ,
  //   then: Yup.string()
  //     .required("VAT Identification is required for EU countries")
  //     .matches(/^[a-zA-Z0-9]+$/, "VAT number must contain only alphanumeric characters")
  //     .max(20, "Maximum 20 characters are required in VAT number")
  //     .trim(),
  //   otherwise: Yup.string().notRequired(),
  // }),

  
  Country_type: Yup.string().nullable().when("vat_number", {
    is: (vatNumber) => !!vatNumber, // Validation is applied when vat_number has a value
    then: Yup.string().nullable().required("Tax Id Type is required"),
    otherwise: Yup.string().nullable(), // Allow empty or null Country_type when vat_number is not present
  }),
  vat_number: Yup.string().when('country', {
    is: (country) => {
      return EUcountries.some((euCountry) => euCountry.value === country) && country !== 'NL';
    },
    then: Yup.string()
      .required('VAT Identification is required for EU countries')
      .matches(/^[a-zA-Z0-9]+$/, 'VAT number must contain only alphanumeric characters')
      .max(20, 'Maximum 20 characters are required in VAT number')
      .test('country-match', 'Country code in VAT number must match the selected country', function (value) {
        const country = this.parent.country;
        const tax_id_value = this.parent.Country_type
        if (value && value.length >= 2 && tax_id_value === 'eu_vat') {
          const vatCountryCode = value.substring(0, 2);
          if (vatCountryCode !== country) {
            return this.createError({
              path: 'vat_number',
              message: 'VAT number country code does not match the selected country',
            });
          }
        }
        return true;
      })
      .trim(),
    otherwise: Yup.string().notRequired(),
  }),

  
});

const initialValues = {
  password: "",
};
const apiCredentialsSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .matches(/^(\S+$)/g, "Secret cannot contain blankspaces"),
});
const MyOrganisationpage: FC = () => {
  const [ActiveStatus, setActiveStatus] = useState<any>(null);
  const [orgSubdomain, setOrgSubdomain] = useState<any>("");
  const [orgSubdomainError, setOrgSubdomainError] = useState("");
  const [orgSubdomainBlurred, setOrgSubdomainBlurred] = useState(false);
  // New code

  const [isPasswordPopupOpen, setIsPasswordPopupOpen] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const openPopup = () => {
    setIsPasswordPopupOpen(true);
  };

  const { classes } = useLayout();
  const [success, setSuccess] = useState("");

  const cancelpassword = (withRefresh?: boolean) => {
    if (withRefresh) {
      formikpassword.resetForm();
      setActiveStatus(myOrganisation?.is_active);
      setIsPasswordPopupOpen(false);
    }
  };
  const clearErrorOnChange = (e: any) => {
    if (formikpassword.status) {
      formikpassword.setStatus("");
    }
    formikpassword.handleChange(e);
  };

  const formikpassword = useFormik({
    initialValues,
    validationSchema: apiCredentialsSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        const res = await passwordVerify(values);
        setSuccess("Password is correct");
        setIsPasswordValid(true);
        setTimeout(() => {
          setSuccess("");
          formikpassword.resetForm();
          setIsPasswordPopupOpen(false);
        }, 2000);
      } catch (error: any) {
        let ErrorMsg =
          error?.response?.data?.message &&
          error?.response?.data?.message != undefined
            ? ", " + error?.response?.data?.message
            : "";
        setStatus(ErrorMsg ? "Password is incorrect" : "");
        setIsPasswordValid(false);
        setSubmitting(false);
      }
    },
  });

  // END: new code
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const [countryData, setCountryData] = useState([]);
  /* fetch country  */
  const API_URL = process.env.REACT_APP_API_URL;

  const fetchCountry = async () => {
    try {
      const response = await axios.get(`${API_URL}countries/`);

      setCountryData(response?.data);
      return response?.data;
    } catch (error) {
      throw error; 
    }
  };

  useEffect(() => {
    fetchCountry();
  }, []);


  const { refetch, data: myOrganisation  } = useQuery(
    `my-organisation`,
    async () => {
      return await getOrganisationById(currentUser?.data?.user?.organizationId);
    },
    {
      cacheTime: 0,
      onError: (err) => {
        console.error(err);
      },
    }
  );
  

  let myOrganisationData = myOrganisation ? myOrganisation : initialMyOrganisation;
  myOrganisationData.address = myOrganisationData?.address ? myOrganisationData.address : ''
  myOrganisationData.vat_number = myOrganisationData?.vat_number ? myOrganisationData.vat_number : ''
  myOrganisationData.billing_name = myOrganisationData?.billing_name ? myOrganisationData.billing_name : ''
  myOrganisationData.billing_address = myOrganisationData?.billing_address ? myOrganisationData.billing_address : ''
  myOrganisationData.billing_zipcode = myOrganisationData?.billing_zipcode ? myOrganisationData.billing_zipcode : ''
  myOrganisationData.default_billing_address = myOrganisationData?.default_billing_address ? myOrganisationData.default_billing_address : false;

  useEffect(() => {
    if (ActiveStatus === null || ActiveStatus === undefined) {
      setActiveStatus(myOrganisation?.is_active);
    }
    if (orgSubdomain === undefined || orgSubdomain == "") {
      // const originalString = myOrganisation?.subdomain;
      // const substringToRemove = '.proxuma.app';
      // const modifiedString = originalString ? originalString.replace(substringToRemove, '') : '';

      setOrgSubdomain(myOrganisation?.subdomain);
    }
  }, [myOrganisation, myOrganisationData, ActiveStatus]);

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch();
    }
  };

  const updateStatus = (fieldsToUpdate: Partial<MyOrganisation>): void => {
    // const updatedData = Object.assign(myOrganisationData, fieldsToUpdate);
    if (!isPasswordValid) {
      openPopup();
    }
    // } else {
    //   setActiveStatus(fieldsToUpdate?.is_active);
    // }
    setActiveStatus(fieldsToUpdate?.is_active);
  };
  const validateSubdomain = (value: any) => {
    if (!value) {
      return "Subdomain is required";
    }
    return "";
  };
  const handleSubdomainBlur = () => {
    setOrgSubdomainBlurred(true);
    const error = validateSubdomain(orgSubdomain);
    setOrgSubdomainError(error);
  };

  const handleSubdomainChange = (event: any) => {
    // Prevent changing the fixed value ".proxuma.app"
    if (!event.target.value.endsWith(".proxuma.app")) {
      const value = event.target.value?.toLowerCase();
      setOrgSubdomain(value);

      const error = orgSubdomainBlurred ? validateSubdomain(value) : "";
      setOrgSubdomainError(error);
    }
  };

  const [loading, setLoading] = useState(false);
  const formik = useFormik<MyOrganisation>({
    enableReinitialize: true,
    initialValues: myOrganisationData,
    validationSchema: myOrganisationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      setLoading(true);
      try {
        if (
          isPasswordValid &&
          values.user_name != myOrganisationData.user_name &&
          values.secret != myOrganisationData.secret
        ) {
          try {
            const { data: auth } = await checkValidCredentials(values);
          } catch (error: any) {
            let ErrorMsg =
              error?.response?.data?.message &&
              error?.response?.data?.message != undefined
                ? ", " + error?.response?.data?.message
                : "";
            let msg =
              "API call was not successful" + ErrorMsg ??
              "Something went wrong.";
            setSubmitting(true);
            setLoading(false);
            alert({
              message: msg,
            });
            return;
          }
        }

        values.is_active = ActiveStatus;
        values.subdomain = orgSubdomain;
 
        if (isNotEmpty(values._id)) {
            await updateOrganisation(values);
          } else {
            await createOrganisation(values);
          } 
      

        navigate("/apps/organisation");
        setSubmitting(true);
        setLoading(false);
        cancel(true);
        setIsPasswordValid(false);
        alert({
          message: "Changes saved successfully!",
          callback: function (result) {
            refetch()
          },
        });
      } catch (ex :any) {      
        navigate("/apps/organisation");
        setSubmitting(true);
        setLoading(false);
        setIsPasswordValid(false);
       if (ex?.response?.data?.message.code === "tax_id_invalid") {
          alert({
            message: "This tax ID is invalid" ,
          
           
          });
      }
        else if (ex?.response?.data && ex?.response?.data?.message) {
          alert({
            message:ex?.response?.data?.message.code ,
          
           
          });
      }
      else{
        alert({
          message: "Something went wrong",
          callback: function (result) {
            window.location.reload();
          },
        });
      }

       
      }
    },
  });
  const [showTypesList, setShowTypesList] = useState(false);

  const [selectedCountry, setSelectedCountry] = useState(formik?.values?.country || 'NL'); // Set a default value
const [selectedTaxIdType, setSelectedTaxIdType] = useState('');

 
  // Update selectedCountry when formik values change
  useEffect(() => {
    setSelectedCountry(formik.values.country || 'NL');
  }, [formik.values.country]);

  const handleCountryChange = (e) => {
    const selectedCountryValue = e.target.value;
    formik.setFieldValue('country', selectedCountryValue);
    setSelectedCountry(selectedCountryValue);

  };

  const RequiredFeild = EUcountries.some(euCountry => euCountry.value === selectedCountry) && selectedCountry !== 'NL'



  useEffect(() => {
    if (countryData && countryData.data) {
      const filteredData = countryData.data
        .filter((country) => country.id === selectedCountry)
        .map((country) => country.type.map((type) => type));
  
      setSelectedTaxIdType(filteredData[0][0])
    if(RequiredFeild){
      formik.setFieldValue('Country_type',filteredData[0][0] );
    }

    if(selectedCountry !== myOrganisationData?.country) {
        formik.setFieldValue('vat_number', "")
    } else{
        formik.setFieldValue('vat_number', myOrganisationData?.vat_number )
    }


    }
  }, [countryData, selectedCountry , myOrganisationData?.country]);

  const renderStatusTooltip = (
    <Popover id="renderAddUserTooltip">
      <div className="p-5 bg-white card">
        <div>
          Unselect here to end your subscription and stop being billed after your contract ends.
        </div>
      </div>
    </Popover>
  )
  return (
    <>
      <div className="card h-100 py-6 px-7 bg-transparent notifyHeight">
        <div id="kt_profile_details" className="h-100 bg-white rounded">
          <form
            onSubmit={formik.handleSubmit}
            noValidate
            className="form d-flex flex-column h-100 overflow-auto"
          >
            <div className="card-header">
              <div className="card-title m-0 w-100 justify-content-between">
                <h3 className="text-dark fw-bold fs-18 m-0">My organisation</h3>
                <button
                  type="submit"
                  className="btn btn-primary h-35px py-0 px-5 rounded-4px fs-16"
                  disabled={loading || orgSubdomainError != ""}
                >
                  {!loading && "Save changes"}
                  <Loading
                    isLoading={loading}
                    text={"Please wait..."}
                  ></Loading>
                </button>
              </div>
            </div>
            <div className="card-body border-top py-5 overflow-auto mh-100">
            <div className="row mb-6">
                <label className="col-form-label required fw-bold fs-16 text-dark mw-300px mw-xxl-400px">
                  Company Name
                </label>
                <div className="fv-row col-md-8">
                  <input
                    type="text"
                    className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                    {...formik.getFieldProps("company_name")}
                  />
                  {formik.touched.company_name && formik.errors.company_name && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block fs-8">
                        {formik.errors.company_name}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="row mb-6">
                <label className="col-form-label required fw-bold fs-16 text-dark mw-300px mw-xxl-400px">
                  First Name
                </label>

                <div className="fv-row col-md-8">
                  <input
                    type="text"
                    className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                    {...formik.getFieldProps("first_name")}
                  />
                  {formik.touched.first_name && formik.errors.first_name && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block fs-8">
                        {formik.errors.first_name}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="row mb-6">
                <label className="col-form-label required fw-bold fs-16 text-dark mw-300px mw-xxl-400px">
                  Last Name
                </label>

                <div className="fv-row col-md-8">
                  <input
                    type="text"
                    className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                    {...formik.getFieldProps("last_name")}
                  />
                  {formik.touched.last_name && formik.errors.last_name && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block fs-8">
                        {formik.errors.last_name}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="row mb-6">
                <label className="col-form-label required fw-bold fs-16 text-dark mw-300px mw-xxl-400px">
                  Email Address
                </label>

                <div className="fv-row col-md-8">
                  <input
                    type="text"
                    className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                    {...formik.getFieldProps("email")}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block fs-8">
                        {formik.errors.email}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="row mb-6">
                <label className="col-form-label required fw-bold fs-16 text-dark mw-300px mw-xxl-400px">
                  Invoice Email Address
                </label>

                <div className="fv-row col-md-8">
                  <input
                    type="text"
                    className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                    {...formik.getFieldProps("invoice_email")}
                  />
                  {formik.touched.invoice_email && formik.errors.invoice_email && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block fs-8">
                        {formik.errors.invoice_email}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-form-label required fw-bold fs-16 text-dark mw-300px mw-xxl-400px">
                  Zipcode
                </label>
                <div className="fv-row col-md-8">
                  <input
                    type="text"
                    className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                    {...formik.getFieldProps("zipcode")}
                  />
                  {formik.touched.zipcode && formik.errors.zipcode && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block fs-8">
                        {formik.errors.zipcode}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-form-label required fw-bold fs-16 text-dark mw-300px mw-xxl-400px">
                  Address
                </label>
                <div className="fv-row col-md-8">
                  <input
                    type="text"
                    className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                    {...formik.getFieldProps("street")}
                  />
                  {formik.touched.street && formik.errors.street && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block fs-8">
                        {formik.errors.street}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-form-label required fw-bold fs-16 text-dark mw-300px mw-xxl-400px">
                  City
                </label>
                <div className="fv-row col-md-8">
                  <input
                    type="text"
                    {...formik.getFieldProps("city")}
                    className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                  />
                  {formik.touched.city && formik.errors.city && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block fs-8">
                        {formik.errors.city}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-form-label required fw-bold fs-16 text-dark mw-300px mw-xxl-400px">
                  Country
                </label>
                <div className="fv-row col-md-8">
                  <select
                    className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                    {...formik.getFieldProps("country")}
                    // onChange={(e) =>
                    //   formik.setFieldValue("country", e.target.value)
                    // }
                    onChange={handleCountryChange}
                  >
                    {countryData?.data &&
                      countryData?.data?.map((country) => (
                        <option key={country.id} value={country.id}>
                          {country.label}
                        </option>
                      ))}
                  </select>
                  {formik.touched.country && formik.errors.country && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block fs-8">
                        {formik.errors.country}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-form-label required fw-bold fs-16 text-dark mw-300px mw-xxl-400px">
                  Autotask Client ID
                </label>
                <div className="fv-row col-md-8">
                  <input
                    type="password"
                    // disabled={myOrganisation?.user_name && (myOrganisation?.user_name != '' || myOrganisation?.user_name != undefined) ? true : false}
                    {...formik.getFieldProps("user_name")}
                    className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                    readOnly={isPasswordPopupOpen}
                    onFocus={isPasswordValid ? undefined : openPopup}
                  />
                  {formik.touched.user_name && formik.errors.user_name && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block fs-8">
                        {formik.errors.user_name}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-form-label required fw-bold fs-16 text-dark mw-300px mw-xxl-400px">
                  Autotask Client Secret
                </label>
                <div className="fv-row col-md-8">
                  <input
                    type="password"
                    // disabled={myOrganisation?.secret && (myOrganisation?.secret != '' || myOrganisation?.secret != undefined) ? true : false}
                    {...formik.getFieldProps("secret")}
                    className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                    readOnly={isPasswordPopupOpen}
                    onFocus={isPasswordValid ? undefined : openPopup}
                  />
                  {formik.touched.secret && formik.errors.secret && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block fs-8">
                        {formik.errors.secret}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-form-label required fw-bold fs-16 text-dark mw-300px mw-xxl-400px">
                  Your Autotask Zone
                </label>
                <div className="fv-row col-md-8">
                  <input
                    type="text"
                    disabled={true}
                    value={myOrganisation?.zoneInfo?.webUrl}
                    // {...formik.getFieldProps('user_name')}
                    className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                  />
                </div>
              </div>

              <div className="row mb-6 align-items-start">
                <label className="col-form-label required fw-bold fs-16 text-dark mw-300px mw-xxl-400px">
                  Subdomain
                </label>

                <div className="fv-row col-md-8 position-relative">
                  <input
                    type="text"
                    disabled={true}
                    className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                    id="orgSubdomain"
                    value={orgSubdomain}
                    onChange={handleSubdomainChange}
                    onBlur={handleSubdomainBlur}
                  />
                  <span
                    className="position-absolute top-50 translate-middle-y end-0 me-8 fs-16"
                    style={{ marginTop: `${orgSubdomainError ? "-12px" : ""}` }}
                  >
                    .proxuma.app
                  </span>

                  {orgSubdomainError && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-row col-md-8 fv-help-block fs-8">
                        {orgSubdomainError}
                      </div>
                    </div>
                  )}
                </div>
              </div>

      

{/* 
              <div className="row mb-6">
                <label className="col-form-label required fw-bold fs-16 text-dark mw-300px mw-xxl-400px">
                  Address
                </label>
                <div className="fv-row col-md-8">
                  <input
                    type="text"
                    className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                    {...formik.getFieldProps("address")}
                  />
                  {formik.touched.address && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block fs-8">
                        {formik.errors.address}
                      </div>
                    </div>
                  )}
                </div>
              </div> */}

{/* Tax id type  */}
{/* Tax id type */}
{selectedCountry && countryData?.data && (
  <div className="row mb-6">
    <label className={`col-form-label ${RequiredFeild ? 'required' : ''} fw-bold fs-16 text-dark mw-300px mw-xxl-400px`}>
      Tax Id Type
    </label>
    <div className="fv-row col-md-8">
      <select
        className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
        {...formik.getFieldProps('Country_type')}
        onChange={(e) => {
          setSelectedTaxIdType(e.target.value);
    formik.setFieldValue('Country_type', e.target.value);

        }}
      >
    {!RequiredFeild && <option value=""></option>}

        {/* <option value="">Select Tax Id Type</option> */}
        {countryData?.data
          .filter((country) => country.id === selectedCountry)
          .map((country) => (
            country.type.map((type) => (
              <option key={type} value={type}>
                {`${country.id.replace(/_/g, ' ').toUpperCase()} ${ country.id === 'GB' && type === 'eu_vat' ? 'EU' : ''} ${type.split('_')[1].toUpperCase()} - ${country.label}`}
              </option>
            ))
          ))}
      </select>
      {formik.touched.Country_type && formik.errors.Country_type && (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block fs-8">
            {formik.errors.Country_type}
          </div>
        </div>
      )}
    </div>
  </div>
)}



              {/* VAT number */}
              <div className="row mb-6">
                <label className={`col-form-label ${RequiredFeild ? 'required' : ''} fw-bold fs-16 text-dark mw-300px mw-xxl-400px`}>
              VAT Identification Number
                </label>
                <div className="fv-row col-md-8">
                  <input
                    type="text"
                    className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                    {...formik.getFieldProps("vat_number")}
                  />
                  {formik.touched.vat_number && formik.errors.vat_number && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block fs-8">
                        {formik.errors.vat_number}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* changes by me  */}

              {!formik.values.default_billing_address && (
                <>
                  <div className="row mb-6">
                    <label className="col-form-label required fw-bold fs-16 text-dark mw-300px mw-xxl-400px">
                      Billing Name
                    </label>
                    <div className="fv-row col-md-8">
                      <input
                        type="text"
                        className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                        
                        {...formik.getFieldProps("billing_name")}
                      />
                      {formik.touched.billing_name && formik.errors.billing_name && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block fs-8">
                              {formik.errors.billing_name}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="row mb-6">
                    <label className="col-form-label required fw-bold fs-16 text-dark mw-300px mw-xxl-400px">
                      Billing Address
                    </label>
                    <div className="fv-row col-md-8">
                      <input
                        type="text"
                        className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                        {...formik.getFieldProps("billing_address")}
                      />
                      {formik.touched.billing_address && formik.errors.billing_address && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block fs-8">
                              {formik.errors.billing_address} 
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="row mb-6">
                    <label className="col-form-label required fw-bold fs-16 text-dark mw-300px mw-xxl-400px">
                      Billing Zipcode
                    </label>
                    <div className="fv-row col-md-8">
                      <input
                        type="text"
                        className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                        {...formik.getFieldProps("billing_zipcode")}
                      />
                      {formik.touched.billing_zipcode &&
                        formik.errors.billing_zipcode && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block fs-8">
                              {formik.errors.billing_zipcode}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </>
              )}

              {/* <div className='row mb-6'>
                        <label className='col-form-label required fw-bold fs-16 text-dark mw-300px mw-xxl-400px'>
                          Autotask integration code
                        </label>
                        <div className='fv-row col-md-8'>
                        <input
                          type='text'
                          {...formik.getFieldProps('integration_code')}
                          className='form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor'
                        />
                        {formik.touched.integration_code && formik.errors.integration_code && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block fs-8'>
                              {formik.errors.integration_code}
                            </div>
                          </div>
                        )}
                        </div>
                    </div> */}

              <div className="row mb-6">
              <label className="col-form-label fw-bold fs-16 text-dark mw-300px mw-xxl-400px d-inline-flex">
                  Status
                  <div className="h-24px w-24px d-flex align-items-center">
                    <OverlayTrigger
                      trigger="click"
                      rootClose
                      placement="bottom-end"
                      overlay={renderStatusTooltip}
                    >
                      <button className="btn p-0 text-center rounded-3px border lh-1 border-primary text-primary fs-6 w-10px color-white infoBtn ms-2" type="button">i</button>
                    </OverlayTrigger>
                  </div>
                </label>
                <div className="d-flex fv-row fv-row col-md-8">
                  <div className="col-lg-8 d-flex align-items-center">
                    <div className="form-check form-check-dark form-switch fv-row">
                      <input
                        className="form-check-input w-45px h-30px"
                        type="checkbox"
                        id="is_active"
                        checked={
                          ActiveStatus === null
                            ? myOrganisation?.is_active
                            : ActiveStatus
                        }
                        onClick={() => {
                          updateStatus({
                            is_active:
                              ActiveStatus === null
                                ? !myOrganisation?.is_active
                                : !ActiveStatus,
                          });
                        }}
                      ></input>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-form-label fw-bold fs-16 text-dark mw-300px mw-xxl-400px">
                  <div className="fw-bolder text-gray-800">
                  Use Company Address As Billing Address​
                  </div>
                </label>
                <div className="d-flex fv-row fv-row col-md-8">
                  <div className="col-lg-8 d-flex align-items-center">
                    <div className="form-check form-check-dark form-switch fv-row">
                <input
                  className="form-check-input me-2 w-45px h-30px" 
                  {...formik.getFieldProps("default_billing_address")}
                  name="default_billing_address"
                  type="checkbox"
                  checked={  formik.values.default_billing_address}
                />
                </div>
                </div>
                </div>
              </div>
            </div>
          </form>
          {/*  Passowrd confirm*/}

          {isPasswordPopupOpen && (
            <div
              id="kt_content_container"
              className={clsx(
                classes.contentContainer.join(" "),
                "container-fluid position-relative px-0 overflow-auto"
              )}
            >
              <div
                className={`modal fade ${
                  isPasswordPopupOpen ? "show d-block" : "d-none"
                }`}
                tab-index="-1"
                aria-labelledby="exampleModalSizeLg"
                role="dialog"
                style={{ backgroundColor: "rgba(0,0,0,0.3)" }}
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title fs-18">
                        Confirm your password
                      </h5>
                    </div>
                    <div className="modal-body">
                      <form
                        className="form w-100"
                        onSubmit={formikpassword.handleSubmit}
                        noValidate
                        id="kt_login_signin_form"
                      >
                        {/* begin :: Message */}
                        {formikpassword.status ? (
                          <div className="mb-8 alert alert-danger p-2 px-3">
                            <div className="alert-text font-weight-bold fs-14">
                              {formikpassword.status}
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        {/* End :: Message */}

                        {/* begin :: Message */}
                        {success ? (
                          <div className="mb-8 alert alert-success p-2 px-3">
                            <div className="alert-text font-weight-bold fs-14">
                              {success}
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        {/* End :: Message */}

                        {/* begin::Form group */}
                        <div className="fv-row mb-8">
                          <label className="fs-16 text-dark fw-bold pb-1">
                            Enter your password:{" "}
                          </label>
                          <input
                            {...formikpassword.getFieldProps("password")}
                            className={clsx(
                              "form-control form-control-solid h-32px px-4 rounded-4px py-0 bg-white border-graybor fw-normal fs-16 text-dark",
                              {
                                "is-invalid":
                                  formikpassword.touched.password &&
                                  formikpassword.errors.password,
                              },
                              {
                                "is-valid":
                                  formikpassword.touched.password &&
                                  !formikpassword.errors.password,
                              }
                            )}
                            type="password"
                            name="password"
                            autoComplete="off"
                            value={formikpassword.values.password}
                            onChange={clearErrorOnChange}
                            onBlur={formikpassword.handleBlur}
                          />
                          {formikpassword.touched.password &&
                            formikpassword.errors.password && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                  <span role="alert" className="fs-16">
                                    {formikpassword.errors.password}
                                  </span>
                                </div>
                              </div>
                            )}
                        </div>

                        {/* End::Form group */}

                        <div className="">
                          {/* begin::Action */}
                          <button
                            type="submit"
                            id="kt_sign_in_submit"
                            className="btn btn-primary text-left h-32px p-0 px-5 me-3" /* text-toggle-hover */
                            disabled={formikpassword.isSubmitting}
                          >
                            <span className="indicator-label">
                              <small className="fs-100">Save</small>
                            </span>
                          </button>
                          &nbsp;
                          <button
                            type="reset"
                            onClick={() => cancelpassword(true)}
                            className="btn btn-secondary text-left h-32px p-0 px-5"
                            data-kt-role-modal-action="close"
                          >
                            Cancel
                          </button>
                          {/* end::Action */}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              {/* END :: Passowrd confirm */}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default MyOrganisationpage;
