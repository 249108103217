import { alert, confirm } from "@mobiscroll/react";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { KTSVG } from "../../../../../_metronic/helpers";
import AppointmentFilters from "../../../../components/modals/appointmentFilterButton/AppointmentFilters";
import { useAppSelector } from "../../../../hook";
import { setLicenseKey } from "../../../auth/core/_requests";
import FilterTooltip from "../FilterTooltip";


type Props = {
  data?: Array<any>;
  isLoading: boolean;
  showdropdown: string;
  title: string;
  currentClick: string;
  inputText: string;
  checked_ids: Array<any>;
  imgpath: string;
  hideSelection?: (e: any) => void | undefined
  storeFilterValue?: (e: any) => void | undefined
  handleChange?: (e: any) => void | undefined
  handleDateChange?: (option: string, montvalue: number) => void | undefined
  handleViewChange?: (option: boolean) => void | undefined
  inputHandler?: (e: any) => void | undefined
  selectAll?: (e: any) => void | undefined
  selectedName: any,
  tooltipTitle: any,
  view?: any,
  id: any,
  isOpen: any,
  toggleDropdown: any,
  filterType?:any,
  company: any,
  projectView: boolean,
  isHighlited: boolean,
  hasSelectedOption: string,
  hasMonthValue: number
};


const DropDown: FC<Props> = ({ data, isLoading, showdropdown, hideSelection, title, storeFilterValue, handleChange, handleDateChange, handleViewChange, checked_ids, currentClick, inputHandler, inputText, imgpath, selectedName, selectAll, tooltipTitle, view, id, isOpen, toggleDropdown, filterType, company, projectView, isHighlited, hasMonthValue, hasSelectedOption }) => {

  const count = useAppSelector((state) => state.counter)
  const [conditioncount, setconditioncount] = useState(0);
  const [isFilterPopupOpen, setisFilterPopupOpen] = useState<any>(false);

  const defaultMonthValue = 0
  const [selectedOption, setSelectedOption] = useState(hasSelectedOption || '');
  const [selectedViewOption, setSelectedViewOption] = useState(projectView || false);
  const [monthValue, setMonthValue] = useState(hasMonthValue || defaultMonthValue);
  const [highlight, setHighlight] = useState(isHighlited || false);

  useEffect(() => {
    if (currentClick === 'ProjectView') {
      handleViewChange && handleViewChange(true);
      // setHighlight(true);
    }
  }, [currentClick])

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    setMonthValue(defaultMonthValue)
  };

  const incrementMonth = () => {
    setMonthValue(prevValue => Math.min(prevValue + 1, 3));
  };

  const decrementMonth = () => {
    setMonthValue(prevValue => Math.max(prevValue - 1, 0));
  };

  const filteredData = data?.filter((el: any) => {
    //if no input the return the original
    if (!inputText || inputText === '') {
      return el;
    }
    //return the item which contains the user input
    else {
      return el.name.toLowerCase().includes(inputText)
    }
  })

  const openFilterPopup = useCallback(
    (e: any) => {
      let count_conditions = 0;
      if (count?.appointment_filter?.groups?.length > 0) {
        count?.appointment_filter?.groups?.map((group: any, groupIndex: number) => {
          count_conditions = count_conditions + group?.conditions?.length
        })
      }
      setconditioncount(count_conditions)
      setisFilterPopupOpen(true);
    },
    [count]
  );

  const handleLockLicense = async (res: string) => {
    confirm({
      title:
        "You don't have a license. Do you want to send a license request?",
      okText: "Yes",
      cancelText: "No",
      callback: async function (event) {
        if (event) {
          try {
            await setLicenseKey(res);
            alert({ message: "License Request Sent!" });
          } catch (error) {
            alert({
              message: "Error Sending License Request', 'An error occurred while sending the license request."
            });
          }
        }
      },
    });

  };

  const handleClose = (e) => {
    if (id === 'ProjectStartDate' || id === 'ProjectEndDate') { 
      if (selectedOption && (monthValue || monthValue === 0)) {
        setHighlight(true);
        handleDateChange(selectedOption, monthValue);
        toggleDropdown(id, "no")
      }
    } else if (id === 'ProjectView') {
      setHighlight(true);
      handleViewChange(false); 
       if(filterType === "common"){
         id = "common-"+id;
       }
       toggleDropdown(id, "no") 
    }
    else { setHighlight(false); closeDropdown(e); }

  }

  const handleHideSelection = (e) => {
    setHighlight(false);
    hideSelection(e);
  }

const closeDropdown = (e: any) => {
  storeFilterValue(e.target.value)
  if(filterType === "common"){
    id = "common-"+id;
  }
  toggleDropdown(id, "no")
}
  return (
    <>
      <div className={`dropDown me-4 ${showdropdown}`}>
        <>
          <button id={`${id}-ID`} className={`btn btn-outline btn-outline-primary fw-normal border-primary rounded-pill px-4 py-0 h-32px fs-16 d-inline-flex align-items-center position-relative mainTootltiphover ${highlight ? 'show' : ''} ${(((view === 'month' || view === "year") && filterType !== "common") || (filterType === "common" && id === "Company")) ? 'disabled-filter-month-year-view' : ''}`} type="button" data-kt-menu-trigger="click" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-start" data-kt-menu-flip="bottom" onClick={() => {
            if(filterType === "common"){
              id = "common-"+id;
            }
            toggleDropdown(id)
            }}>
            <KTSVG path={imgpath} className='me-2' svgClassName="w-auto h-auto mw-14px mh-14px" stylecode={{ color: "inherit" }} />
            <span>{title}</span> &nbsp;
            {checked_ids?.length > 0 && <span className="badge rounded-pill badge-primary bg-yellow text-dark fs-16 px-3 top-0 ms-1 d-inline-block min-h-24px min-w-24px">+{checked_ids.length}</span>}

            <div className="closeDiv ms-2 me-n4 triggerTooltip position-relative">
              <button value={id} onClick={handleHideSelection} className='p-0 bg-transparent border-0 w-20px h-auto'>
                <KTSVG path="/media/icons/duotune/extra/close.svg" className='text-primary pe-none' svgClassName="w-10px h-auto" />
              </button>
              {/* Code start - Set this in component */}
              <FilterTooltip />
              {/* Code end */}
            </div>

            {id === 'ProjectStartDate' || id === 'ProjectEndDate' || id === 'ProjectView' ?
              (<div className="tooltip bs-tooltip-bottom cusTooltip top-100 start-50 translate-middle-x">
                <div className="tooltip-arrow position-absolute start-50 translate-middle-x"></div>
                <div className="tooltip-inner min-w-200px mh-400px overflow-hidden"> {(selectedOption) ? `${selectedOption.replace("is", "")} " ${monthValue}"` : tooltipTitle}
                </div>
              </div>) : (
              <div className="tooltip bs-tooltip-bottom cusTooltip top-100 start-50 translate-middle-x">
                <div className="tooltip-arrow position-absolute start-50 translate-middle-x"></div>
                <div className="tooltip-inner min-w-200px mh-400px overflow-hidden"> {selectedName?.length > 0 ? (selectedName?.join(', ')) : (tooltipTitle)}
                </div>
              </div>
            )}

          </button>

          {isOpen && (
            <div className={`menu menu-sub menu-sub-dropdown bg-white px-0 pt-3 pb-0 w-200px show`} style={{ animation: "none", position: 'absolute' }} data-kt-menu="true">
              {id === 'ProjectStartDate' || id === 'ProjectEndDate' || id === 'ProjectView' ? '' : (
                <div className="searchBox d-flex align-items-center border-bottom mb-3 px-3">
                  <KTSVG path="/media/icons/duotune/extra/search.svg" className='me-2 opacity-50' svgClassName="w-12px h-12px" stylecode={{ color: "#292D32" }} />
                  <input type="text" value={inputText} className="form-control form-control-flush p-0 fw-normal fs-14 h-30px pe-2" id={`${id}-search`} name="search" onChange={inputHandler} placeholder="Search..." autoComplete="off" ></input>
                  <div className="form-check form-check-custom d-flex align-items-center justify-content-between">
                    {filteredData && filteredData?.length > 0 &&
                      <input className="form-check-input h-15px w-15px" style={{ borderRadius: "5px" }} type="checkbox" value="" checked={checked_ids?.length != 0 && filteredData?.length != 0 && checked_ids?.length == filteredData?.length} id={id} onChange={selectAll} />
                    }
                  </div>
                </div>
              )}
              {id === 'ProjectStartDate' || id === 'ProjectEndDate' ?
                (<>
                    <div className="p-4">
                      <h5 className="fs-14 mb-4 lh-sm">From today</h5>

                      <div key={"ProjectStartDate"}>
                        {/* Onward radio start */}
                        <div className="bg-gray-200 rounded-4px form-check form-check-outline-primary form-check-outline-primary-small form-check-custom form-check-solid fs-16 flex-wrap p-3 mb-3 hideShowDiv">
                          <input
                            className="form-check-input me-2"
                            name="onward"
                            type="radio"
                            id="isOnward"
                            checked={selectedOption === 'isOnward'}
                            onChange={() => handleOptionChange('isOnward')}
                          />
                          <label htmlFor="isOnward" className="fs-14 fw-bold">Onward</label>

                          <div className="w-100 d-flex align-items-center justify-content-between mt-3 radio-hidden-div">
                            <label htmlFor="onward_month" className="text-dark fs-12 fw-normal">Month</label>
                            <div>
                              <button
                                className="w-16px h-16px border-0 bg-bg-transparent p-0 d-inline-flex align-items-center justify-content-center"
                                onClick={decrementMonth}
                              >
                                <KTSVG
                                  path="/media/icons/duotune/new-icons/minus-bold.svg"
                                  className="text-primary"
                                  svgClassName="w-8px h-auto"
                                />
                              </button>

                              <input type="text" name="" id="" value={monthValue} className="h-24px w-24px rounded-4px p-0 bg-white fw-bold text-primary fs-14 text-center mx-2" readOnly style={{ border: '1px solid #E8ECF3', outline: 'none' }} />

                              <button
                                className="w-16px h-16px border-0 bg-bg-transparent p-0 d-inline-flex align-items-center justify-content-center"
                                onClick={incrementMonth}
                              >
                                <KTSVG
                                  path="/media/icons/duotune/new-icons/plus-bold.svg"
                                  className="text-primary"
                                  svgClassName="w-12px h-auto"
                                />
                              </button>
                            </div>
                          </div>

                        </div>
                        {/* Onward radio end */}

                        {/* Backward radio start */}
                        <div className="bg-gray-200 rounded-4px form-check form-check-outline-primary form-check-outline-primary-small form-check-custom form-check-solid fs-16 flex-wrap p-3 hideShowDiv">
                          <input
                            className="form-check-input me-2"
                            name="showEvents"
                            type="radio"
                            id="isBackward"
                            checked={selectedOption === 'isBackward'}
                            onChange={() => handleOptionChange('isBackward')}
                          />
                          <label htmlFor="isBackward" className="fs-14 fw-bold">Backward</label>

                          <div className="w-100 d-flex align-items-center justify-content-between mt-3 radio-hidden-div">
                            <label htmlFor="onward_month" className="text-dark fs-12 fw-normal">Month</label>
                            <div>
                              <button
                                className="w-16px h-16px border-0 bg-bg-transparent p-0 d-inline-flex align-items-center justify-content-center"
                                onClick={decrementMonth}
                              >
                                <KTSVG
                                  path="/media/icons/duotune/new-icons/minus-bold.svg"
                                  className="text-primary"
                                  svgClassName="w-8px h-auto"
                                />
                              </button>

                              <input type="text" name="" id="" value={monthValue} className="h-24px w-24px rounded-4px p-0 bg-white fw-bold text-primary fs-14 text-center mx-2" readOnly style={{ border: '1px solid #E8ECF3', outline: 'none' }} />

                              <button
                                className="w-16px h-16px border-0 bg-bg-transparent p-0 d-inline-flex align-items-center justify-content-center"
                                onClick={incrementMonth}
                              >
                                <KTSVG
                                  path="/media/icons/duotune/new-icons/plus-bold.svg"
                                  className="text-primary"
                                  svgClassName="w-12px h-auto"
                                />
                              </button>
                            </div>
                          </div>

                        </div>
                        {/* Backward radio end */}
                      </div>

                    </div>
                  </>)
                :
                <>
                  {!isLoading && filteredData !== undefined ?
                    (<div className="chkWrapper px-3 fs-14 mh-250px overflow-auto">
                      {Array.from(filteredData).map((e: any, key) => {
                        let keylabel = (Math.random() + 1).toString(36).substring(7);
                        return <div key={key} className={`form-check form-check-custom d-flex align-items-center justify-content-between mb-3 `}>
                          <div className="d-inline-flex align-items-center">
                            {(id === 'People' || id === 'project_lead') &&
                              (
                                (e?.has_license == false && e?.resource_only == false) ?
                                  <button
                                    className={`bg-transparent border-0 p-0 text-ellipsis min-w-14px me-2`}
                                    onClick={() => handleLockLicense(e.database_id)}
                                  >
                                    <KTSVG
                                      path='/media/icons/duotune/new-icons/lock-icon.svg'
                                      className="text-dark min-w-14px d-flex align-items-center justify-content-center cursor-pointer"
                                      svgClassName="w-14px h-auto" />
                                  </button>
                                  : ''
                              )}
                            <label htmlFor={keylabel} className={`form-check-label fw-semibold text-black ms-0 pe-2 d-inline-flex align-items-center ${(id === 'People' || id === 'project_lead') && ((e?.has_license == false && e?.resource_only == false) ? 'isoffline pe-none' : '')}`}>
                              <span>{e.name} </span>
                            </label>
                          </div>
                          <span className={`d-inline-flex align-items-center`}>
                            {id === 'Type' && e.name === 'Appointment' && checked_ids.includes('appointments') &&
                              <a
                                className="d-flex align-items-center text-black fw-semibold text-hover-primary text-nowrap me-2"
                                href="#"
                                onClick={openFilterPopup}
                              >
                                <KTSVG
                                  path="/media/icons/duotune/general/gen055.svg"
                                  stylecode={{ color: "#164387" }}
                                  className="w-20px me-0"
                                  svgClassName="w-16px h-auto"
                                />
                              </a>
                            }
                            <input className={`form-check-input h-15px w-15px checkBoxClass`} style={{ borderRadius: "5px" }} type="checkbox" checked={checked_ids?.includes(e.id)} onChange={handleChange} data-id={id} value={`${e.id}`} id={keylabel} name={e.name} />
                          </span>
                        </div>;
                      })}
                    </div>) : id !== "ProjectView" && (<div>
                      <span className="ms-7">No data avaialble</span>
                    </div>)
                  }
                </>
              }
              <div className="w-100">
                {checked_ids?.length === 0 ? (<OverlayTrigger
                  key="copy-to-clipboard"
                  placement="bottom"
                  trigger={['hover', 'focus']}
                  overlay={<Tooltip id="tooltip-copy-to-clipboard" className="position-absolute"><span className="pe-2">Please select at least one item</span></Tooltip>}
                >
                  <button type="button" value={id} className="btn w-100 border-gray-101 p-3 fs-14" >Done</button>
                </OverlayTrigger>) :
                  id !== "ProjectView" && (<OverlayTrigger
                    key="copy-to-clipboard"
                    placement="bottom"
                    trigger={['hover', 'focus']}
                    overlay={<Tooltip id="tooltip-copy-to-clipboard" className="position-absolute"><span className="pe-2">Please select at least one option </span></Tooltip>}
                  >
                    <button type="button" value={id} className="btn w-100 border-gray-101 p-3 fs-14" onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleClose(e)}>Done</button>
                  </OverlayTrigger>
                )}
              </div>
            </div>)}
        </>
      </div>

      <AppointmentFilters
        conditioncount={conditioncount}
        setconditioncount={setconditioncount}
        isFilterPopupOpen={isFilterPopupOpen}
        setisFilterPopupOpen={setisFilterPopupOpen}
      />
    </>
  )
};
export { DropDown };
