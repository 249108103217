import { FC } from "react";
import { KTSVG, toApiAbsoluteUrl } from "../../../../../_metronic/helpers";
import { setLicenseKey } from "../../../auth/core/_requests";
import { alert, confirm } from "@mobiscroll/react";
// import withReactContent from 'sweetalert2-react-content';
const handleButtonClick = async (res: string) => {
  confirm({
      title:
      "You don't have a license. Do you want to send a license request?",
      okText: "Yes",
      cancelText: "No",
      callback: async function (event) {
      if (event) {
          try {
              await setLicenseKey(res);
              alert({message: "License Request Sent!"});
          } catch (error) {
              alert({
                  message: "Error Sending License Request', 'An error occurred while sending the license request."
                });
          }
      }
      },
  });

}; 
const RenderMyResource: FC<any> = ({ resource }) => {

  let userImage = ()=> {
    if(resource?.has_license == false && resource?.resource_only == false){
      return (<><span className="rounded-circle bg-graydarkBg w-40px h-40px d-inline-flex align-items-center justify-content-center text-dark fs-16 fw-bold lh-sm" onClick={() => handleButtonClick(resource.database_id)}>
        <KTSVG path='/media/icons/duotune/new-icons/lock-icon.svg' className="text-dark w-100 h-100 d-flex align-items-center justify-content-center cursor-pointer" svgClassName="w-18px h-auto" />
        </span></>)
    }else{
        if(resource.img && resource.img != "public/avatar.png"){
            return (<><img
              className="w-40px h-auto me-3 rounded-circle"
              src={toApiAbsoluteUrl(`${resource.img}`)}
              alt="Avatar"
            /></>)
        } else{
            return (<><span
            style={{ textTransform: "uppercase" }}
            className="rounded-circle bg-graydarkBg w-40px h-40px d-inline-flex align-items-center justify-content-center text-dark fs-16 fw-bold lh-sm">
            {resource?.firstName.substring(0, 1)}
            {resource?.lastName.substring(0, 1)}
            {/* {resource?.firstName} */}
          </span></>)
        }
    }
  }
  
  return (
    <>
      <div className={`d-flex align-items-center`}>
        {/*  */}
        <div className="d-flex align-items-center timelineUser flex-wrap">
        {userImage()}

          <b className="ps-3">
            {resource?.firstName} {resource?.lastName}
          </b>
        </div>
      </div>
    </>
  );
};

export default RenderMyResource;
