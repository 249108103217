/** @jsxRuntime classic */
/** @jsx createElement */
import { createElement } from '../../../react/renderer';
import { CalendarDay } from '../../../react/shared/calendar-day';
import { UNDEFINED } from '../../util/misc';
import { CalendarWeekDays } from './calendar-weekdays';
import { IMonthViewProps, MonthViewBase } from './month-view';

export function template(s: IMonthViewProps, inst: MonthViewBase): any {
  const showWeekNumbers = s.showWeekNumbers;
  const calWeekDays = s.showWeekDays ? (
    <CalendarWeekDays
      dayNamesShort={s.dayNamesShort}
      firstDay={s.firstDay}
      rtl={inst._rtl}
      showWeekNumbers={showWeekNumbers}
      theme={inst._theme}
    />
  ) : null;
  return (
    <div
      aria-hidden={s.isActive ? UNDEFINED : 'true'}
      className={'mbsc-calendar-table mbsc-flex-col mbsc-flex-1-1' + (s.isActive ? ' mbsc-calendar-table-active' : '')}
    >
      {calWeekDays}
      {inst._rows.map((row: any, i: number) => {
        const weekNr = showWeekNumbers ? inst._getWeekNr(s, row[0].date) : '';
        return (
          <div className={'mbsc-calendar-row mbsc-flex mbsc-flex-1-0'} key={i} style={{ minHeight: inst._rowHeights[i] }}>
            {showWeekNumbers && (
              <div className={'mbsc-calendar-cell mbsc-flex-none mbsc-calendar-day mbsc-calendar-week-nr' + inst._theme}>
                <div aria-hidden="true">{weekNr}</div>
                <div className="mbsc-hidden-content">{s.weekText!.replace('{count}', weekNr)}</div>
              </div>
            )}
            {row.map((cell: any, j: number) => (
              <CalendarDay
                active={cell.display && inst._isActive(cell.date)}
                amText={s.amText}
                clickToCreate={s.clickToCreate}
                colors={cell.colors}
                date={cell.date}
                day={cell.day}
                disabled={inst._isInvalid(cell.date)}
                display={cell.display}
                dataTimezone={s.dataTimezone}
                displayTimezone={s.displayTimezone}
                dragData={s.dragData}
                dragToCreate={s.dragToCreate}
                dragToResize={s.dragToResize}
                dragToMove={s.dragToMove}
                eventText={s.eventText}
                events={cell.events}
                eventsText={s.eventsText}
                exclusiveEndDates={s.exclusiveEndDates}
                firstDay={s.firstDay}
                hasMarks={s.hasMarks}
                hoverEnd={s.hoverEnd}
                hoverStart={s.hoverStart}
                isActiveMonth={s.isActive}
                isPicker={s.isPicker}
                key={cell.date}
                labels={cell.labels}
                pmText={s.pmText}
                marks={cell.marks}
                month={cell.month}
                monthShort={cell.monthShort}
                onDayClick={s.onDayClick}
                onDayDoubleClick={s.onDayDoubleClick}
                onDayRightClick={s.onDayRightClick}
                onLabelClick={s.onLabelClick}
                onLabelDoubleClick={s.onLabelDoubleClick}
                onLabelRightClick={s.onLabelRightClick}
                onLabelHoverIn={s.onLabelHoverIn}
                onLabelHoverOut={s.onLabelHoverOut}
                onLabelDelete={s.onLabelDelete}
                onLabelUpdateStart={s.onLabelUpdateStart}
                onLabelUpdateMove={s.onLabelUpdateMove}
                onLabelUpdateEnd={s.onLabelUpdateEnd}
                onLabelUpdateModeOn={s.onLabelUpdateModeOn}
                onLabelUpdateModeOff={s.onLabelUpdateModeOff}
                outer={cell.outer}
                renderDay={s.renderDay}
                renderDayContent={s.renderDayContent}
                renderLabel={s.renderLabel}
                renderLabelContent={s.renderLabelContent}
                rangeEnd={s.rangeEnd}
                rangeStart={s.rangeStart}
                resourcesMap={s.resourcesMap}
                selectedEventsMap={s.selectedEventsMap}
                rtl={s.rtl}
                showEventTooltip={s.showEventTooltip}
                selected={inst._isSelected(cell.date)}
                text={cell.text}
                theme={s.theme}
                timeFormat={s.timeFormat}
                timezonePlugin={s.timezonePlugin}
                todayText={s.todayText}
                type="day"
                year={cell.year}
                // In case of Preact we need to force update by always passing a new object,
                // otherwise sometimes DOM elements will mix up
                // update={isPreact ? {} : 0}
                onHoverIn={s.onDayHoverIn}
                onHoverOut={s.onDayHoverOut}
              />
            ))}
          </div>
        );
      })}
    </div>
  );
}
/** @hidden */
export class MonthView extends MonthViewBase {
  protected _template(s: IMonthViewProps): any {
    return template(s, this);
  }
}
