/** @jsxRuntime classic */
/** @jsx createElement */
import { createElement } from '../../../react/renderer';
import { ARRAY7 } from '../../util/misc';

/** @hidden */
export interface ICalendarWeekDaysProps {
  dayNamesShort: string[];
  firstDay: number;
  hasScroll?: boolean;
  hidden?: boolean;
  rtl: string;
  theme: string;
  showWeekNumbers?: boolean;
}

/** @hidden */
export const CalendarWeekDays = (props: ICalendarWeekDaysProps) => {
  const { firstDay, hidden, rtl, theme, dayNamesShort, showWeekNumbers, hasScroll } = props;
  return (
    <div aria-hidden="true" className={'mbsc-calendar-week-days mbsc-flex' + (hidden ? ' mbsc-hidden' : '')}>
      {showWeekNumbers && <div className={'mbsc-calendar-week-day mbsc-flex-none mbsc-calendar-week-nr' + theme + rtl} />}
      {ARRAY7.map((x, i) => (
        <div className={'mbsc-calendar-week-day mbsc-flex-1-0-0' + theme + rtl} key={i}>
          {dayNamesShort[(i + firstDay) % 7]}
        </div>
      ))}
      {hasScroll && <div className="mbsc-schedule-fake-scroll-y" />}
    </div>
  );
};
