/** @jsxRuntime classic */
/** @jsx createElement */
import { CalendarBase, MbscCalendarOptions } from './calendar';
import { InstanceServiceBase } from '../../shared/instance-service';
import { createElement } from '../../../react/renderer';
import { CalendarNav, CalendarNext, CalendarPrev, CalendarToday } from '../../../react/shared/calendar-header';
import { CalendarView } from '../../../react/shared/calendar-view';

import './calendar.scss';

export { CalendarNext, CalendarPrev, CalendarToday, CalendarNav };

export function template(s: MbscCalendarOptions, inst: CalendarBase): any {
  return (
    <CalendarView
      ref={inst._setCal}
      refDate={s.refDate}
      activeDate={s.active}
      amText={s.amText}
      cssClass={inst._className + ' mbsc-flex-1-1 mbsc-calendar-' + s.display}
      calendarScroll={s.calendarScroll}
      calendarType={s.calendarType}
      colors={s.colors}
      context={s.context}
      dataTimezone={s.dataTimezone}
      displayTimezone={s.displayTimezone}
      timezonePlugin={s.timezonePlugin}
      downIcon={s.downIcon}
      exclusiveEndDates={s.exclusiveEndDates}
      hoverEnd={s.hoverEnd}
      hoverStart={s.hoverStart}
      invalid={s.invalid}
      instanceService={inst._instanceService}
      isPicker={true}
      labels={s.labels}
      marked={s.marked}
      max={s.max}
      min={s.min}
      mousewheel={s.mousewheel}
      navService={inst._navService}
      nextIconH={s.nextIconH}
      nextIconV={s.nextIconV}
      nextPageText={s.nextPageText}
      noOuterChange={s.selectRange}
      onActiveChange={inst._onActiveChange}
      onCellHoverIn={s.onCellHoverIn}
      onCellHoverOut={s.onCellHoverOut}
      onDayClick={inst._onDayClick}
      onDayHoverIn={s.onDayHoverIn}
      onDayHoverOut={s.onDayHoverOut}
      onLabelClick={s.onLabelClick}
      onPageChange={s.onPageChange}
      onPageLoaded={s.onPageLoaded}
      onPageLoading={s.onPageLoading}
      onTodayClick={inst._onTodayClick}
      pages={s.pages}
      pmText={s.pmText}
      prevIconH={s.prevIconH}
      prevIconV={s.prevIconV}
      prevPageText={s.prevPageText}
      renderDay={s.renderDay}
      renderDayContent={s.renderDayContent}
      renderHeader={s.renderCalendarHeader}
      rangeEnd={s.rangeEnd}
      rangeStart={s.rangeStart}
      rtl={s.rtl}
      selectedDates={inst._tempValueRep}
      selectView={s.selectView}
      showCalendar={true}
      showControls={s.showControls}
      showOuterDays={s.showOuterDays}
      showToday={false}
      showWeekNumbers={s.showWeekNumbers}
      size={s.size}
      theme={s.theme}
      themeVariant={s.themeVariant}
      update={inst._update}
      upIcon={s.upIcon}
      valid={s.valid}
      weeks={s.weeks}
      width={s.width}
      // Calendar system
      getDate={s.getDate}
      getDay={s.getDay}
      getMaxDayOfMonth={s.getMaxDayOfMonth}
      getMonth={s.getMonth}
      getWeekNumber={s.getWeekNumber}
      getYear={s.getYear}
      // Localization
      dateFormat={s.dateFormat}
      dayNames={s.dayNames}
      dayNamesMin={s.dayNamesMin}
      dayNamesShort={s.dayNamesShort}
      eventText={s.eventText}
      eventsText={s.eventsText}
      firstDay={s.firstDay}
      fromText={s.fromText}
      monthNames={s.monthNames}
      monthNamesShort={s.monthNamesShort}
      moreEventsPluralText={s.moreEventsPluralText}
      moreEventsText={s.moreEventsText}
      todayText={s.todayText}
      toText={s.toText}
      weekText={s.weekText}
      yearSuffix={s.yearSuffix}
    />
  );
}

/**
 * The Calendar component.
 *
 * Usage:
 *
 * ```
 * <Calendar />
 * ```
 */
export class Calendar extends CalendarBase {
  // tslint:disable-next-line: variable-name
  public _instanceService: InstanceServiceBase = new InstanceServiceBase();

  protected _template(s: MbscCalendarOptions): any {
    return template(s, this);
  }
}
