import { useState, FC, useEffect } from "react";
import { Loading } from "../../../components/loading/Loading";
import { useNavigate } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { RegistrationDetail, initialRegistrationDetail } from "./_model";
import { useFormik } from "formik";
import { alert } from "@mobiscroll/react";
import { registrationDetailSchema } from "./RegistrationSchema"
import Select from "react-select";
import axios from "axios";

const RegistrationPage: FC = () => {
  const datacenterArray = [
    {
      value: "frankfurt",
      label: "Frankfurt",
    },
  ];
  //
  const navigate = useNavigate();
  let registerForm = JSON.parse(sessionStorage.getItem("register") || "{}");
  let registrationDetailData = registerForm
    ? registerForm
    : initialRegistrationDetail;

  const [loading, setLoading] = useState(false);
  const [orgCountry, setorgCountry] = useState(registerForm?.country || "NL");
  const [orgDatacenter, setorgDatacenter] = useState(registerForm?.datacenter || "frankfurt");

  const onChangeCountry = (e: any) => {
    let tcountry = e[e.length - 1];
    const countryval = tcountry ? tcountry.value : "";
    setorgCountry(countryval);
  };
  const onChangeDatacenter = (e: any) => {
    let tdatacenter = e[e.length - 1];
    const datacenterval = tdatacenter ? tdatacenter.value : "";
    setorgDatacenter(datacenterval);
  };

  const formik = useFormik<RegistrationDetail>({
    enableReinitialize: false,
    initialValues: registrationDetailData,
    validationSchema: registrationDetailSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      setLoading(true);
      setSubmitting(true);
      setLoading(true);
      try {
        values.country = orgCountry;
        values.datacenter = orgDatacenter;
        setSubmitting(true);
        setLoading(false);
        sessionStorage.setItem("register", JSON.stringify(values));
        navigate("/subscription/");
      } catch (ex) {
        alert({
          message: "Something went wrong",
          callback: function (result) {
            window.location.reload();
          },
        });
        navigate("/register");       
        } finally {

      }
    },
  });
  /*  for get the country */
  const [countryData, setCountryData] = useState([]);


  /* fetch country  */
  const API_URL = process.env.REACT_APP_API_URL;

  const fetchCountry = async () => {
    try {
      const response = await axios.get(`${API_URL}countries/`);
      setCountryData(response?.data);
      return response?.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };
  useEffect(() => {
    fetchCountry();
  }, []);

  const countryOptions = countryData?.data?.map((country) => ({
    label: country.label,
    value: country.id,
  }));


  return (
    <>
      {/* Added logo and sidebar */}
      <div className="d-flex flex-column flex-root login" id="kt_app_root">
        <div className="d-flex flex-column flex-lg-row flex-column-fluid">
          <div
            className="d-flex align-items-end justify-content-center w-40 bgi-size-cover bgi-position-center p-10 p-sm-15 loginLeft position-relative"
            style={{
              backgroundImage: `url(${toAbsoluteUrl(
                "/media/misc/login-bg.jpg"
              )})`,
            }}
          >
            {/* <div className="logoName text-white fs-1">Proxuma</div> */}
            <div className="position-absolute top-50 start-50 translate-middle">
              <img
                alt="Proxuma"
                className="h-auto logo w-250px"
                src={toAbsoluteUrl("/media/logos/horizontal-dark-bg.svg")}
              />
            </div>
          </div>
          <div className="position-absolute start-0 bottom-0 fs-16 text-white ms-4 ps-4 mb-10 pb-10 fw-bold">
            {process.env.REACT_APP_RELEASE_VERSION}
          </div>

          <div className="d-flex w-60 p-0 loginForm bg-body height100 overflow-hidden">
            <div className="d-flex p-10 w-100 h-100 overflow-auto">
              {/* Registration Form */}
              {/* <div className='card h-100 py-6 px-7 bg-transparent'>
                    <div id='kt_register_details' className="h-100 bg-white rounded"> */}
              <div className="w-100 w-sm-800px mt-auto mb-auto mx-auto">
                <div>
                  <form
                    onSubmit={formik.handleSubmit}
                    noValidate
                    // className='form d-flex flex-column h-100 overflow-auto'
                    className="form w-100"
                    id="kt_login_signin_form"
                  >
                    <div className="mb-8">
                      <h1 className="text-dark fw-bold mb-0 h2 fs-1 fs-ls-30">
                        Registration Page
                      </h1>
                    </div>
                    <div className="card-body row">
                      <div className="fv-row mb-8 col-12">
                        <label className="col-form-label required fw-bold fs-16 mw-300px mw-xxl-400px fs-ls-14">
                          Company name
                        </label>

                        <div className="fv-row">
                          <input
                            type="text"
                            className="form-control form-control-solid fs-16 h-45px p-0 px-4 h-ls-48 fs-ls-14"
                            {...formik.getFieldProps("company_name")}
                          />
                          {formik.touched.company_name &&
                            formik.errors.company_name && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block fs-14">
                                  {formik.errors.company_name}
                                </div>
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="fv-row mb-8 col-12">
                        <label className="col-form-label required fw-bold fs-16 mw-300px mw-xxl-400px fs-ls-14">
                          Subdomain
                        </label>

                        <div className="fv-row position-relative">
                          <input
                            type="text"
                            className="form-control form-control-solid fs-16 h-45px p-0 px-4 h-ls-48 fs-ls-14 pe-34"
                            id="orgSubdomain"
                            {...formik.getFieldProps("subdomain")}

                          />
                          <span className="position-absolute top-50 translate-middle-y end-0 me-4 fs-16">
                            .proxuma.app
                          </span>
                        </div>
                        {formik.touched.subdomain &&
                          formik.errors.subdomain && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block fs-14">
                                {formik.errors.subdomain}
                              </div>
                            </div>
                          )}
                      </div>

                      <div className="fv-row mb-8 col-6">
                        <label className="col-form-label required fw-bold fs-16 mw-300px mw-xxl-400px fs-ls-14">
                          First name
                        </label>

                        <div className="fv-row">
                          <input
                            type="text"
                            className="form-control form-control-solid fs-16 h-45px p-0 px-4 h-ls-48 fs-ls-14"
                            {...formik.getFieldProps("first_name")}
                          />
                          {formik.touched.first_name &&
                            formik.errors.first_name && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block fs-14">
                                  {formik.errors.first_name}
                                </div>
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="fv-row mb-8 col-6">
                        <label className="col-form-label required fw-bold fs-16 mw-300px mw-xxl-400px fs-ls-14">
                          Last name
                        </label>

                        <div className="fv-row">
                          <input
                            type="text"
                            className="form-control form-control-solid fs-16 h-45px p-0 px-4 h-ls-48 fs-ls-14"
                            {...formik.getFieldProps("last_name")}
                          />
                          {formik.touched.last_name && formik.errors.last_name && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block fs-14">
                                {formik.errors.last_name}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="fv-row mb-8 col-6">
                        <label className="col-form-label required fw-bold fs-16 mw-300px mw-xxl-400px fs-ls-14">
                          Email address
                        </label>

                        <div className="fv-row">
                          <input
                            type="text"
                            className="form-control form-control-solid fs-16 h-45px p-0 px-4 h-ls-48 fs-ls-14"
                            {...formik.getFieldProps("email")}
                          />
                          {formik.touched.email && formik.errors.email && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block fs-14">
                                {formik.errors.email}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="fv-row mb-8 col-6">
                        <label className="col-form-label required fw-bold fs-16 mw-300px mw-xxl-400px fs-ls-14">
                          Invoice Email address
                        </label>

                        <div className="fv-row">
                          <input
                            type="text"
                            className="form-control form-control-solid fs-16 h-45px p-0 px-4 h-ls-48 fs-ls-14"
                            {...formik.getFieldProps("invoice_email")}
                          />
                          {formik.touched.invoice_email &&
                            formik.errors.invoice_email && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block fs-14">
                                  {formik.errors.invoice_email}
                                </div>
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="fv-row mb-8 col-2">
                        <label className="col-form-label required fw-bold fs-16 mw-300px mw-xxl-400px fs-ls-14">
                          Zipcode
                        </label>

                        <div className="fv-row">
                          <input
                            type="text"
                            className="form-control form-control-solid fs-16 h-45px p-0 px-4 h-ls-48 fs-ls-14"
                            {...formik.getFieldProps("zipcode")}
                          />
                          {formik.touched.zipcode && formik.errors.zipcode && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block fs-14">
                                {formik.errors.zipcode}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="fv-row mb-8 col-4">
                        <label className="col-form-label required fw-bold fs-16 mw-300px mw-xxl-400px fs-ls-14">
                          Street
                        </label>

                        <div className="fv-row">
                          <input
                            type="text"
                            className="form-control form-control-solid fs-16 h-45px p-0 px-4 h-ls-48 fs-ls-14"
                            {...formik.getFieldProps("street")}
                          />
                          {formik.touched.street && formik.errors.street && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block fs-14">
                                {formik.errors.street}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="fv-row mb-8 col-6">
                        <label className="col-form-label required fw-bold fs-16 mw-300px mw-xxl-400px fs-ls-14">
                          City
                        </label>

                        <div className="fv-row">
                          <input
                            type="text"
                            className="form-control form-control-solid fs-16 h-45px p-0 px-4 h-ls-48 fs-ls-14"
                            {...formik.getFieldProps("city")}
                          />
                          {formik.touched.city && formik.errors.city && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block fs-14">
                                {formik.errors.city}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="fv-row mb-8 col-6">
                        <label className="col-form-label required fw-bold fs-16 mw-300px mw-xxl-400px fs-ls-14">
                          Country
                        </label>

                        <div className="fv-row">
                          {countryData && (
                            <Select
                              value={countryOptions?.filter(
                                (option) => option.value === orgCountry
                              )}
                              isMulti
                              isClearable={false}
                              placeholder="Select country"
                              name="Country"
                              options={countryOptions}
                              className="basic-multi-select-register"
                              classNamePrefix="select"
                              onChange={(e: any) => onChangeCountry(e)}
                            />
                          )}
                          {orgCountry == "" && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block fs-14">
                                Country is required
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="fv-row mb-8 col-6">
                        <label className="col-form-label required fw-bold fs-16 mw-300px mw-xxl-400px fs-ls-14">
                          Select datacenter
                        </label>

                        <div className="fv-row">

                          {datacenterArray && (
                            <Select
                              value={datacenterArray.filter(
                                (option) => option.value === orgDatacenter
                              )}
                              isMulti
                              isClearable={false}
                              placeholder="Select datacenter"
                              name="Datacenter"
                              options={datacenterArray}
                              className="basic-multi-select-register"
                              classNamePrefix="select"
                              onChange={(e: any) => onChangeDatacenter(e)}
                            />
                          )}
                          {orgDatacenter == "" && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block fs-14">
                                Data center is required
                              </div>
                            </div>
                          )}
                        </div>
                      </div>


                      <div className="fv-row mb-6">
                        <div className="form-check form-check-custom form-check-solid fs-16">
                          <input
                            className="form-check-input me-2"
                            type="checkbox"
                            defaultChecked={formik.values.terms_condition}
                            {...formik.getFieldProps("terms_condition")}
                            defaultChecked={formik.values.terms_condition}
                            style={{ border: "1px solid #20519D" }}
                          />

                          <label className="form-check-label me-10">
                            <div className="fw-bolder text-gray-800 fs-18">
                              I agree to the
                              <a href="http://proxuma.io/" target="_blank" rel="noreferrer">
                                <span
                                  className="ms-1"
                                  style={{ textDecoration: "underline" }}
                                >
                                  terms and conditions
                                </span>
                              </a>
                            </div>
                          </label>
                        </div>
                        {formik.touched.terms_condition && formik.errors.terms_condition && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block fs-14">
                              {formik.errors.terms_condition}
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="fv-row">
                        {/* begin::Action */}
                        <button
                          type="submit"
                          className={`btn btn-primary h-45px fs-6 px-10 fs-ls-20 h-ls-60 
                          ${!(formik.isValid) || orgCountry === "" || orgDatacenter === "" || formik.values.terms_condition !== true ? 'disabled' : ''}`}
                        >
                          {!formik.isSubmitting ? "Register" : <Loading isLoading={true} text={"Please wait..."} />}
                        </button>
                        {/* end::Action */}
                      </div>
                    </div>
                  </form>
                </div>
                {/* END :: Registration Form */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* END :: Added logo and sidebar */}
    </>
  );
};
export default RegistrationPage;
