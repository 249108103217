
import { BaseComponent } from '../../../base';
import {
  ICalendarLabelData,
  MbscCalendarEvent,
  MbscCalendarEventData,
  MbscResource,
} from '../../../shared/calendar-view/calendar-view.types';
import {
  computeEventDragBetweenResources,
  computeEventDragBetweenSlots,
  computeEventDragInTime,
  getLabels,
  sortEvents,
} from '../../../shared/calendar-view/calendar-view.util';
import {
  addDays,
  addMonths,
  addTimezone,
  checkDateRangeOverlap,
  createDate,
  formatDate,
  getDateOnly,
  getDateStr,
  getDayDiff,
  getDayMilliseconds,
  getEndDate,
  getFirstDayOfWeek,
  getGridDayDiff,
  isDate,
  isInWeek,
  isMBSCDate,
  isSameDay,
  makeDate,
  ONE_DAY,
  ONE_HOUR,
  ONE_MIN,
  REF_DATE,
  removeTimezone,
  roundTime,
} from '../../../util/datetime';
import { closest, getDocument, getTextColor, hasSticky, smoothScroll } from '../../../util/dom';
import { gestureListener } from '../../../util/gesture';
import { constrain, findIndex, floor, getArray, isArray, isString, ngSetTimeout, round, step, UNDEFINED } from '../../../util/misc';
import { isBrowser } from '../../../util/platform';
import { dragObservable, moveClone, subscribeExternalDrag, unsubscribeExternalDrag } from '../../draggable/draggable';
import { MbscEventClickEvent, MbscEventDragEvent, MbscSlot } from '../eventcalendar.types';
import { getEventData, getEventId } from '../eventcalendar.util';
import {
  ICalendarEventDragArgs,
  IDailyData,
  IDayData,
  IEventPosData,
  IGroupData,
  ISTOptions,
  ISTState,
  IVirtualPage,
} from './schedule-timeline-base.types';
import {
  calcLayout,
  calcSchedulerTime,
  calcTimelineTime,
  checkCollision,
  DEF_ID,
  getEventLayoutEnd,
  getEventLayoutStart,
  getEventStart,
  getResourceMap,
  roundStep,
} from './schedule-timeline-base.util';

// tslint:disable no-non-null-assertion
// tslint:disable no-inferrable-types
// tslint:disable directive-class-suffix
// tslint:disable directive-selector

/** @hidden */

export class STBase<PropType extends ISTOptions = ISTOptions, StateType extends ISTState = ISTState> extends BaseComponent<
  PropType,
  StateType
> {
  // tslint:disable variable-name
  public _batchEnd!: Date;
  public _batchStart!: Date;
  public _batchRowNr!: number;
  public _colClass!: string;
  public _colIndexMap!: { [key: string]: number };
  public _colors!: { [key: string]: { [key: string]: { [key: string]: IDailyData } } };
  /** Array containing the columns to display */
  public _cols!: IDayData[];
  public _colsNr!: number;
  /** Map containing the day index for each day; timestamp ->  dayIndex */
  public _dayIndexMap!: { [key: string]: number };
  public _dayNames!: string[];
  /** Array containing the days to display */
  public _days!: IDayData[];
  public _daysBatch!: IDayData[];
  public _daysBatchNr!: number;
  /** Number of displayed days */
  public _daysNr!: number;
  public _displayTime?: boolean;
  public _dragCol!: string;
  public _dragRow!: string;
  public _endCellStyle?: { height?: string; width?: string };
  /** Displayed end time as milliseconds since midnight */
  public _endTime!: number;
  public _eventDropped!: boolean;
  public _eventHeight!: number;
  public _eventMap!: { [key: string]: MbscCalendarEventData };
  /** Number of event rows for a resource, used for row height calculation */
  public _eventRows!: { [key: string]: number };
  public _events!: { [key: string]: { [key: string]: { [key: string]: IDailyData } } };
  public _firstDay!: Date;
  public _firstDayTz!: Date;
  public _fixedResourceTops!: { [key: string]: number };
  public _gridWidth!: number;
  public _groupByResource?: boolean;
  public _gridHeight!: number;
  public _hasHierarchy?: boolean;
  public _hasResources?: boolean;
  public _hasResY?: boolean;
  public _hasRows?: boolean;
  public _hasSlots?: boolean;
  public _hasSideSticky?: boolean;
  public _hasSticky?: boolean;
  public _headerDays!: IDayData[];
  public _invalids!: { [key: string]: { [key: string]: { [key: string]: IDailyData } } };
  public _isDailyResolution?: boolean;
  public _isMulti?: boolean;
  public _isSingleResource!: boolean;
  public _isTimeline?: boolean;
  public _lastDay!: Date;
  public _lastDayTz!: Date;
  public _placeholderSizeX?: number;
  public _placeholderSizeY?: number;
  /** Contains the resources flatten out into one level */
  public _resources!: MbscResource[];
  /** Contains the map of resources flatten out into one level */
  public _resourcesMap!: { [key: string]: MbscResource };
  public _rowBatch!: Array<{ day?: IDayData; hidden?: boolean; rows: MbscResource[] }>;
  public _rowHeights!: { [key: string]: string | undefined };
  public _selectedDay?: number;
  public _setRowHeight?: boolean;
  public _showTimeIndicator?: boolean;
  public _showCursorTime?: boolean;
  public _slots!: MbscSlot[];
  public _startCellStyle?: { height?: string; width?: string };
  /** Displayed start time as milliseconds since midnight */
  public _startTime!: number;
  public _stepCell!: number;
  public _stepLabel!: number;
  /** Displayed time as milliseconds */
  public _time!: number;
  /** Array containing the hours to display */
  public _timeLabels!: { [key: number]: string };
  public _times!: number[];
  public _timesBetween!: number[];

  protected _calcConnections?: boolean;
  protected _createEventMaps?: boolean;
  protected _cursorTimeCont?: HTMLElement | null;
  protected _gridCont?: HTMLElement | null;
  protected _footerCont?: HTMLElement | null;
  protected _headerCont?: HTMLElement | null;
  protected _isParentClick?: boolean;
  protected _isScrolling = 0;
  protected _resCont?: HTMLElement | null;
  protected _resourcesCopy!: MbscResource[];
  protected _resourceTops!: { [key: string]: number };
  protected _scrollCont?: HTMLElement | null;
  protected _shouldAnimateScroll?: boolean;
  protected _shouldCheckSize?: boolean;
  protected _shouldScroll?: boolean;
  protected _sidebarCont?: HTMLElement | null;
  protected _stickyFooter?: HTMLElement | null;
  protected _viewChanged?: boolean;
  protected _visibleResources!: MbscResource[];
  protected _virtualPagesY!: IVirtualPage[];

  private _allDayTop!: number;
  private _body!: HTMLElement;
  private _clone!: HTMLElement;
  private _cursorX?: number;
  private _cursorY?: number;
  private _colHeight!: number;
  private _colWidth!: number;
  private _dragDayDelta!: number;
  private _dragDelta?: number;
  private _fixedHeight!: number;
  private _fixedResources!: Array<{ index: number; height: number; key: string; resource: MbscResource }>;
  private _gridContBottom!: number;
  private _gridContLeft!: number;
  private _gridContRight!: number;
  private _gridContTop!: number;
  private _gridLeft!: number;
  private _gridRight!: number;
  private _gridTop!: number;
  private _isCursorTimeVisible?: boolean;
  private _isTouch?: boolean;
  private _maxEventStack?: number | 'all';
  private _onCalendar?: boolean;
  private _rows!: Array<{ dayIndex: number; key: string; resource: MbscResource }>;
  private _reloadEvents?: boolean;
  private _scrollAfterResize?: boolean;
  private _scrollTimer: any;
  // private _scrollToMiddle?: boolean;
  private _scrollX!: number;
  private _scrollY!: number;
  private _startSlotIndex?: number;
  private _tempAllDay?: boolean;
  private _tempEnd?: number;
  private _tempEvent?: MbscCalendarEventData;
  private _tempResource?: number | string;
  private _tempSlot?: number | string;
  private _tempStart?: number;
  private _touchTimer: any;
  private _unlisten?: () => void;
  private _unsubscribe?: number;

  // tslint:enable variable-name

  public _isToday(d: number) {
    return isSameDay(new Date(d), createDate(this.s));
  }

  public _formatTime(v: number, timezone?: string) {
    const s = this.s;
    const format = s.timeFormat!;
    const timeFormat = /a/i.test(format) && this._stepLabel === ONE_HOUR && v % ONE_HOUR === 0 ? format.replace(/.[m]+/i, '') : format;

    const d = new Date(+REF_DATE + v);
    const dd = createDate(s, d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes());
    if (isMBSCDate(dd) && timezone) {
      dd.setTimezone(timezone);
    }
    return formatDate(timeFormat, dd, s);
  }

  // tslint:disable-next-line: variable-name no-empty
  public _onScroll = () => {};

  // tslint:disable-next-line: variable-name
  public _onMouseLeave = (ev?: any, force?: boolean) => {
    if (this._cursorTimeCont && (!this.state.dragData || force)) {
      this._cursorTimeCont.style.visibility = 'hidden';
      this._isCursorTimeVisible = false;
    }
  };

  // tslint:disable-next-line: variable-name
  public _onMouseMove = (ev?: any) => {
    if (this._showCursorTime) {
      const s = this.s;
      const rtl = s.rtl;
      const isTimeline = this._isTimeline;
      const timeCont = this._cursorTimeCont!;

      if (!this._isTouch || this._tempStart) {
        if (!this._isCursorTimeVisible && ev) {
          timeCont.style.visibility = 'visible';
          this._isCursorTimeVisible = true;
        }
      } else {
        timeCont.style.visibility = 'hidden';
        this._isCursorTimeVisible = false;
      }

      if (this._isCursorTimeVisible && this._colWidth) {
        const gridCont = this._gridCont!;
        const gridRect = gridCont.getBoundingClientRect();
        const clientX = ev ? ev.clientX : this._cursorX || 0;
        const clientY = ev ? ev.clientY : this._cursorY || 0;
        const posX = rtl ? gridRect.right - clientX : clientX - gridRect.left;
        const posY = constrain(clientY - gridRect.top, 8, this._colHeight);

        let dayIndex: number;
        let date: Date;
        let time: number;

        if (this._dragDelta !== UNDEFINED) {
          // use the _tempStart/_tempEnd since that is already calculated
          date = createDate(s, this._dragDelta < 0 ? this._tempStart : this._tempEnd);
          dayIndex = isTimeline && !this._hasResY ? this._dayIndexMap[getDateStr(date)] : 0;
          time = getDayMilliseconds(date);
          time = time === 0 ? (this._dragDelta < 0 ? time : ONE_DAY) : time;
        } else {
          dayIndex = isTimeline && !this._hasResY ? constrain(floor(posX / this._colWidth), 0, this._daysNr - 1) : 0;
          time =
            this._startTime +
            step(
              isTimeline
                ? floor((this._time * (posX - dayIndex * this._colWidth)) / this._colWidth)
                : floor((this._time * (posY - 8)) / (this._colHeight - 16)),
              s.dragTimeStep! * ONE_MIN,
            ); // Remove 16px for top and bottom spacing
          const day = this._days[dayIndex].date;
          const d = new Date(+REF_DATE + time); // Date with no DST
          date = createDate(s, day.getFullYear(), day.getMonth(), day.getDate(), d.getHours(), d.getMinutes());
        }

        const milliSeconds = this._time * (isTimeline ? this._daysNr : 1);
        const pos = isTimeline ? (rtl ? 'right' : 'left') : 'top';
        const timeContStyle = timeCont.style;

        timeContStyle[pos] = ((dayIndex * this._time + time - this._startTime) * 100) / milliSeconds + '%';
        timeContStyle[rtl ? 'left' : 'right'] = '';
        timeCont.textContent = formatDate(s.timeFormat!, date, s);

        this._cursorX = clientX;
        this._cursorY = clientY;
      }
    }
  };

  // tslint:disable-next-line: variable-name
  public _onEventClick = (args: MbscEventClickEvent) => {
    // tslint:disable-next-line: no-string-literal
    const more = args.event['more'];
    if (more) {
      this._hook('onMoreClick', {
        context: this._scrollCont,
        date: args.date,
        key: args.event.id,
        list: more,
        target: args.domEvent!.target,
      });
    } else {
      this._hook('onEventClick', args);
    }
  };

  // #region Drag & Drop

  // tslint:disable-next-line: variable-name
  public _onEventDragModeOn = (args: ICalendarEventDragArgs) => {
    if (this.s.externalDrag && args.drag && !args.create) {
      dragObservable.next({
        ...args,
        create: true,
        eventName: 'onDragModeOn',
        external: true,
        from: this,
      });
    }
    const event = args.create ? this._tempEvent! : args.eventData!;
    const resource = args.create ? this._tempResource! : args.resource!;
    const slot = args.create ? this._tempSlot! : args.slot!;
    this.setState({
      dragData: {
        draggedEvent: event,
        originDates: args.external ? UNDEFINED : this._getDragDates(event, resource, slot),
        resource,
      },
      isTouchDrag: true,
    });
  };

  // tslint:disable-next-line: variable-name
  public _onEventDragModeOff = (args: ICalendarEventDragArgs) => {
    this._hook<MbscEventDragEvent>('onEventDragEnd', {
      domEvent: args.domEvent,
      event: args.create ? this._tempEvent!.original! : args.event!,
      resource: this._tempResource !== DEF_ID ? this._tempResource : UNDEFINED,
      slot: this._tempSlot !== DEF_ID ? this._tempSlot : UNDEFINED,
      source: this._isTimeline ? 'timeline' : 'schedule',
    });
    this.setState({
      dragData: UNDEFINED,
      isTouchDrag: false,
    });
  };

  // tslint:disable-next-line: variable-name
  public _onEventDragStart = (args: ICalendarEventDragArgs) => {
    const s = this.s;
    const isClick = args.click;
    const isListing = s.eventList;
    const isTimeline = this._isTimeline;
    const resources = this._visibleResources;
    const slots = this._slots;
    const timeStep = s.dragTimeStep!;
    const startX = args.startX;
    const startY = args.startY;
    this._isTouch = args.isTouch;
    this._scrollY = 0;
    this._scrollX = 0;
    this._calcGridSizes();
    const posX = s.rtl ? this._gridRight - startX : startX - this._gridLeft;
    const posY = constrain(startY - this._gridTop, 8, this._colHeight - 9); // There's 8px top and 8px bottom spacing
    const cols = isListing ? this._cols : this._days;
    const colsNr = cols.length;
    const colWidth = this._colWidth;
    const colIndex = colWidth ? floor(posX / colWidth) : 1;
    const resourceTops = this._resourceTops;
    const scrollContTop = this._scrollCont!.scrollTop;

    let resourceIndex = 0;
    let dayIndex = colIndex;
    let slotIndex = 0;

    if (s.externalDrag && args.drag && !args.create) {
      const eventEl = closest(args.domEvent.target as HTMLElement, '.mbsc-schedule-event', this._el)!;
      const clone = eventEl.cloneNode(true) as HTMLElement;
      const cloneClass = clone.classList;
      clone.style.display = 'none';
      cloneClass.add('mbsc-drag-clone', 'mbsc-schedule-drag-clone', 'mbsc-font');
      cloneClass.remove('mbsc-schedule-event-hover');
      this._clone = clone;
      this._body = getDocument(this._el)!.body;
      this._body.appendChild(clone);
      this._eventDropped = false;

      dragObservable.next({
        ...args,
        create: true,
        dragData: args.eventData!.original,
        eventName: 'onDragStart',
        external: true,
        from: this,
      });
    }

    if (!isTimeline) {
      const groupByResource = this._groupByResource;
      const groupCount = groupByResource ? colsNr : this._hasSlots ? this._slots.length : resources.length;
      resourceIndex = groupByResource ? floor(colIndex / groupCount) : colIndex % groupCount;
      dayIndex = groupByResource ? colIndex % groupCount : floor(colIndex / groupCount);
    } else {
      slotIndex = colWidth ? floor(posX / (colWidth / slots.length)) % slots.length : 0;
      if (this._hasResY) {
        cols.forEach((d, i) => {
          resources.forEach((r, j) => {
            if (posY > resourceTops[d.dateKey + '-' + r.id]) {
              dayIndex = i;
              resourceIndex = j;
            }
          });
        });
      } else {
        resources.forEach((r, i) => {
          if (posY > resourceTops[r.id]) {
            resourceIndex = i;
          }
        });

        const fixedY = posY - scrollContTop + this.state.headerHeight!;
        if (scrollContTop && fixedY < this._fixedHeight && posY - scrollContTop > 0) {
          for (const r of this._fixedResources) {
            if (fixedY > this._fixedResourceTops[r.key]) {
              resourceIndex = r.index;
            }
          }
        }
      }
      this._startSlotIndex = slotIndex;
    }

    const resource = args.external ? UNDEFINED : resources[resourceIndex];
    const resourceId = resource ? resource.id : UNDEFINED;
    const slot = args.external ? UNDEFINED : slots[slotIndex];
    const slotId = slot ? slot.id : UNDEFINED;

    if (resource && resource.eventCreation === false) {
      return false;
    }

    if (args.create) {
      dayIndex = constrain(dayIndex, 0, colsNr - 1);
      // It's enough to check the bottom of the all day area,
      // because the create gesture is surely started on an all day cell or a time cell
      const allDay = !isTimeline && s.showAllDay && args.endY < this._gridContTop;
      const eventDay = s.type === 'day' && s.size === 1 ? this._firstDay : cols[dayIndex].date;
      const eventLength = !isListing && (args.external || isClick) ? this._stepCell : timeStep * ONE_MIN;
      const gridTime = this._getGridTime(eventDay, posX, posY, dayIndex, isClick ? this._stepCell / ONE_MIN : timeStep);
      const newStart = !this._isDailyResolution || allDay || isListing ? (allDay ? eventDay : addTimezone(s, eventDay)) : gridTime;
      const nextDay =
        s.resolution === 'year'
          ? addMonths(newStart, 12, s)
          : s.resolution === 'quarter'
          ? addMonths(newStart, 3, s)
          : s.resolution === 'month'
          ? addMonths(newStart, 1, s)
          : s.resolution === 'week'
          ? addDays(newStart, s.endDay - s.startDay + 1 + (s.endDay < s.startDay ? 7 : 0))
          : addDays(newStart, 1);
      const allDayEnd = s.exclusiveEndDates ? nextDay : new Date(+nextDay - 1);
      const newEnd = allDay || isListing ? allDayEnd : roundTime(createDate(s, +newStart + eventLength), isClick ? 1 : timeStep);
      const eventData = s.extendDefaultEvent
        ? s.extendDefaultEvent({
            resource: resourceId,
            slot: slotId,
            start: newStart,
          })
        : UNDEFINED;

      const newEvent: MbscCalendarEvent = {
        allDay,
        end: newEnd,
        id: getEventId(),
        resource: resource && resourceId !== DEF_ID ? resourceId : UNDEFINED,
        slot: slot && slotId !== DEF_ID ? slotId : UNDEFINED,
        start: newStart,
        title: s.newEventText,
        ...eventData,
        ...args.dragData,
      };

      const ev = this._getEventData(newEvent, eventDay, resource);

      if (isTimeline && resourceId !== UNDEFINED && this._setRowHeight) {
        ev.position.top = constrain(
          floor((posY - (this._fixedResourceTops[resourceId] ? scrollContTop : 0) - resourceTops[resourceId]) / this._eventHeight),
          0,
          this._eventRows[resourceId] - 1,
        );
      }

      if (args.dragData) {
        const eventDuration = +ev.endDate - +ev.startDate;
        if (computeEventDragInTime(args.dragData.dragInTime, UNDEFINED, s.dragInTime)) {
          ev.startDate = eventDay;
          ev.endDate = new Date(+eventDay + eventDuration);
        }
      }

      this._tempEvent = ev;
      this._tempResource = resourceId;
      this._tempSlot = slotId;
    }

    // Close the popover if drag started from more popover
    this._hook('onPopoverClose', { source: 'dragStart' });

    if (!isClick) {
      this._hook<MbscEventDragEvent>('onEventDragStart', {
        action: args.create ? 'create' : args.resize ? 'resize' : 'move',
        domEvent: args.domEvent,
        event: (args.create ? this._tempEvent! : args.eventData!).original!,
        resource: resourceId !== DEF_ID ? resourceId : UNDEFINED,
        slot: slotId !== DEF_ID ? slotId : UNDEFINED,
        source: isTimeline ? 'timeline' : 'schedule',
      });
    }
    return true;
  };

  // tslint:disable-next-line: variable-name
  public _onEventDragMove = (args: ICalendarEventDragArgs) => {
    clearTimeout(this._scrollTimer);
    const s = this.s;
    const rtl = s.rtl;
    const rtlNr = rtl ? -1 : 1;
    const isTimeline = this._isTimeline;
    const isListing = s.eventList;
    const isMonthYearResolution = s.resolution === 'month' || s.resolution === 'year';
    const cols = isListing ? this._cols : this._days;
    const colWidth = this._colWidth;
    const colsNr = cols.length;
    const slots = this._slots;
    const groupByResource = this._groupByResource;
    const resources = this._visibleResources;
    const dragData = this.state.dragData;
    const timeStep = s.dragTimeStep!;
    const timeFormat = s.timeFormat!;
    // Limit coordinates to the droppable area
    const startX = args.startX;
    const endX = constrain(args.endX, this._gridContLeft, this._gridContRight - 1);
    const endY = constrain(args.endY, this._gridContTop, this._gridContBottom - 1);
    const deltaY = endY - args.startY + this._scrollY;
    const deltaX = rtl ? startX - endX + this._scrollX : endX - startX + this._scrollX;
    const delta = isTimeline ? deltaX : deltaY;
    const daySize = isTimeline ? colWidth : this._colHeight - 16; // Extract 16 to compensate for top/bottom spacing
    const gridWidth = this._gridRight - this._gridLeft - 1;
    const startPosY = constrain(args.startY - this._gridTop, 8, this._colHeight - 9); // There's 8px top and 8px bottom spacing
    const posX = constrain(rtl ? this._gridRight + this._scrollX - endX : endX - this._gridLeft + this._scrollX, 0, gridWidth);
    const posY = constrain(endY - this._gridTop + this._scrollY, 8, this._colHeight - 9); // There's 8px top and 8px bottom spacing
    const oldIndex = floor((rtl ? this._gridRight - startX : startX - this._gridLeft) / colWidth);
    const newIndex = floor(posX / colWidth);
    const inAllDay = s.showAllDay && args.endY < this._gridContTop;
    const resourceTops = this._resourceTops;
    const hasResY = this._hasResY;
    const scrollCont = this._scrollCont!;
    const scrollContTop = scrollCont.scrollTop;
    const event = args.create ? this._tempEvent! : args.eventData!;
    const origEvent = event.original!;
    const draggedEvent = { ...event };

    let oldDayIndex = oldIndex;
    let newDayIndex = newIndex;
    let resourceIndex = 0;
    let slotIndex = 0;
    let hasScroll = false;

    const distBottom = this._gridContBottom - args.endY;
    const distTop = args.endY - this._gridContTop;
    const distLeft = args.endX - this._gridContLeft;
    const distRight = this._gridContRight - args.endX;
    const maxScrollH = (scrollCont.scrollWidth - scrollCont.clientWidth) * rtlNr;
    const rightLimit = rtl ? 0 : maxScrollH;
    const leftLimit = rtl ? maxScrollH : 0;

    if (s.externalDrag && args.drag && !args.create) {
      dragObservable.next({
        ...args,
        clone: this._clone,
        create: true,
        dragData: origEvent,
        eventName: 'onDragMove',
        external: true,
        from: this,
      });

      if (!this._onCalendar) {
        moveClone(args, this._clone);
        if (!dragData) {
          // In case of instant drag the dragged event is not set
          this.setState({ dragData: { draggedEvent } });
        }
        return;
      }
    }

    // Vertical scroll
    if (distBottom < 30 && scrollContTop < scrollCont.scrollHeight - scrollCont.clientHeight) {
      scrollCont.scrollTop += 5;
      this._scrollY += 5;
      hasScroll = true;
    }

    if (distTop < 30 && !inAllDay && scrollContTop > 0) {
      scrollCont.scrollTop -= 5;
      this._scrollY -= 5;
      hasScroll = true;
    }

    // Horizontal scroll
    if (distLeft < 30 && scrollCont.scrollLeft > leftLimit) {
      scrollCont.scrollLeft -= 5;
      this._scrollX -= 5 * rtlNr;
      hasScroll = true;
    }

    if (distRight < 30 && scrollCont.scrollLeft < rightLimit) {
      scrollCont.scrollLeft += 5;
      this._scrollX += 5 * rtlNr;
      hasScroll = true;
    }

    if (hasScroll) {
      this._scrollTimer = setTimeout(() => {
        this._onEventDragMove(args);
      }, 20);
    }

    if (!isTimeline) {
      const groupCount = groupByResource ? colsNr : this._resources.length;
      oldDayIndex = groupByResource ? oldIndex % groupCount : floor(oldIndex / groupCount);
      newDayIndex = groupByResource ? newIndex % groupCount : floor(newIndex / groupCount);
      resourceIndex = groupByResource ? floor(newIndex / groupCount) : newIndex % groupCount;
    } else {
      slotIndex = floor(posX / (colWidth / slots.length)) % slots.length;
      if (hasResY) {
        cols.forEach((d, i) => {
          resources.forEach((r, j) => {
            if (startPosY > resourceTops[d.dateKey + '-' + r.id]) {
              oldDayIndex = i;
            }
            if (posY > resourceTops[d.dateKey + '-' + r.id]) {
              newDayIndex = i;
              resourceIndex = j;
            }
          });
        });
      } else {
        resources.forEach((r, i) => {
          if (posY > resourceTops[r.id]) {
            resourceIndex = i;
          }
        });

        const fixedY = posY - scrollContTop + this.state.headerHeight!;
        if (scrollContTop && fixedY < this._fixedHeight && posY - scrollContTop > 0) {
          for (const r of this._fixedResources) {
            if (fixedY > this._fixedResourceTops[r.key]) {
              resourceIndex = r.index;
            }
          }
        }
      }
    }

    oldDayIndex = constrain(oldDayIndex, 0, colsNr - 1);
    newDayIndex = constrain(newDayIndex, 0, colsNr - 1);

    const start = event.startDate;
    const end = event.endDate;
    const duration = +end - +start;
    const ms = this._time;
    const timeDelta = floor((ms * delta) / daySize);
    const resource = resources[resourceIndex];
    const startResource = (args.create ? this._tempResource : args.resource)!;
    const startSlot = (args.create ? this._tempSlot : args.slot)!;

    // On external drag don't create the event if dragged on a resource with event creation disabled
    if (resource.eventCreation === false && this._tempResource === UNDEFINED) {
      return false;
    }
    let slotId = slots[slotIndex].id;
    let resourceId = resource.eventCreation !== false ? resource.id : this._tempResource!;
    let allDay = event.allDay;
    let tzOpt = allDay ? UNDEFINED : s;
    let addDayOnly = allDay || isListing;
    let newStart = start;
    let newEnd = end;
    let newDate: Date;
    let isEventDraggableBetweenResources = true;
    let isEventDraggableBetweenSlots = true;
    let isEventDraggableInTime = true;
    const oldDay = cols[oldDayIndex].date;
    const newDay = cols[newDayIndex].date;
    let dayDelta = s.type === 'day' && s.size === 1 ? 0 : getDayDiff(oldDay, newDay);
    const colDelta = newDayIndex - oldDayIndex;
    const months = s.resolution === 'year' ? 12 : 1;
    const deltaDiff = dayDelta - colDelta;

    if ((args.drag && !args.create) || args.external) {
      if (!args.external) {
        isEventDraggableBetweenResources = computeEventDragBetweenResources(
          origEvent.dragBetweenResources,
          this._resourcesMap[startResource].eventDragBetweenResources,
          s.dragBetweenResources,
        );
        isEventDraggableBetweenSlots = computeEventDragBetweenSlots(
          origEvent.dragBetweenSlots,
          this._resourcesMap[startResource].eventDragBetweenSlots,
          slots[this._startSlotIndex || 0].eventDragBetweenSlots,
          s.dragBetweenSlots,
        );
      }

      isEventDraggableInTime = computeEventDragInTime(
        origEvent.dragInTime,
        args.external || this._resourcesMap[startResource].eventDragInTime,
        s.dragInTime,
      );
    }

    if (args.drag || args.external) {
      if (!isTimeline && !isEventDraggableBetweenResources && startResource !== resourceId) {
        // preserve the previous dayDelta if it is moved out from the resource group
        dayDelta = this._dragDayDelta;
      }
      // Drag
      if (isTimeline && isListing && isMonthYearResolution) {
        newStart = addMonths(start, colDelta * months, s);
        newEnd = addMonths(end, colDelta * months, s);
      } else {
        // Only allow changing between all-day / not all-day in case of drag (not resize or create)
        allDay = inAllDay || (isTimeline && event.allDay);
        addDayOnly = allDay || isListing;
        tzOpt = allDay ? UNDEFINED : s;
        if ((!isTimeline && !inAllDay && (event.allDay || args.external)) || (isTimeline && args.external && !event.allDay && !isListing)) {
          const day = getDateOnly(addDays(start, dayDelta));
          newStart = this._getGridTime(day, posX, posY, newDayIndex, timeStep);
        } else {
          if (isTimeline && !addDayOnly && !hasResY) {
            newStart = roundTime(createDate(s, +start + timeDelta + (ONE_DAY - ms) * dayDelta + ms * deltaDiff), timeStep);
          } else {
            newDate = addDays(start, dayDelta);
            newStart = addDayOnly ? newDate : roundTime(createDate(tzOpt, +newDate + timeDelta), timeStep);
          }
        }
        if (resource.eventCreation === false && !isTimeline) {
          newStart = createDate(s, this._tempStart);
        }
        // if (end.getMilliseconds() === 999) {
        //   // TODO: this should be removed when non-inclusive end dates are implemented
        //   duration += 1;
        // }
        newEnd = createDate(tzOpt, +newStart + duration);
      }
    } else {
      // Resize, create
      const gridDelta = isTimeline ? colDelta : newIndex - oldIndex;
      const endResize = args.create ? (gridDelta ? gridDelta > 0 : delta > 0) : args.direction === 'end';
      const days = getDayDiff(start, end);

      if (!isTimeline && groupByResource && startResource !== resourceId) {
        // preserve the previous dayDelta if it is moved out from the resource group
        dayDelta = this._dragDayDelta;
      }

      if (endResize) {
        if (isTimeline && isListing && isMonthYearResolution) {
          newEnd = addMonths(end, colDelta * months, s);
        } else if (isTimeline && !addDayOnly && !hasResY) {
          newEnd = roundTime(createDate(s, +end + timeDelta + dayDelta * (ONE_DAY - ms) + ms * deltaDiff), timeStep);
        } else {
          newDate = addDays(end, Math.max(-days, dayDelta));
          newEnd = addDayOnly ? newDate : roundTime(createDate(tzOpt, +newDate + timeDelta), timeStep);
          // Ensure that end time remains between visible hours
          // TODO: this should be simpler
          if (!addDayOnly && (getDayMilliseconds(newEnd) > this._endTime + 1 || newEnd >= addDays(getDateOnly(newDate), 1))) {
            newEnd = createDate(s, +getDateOnly(newDate) + this._endTime + 1);
          }
        }
      } else {
        if (isTimeline && isListing && isMonthYearResolution) {
          newStart = addMonths(start, colDelta * months, s);
        } else if (isTimeline && !addDayOnly && !hasResY) {
          newStart = roundTime(createDate(s, +start + timeDelta + dayDelta * (ONE_DAY - ms) + ms * deltaDiff), timeStep);
        } else {
          newDate = addDays(start, Math.min(days, dayDelta));
          newStart = addDayOnly ? newDate : roundTime(createDate(tzOpt, +newDate + timeDelta), timeStep);
          // Ensure that start time remains between visible hours
          // TODO: this should be simpler
          if (!addDayOnly && (getDayMilliseconds(newStart) < this._startTime || newStart < getDateOnly(newDate))) {
            newStart = createDate(s, +getDateOnly(newDate) + this._startTime);
          }
        }
      }
      resourceId = startResource; // set the resource back to the starting resource

      // Don't allow end date before start date when resizing all day events
      if (addDayOnly && newEnd < newStart) {
        if (endResize) {
          newEnd = createDate(s, newStart);
        } else {
          newStart = createDate(s, newEnd);
        }
      }
      // Let's have dragTimeStep minutes minimum duration
      if (!addDayOnly && (newEnd < newStart || Math.abs(+newEnd - +newStart) < timeStep * ONE_MIN)) {
        if (endResize) {
          newEnd = createDate(s, +newStart + timeStep * ONE_MIN);
        } else {
          newStart = createDate(s, +newEnd - timeStep * ONE_MIN);
        }
      }
    }

    if (args.drag || args.external) {
      // Check if event not movable in time -
      if (!isEventDraggableInTime) {
        newStart = start;
        newEnd = end;
        allDay = this._tempAllDay;
      }

      // Check if event not movable between resources
      if (!isEventDraggableBetweenResources) {
        resourceId = startResource;
      }

      if (!isEventDraggableBetweenSlots) {
        slotId = startSlot;
      }
    }

    // Check if dates changed since last move
    if (
      this._tempStart !== +newStart ||
      this._tempEnd !== +newEnd ||
      this._tempAllDay !== allDay ||
      this._tempResource !== resourceId ||
      this._tempSlot !== slotId
    ) {
      let startStr: string;
      let endStr: string;
      if (!this._isDailyResolution) {
        startStr = formatDate(s.dateFormat!, newStart, s);
        endStr = formatDate(s.dateFormat!, getEndDate(s, allDay, newStart, newEnd), s);
      } else {
        startStr = formatDate(timeFormat, newStart, s);
        endStr = formatDate(timeFormat, newEnd, s);
      }
      // Modify the dates
      draggedEvent.startDate = newStart;
      draggedEvent.endDate = newEnd;
      draggedEvent.start = startStr;
      draggedEvent.end = endStr;
      draggedEvent.allDay = allDay;
      draggedEvent.date = +newDay;

      if (origEvent.bufferAfter) {
        draggedEvent.bufferEnd = makeDate(+newEnd + origEvent.bufferAfter * 60000, tzOpt);
      }

      if (origEvent.bufferBefore) {
        draggedEvent.bufferStart = makeDate(+newStart - origEvent.bufferBefore * 60000, tzOpt);
      }

      this._tempStart = +newStart;
      this._tempEnd = +newEnd;
      this._tempAllDay = allDay;
      this._tempResource = resourceId;
      this._tempSlot = slotId;
      this._dragDelta = args.drag || args.external ? -1 : args.direction ? (args.direction === 'end' ? 1 : -1) : delta;
      this._dragDayDelta = dayDelta;

      // Call mouse move to display the time during drag
      if (!allDay) {
        this._onMouseMove(args.domEvent);
      }

      this.setState({
        dragData: {
          draggedDates: this._getDragDates(draggedEvent, resourceId, slotId),
          draggedEvent,
          originDate: event.date,
          originDates: dragData && dragData.originDates,
          originResource: args.external ? UNDEFINED : startResource,
          resource: resourceId,
          slot: slotId,
        },
      });
    }
    return true;
  };

  // tslint:disable-next-line: variable-name
  public _onEventDragEnd = (args: ICalendarEventDragArgs) => {
    clearTimeout(this._scrollTimer);
    const s = this.s;
    const isCreating = args.create;
    const state = this.state;
    let dragData = state.dragData;
    let eventLeft = false;

    if (s.externalDrag && args.drag && !args.create) {
      dragObservable.next({
        ...args,
        action: 'externalDrop',
        create: true,
        dragData: args.eventData!.original,
        eventName: 'onDragEnd',
        external: true,
        from: this,
      });

      this._body.removeChild(this._clone);

      if (!this._onCalendar) {
        eventLeft = true;
        if (this._eventDropped) {
          args.event = args.eventData!.original;
          s.onEventDelete(args);
        }
      }
    }

    if (isCreating && !dragData) {
      // if there was no drag move create dummy object for create on click to work
      dragData = {};
      dragData.draggedEvent = this._tempEvent;
    }

    if (dragData && dragData.draggedEvent) {
      const showBuffer = s.showEventBuffer !== false;
      const event = args.eventData!;
      const draggedEvent = dragData.draggedEvent;
      const origEvent = draggedEvent.original!;
      const newStart = draggedEvent.startDate;
      const newEnd = draggedEvent.endDate;
      const newBufferStart = (showBuffer && draggedEvent.bufferStart) || newStart;
      const newBufferEnd = (showBuffer && draggedEvent.bufferEnd) || newEnd;
      const allDay = draggedEvent.allDay;
      const oldResource: string | number = isCreating && !args.external ? this._tempResource! : args.resource!;
      const newResource = dragData.resource === UNDEFINED ? oldResource : dragData.resource;
      const eventResource = origEvent.resource === UNDEFINED ? newResource : origEvent.resource;
      const oldSlot: string | number = isCreating ? this._tempSlot! : args.slot!;
      const newSlot = dragData.slot === UNDEFINED ? oldSlot : dragData.slot;
      const invalids: { [key: string]: { [key: string]: IDailyData } } = {};
      const events: { [key: string]: { [key: string]: IDailyData } } = {};
      const isTimeline = this._isTimeline;
      const source = isTimeline ? 'timeline' : 'schedule';
      const changed =
        isCreating ||
        +newStart !== +event.startDate ||
        +newEnd !== +event.endDate ||
        allDay !== event.allDay ||
        oldResource !== newResource ||
        oldSlot !== newSlot;

      let updatedResource = eventResource;
      let collisionResources: Array<string | number>;

      if (oldResource !== newResource && (!isCreating || args.external) && !this._isSingleResource) {
        if (isArray(eventResource) && eventResource.length && newResource !== UNDEFINED) {
          const indx = eventResource.indexOf(oldResource);
          if (eventResource.indexOf(newResource) === -1) {
            // Don't allow to two resource combine
            updatedResource = [...eventResource];
            updatedResource.splice(indx, 1, newResource);
          }
        } else {
          updatedResource = newResource;
        }
      }

      if (!updatedResource || !s.resources) {
        // if the event is not tied to a resource, process all resources
        collisionResources = this._resources.map((r) => r.id);
      } else {
        collisionResources = isArray(updatedResource) ? updatedResource : [updatedResource];
      }

      const newRes = this._resourcesMap[newResource];
      const allowOverlap = origEvent.overlap !== false && newRes.eventOverlap !== false && s.eventOverlap !== false;

      for (const r of collisionResources) {
        if (this._invalids[r]) {
          invalids[r] = this._invalids[r][newSlot];
        }
        if (this._events[r]) {
          const possibleOverlaps: { [key: string]: IDailyData } = {};
          const eventsForResource = this._events[r][newSlot];
          for (const dateKey of Object.keys(eventsForResource)) {
            const eventsForDay = eventsForResource[dateKey];
            possibleOverlaps[dateKey] = {
              allDay: eventsForDay.allDay.filter((e) => e.id !== draggedEvent.id && (!allowOverlap || e.original!.overlap === false)),
              data: eventsForDay.data.filter((e) => e.id !== draggedEvent.id && (!allowOverlap || e.original!.overlap === false)),
            };
          }
          events[r] = possibleOverlaps;
        }
      }

      const action = args.action || (state.dragData ? 'drag' : 'click');
      const allowUpdate =
        !eventLeft &&
        (changed
          ? s.eventDragEnd({
              action,
              collision: checkCollision(invalids, newBufferStart, newBufferEnd, allDay, isTimeline, s.invalidateEvent, s),
              create: isCreating,
              domEvent: args.domEvent,
              event: draggedEvent,
              external: args.external,
              from: args.from,
              newResource,
              newSlot,
              oldResource,
              oldSlot,
              overlap: checkCollision(events, newBufferStart, newBufferEnd, allDay, isTimeline, 'strict', s),
              resource: updatedResource !== DEF_ID ? updatedResource : UNDEFINED,
              slot: newSlot !== DEF_ID ? newSlot : UNDEFINED,
              source,
            })
          : true);

      const keepDragMode = state.isTouchDrag && !eventLeft && (!isCreating || allowUpdate);
      if (allowUpdate && keepDragMode && oldResource !== newResource && !origEvent.color) {
        const resColor = newRes && newRes.color;
        // update drag mode event color manually
        if (resColor) {
          draggedEvent.color = resColor;
          draggedEvent.style.background = resColor;
          draggedEvent.style.color = getTextColor(resColor);
        } else {
          draggedEvent.color = UNDEFINED;
          draggedEvent.style = {};
        }
      }

      if (!keepDragMode && action !== 'click') {
        this._hook<MbscEventDragEvent>('onEventDragEnd', {
          domEvent: args.domEvent,
          event: (isCreating ? this._tempEvent! : event).original!,
          resource: newResource !== DEF_ID ? newResource : UNDEFINED,
          slot: newSlot !== DEF_ID ? newSlot : UNDEFINED,
          source,
        });
      }

      this.setState({
        dragData: keepDragMode
          ? {
              draggedEvent: allowUpdate ? draggedEvent : { ...event },
              originDate: allowUpdate ? draggedEvent.date : event.date,
              originDates: allowUpdate ? this._getDragDates(draggedEvent, newResource, newSlot) : dragData.originDates,
              originResource: allowUpdate ? newResource : dragData.originResource,
            }
          : UNDEFINED,
        isTouchDrag: keepDragMode,
      });

      this._tempStart = 0;
      this._tempEnd = 0;
      this._tempAllDay = UNDEFINED;
      this._dragDelta = UNDEFINED;
      this._onMouseMove(args.domEvent);
      this._isTouch = false;
    }
  };

  // tslint:disable-next-line: variable-name
  public _onExternalDrag = (args: ICalendarEventDragArgs) => {
    const s = this.s;
    const clone: HTMLElement = args.clone!;
    const isSelf = args.from === this;
    const externalDrop = !isSelf && s.externalDrop;
    const instantDrag = isSelf && s.externalDrag && !s.dragToMove;
    const dragData = this.state.dragData;

    if (externalDrop || s.externalDrag) {
      const isInArea =
        !instantDrag &&
        args.endY < this._gridContBottom &&
        args.endY > this._allDayTop &&
        args.endX > this._gridContLeft &&
        args.endX < this._gridContRight;
      switch (args.eventName) {
        case 'onDragModeOff':
          if (externalDrop) {
            this._onEventDragModeOff(args);
          }
          break;
        case 'onDragModeOn':
          if (externalDrop) {
            this._onEventDragModeOn(args);
          }
          break;
        case 'onDragStart':
          if (externalDrop) {
            this._onEventDragStart(args);
          } else if (isSelf) {
            this._onCalendar = true;
          }
          break;
        case 'onDragMove':
          if (!isSelf && !externalDrop) {
            return;
          }
          if (isInArea) {
            if (!this._onCalendar) {
              this._hook<MbscEventDragEvent>('onEventDragEnter', {
                domEvent: args.domEvent,
                event: args.dragData!,
                source: this._isTimeline ? 'timeline' : 'schedule',
              });
            }
            if (isSelf || (externalDrop && this._onEventDragMove(args) !== false)) {
              clone.style.display = 'none';
            }
            this._onCalendar = true;
          } else {
            if (this._onCalendar) {
              this._hook<MbscEventDragEvent>('onEventDragLeave', {
                domEvent: args.domEvent,
                event: args.dragData!,
                source: this._isTimeline ? 'timeline' : 'schedule',
              });
              clearTimeout(this._scrollTimer);
              clone.style.display = 'table';

              if (!isSelf || dragData) {
                this.setState({
                  dragData: {
                    draggedDates: {},
                    draggedEvent: isSelf ? dragData && dragData.draggedEvent : UNDEFINED,
                    originDates: isSelf ? dragData && dragData.originDates : UNDEFINED,
                  },
                });
              }

              this._tempStart = 0;
              this._tempEnd = 0;
              this._tempAllDay = UNDEFINED;
              this._tempResource = UNDEFINED;
              this._dragDelta = UNDEFINED;
              this._onCalendar = false;
              this._onMouseLeave(UNDEFINED, true);
            }
          }
          break;
        case 'onDragEnd':
          if (externalDrop) {
            // This is needed, otherwise it creates event on drag click,
            // also, the temp resource might be undefined if dragged over a resource with event creation disabled
            if (isInArea && this._tempResource !== UNDEFINED) {
              this._onEventDragEnd(args);
            } else {
              this.setState({
                dragData: UNDEFINED,
                isTouchDrag: false,
              });
              this._hook<MbscEventDragEvent>('onEventDragEnd', {
                domEvent: args.domEvent,
                event: args.dragData!,
                resource: args.resource,
                slot: args.slot,
                source: args.source!,
              });
            }
          }
          break;
      }
    }
  };

  // #endregion Drag & Drop

  protected _getEventPos(
    event: MbscCalendarEventData,
    day: Date,
    dateKey: string,
    displayedMap: Map<MbscCalendarEvent, boolean>,
  ): IEventPosData | undefined {
    const s = this.s;
    const tzOpt = event.allDay ? UNDEFINED : s;
    const d = createDate(tzOpt, day.getFullYear(), day.getMonth(), day.getDate());
    const nextDay = getDateOnly(addDays(d, 1));
    const firstDay = tzOpt ? this._firstDayTz : this._firstDay;
    const lastDay = tzOpt ? this._lastDayTz : this._lastDay;
    const isTimeline = this._isTimeline;
    const groupByDate = !isTimeline && !this._groupByResource;
    const isAllDay = event.allDay;
    const origEvent = event.original!;
    const startTime = this._startTime;
    const endTime = this._endTime + 1;
    const displayedTime = this._time;
    const hasSlots = this._hasSlots;
    const hasResY = this._hasResY;
    const isDailyResolution = this._isDailyResolution;
    const isListing = s.eventList;

    let bufferAfter = '';
    let bufferBefore = '';
    let bufferStart = event.bufferStart;
    let bufferEnd = event.bufferEnd;
    let dayIndex = hasResY ? 0 : this._dayIndexMap[dateKey];
    let start = event.start;
    let end = event.end;
    let startDate = getEventLayoutStart(event, s, isListing, isTimeline, isDailyResolution, firstDay, this._cols, this._colIndexMap);
    let endDate = getEventLayoutEnd(event, s, isListing, isTimeline, isDailyResolution, lastDay, this._cols, this._colIndexMap);
    // Increase endDate by 1ms if start equals with end, to make sure we display
    // 0 length events at the beginning of displayed time range
    const adjust = +startDate === +endDate ? 1 : 0;
    const showBuffer = s.showEventBuffer !== false && !isListing && !isAllDay;

    if (!(isAllDay || isTimeline) || (hasResY && !hasSlots)) {
      if (startDate < d) {
        start = '';
        startDate = createDate(s, d);
      }

      if (endDate >= nextDay) {
        end = '';
        endDate = createDate(s, +nextDay - 1);
      }

      if (endDate >= nextDay) {
        endDate = createDate(s, +nextDay - 1);
      }
    }

    if (isAllDay || isTimeline) {
      if (!displayedMap.get(origEvent) || hasSlots || hasResY || groupByDate) {
        const startDay = s.startDay;
        const endDay = s.endDay;
        const isFullDay = isAllDay || isListing;
        const isMultiDay = !isSameDay(startDate, endDate);
        const daysNr = this._daysNr;

        if (isTimeline && isMultiDay && getDayMilliseconds(startDate) >= endTime) {
          startDate = createDate(s, +getDateOnly(startDate) + endTime);
        }

        const eventTime = calcTimelineTime(startDate, endDate, firstDay, lastDay, startTime, endTime, startDay, endDay, isFullDay);

        let leftPos = getEventStart(startDate, startTime, displayedTime, firstDay, startDay, endDay);
        let width = (eventTime * 100) / displayedTime;

        if (showBuffer && bufferStart) {
          const t = calcTimelineTime(bufferStart, startDate, firstDay, lastDay, startTime, endTime, startDay, endDay, isFullDay);
          bufferBefore = Math.max(0, (t * 100) / eventTime) + '%';
        }

        if (showBuffer && bufferEnd) {
          const t = calcTimelineTime(endDate, bufferEnd, firstDay, lastDay, startTime, endTime, startDay, endDay, isFullDay);
          bufferAfter = Math.max(0, (t * 100) / eventTime) + '%';
        }

        if (isTimeline) {
          let diff = 0;
          if (isListing && !isDailyResolution) {
            dayIndex = this._dayIndexMap[getDateStr(startDate)];
          }
          if (s.resolution === 'month' || s.resolution === 'quarter') {
            const startDayDiff = this._days[dayIndex].dayDiff;
            const endKey = getDateStr(endDate >= lastDay ? addDays(lastDay, -1) : endDate);
            const endIndex = this._dayIndexMap[endKey];
            const endDayDiff = this._days[endIndex].dayDiff;
            diff = endDayDiff - startDayDiff;
          }
          width = (width + diff * 100) / daysNr;
          leftPos = (leftPos + dayIndex * 100) / daysNr;
        }

        const position = isTimeline
          ? isFullDay
            ? {
                left: s.rtl ? '' : (hasSlots ? '' : (dayIndex * 100) / daysNr) + '%',
                right: s.rtl ? (hasSlots ? '' : (dayIndex * 100) / daysNr) + '%' : '',
                width: (hasSlots ? '' : width) + '%',
              }
            : {
                height: this._setRowHeight ? '' : '100%',
                left: s.rtl ? '' : leftPos + '%',
                right: s.rtl ? leftPos + '%' : '',
                top: '0',
                width: width + '%',
              }
          : {
              width: (isMultiDay && !groupByDate ? width : 100) + '%',
            };

        const isStartInView = getDayMilliseconds(startDate) < endTime && endDate > firstDay;
        const isEndInView = getDayMilliseconds(endDate) + adjust > startTime;
        // Skip events not in view
        if (isFullDay || (isMultiDay && width > 0) || (isStartInView && isEndInView)) {
          displayedMap.set(origEvent, true);

          return {
            bufferAfter,
            bufferBefore,
            end,
            endDate,
            position,
            start,
            startDate,
          };
        }
      }
    } else {
      // Skip events not in view
      if (getDayMilliseconds(startDate) < endTime && getDayMilliseconds(endDate) + adjust > startTime && endDate >= startDate) {
        // Need to use the original (inclusive) end date for proper height on DST day
        const eventTime = calcSchedulerTime(startDate, endDate, startTime, endTime);
        const eventHeight = (eventTime * 100) / displayedTime;

        if (showBuffer && bufferStart) {
          if (!isSameDay(bufferStart, startDate)) {
            bufferStart = createDate(s, +getDateOnly(startDate) + startTime);
          }
          const bufferTime = calcSchedulerTime(bufferStart, startDate, startTime, endTime);
          bufferBefore = (bufferTime * 100) / eventTime + '%';
        }

        if (showBuffer && bufferEnd) {
          if (!isSameDay(bufferEnd, startDate)) {
            bufferEnd = createDate(s, +getDateOnly(startDate) + endTime - 1);
          }
          const bufferTime = calcSchedulerTime(endDate, bufferEnd, startTime, endTime);
          bufferAfter = (bufferTime * 100) / eventTime + '%';
        }

        return {
          bufferAfter,
          bufferBefore,
          cssClass: eventHeight < 2 ? ' mbsc-schedule-event-small-height' : '',
          end,
          endDate,
          position: {
            height: eventHeight + '%',
            top: getEventStart(startDate, startTime, displayedTime) + '%',
            width: '100%',
          },
          start,
          startDate,
        };
      }
    }
    return UNDEFINED;
  }

  protected _getEventData(event: MbscCalendarEvent, d: Date, resource?: MbscResource, skipLabels?: boolean) {
    const s = this.s;
    const ev = getEventData(s, event, d, true, resource, false, !this._isTimeline || this._hasResY, this._isDailyResolution, skipLabels);

    if (event.allDay && s.exclusiveEndDates && +ev.endDate === +ev.startDate) {
      ev.endDate = getDateOnly(addDays(ev.startDate, 1));
    }

    return ev;
  }

  protected _getEvents(eventMap: { [key: string]: MbscCalendarEvent[] }): {
    [key: string]: { [key: string]: { [key: string]: IDailyData } };
  } {
    const s = this.s;
    const resources = this._resources;
    const slots = this._slots;
    const hasSlots = this._hasSlots;
    const hasResY = this._hasResY;
    const isTimeline = this._isTimeline;
    const isSchedule = !isTimeline;
    const events: { [key: string]: { [key: string]: { [key: string]: IDailyData } } } = {};
    const eventMaps = getResourceMap(eventMap, resources, slots, !!s.resources, !!s.slots);
    const eventLabels: { [key: string]: { [key: string]: ICalendarLabelData } } = {};
    const firstDay = this._firstDay;
    const lastDay = this._lastDay;
    const variableRow = this._setRowHeight;
    const connectionMap: { [key: string]: boolean } = {};
    const cols = this._cols;
    const allKey = 'all';
    const createEventMaps =
      this._createEventMaps ||
      s.renderHour ||
      s.renderHourFooter ||
      s.renderDay ||
      s.renderDayFooter ||
      s.renderWeek ||
      s.renderWeekFooter ||
      s.renderMonth ||
      s.renderMonthFooter ||
      s.renderQuarter ||
      s.renderQuarterFooter ||
      s.renderYear ||
      s.renderYearFooter;

    if (createEventMaps) {
      // reset event list calculated for columns
      cols.forEach((c) => (c.eventMap = { all: [] }));
    }

    if (s.connections) {
      for (const c of s.connections) {
        connectionMap[c.from] = true;
        connectionMap[c.to] = true;
      }
    }

    for (const resource of resources) {
      const resourceId = resource.id as string;
      const eventDisplayMap = new Map();
      let eventRows = 0;
      let groups: IGroupData[] = [];
      let next: { [key: string]: number } = {};

      const processGroups = (slotId: string | number, date?: Date) => {
        // Set the size and position of the events, based on the final layout
        for (let n = 0; n < groups.length; n++) {
          const group = groups[n];
          const nr = group.stacks.length;
          const moreNr = group.more.length;

          if (variableRow && nr > eventRows) {
            eventRows = nr;
          }

          if (!hasSlots) {
            for (let i = 0; i < nr; i++) {
              for (const event of group.stacks[i]) {
                const add = isTimeline && moreNr && !variableRow ? 1 : 0;
                const dimension = (((next[event.uid!] || nr + add) - i) / (nr + add)) * 100;
                if (isSchedule) {
                  event.position.width = dimension + '%';
                  event.position[s.rtl ? 'right' : 'left'] = (i * 100) / nr + '%';
                  event.position[s.rtl ? 'left' : 'right'] = 'auto';
                } else {
                  event.position.height = variableRow ? '' : dimension + '%';
                  event.position.top = variableRow ? i : (i * 100) / (nr + add) + '%';
                }
              }
            }
          }
          // Handle the more button
          if (moreNr) {
            let moreStart: Date | undefined;
            let moreEnd: Date | undefined;
            for (const event of group.more) {
              if (!moreStart || event.startDate < moreStart) {
                moreStart = event.startDate;
              }
              if (!moreEnd || event.endDate > moreEnd) {
                moreEnd = event.endDate;
              }
            }
            const moreDate = date || new Date(group.more[0].date);
            const moreDateKey = getDateStr(moreDate);
            const key = 'more-' + (isSchedule || hasResY ? moreDateKey + '-' : '') + resourceId;
            const mText = s.moreEventsText || '';
            const moreText = (moreNr > 1 ? s.moreEventsPluralText || mText : mText).replace(/{count}/, moreNr as any as string);
            const moreData = this._getEventData(
              {
                color: '#ddd',
                cssClass: 'mbsc-schedule-event-more',
                editable: false,
                end: moreEnd,
                id: key + (hasSlots ? (hasResY ? '' : '-' + moreDateKey) + '-' + slotId : '') + '-' + n,
                more: group.more,
                start: moreStart,
                text: (isSchedule ? '+' : '') + (isTimeline ? moreText : moreNr),
              },
              moreDate,
              resource,
            );
            const morePos = this._getEventPos(moreData, moreDate, moreDateKey, eventDisplayMap);
            if (morePos) {
              moreData.position = morePos.position;
              if (isTimeline) {
                moreData.position.height = variableRow ? '' : 100 / (nr + 1) + '%';
                moreData.position.top = hasSlots ? '' : variableRow ? nr : (nr * 100) / (nr + 1) + '%';
                events[resourceId][slotId][hasResY || hasSlots ? moreDateKey : allKey].data.push(moreData);
              } else {
                moreData.showText = true;
                moreData.position.width = '24px'; // TODO: em?
                moreData.position[s.rtl ? 'right' : 'left'] = 'auto';
                moreData.position[s.rtl ? 'left' : 'right'] = '-24px'; // TODO: em?
                events[resourceId][slotId][moreDateKey].hasMore = true;
                events[resourceId][slotId][moreDateKey].data.push(moreData);
              }
            }
            this._eventRows[key] = 1;
          }
        }
      };

      events[resourceId] = {};

      for (const slot of slots) {
        const slotId = slot.id;
        const eventsForSlot = eventMaps[resourceId][slotId];
        const eventKeys = Object.keys(eventsForSlot).sort();

        events[resourceId][slotId] = { all: { allDay: [], data: [] } };

        if (isSchedule) {
          eventLabels[slotId] = getLabels(s, eventsForSlot, firstDay, lastDay, -1, this._daysNr, true, s.startDay, false, s.eventOrder);
        }

        for (const dateKey of eventKeys) {
          // The date object is stored on the array for performance reasons, so we don't have to parse it all over again
          // TODO: do this with proper types
          const d: Date = (eventMap[dateKey] as any).date;
          if (this._dayIndexMap[dateKey] !== UNDEFINED && isInWeek(d.getDay(), s.startDay, s.endDay)) {
            const eventsForDay = sortEvents(eventsForSlot[dateKey]) || [];

            if (isSchedule || hasResY || hasSlots) {
              groups = [];
              next = {};
            }

            events[resourceId][slotId][dateKey] = { allDay: [], data: [] };

            if (hasResY) {
              eventRows = this._eventRows[dateKey + '-' + resourceId] || 0;
            }

            for (const ev of eventsForDay) {
              if (!ev.allDay || isTimeline) {
                const event = this._getEventData(ev, d, resource);
                const pos = this._getEventPos(event, d, dateKey, eventDisplayMap);
                event.position = UNDEFINED;

                if (pos) {
                  event.cssClass = pos.cssClass;
                  event.position = pos.position;
                  event.bufferAfter = pos.bufferAfter;
                  event.bufferBefore = pos.bufferBefore;
                  if (isSchedule || hasResY) {
                    event.showText = true;
                  }

                  calcLayout(
                    s,
                    groups,
                    event,
                    next,
                    this._maxEventStack || 1,
                    s.eventList,
                    isTimeline,
                    this._isDailyResolution,
                    firstDay,
                    this._firstDayTz,
                    lastDay,
                    this._lastDayTz,
                    this._cols,
                    this._colIndexMap,
                  );

                  events[resourceId][slotId][allKey].data.push(event);

                  this._eventMap[event.id] = event;

                  if (createEventMaps) {
                    const timeStep = this._stepCell;
                    const isHoursResolution = this._isDailyResolution && timeStep < 1440 * ONE_MIN;
                    const firstDayTz = ev.allDay ? firstDay : addTimezone(s, firstDay);
                    const first = event.startDate > firstDayTz ? event.startDate : firstDayTz;
                    let colIndex = this._colIndexMap[getDateStr(first)];
                    let overlap = true;
                    while (overlap && colIndex < cols.length) {
                      const col = cols[colIndex];
                      const dtStart = col.date;
                      const dtEnd = colIndex < cols.length - 1 ? cols[colIndex + 1].date : lastDay;
                      let start = dtStart;
                      let addedToAll = false;
                      while (start < dtEnd) {
                        const ts = +start;
                        const end = isHoursResolution ? new Date(ts + timeStep) : dtEnd;
                        const colStart = ev.allDay ? dtStart : addTimezone(s, start);
                        const colEnd = ev.allDay ? dtEnd : addTimezone(s, end);
                        if (checkDateRangeOverlap(event.startDate, event.endDate, colStart, colEnd, true)) {
                          if (!col.eventMap[ts]) {
                            col.eventMap[ts] = [];
                          }
                          if (!addedToAll) {
                            // In case of hourly resolution we also need to store the event for the day, not just hour
                            col.eventMap.all.push(event.original!);
                            addedToAll = true;
                          }
                          col.eventMap[ts].push(event.original!);
                          overlap = true;
                        } else {
                          overlap = false;
                        }
                        start = end;
                      }
                      colIndex++;
                    }
                  }
                }

                // Add the event to the daily groups even if not in view, needed for overlap check
                events[resourceId][slotId][dateKey].data.push(event);
                if (isTimeline && ev.allDay) {
                  events[resourceId][slotId][dateKey].allDay.push(event);
                }
              }
            }

            // All day events for scheduler
            if (isSchedule && eventLabels[slotId][dateKey]) {
              eventLabels[slotId][dateKey].data.forEach(({ event, width }) => {
                if (event) {
                  const ev = this._getEventData(event, d, resource);
                  const pos = this._getEventPos(ev, d, dateKey, eventDisplayMap);
                  if (pos) {
                    ev.bufferAfter = pos.bufferAfter;
                    ev.bufferBefore = pos.bufferBefore;
                  }
                  ev.position = { width: pos ? pos.position!.width : width };
                  ev.showText = !!pos;
                  events[resourceId][slotId][dateKey].allDay.push(ev);
                }
              });
            }

            if (isSchedule || hasResY || hasSlots) {
              processGroups(slotId, d);
            }

            if (hasResY) {
              this._eventRows[dateKey + '-' + resourceId] = eventRows || 1;
            }
          } else if (s.connections) {
            // Process the events which are part of a connection, but not shown on the view
            const eventsForDay = eventsForSlot[dateKey] || [];
            for (const event of eventsForDay) {
              const id = event.id!;
              if (!this._eventMap[id] && connectionMap[id]) {
                this._eventMap[id] = this._getEventData(event, d, resource);
              }
            }
          }
        }
      }

      // In case of the timeline, calculate the layout for the whole displayed view, not just per day
      if (isTimeline && !hasSlots && !hasResY) {
        processGroups(DEF_ID);
      }

      if (!hasResY) {
        this._eventRows[resourceId] = eventRows || 1; // make sure the min-height will be at least 1 event tall
      }
    }

    return events;
  }

  protected _getInvalids(invalidMap: { [key: string]: MbscCalendarEvent[] }): {
    [key: string]: { [key: string]: { [key: string]: IDailyData } };
  } {
    const s = this.s;
    const isListing = s.eventList;
    const map = invalidMap || {};
    const invalids: { [key: string]: { [key: string]: { [key: string]: IDailyData } } } = {};
    const minDate = isListing ? getDateOnly(new Date(s.minDate)) : new Date(s.minDate);
    const maxDate = isListing ? getDateOnly(addDays(new Date(s.maxDate), 1)) : new Date(s.maxDate);
    const isTimeline = this._isTimeline;

    if (s.minDate) {
      for (const d = getDateOnly(this._firstDay); d < minDate; d.setDate(d.getDate() + 1)) {
        const dateKey = getDateStr(d);
        const invalidsForDay = map[dateKey] || [];
        invalidsForDay.push({
          end: minDate,
          start: new Date(d),
        });
        map[dateKey] = invalidsForDay;
      }
    }

    if (s.maxDate) {
      for (const d = getDateOnly(maxDate); d < this._lastDay; d.setDate(d.getDate() + 1)) {
        const dateKey = getDateStr(d);
        const invalidsForDay = map[dateKey] || [];
        invalidsForDay.push({
          end: new Date(this._lastDay),
          start: maxDate,
        });
        map[dateKey] = invalidsForDay;
      }
    }

    const invalidMaps = getResourceMap(map, this._resources, this._slots, !!s.resources, !!s.slots);
    const invalidKeys = Object.keys(map).sort();

    for (const resource of this._resources) {
      const resourceId = resource.id;
      const invalidDisplayedMap = new Map();
      invalids[resourceId] = {};
      for (const slot of this._slots) {
        const slotId = slot.id;
        const allInvalids: IDailyData = { allDay: [], data: [] };
        invalids[resourceId][slotId] = { all: allInvalids };

        for (const dateKey of invalidKeys) {
          const d = makeDate(dateKey);
          if (this._dayIndexMap[dateKey] !== UNDEFINED && isInWeek(d.getDay(), s.startDay, s.endDay)) {
            const invalidsForDay = invalidMaps[resourceId][slotId][dateKey] || [];
            // Contains all invalids for the day
            const allDailyInvalids: IDailyData = { allDay: [], data: [] };
            // Only contains invalids beginning on the day, for the timeline
            let dailyInvalids: MbscCalendarEventData[] = [];

            invalids[resourceId][slotId][dateKey] = allDailyInvalids;

            for (let invalid of invalidsForDay) {
              // if a string or a date object is passed
              if (isString(invalid) || isDate(invalid)) {
                const start = makeDate(invalid);
                const end = new Date(start);
                invalid = { allDay: true, end, start };
              }

              const invalidData = this._getEventData(invalid, d, resource, true);
              invalidData.cssClass = invalid.cssClass ? ' ' + invalid.cssClass : '';
              invalidData.position = UNDEFINED;

              const pos = this._getEventPos(invalidData, d, dateKey, invalidDisplayedMap);
              if (pos) {
                // If the invalid spans across the whole day, make it invalid
                if (!isTimeline && getDayMilliseconds(pos.startDate) === 0 && new Date(+pos.endDate + 1) >= addDays(d, 1)) {
                  invalidData.allDay = true;
                } else {
                  invalidData.position = pos.position;
                  if (getDayMilliseconds(pos.startDate) <= this._startTime) {
                    invalidData.cssClass += ' mbsc-schedule-invalid-start';
                  }
                  if (getDayMilliseconds(pos.endDate) >= this._endTime) {
                    invalidData.cssClass += ' mbsc-schedule-invalid-end';
                  }
                }
                dailyInvalids.push(invalidData);
              }

              allDailyInvalids.data.push(invalidData);

              if (invalidData.allDay) {
                if (!isTimeline) {
                  invalidData.position = {};
                  // Extend invalid range to the end of the day
                  if (pos && +invalidData.startDate === +invalidData.endDate) {
                    invalidData.endDate = pos.endDate;
                  }
                }
                allDailyInvalids.allDay = [invalidData];
                allDailyInvalids.data = [invalidData];
                dailyInvalids = [invalidData];
                break;
              }
            }
            allInvalids.data.push(...dailyInvalids);
          }
        }
      }
    }
    return invalids;
  }

  protected _getColors(colorMap: { [key: string]: MbscCalendarEvent[] }): {
    [key: string]: { [key: string]: { [key: string]: IDailyData } };
  } {
    const s = this.s;
    const colors: { [key: string]: { [key: string]: { [key: string]: IDailyData } } } = {};
    const colorMaps = getResourceMap(colorMap, this._resources, this._slots, !!s.resources, !!s.slots);
    const colorKeys = Object.keys(colorMap || {}).sort();
    const hasSlots = this._hasSlots;
    const isTimeline = this._isTimeline;
    const hasResY = this._hasResY;

    for (const resource of this._resources) {
      const resourceId = resource.id;
      const colorDisplayedMap = new Map();
      colors[resourceId] = {};

      for (const slot of this._slots) {
        const slotId = slot.id;
        colors[resourceId][slotId] = { all: { allDay: [], data: [] } };

        for (const dateKey of colorKeys) {
          const d = makeDate(dateKey);
          if (this._dayIndexMap[dateKey] !== UNDEFINED && isInWeek(d.getDay(), s.startDay, s.endDay)) {
            const colorsForDay = colorMaps[resourceId][slotId][dateKey] || [];
            const key = !hasResY && !hasSlots && isTimeline ? 'all' : dateKey;

            if (!isTimeline || hasSlots || hasResY) {
              colors[resourceId][slotId][key] = { allDay: [], data: [] };
            }

            const dailyColors = colors[resourceId][slotId][key];

            for (const color of colorsForDay) {
              const colorData = this._getEventData(color, d, resource, true);
              colorData.cssClass = color.cssClass ? ' ' + color.cssClass : '';

              if (colorData.allDay && !isTimeline) {
                dailyColors.allDay = [colorData];
              } else {
                const pos = this._getEventPos(colorData, d, dateKey, colorDisplayedMap);
                if (pos) {
                  colorData.position = pos.position;
                  if (getDayMilliseconds(pos.startDate) <= this._startTime) {
                    colorData.cssClass += ' mbsc-schedule-color-start';
                  }
                  if (getDayMilliseconds(pos.endDate) >= this._endTime) {
                    colorData.cssClass += ' mbsc-schedule-color-end';
                  }
                  dailyColors.data.push(colorData);
                }
              }
              colorData.position.background = color.background;
              colorData.position.color = color.textColor ? color.textColor : getTextColor(color.background);
            }
          }
        }
      }
    }
    return colors;
  }

  protected _flattenResources(
    resources: MbscResource[] | null | undefined,
    flat: MbscResource[],
    depth: number,
    copy?: MbscResource[],
    fixed?: boolean,
  ) {
    const res = resources && resources.length ? resources : [{ id: DEF_ID }];
    const immutable = this.s.immutableData;
    for (const resource of res) {
      const r = copy && immutable ? { ...resource } : resource;
      const children = r.children;
      if (immutable) {
        r.original = resource;
      }
      r.depth = depth;
      r.isParent = !!(children && children.length);
      r.fixed = r.fixed || fixed;
      flat.push(r);
      this._resourcesMap[r.id] = r;
      if (copy && (immutable || !depth)) {
        copy.push(r);
      }
      if (r.isParent) {
        this._hasHierarchy = true;
        if (!r.collapsed || copy) {
          if (copy && immutable) {
            r.children = [];
          }
          this._flattenResources(children, flat, depth + 1, copy && r.children, r.fixed);
        }
      }
    }
    return flat;
  }

  // #region Lifecycle hooks

  protected _render(s: ISTOptions, state: ISTState) {
    const prevS = this._prevS;
    const isTimeline = this._isTimeline;
    const selected = new Date(s.selected);
    const size = +s.size!;
    const stepLabel = roundStep(s.timeLabelStep);
    const stepCell = roundStep(s.timeCellStep);
    const firstDay = s.firstDay!;
    const startDay = s.startDay;
    const endDay = s.endDay;
    const resources = s.resources;
    const slots = s.slots;
    const disableVirtual = s.virtualScroll === false;
    const resolution = s.resolution;
    const isDailyResolution = resolution === 'day' || resolution === 'hour' || !isTimeline;
    const hasResY = s.resolutionVertical === 'day';

    let calcDays = false;
    let reloadData = false;
    let startTime = this._startTime;
    let endTime = this._endTime;

    if (
      startDay !== prevS.startDay ||
      endDay !== prevS.endDay ||
      s.checkSize !== prevS.checkSize ||
      s.eventList !== prevS.eventList ||
      s.refDate !== prevS.refDate ||
      s.size !== prevS.size ||
      s.type !== prevS.type ||
      s.resolution !== prevS.resolution ||
      s.resolutionVertical !== prevS.resolutionVertical ||
      s.displayTimezone !== prevS.displayTimezone ||
      s.weekNumbers !== prevS.weekNumbers
    ) {
      calcDays = true;
      this._viewChanged = true;
    }

    if (
      calcDays ||
      s.rtl !== prevS.rtl ||
      s.dateFormat !== prevS.dateFormat ||
      s.getDay !== prevS.getDay ||
      s.rowHeight !== prevS.rowHeight ||
      s.maxEventStack !== prevS.maxEventStack
    ) {
      reloadData = true;
    }

    if (
      s.startTime !== prevS.startTime ||
      s.endTime !== prevS.endTime ||
      s.timeLabelStep !== prevS.timeLabelStep ||
      s.timeCellStep !== prevS.timeCellStep ||
      s.timeFormat !== prevS.timeFormat ||
      this._startTime === UNDEFINED ||
      this._endTime === UNDEFINED
    ) {
      const start = makeDate(s.startTime || '00:00');
      const end = new Date(+makeDate(s.endTime || '00:00') - 1);

      this._startTime = startTime = getDayMilliseconds(start);
      this._endTime = endTime = getDayMilliseconds(end);
      this._time = endTime - startTime + 1;
      this._timesBetween = getArray(floor(stepCell / stepLabel) - 1);
      this._times = [];
      this._timeLabels = {};
      this._viewChanged = true;

      const timeStep = stepCell * ONE_MIN;
      const timesFrom = floor(startTime / timeStep) * timeStep;

      for (let d = timesFrom; d <= endTime; d += timeStep) {
        this._times.push(d);
        if (isTimeline) {
          // Pre-generate time labels to prevent in on every render
          const first = d === timesFrom;
          this._timeLabels[d] = first || d % (stepLabel * ONE_MIN) === 0 ? this._formatTime(first ? startTime : d) : '';
          this._timesBetween.forEach((tb, i) => {
            const ms = d + (i + 1) * stepLabel * ONE_MIN;
            this._timeLabels[ms] = this._formatTime(ms);
          });
        }
      }
      reloadData = true;
    }

    if (s.slots !== prevS.slots || this._slots === UNDEFINED) {
      this._hasSlots = isTimeline && !!slots && slots.length > 0;
      this._slots = slots && slots.length ? slots : [{ id: DEF_ID }];
      reloadData = true;
    }

    if (resources !== prevS.resources || this._resources === UNDEFINED) {
      this._hasResources = !!resources && resources.length > 0;
      this._hasHierarchy = false;
      this._resourcesMap = {};
      this._resourcesCopy = [];
      this._resources = this._flattenResources(resources, [], 0, this._resourcesCopy);
      this._visibleResources = this._flattenResources(this._resourcesCopy, [], 0);
      this._isSingleResource = this._resources.length === 1;
      reloadData = true;
    }

    if (
      calcDays ||
      s.selected !== prevS.selected ||
      s.getDay !== prevS.getDay ||
      s.monthNames !== prevS.monthNames ||
      s.dateFormat !== prevS.dateFormat ||
      s.currentTimeIndicator !== prevS.currentTimeIndicator
    ) {
      const now = removeTimezone(createDate(s));
      const isDaily = s.type === 'day';
      const isMonthly = s.type === 'month';
      const isYearly = s.type === 'year';
      const isDayViewOnly = isDaily && size < 2;
      const navService = s.navService!;
      const monthPos = s.dateFormat!.search(/m/i);
      const yearPos = s.dateFormat!.search(/y/i);
      const datePos = s.dateFormat!.search(/d/i);
      const yearFirst = yearPos < monthPos;
      const dayFirst = datePos < monthPos;
      let firstGridDay: Date;
      let lastGridDay: Date;
      let firstHeaderDay: Date;
      let lastHeaderDay: Date;

      if (size > 1 || isYearly || isMonthly) {
        firstHeaderDay = firstGridDay = navService.firstDay;
        lastHeaderDay = lastGridDay = navService.lastDay;
      } else {
        const firstWeekDay = getFirstDayOfWeek(selected, s);
        firstHeaderDay = addDays(firstWeekDay, startDay - firstDay + (startDay < firstDay ? 7 : 0));
        if (isDaily) {
          // When startDay is different from the locale firstDay, the selected day might end up
          // outside of the week defined by startDay and end Day
          if (selected < firstHeaderDay) {
            firstHeaderDay = addDays(firstHeaderDay, -7);
          }
          if (selected >= addDays(firstHeaderDay, 7)) {
            firstHeaderDay = addDays(firstHeaderDay, 7);
          }
        }
        lastHeaderDay = addDays(firstHeaderDay, endDay - startDay + 1 + (endDay < startDay ? 7 : 0));
        firstGridDay = isDaily ? getDateOnly(selected) : firstHeaderDay;
        lastGridDay = isDaily ? addDays(firstGridDay, 1) : lastHeaderDay;
      }

      if (isTimeline && resolution === 'week' && (isYearly || isMonthly)) {
        firstGridDay = navService.viewStart;
        lastGridDay = navService.viewEnd;
      }

      // When a weekly calendar is also displayed, eventMap is not regenerated on day change,
      // so we need to enforce data reload to regenerate all-day labels for the scheduler.
      if (s.selected !== prevS.selected && isDaily && size < 2) {
        reloadData = true;
      }

      this._isMulti = size > 1 || isYearly;
      this._isDailyResolution = isDailyResolution;
      this._hasResY = hasResY;
      this._firstDayTz = createDate(s, firstGridDay.getFullYear(), firstGridDay.getMonth(), firstGridDay.getDate());
      this._lastDayTz = createDate(s, lastGridDay.getFullYear(), lastGridDay.getMonth(), lastGridDay.getDate());
      this._selectedDay = +getDateOnly(selected);
      this._setRowHeight = s.eventList || s.rowHeight !== 'equal';
      this._shouldAnimateScroll = prevS.selected !== UNDEFINED && s.selected !== prevS.selected && !this._viewChanged;
      this._showTimeIndicator =
        !s.eventList &&
        (s.currentTimeIndicator === UNDEFINED ? !isTimeline || (isDailyResolution && stepCell < 1440) : s.currentTimeIndicator) &&
        (isDaily && size < 2 ? isSameDay(now, selected) : firstGridDay <= now && lastGridDay >= now);

      if (reloadData || +firstGridDay !== +this._firstDay || +lastGridDay !== +this._lastDay) {
        this._firstDay = firstGridDay;
        this._lastDay = lastGridDay;

        // Generate day data
        this._colIndexMap = {};
        this._cols = [];
        this._dayIndexMap = {};
        this._days = [];
        this._headerDays = [];

        let i = 0;
        let j = -1;
        let dayDiff = 0;
        let daysInMonth = 0;
        let year = -1;
        let columnTitle = '';
        let month = -1;
        let monthIndex = -1;
        let monthText = '';
        let week = -1;
        let weekIndex = -1;
        let weekText = '';
        let first = firstGridDay;
        let last = lastGridDay;
        let lastColStart = 0;
        let weekEndDay: Date | undefined = UNDEFINED;
        let newWeek = 0;

        if (!isTimeline && isDayViewOnly) {
          first = firstHeaderDay;
          last = lastHeaderDay;
        }

        for (const d = getDateOnly(first); d < getDateOnly(last); d.setDate(d.getDate() + 1)) {
          const dateKey = getDateStr(d);
          const weekDay = d.getDay();
          this._dayIndexMap[dateKey] = i;
          if (isInWeek(weekDay, startDay, endDay)) {
            let lastOfMonth: boolean | undefined;
            let monthTitle = '';
            let weekTitle = '';
            let columnChange = isDailyResolution;

            if (isTimeline && !hasResY) {
              newWeek = s.getWeekNumber!(addDays(d, (7 - firstDay + 1) % 7));
              const newDay = s.getDay!(d);
              const newMonth = s.getMonth!(d);
              const newYear = s.getYear!(d);
              const monthName = s.monthNames![newMonth];

              if (year !== newYear) {
                year = newYear;
                if (resolution === 'year') {
                  columnChange = true;
                  columnTitle = '' + year;
                }
              }

              if (month !== newMonth) {
                if (resolution === 'month') {
                  columnTitle = isYearly && size < 2 ? monthName : yearFirst ? newYear + ' ' + monthName : monthName + ' ' + newYear;
                  columnChange = true;
                } else if (resolution === 'quarter' && newMonth % 3 === 0) {
                  const quarterNumber = newMonth / 3 + 1;
                  const quarterText = s.quarterText!.replace('{count}', '' + quarterNumber);
                  columnTitle = isYearly && size < 2 ? quarterText : yearFirst ? newYear + ' ' + quarterText : quarterText + ' ' + newYear;
                  columnChange = true;
                } else if (isDailyResolution) {
                  monthText = yearFirst ? newYear + ' ' + monthName : monthName + ' ' + newYear;
                  monthTitle = monthText;
                }

                monthIndex = i;
                month = newMonth;
                daysInMonth = s.getMaxDayOfMonth!(year, month);
              }

              if (week !== newWeek) {
                weekIndex = i;
                week = newWeek;
                weekText = s.weekText!.replace(/{count}/, week as any as string);
                weekTitle = weekText;
                if (i > 0) {
                  this._days[i - 1].lastOfWeek = true;
                }
              }

              if ((weekDay === startDay || !i) && resolution === 'week') {
                const dateFormat = dayFirst ? 'D MMM' : 'MMM D';
                weekEndDay = addDays(d, endDay - startDay + (endDay < startDay ? 7 : 0));
                columnTitle = formatDate(dateFormat, d, s) + ' - ' + formatDate(dateFormat, weekEndDay, s);
                columnChange = true;
              }

              const hiddenWeekDays = (startDay - endDay - 1 + 7) % 7;
              lastOfMonth = newDay === daysInMonth || (weekDay === endDay && hiddenWeekDays >= daysInMonth - newDay);

              if (lastOfMonth && (resolution === 'month' || resolution === 'quarter')) {
                dayDiff += 31 - daysInMonth;
              }
            }

            const dayData: IDayData = {
              columnTitle,
              date: new Date(d),
              dateIndex: i,
              dateKey,
              dateText: formatDate(
                hasResY
                  ? isMonthly && !this._isMulti
                    ? 'D DDD'
                    : resources
                    ? s.dateFormatLong!
                    : s.dateFormat!
                  : isMonthly || this._isMulti
                  ? 'D DDD'
                  : s.dateFormatLong!,
                d,
                s,
              ),
              day: s.getDay!(d),
              dayDiff,
              endDate: weekEndDay,
              eventMap: { all: [] },
              label: formatDate('DDDD, MMMM D, YYYY', d, s),
              lastOfMonth,
              monthIndex,
              monthText,
              monthTitle,
              timestamp: +getDateOnly(d),
              weekIndex,
              weekNr: newWeek,
              weekText,
              weekTitle,
            };

            if (columnChange) {
              dayData.isActive = d <= now && now < last;
              if (lastColStart) {
                this._cols[j].isActive = lastColStart <= +now && now < d;
              }
              lastColStart = +d;
              this._cols.push(dayData);
              j++;
            }

            if (isDayViewOnly) {
              this._headerDays.push(dayData);
            }

            if (!isDayViewOnly || this._selectedDay === +d) {
              this._days.push(dayData);
            }

            if (lastOfMonth && (resolution === 'month' || resolution === 'quarter')) {
              // Since month widths are equal, we handle each month as 31 days,
              // and fill the remaining days with the data of the last day of the month
              for (let k = daysInMonth; k < 31; k++) {
                this._days.push(dayData);
                i++;
              }
            }
            i++;
          }
          this._colIndexMap[dateKey] = j < 0 ? 0 : j;
        }
        this._colsNr = hasResY ? 1 : j + 1;
        this._daysNr = hasResY || isDayViewOnly ? 1 : i;
      }
    }

    this._groupByResource = (s.groupBy !== 'date' && !(s.type === 'day' && size < 2)) || this._isSingleResource;
    this._stepCell = stepCell * ONE_MIN;
    this._stepLabel = stepLabel * ONE_MIN;
    this._dayNames = state.dayNameWidth! > 49 ? s.dayNamesShort! : s.dayNamesMin!;
    this._displayTime = stepLabel < 1440 && isDailyResolution;
    this._eventHeight = state.eventHeight || (s.eventList ? 24 : 46);
    this._showCursorTime = this._displayTime && !!(s.dragToCreate || s.dragToMove || s.dragToResize);

    if (s.maxEventStack !== 'auto') {
      this._maxEventStack = s.maxEventStack || 'all';
    }

    // if (this._viewChanged && this._events) {
    //   this._scrollToMiddle = true;
    // }

    if (s.colorsMap !== prevS.colorsMap || reloadData) {
      this._colors = this._getColors(s.colorsMap!);
    }

    if (s.eventMap !== prevS.eventMap || s.showEventBuffer !== prevS.showEventBuffer || reloadData || !this._events || this._reloadEvents) {
      this._eventMap = {};
      this._eventRows = {};
      this._events = this._getEvents(s.eventMap!);
      this._reloadEvents = false;
    }

    if (s.invalidsMap !== prevS.invalidsMap || reloadData) {
      this._invalids = this._getInvalids(s.invalidsMap!);
    }

    // We need to check the event height in case of timeline
    const checkEventHeight = isTimeline && s.eventMap !== prevS.eventMap;

    if (s.height !== prevS.height || s.width !== prevS.width || checkEventHeight || reloadData) {
      this._shouldCheckSize = isBrowser && !!s.height && !!s.width;
    }

    if (s.scroll !== prevS.scroll) {
      this._shouldScroll = true;
    }

    if (s.height !== UNDEFINED) {
      // Only set sticky on the second render, to solve SSR different markup issues
      this._hasSideSticky = hasSticky && !s.rtl;
      this._hasSticky = hasSticky;
    }

    // Calculate day batches for virtual scroll
    if (isTimeline) {
      const cols = this._cols;
      const colsNr = this._colsNr;
      const daysBatch: IDayData[] = [];
      // limit rendered days to min 1 day & max 30(maxBatchDay) days
      const daysBatchNr = this._daysBatchNr === UNDEFINED ? constrain(floor(this._stepCell / (this._time / 30)), 1, 30) : this._daysBatchNr;
      const dayIndex = this._dayIndexMap[getDateStr(selected)] || 0;
      const batchIndexX = state.batchIndexX !== UNDEFINED ? state.batchIndexX : round(dayIndex / daysBatchNr);
      // limit the batch day index within the displayed days (it can be bigger if switching from a big view to a smaller one)
      const batchDayIndex = Math.min(batchIndexX * daysBatchNr, colsNr - 1);
      const batchStart = disableVirtual ? 0 : Math.max(0, batchDayIndex - floor((daysBatchNr * 3) / 2));
      const batchEnd = disableVirtual ? colsNr : Math.min(batchStart + 3 * daysBatchNr, colsNr);
      const batchStartDay = cols[batchStart].date;
      const batchEndDay = batchEnd < colsNr ? cols[batchEnd].date : this._lastDay;
      const addedCols: { [key: string]: boolean } = {};
      for (let i = batchStart; i < batchEnd; i++) {
        addedCols[cols[i].dateKey] = true;
        daysBatch.push(cols[i]);
      }

      this._batchStart = createDate(s, batchStartDay.getFullYear(), batchStartDay.getMonth(), batchStartDay.getDate());
      this._batchEnd = createDate(s, batchEndDay.getFullYear(), batchEndDay.getMonth(), batchEndDay.getDate());
      this._daysBatch = daysBatch;
      this._daysBatchNr = daysBatchNr;
      this._placeholderSizeX = disableVirtual ? 0 : state.dayWidth! * round(Math.max(0, batchDayIndex - (daysBatchNr * 3) / 2)) || 0;
      this._rowHeights = {};
      this._dragCol = '';
      this._dragRow = '';
      this._fixedResources = [];
      this._fixedResourceTops = {};
      this._fixedHeight = state.headerHeight || 0;

      // vertical virtual scroll
      const gridContHeight = (state.scrollContHeight || 0) - (state.headerHeight || 0) - (state.footerHeight || 0);
      const rowHeight = state.rowHeight || 52;
      const parentRowHeight = state.parentRowHeight || 52;
      const gutterHeight = state.gutterHeight !== UNDEFINED ? state.gutterHeight : 16;
      const batchIndexY = state.batchIndexY || 0;
      const visibleResources = this._visibleResources;
      const verticalDays: IDayData[] = hasResY ? this._days : [{} as IDayData];
      const totalRows = visibleResources.length * verticalDays.length;
      const rows: Array<{ dayIndex: number; key: string; resource: MbscResource }> = [];
      const rowBatch: Array<{ day?: IDayData; hidden?: boolean; rows: MbscResource[] }> = [];
      const addedGroups: { [key: string]: { day?: IDayData; hidden?: boolean; rows: MbscResource[] } } = {};
      const addedRows: { [key: string]: boolean } = {};
      const resourceMap: { [key: string]: MbscResource } = {};
      const virtualPagesY: IVirtualPage[] = [];

      let gridHeight = 0;
      let pageHeight = 0;

      // calculate virtual pages for vertical scroll
      if (state.hasScrollY) {
        this._resourceTops = {};
      }
      verticalDays.forEach((d, i) => {
        visibleResources.forEach((r, j) => {
          const key = (hasResY ? d.dateKey + '-' : '') + r.id;
          resourceMap[key] = r;
          if (gridContHeight) {
            // in case of event listing the default calculated height is less then css min-height
            const currRowHeight = r.children ? parentRowHeight : rowHeight;
            const showMoreHeight = this._eventRows['more-' + key] ? 24 : 0; // TODO: custom height?
            const resHeight = this._setRowHeight
              ? r.eventCreation === false
                ? currRowHeight
                : Math.max((this._eventRows[key] || 1) * this._eventHeight + gutterHeight + showMoreHeight, currRowHeight)
              : currRowHeight;

            this._rowHeights[key] = this._setRowHeight ? resHeight + 'px' : UNDEFINED;

            if (!hasResY && r.fixed) {
              // if (j === 0) {
              //   this._fixedHeight = state.headerHeight || 0;
              // }
              this._fixedResourceTops[key] = this._fixedHeight;
              this._fixedHeight += resHeight;
              this._fixedResources.push({
                height: resHeight,
                index: j,
                key,
                resource: r,
              });
            }

            if (state.hasScrollY) {
              // Store resource row tops if there is vertical scroll
              this._resourceTops[key] = gridHeight;
            }
            if (!pageHeight) {
              virtualPagesY.push({
                startIndex: i * visibleResources.length + j,
                top: gridHeight,
              });
            }
            gridHeight += resHeight;
            pageHeight += resHeight;
            if (pageHeight > gridContHeight) {
              pageHeight = 0;
            }
          }
          rows.push({ dayIndex: i, key, resource: r });
        });
      });

      const startPage = virtualPagesY[batchIndexY - 1];
      const endPage = virtualPagesY[batchIndexY + 2];
      let batchStartY = startPage ? startPage.startIndex : 0;
      // Render max 30 resources on the initial render
      let batchEndY = endPage ? endPage.startIndex : gridHeight ? totalRows : 30;

      // When there's no scroll, render all rows
      if (disableVirtual || (gridHeight && gridHeight <= gridContHeight)) {
        batchStartY = 0;
        batchEndY = totalRows;
      }

      let rowGroup: MbscResource[] = [];
      let lastDayIndex = -1;

      for (let i = batchStartY; i < batchEndY; i++) {
        const row = rows[i];
        if (row) {
          const currDayIndex = row.dayIndex;
          if (lastDayIndex !== currDayIndex) {
            rowGroup = [];
            rowBatch.push({ day: hasResY ? this._days[currDayIndex] : UNDEFINED, rows: rowGroup });
            lastDayIndex = currDayIndex;
            addedGroups[currDayIndex] = rowBatch[rowBatch.length - 1];
          }
          addedRows[row.key] = true;
          rowGroup.push(row.resource);
        }
      }

      let compensateY = 0;

      for (const r of this._fixedResources) {
        if (!addedRows[r.key]) {
          rowGroup.unshift(r.resource);
          addedRows[r.key] = true;
          compensateY += r.height;
        }
      }

      // Add the row of the dragged event, if not on the virtual page, otherwise mouse and touch events will stop firing
      if (state.dragData && state.dragData.originResource !== UNDEFINED) {
        const resource = state.dragData.originResource;
        const dateKey = getDateStr(new Date(state.dragData.originDate!));
        const key = (hasResY ? dateKey + '-' : '') + resource;
        const groupIndex = hasResY ? this._dayIndexMap[dateKey] : 0;
        const colIndex = hasResY ? this._colIndexMap[dateKey] : 0;
        const colKey = cols[colIndex].dateKey;
        if (!addedRows[key]) {
          let group = addedGroups[groupIndex];
          if (!group) {
            group = { day: hasResY ? this._days[groupIndex] : UNDEFINED, hidden: true, rows: [] };
            rowBatch.push(group);
          }
          group.rows.push(resourceMap[key]);
          this._dragRow = key;
        }
        if (!hasResY && !addedCols[colKey]) {
          this._dragCol = colKey;
          daysBatch.push(cols[colIndex]);
        }
      }

      //added below line to filter rows array object
      let modifiedRowBatch = rowBatch.map((rr: any) => ({"day" : rr.day, "rows": rr.rows.filter((rItem:any) => rItem)}))
      this._gridHeight = gridHeight;
      this._virtualPagesY = virtualPagesY;
      this._colClass = resources || !hasResY ? 'mbsc-timeline-resource-col' : 'mbsc-timeline-date-col';
      this._hasRows = this._hasResources || hasResY;
      this._rows = rows;
      this._rowBatch = modifiedRowBatch;
      this._placeholderSizeY = startPage && !disableVirtual ? startPage.top - compensateY : 0;
    }
  }

  protected _mounted() {
    let allowCreate: boolean;
    let allowStart: boolean;
    let validTarget: boolean | null;
    this._unlisten = gestureListener(this._el, {
      onDoubleClick: (args: ICalendarEventDragArgs) => {
        const s = this.s;
        if (validTarget && s.clickToCreate && s.clickToCreate !== 'single') {
          args.click = true;
          if (this._onEventDragStart(args)) {
            this._onEventDragEnd(args);
          }
        }
      },
      onEnd: (args: ICalendarEventDragArgs) => {
        if (!allowCreate && allowStart && this.s.clickToCreate === 'single') {
          args.click = true;
          if (this._onEventDragStart(args)) {
            allowCreate = true;
          }
        }
        if (allowCreate) {
          // Will prevent mousedown event on doc, which would exit drag mode
          args.domEvent.preventDefault();
          this._onEventDragEnd(args);
        }
        clearTimeout(this._touchTimer);
        allowCreate = false;
        allowStart = false;
      },
      onMove: (args: ICalendarEventDragArgs) => {
        const s = this.s;
        if (allowCreate && s.dragToCreate) {
          args.domEvent.preventDefault();
          this._onEventDragMove(args);
        } else if (allowStart && s.dragToCreate && (Math.abs(args.deltaX) > 7 || Math.abs(args.deltaY) > 7)) {
          if (this._onEventDragStart(args)) {
            allowCreate = true;
          } else {
            allowStart = false;
          }
        } else {
          clearTimeout(this._touchTimer);
        }
      },
      onStart: (args: ICalendarEventDragArgs) => {
        const s = this.s;
        args.create = true;
        args.click = false;
        this._isTouch = args.isTouch;
        if (!allowCreate && (s.dragToCreate || s.clickToCreate)) {
          const targetClasses = args.domEvent.target && (args.domEvent.target as HTMLElement).classList;
          validTarget =
            targetClasses &&
            (targetClasses.contains('mbsc-schedule-item') ||
              targetClasses.contains('mbsc-schedule-all-day-item') ||
              targetClasses.contains('mbsc-timeline-column'));
          if (validTarget) {
            if (args.isTouch && s.dragToCreate) {
              this._touchTimer = setTimeout(() => {
                if (this._onEventDragStart(args)) {
                  this._onEventDragModeOn(args);
                  allowCreate = true;
                }
              }, 350);
            } else {
              allowStart = !args.isTouch;
            }
          }
        }
      },
    });

    this._unsubscribe = subscribeExternalDrag(this._onExternalDrag);
  }

  protected _updated() {
    const s = this.s;
    const state = this.state;

    if (this._scrollAfterResize) {
      this._onScroll();
      this._scrollAfterResize = false;
    }

    if (this._shouldCheckSize) {
      ngSetTimeout(this, () => {
        const resCont = this._resCont;
        const headerCont = this._headerCont!;
        const footerCont = this._footerCont!;
        const sidebarCont = this._sidebarCont!;
        const stickyFooter = this._stickyFooter!;
        const headerHeight = headerCont.offsetHeight;
        const resContWidth = resCont ? resCont.offsetWidth : 0;
        const sidebarWidth = sidebarCont ? sidebarCont.offsetWidth : 0;
        const footerHeight = footerCont ? footerCont.offsetHeight : 0;
        const scrollCont = this._scrollCont!;
        const scrollContWidth = scrollCont.offsetWidth;
        const scrollContHeight = scrollCont.offsetHeight;
        const scrollClientWidth = scrollCont.clientWidth;
        const scrollClientHeight = scrollCont.clientHeight;
        const gridContWidth = scrollClientWidth - resContWidth - sidebarWidth; // Available space for grid
        const gridContHeight = scrollClientHeight - headerHeight - footerHeight;
        const gridHeight = this._gridHeight;
        let scrollBarSizeY = scrollContWidth - scrollClientWidth;
        let scrollBarSizeX = scrollContHeight - scrollClientHeight;
        let hasScrollY = scrollCont.scrollHeight > scrollClientHeight;
        let hasScrollX = scrollCont.scrollWidth > scrollClientWidth;
        let cellHeight: number | undefined;
        let cellWidth: number | undefined;
        let dayWidth: number | undefined;
        let dayNameWidth: number | undefined;
        let gridWidth: number | undefined;
        let rowHeight: number | undefined;
        let parentRowHeight: number | undefined;
        let gutterHeight: number | undefined;
        let eventHeight = state.eventHeight;

        if (this._isTimeline) {
          const day = scrollCont.querySelector('.mbsc-timeline-day') as HTMLElement;
          const gridRow = scrollCont.querySelector('.mbsc-timeline-empty-row') as HTMLElement;
          const parentRow = scrollCont.querySelector('.mbsc-timeline-empty-parent') as HTMLElement;
          const gutter = scrollCont.querySelector('.mbsc-timeline-row-gutter') as HTMLElement;
          const colsNr = this._colsNr;

          dayWidth = day ? day.offsetWidth : 64;
          rowHeight = gridRow ? gridRow.offsetHeight : 52;
          parentRowHeight = parentRow ? parentRow.offsetHeight : 52;
          gutterHeight = gutter ? gutter.offsetHeight : 16;

          // Since the width of the grid is set in case of virtual scroll, we need to double check, if there will be horizontal scroll
          if (dayWidth * colsNr < gridContWidth) {
            hasScrollX = false;
            scrollBarSizeX = 0;
          }

          if (gridHeight && gridHeight < gridContHeight) {
            hasScrollY = false;
            scrollBarSizeY = 0;
          }

          dayWidth = hasScrollX ? dayWidth : round(gridContWidth / colsNr);
          gridWidth = hasScrollX ? dayWidth * colsNr : gridContWidth;
          cellWidth = (this._stepCell * dayWidth) / this._time;

          this._gridWidth = gridWidth;
          // Day width might be 0, if the calendar container is removed while rendering
          this._daysBatchNr = Math.max(1, Math.ceil(gridContWidth / (dayWidth || 1)));

          if (!this._hasSticky) {
            headerCont.style[s.rtl ? 'left' : 'right'] = scrollBarSizeY + 'px';
            if (footerCont) {
              footerCont.style[s.rtl ? 'left' : 'right'] = scrollBarSizeY + 'px';
              footerCont.style.bottom = scrollBarSizeX + 'px';
            }
          }

          if (!this._hasSideSticky) {
            if (resCont) {
              resCont.style.bottom = scrollBarSizeX + 'px';
            }
            if (sidebarCont) {
              sidebarCont.style[s.rtl ? 'left' : 'right'] = scrollBarSizeY + 'px';
            }
          }

          if (stickyFooter) {
            stickyFooter.style.bottom = scrollBarSizeX + 'px';
          }

          if (this._setRowHeight) {
            const event = scrollCont.querySelector('.mbsc-schedule-event');
            eventHeight = event ? event.clientHeight : eventHeight || (s.eventList ? 24 : 46);
          }

          if (!hasScrollY && state.rowHeight) {
            // Calculate tops of the resource rows, when there's no vertical scroll
            this._resourceTops = {};
            const grid = this._gridCont!;
            const gridRect = grid.getBoundingClientRect();
            const rows = grid.querySelectorAll('.mbsc-timeline-row');
            rows.forEach((r, i) => {
              //this._resourceTops[this._rows[i].key] = r.getBoundingClientRect().top - gridRect.top;
              if (this._rows[i]?.key) {
                this._resourceTops[this._rows[i].key] = r.getBoundingClientRect().top - gridRect.top;
              }
            });
          }
        } else {
          const gridCol = this._el.querySelector('.mbsc-schedule-column-inner') as HTMLElement;
          const dayName = this._el.querySelector('.mbsc-schedule-header-item') as HTMLElement;
          cellHeight = gridCol ? (this._stepCell * gridCol.offsetHeight) / this._time : 0;
          dayNameWidth = dayName ? dayName.offsetWidth : 0;
          if (s.maxEventStack === 'auto') {
            const maxEventStack = floor(gridCol.offsetWidth / (s.minEventWidth || 50));
            this._reloadEvents = this._maxEventStack !== maxEventStack;
            this._maxEventStack = maxEventStack;
          }
        }

        if (scrollCont.scrollTop > gridHeight - gridContHeight) {
          // Firefox remains in overscroll state, if new content is less than the old content.
          // Looks like this happens because of the time indicator container, here's a reproduction case: https://jsfiddle.net/kt9cmah1/13/
          scrollCont.scrollTop = gridHeight - gridContHeight;
        } else {
          // Make sure scroll remains in sync
          this._onScroll();
        }

        this._calcConnections = !!s.connections && (this._isParentClick || this._calcConnections || !hasScrollY);
        // We need another round here to calculate the correct resource tops, after the row heights are set
        this._shouldCheckSize = rowHeight !== state.rowHeight || eventHeight !== state.eventHeight;
        this._scrollAfterResize = s.virtualScroll && !this._shouldCheckSize;
        this._isCursorTimeVisible = false;

        this._calcGridSizes();

        this.setState({
          cellHeight,
          cellWidth,
          dayNameWidth,
          dayWidth,
          eventHeight,
          footerHeight,
          gridContWidth,
          gridWidth,
          gutterHeight,
          hasScrollX,
          hasScrollY,
          headerHeight,
          parentRowHeight,
          rowHeight,
          scrollContHeight: hasScrollX ? scrollClientHeight : scrollContHeight,
          // Force update if connection calculation is needed
          update: this._calcConnections || this._reloadEvents ? (state.update || 0) + 1 : state.update,
        });
      });
    }

    // only scroll to time when the dayWidth is set in case of timeline
    if (this._shouldScroll && (state.dayWidth || !this._isTimeline)) {
      setTimeout(() => {
        this._scrollToTime(this._shouldAnimateScroll);
        this._shouldAnimateScroll = false;
      });
      this._shouldScroll = false;
    }

    if (this._viewChanged) {
      setTimeout(() => {
        this._viewChanged = false;
        // this._scrollToMiddle = false;
      }, 10);
    }
  }

  protected _destroy() {
    if (this._unlisten) {
      this._unlisten();
    }
    if (this._unsubscribe) {
      unsubscribeExternalDrag(this._unsubscribe);
    }
  }

  // #endregion Lifecycle hooks

  private _calcGridSizes() {
    const s = this.s;
    const resources = this._resources;
    const isTimeline = this._isTimeline;
    const daysNr = this._daysNr * (isTimeline ? 1 : resources.length);
    const grid = this._gridCont!;
    const scrollCont = this._scrollCont!;
    const allDayCont = !isTimeline && (this._el.querySelector('.mbsc-schedule-all-day-wrapper') as HTMLElement);
    const allDayRect = allDayCont && allDayCont.getBoundingClientRect();
    const rect = grid.getBoundingClientRect();
    const gridRect = scrollCont.getBoundingClientRect();
    const gridWidth = isTimeline ? this._gridWidth : grid.scrollWidth;
    const resWidth = this._resCont ? this._resCont.offsetWidth : 0;
    this._gridLeft = s.rtl ? rect.right - gridWidth : rect.left;
    this._gridRight = s.rtl ? rect.right : rect.left + gridWidth;
    this._gridTop = rect.top;
    this._gridContTop = gridRect.top;
    this._gridContBottom = gridRect.bottom;
    this._gridContLeft = gridRect.left + (s.rtl ? 0 : resWidth);
    this._gridContRight = gridRect.right - (s.rtl ? resWidth : 0);
    this._allDayTop = allDayRect ? allDayRect.top : this._gridContTop;
    this._colWidth = gridWidth / (s.eventList ? this._colsNr : daysNr);
    this._colHeight = rect.height;
  }

  private _getDragDates(event: MbscCalendarEventData, resourceId: string | number, slotId: string | number) {
    const s = this.s;
    const dates: { [key: string]: MbscCalendarEventData } = {};
    const dragDisplayedMap = new Map();
    const first = event.allDay ? this._firstDay : this._firstDayTz;
    let start = event.startDate;
    let end = event.endDate;

    start = getDateOnly(start);
    start = start < first ? first : start;
    end = getEndDate(s, event.allDay || s.eventList, start, end);

    // If event has no duration, it should still be added to the start day
    while (start <= end) {
      const eventForDay = { ...event };
      const dateKey = getDateStr(start);
      const pos = isInWeek(start.getDay(), s.startDay, s.endDay) && this._getEventPos(event, start, dateKey, dragDisplayedMap);

      if (pos) {
        const eventResource = eventForDay.resource;
        if (
          this._isTimeline &&
          this._setRowHeight &&
          (isArray(eventResource) ? eventResource : [eventResource]).indexOf(this._tempResource) !== -1
        ) {
          // update the dragged event with the original event's top position to remain on the same place
          pos.position!.top = eventForDay.position.top;
        }
        const key = this._isTimeline && !this._hasSlots && !this._hasResY ? 'all' : dateKey;
        eventForDay.date = +getDateOnly(start, true);
        eventForDay.cssClass = pos.cssClass;
        eventForDay.start = pos.start;
        eventForDay.end = pos.end;
        eventForDay.position = pos.position;
        eventForDay.bufferAfter = pos.bufferAfter;
        eventForDay.bufferBefore = pos.bufferBefore;
        // Add the data for the day
        dates[resourceId + '__' + (this._isTimeline ? slotId + '__' : '') + key] = eventForDay;
      }

      start = addDays(start, 1);
    }

    return dates;
  }

  /**
   * Returns a date with the time based on the coordinates on the grid.
   * @param day We're on this day.
   * @param posX X coord - for timeline.
   * @param posY Y coord - for schedule.
   * @param dayIndex Index of the day on the timeline.
   * @param timeStep Time step in minutes.
   */
  private _getGridTime(day: Date, posX: number, posY: number, dayIndex: number, timeStep: number): Date {
    const dayIdx = this._hasResY ? 0 : dayIndex;
    const ms = step(
      this._isTimeline
        ? floor((this._time * (posX - dayIdx * this._colWidth)) / this._colWidth)
        : floor((this._time * (posY - 8)) / (this._colHeight - 16)),
      timeStep * ONE_MIN,
    ); // Remove 16px for top and bottom spacing
    const time = new Date(+REF_DATE + this._startTime + ms); // Date with no DST
    return createDate(this.s, day.getFullYear(), day.getMonth(), day.getDate(), time.getHours(), time.getMinutes());
  }

  private _scrollToTime(animate?: boolean) {
    const el = this._scrollCont;
    const gridCont = this._gridCont!;
    const isTimeline = this._isTimeline;
    if (el) {
      const s = this.s;
      const hasResY = this._hasResY;
      const targetEvent = s.navigateToEvent;
      let targetDate =
        targetEvent && targetEvent.start
          ? roundTime(new Date(+makeDate(targetEvent.start, s) - this._stepCell), this._stepCell / ONE_MIN)
          : new Date(s.selected); // : createDate(s, s.selected);
      const colIndex = this._colIndexMap[getDateStr(targetDate)];
      if (colIndex !== UNDEFINED && isTimeline && !hasResY && (s.resolution !== 'hour' || this._stepCell === ONE_DAY || s.eventList)) {
        targetDate = this._cols[colIndex].date;
      } else {
        targetDate.setHours(s.eventList ? 0 : targetDate.getHours(), 0);
      }

      const timeStart = getEventStart(targetDate, this._startTime, this._time * (isTimeline ? this._daysNr : 1));
      const dayDiff = hasResY ? 0 : getGridDayDiff(this._firstDay, targetDate, s.startDay, s.endDay);
      const width = isTimeline ? gridCont.offsetWidth : gridCont.scrollWidth;
      let newScrollPosX: number | undefined =
        (width * ((dayDiff * 100) / this._daysNr + (isTimeline ? timeStart : 0))) / 100 +
        // (this._scrollToMiddle ? this.state.gridContWidth! / 2 : 0) +
        1;
      let newScrollPosY: number | undefined;

      if (targetEvent || hasResY) {
        const resources = this._visibleResources;
        const resource = targetEvent ? targetEvent.resource : resources[0].id;
        const targetResource = isArray(resource) ? resource[0] : resource;
        if (targetResource) {
          if (isTimeline) {
            const key = (hasResY ? getDateStr(targetDate) + '-' : '') + targetResource;
            newScrollPosY = this._resourceTops && this._resourceTops[key];
          } else {
            const colWidth = this._colWidth;
            const resourceIndex = findIndex(resources, (r) => r.id === targetResource) || 0;
            if (this._groupByResource && !this._isSingleResource) {
              newScrollPosX = this._daysNr * colWidth * resourceIndex + colWidth * dayDiff;
            } else {
              newScrollPosX = resources.length * dayDiff * colWidth + resourceIndex * colWidth;
            }
          }
        }
      }

      if (!isTimeline) {
        const gridCol = el.querySelector('.mbsc-schedule-column-inner') as HTMLElement;
        newScrollPosY = gridCol ? (gridCol.offsetHeight * timeStart) / 100 : 0;
        if (this._groupByResource && !this._isSingleResource && !targetEvent) {
          newScrollPosX = UNDEFINED;
        }
      }

      this._isScrolling++;
      smoothScroll(el, newScrollPosX, newScrollPosY, animate, s.rtl, () => {
        setTimeout(() => {
          this._isScrolling--;
        }, 150);
      });
    }
  }
}
