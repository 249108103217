import { FC, useEffect, useState } from "react";
import {
  saveTooltipData,
  updateTooltipDataById,
} from "./core/_request";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Tooltip, initialTooltip } from "./core/_types";
import clsx from "clsx";
import { useListView } from "./core/ListViewProvider";
import { useQueryResponse } from "./core/QueryResponseProvider";
import { useNavigate } from "react-router-dom";
import React from "react";

const SchemaForHint = Yup.object().shape({
  element: Yup.string()
  .required("Value is required")
  .test("starts-with-dot-or-hash", 'Element-Id must start with a "#" or "." and not contain spaces', (value) => {
    if (!value) return false; // If value is empty, validation fails
    const startsWithValidChar = value[0] === "#" || value[0] === ".";
    const containsNoSpaces = !/\s/.test(value);
    return startsWithValidChar && containsNoSpaces;
    }),
  type: Yup.string().required("Type must be selected"),
  page: Yup.string().required("Page must be selected"),
  intro: Yup.string().when("type", {
    is: (val: string) => val === "tour",
    then: Yup.string().required("Tooltip-text is required"),
    otherwise: Yup.string(),
  }),
  title: Yup.string().when("type", {
    is: (val: string) => val === "tour",
    then: Yup.string().required("Tooltip-title is required"),
    otherwise: Yup.string(),
  }),
  hint: Yup.string().when("type", {
    is: (val: string) => val === "hint",
    then: Yup.string().required("Hint-text is required"),
    otherwise: Yup.string(),
  }),
});
type Props = {
  tooltips: Tooltip;
  isRefetch?: any;
  setIsShowModal?: (state: any) => void;
  setId?: (state: any) => void;
  // isrefetching?: any;
  setIsEditForUpdate?: (state: any) => void;
  id?: any;
  isOpenModalforNew?: any;
  setIsOpenModalforNew?: (state: any) => void;
  openUserModal?: any;
  isUserLoading?: any;
};

export const IntrojsEditModalForm: FC<Props> = ({
  tooltips,
  id,
  openUserModal,
  isUserLoading,
}) => {
  const [introForEdit] = useState<Tooltip>({
    ...tooltips,
    element: tooltips.element || initialTooltip.element,
    type: tooltips.type || initialTooltip.type,
    page: tooltips.page || initialTooltip.page,
    title: tooltips.title || initialTooltip.title,
    intro: tooltips.intro || initialTooltip.intro,
    // priority: tooltips.priority || initialTooltip.priority,
    tooltipClass: tooltips.tooltipClass || initialTooltip.tooltipClass,
    highlightClass: tooltips.highlightClass || initialTooltip.highlightClass,
    position: tooltips.position || initialTooltip.position,
    scrollTo: tooltips.scrollTo || initialTooltip.scrollTo,
    disableScrolling:
      tooltips.disableScrolling || initialTooltip.disableScrolling,
    hint: tooltips.hint || initialTooltip.hint,
    hintPosition: tooltips.hintPosition || initialTooltip.hintPosition,
  });
  const positions = [
    "top",
    "left",
    "right",
    "bottom",
    "bottom-left-aligned",
    "bottom-middle-aligned",
    "bottom-right-aligned",
    "auto",
  ];
  const HintPositions = [
    "top-middle",
    "top-left",
    "top-right",
    "bottom-left",
    "bottom-right",
    "bottom-middle",
    "middle-left",
    "middle-right",
    "middle-middle",
    "top-middle",
  ];
  const pages = [
    "Dashboard",
    "Planning",
    "Users",
    "Projects",
    "Notifications",
    // "Logs",
    "Logs-Login",
    "Logs-APIs",
    // "Settings",
    "Settings-General",
    "Settings-Emails",
    "Settings-SSO",
    "AI settings",
    "My organisation",
    // "Billing & Subscription",
    "Billing & Subscription-Invoice",
    "Billing & Subscription-Payment",
    "Billing & Subscription-Subscription",
    "Profile",
  ];
  useEffect(() => {}, [id]);

  

//   const tooltipForEdit = !isOpenModalforNew ? introById : initialTooltip;
  const { setItemIdForUpdate } = useListView();
  const { refetch } = useQueryResponse();
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch();
    }
    openUserModal(false);
    setItemIdForUpdate(undefined);
  };
  const navigate = useNavigate();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: introForEdit,
    validationSchema: SchemaForHint,
    onSubmit: async (value, { setSubmitting }) => {
      setSubmitting(true);
      console.log("handleSubmit called");
      try {
        if (!value._id) {
          await saveTooltipData(value);
          // isrefetching();
        } else {
          await updateTooltipDataById(value, value?._id);
          setSubmitting(true);
          // isrefetching();
        }
      } catch (error) {
        console.log(error);
        setSubmitting(true);
        // setIsShowModal(false);
      } finally {
        navigate("/superadmin/introjs/introjs-setting");
        setSubmitting(true);
        cancel(true);
      }
    },
  });

  return (
    <>
      <form
        id="kt_modal_add_email_form"
        className="form h-100 d-flex flex-column"
        onSubmit={formik.handleSubmit}
        noValidate
      >
        {/* <div className="modal-header p-7">
          <h2 className="fw-bolder">Tour guide settings</h2>
        </div> */}
        <div className=" h-100 flex-fill d-flex flex-column">
          <div
          // className="px-7"
          >
            {/* <h5 className="mb-5">Add new Tour/Hint</h5> */}

            {/* form start */}
            <div className="fv-row mb-7">
              <label className="col-form-label required fw-bold fs-6">
                Element Id
              </label>
              <input
                type="text"
                className={clsx(
                  "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                  {
                    "is-valid":
                      formik.touched.element && !formik.errors.element,
                  },
                  {
                    "is-invalid":
                      formik.touched.element && formik.errors.element,
                  }
                )}
                id="elementid"
                placeholder="#elementid"
                {...formik.getFieldProps("element")}
              />
              {formik.touched.element && formik.errors.element && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert" className="text-danger">
                      {formik.errors.element as string}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className="fv-row mb-7">
              <label className="col-form-label required fw-bold fs-6">
                Type
              </label>
              <select
                className={clsx(
                  "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                  {
                    "is-valid": formik.touched.type && !formik.errors.type,
                  },
                  {
                    "is-invalid": formik.touched.type && formik.errors.type,
                  }
                )}
                id="tour"
                {...formik.getFieldProps("type")}
              >
                <option value="">Select Type</option>
                <option value={"tour"}>Tour</option>
                {/* <option value={"hint"}>Hint</option> */}
              </select>
              {formik.touched.type && formik.errors.type && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert" className="text-danger">
                      {formik.errors.type as string}
                    </span>
                  </div>
                </div>
              )}
            </div>

            <div className="fv-row mb-7">
              <label className="col-form-label required fw-bold fs-6">
                Page
              </label>
              <select
                className={clsx(
                  "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                  {
                    "is-valid": formik.touched.page && !formik.errors.page,
                  },
                  {
                    "is-invalid": formik.touched.page && formik.errors.page,
                  }
                )}
                id="tour"
                {...formik.getFieldProps("page")}
              >
                <option value="">Select Page</option>
                {pages.map((page) => (
                  <option key={page} value={page}>
                    {page}
                  </option>
                ))}
              </select>
              {formik.touched.page && formik.errors.page && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert" className="text-danger">
                      {formik.errors.page as string}
                    </span>
                  </div>
                </div>
              )}
            </div>

            {formik.values.type === "tour" && (
              <>
                <div className="fv-row mb-7">
                  <label className="col-form-label required fw-bold fs-6">
                    Tour text
                  </label>
                  <textarea
                    className={clsx(
                      "form-control form-control-solid mb-3 mb-lg-0",
                      {
                        "is-valid":
                          formik.touched.intro && !formik.errors.intro,
                      },
                      {
                        "is-invalid":
                          formik.touched.intro && formik.errors.intro,
                      }
                    )}
                    {...formik.getFieldProps("intro")}
                  />
                  {formik.touched.intro && formik.errors.intro && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert" className="text-danger">
                          {formik.errors.intro as string}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div className="fv-row mb-7">
                  <label className="col-form-label required fw-bold fs-6">
                    Tour Title
                  </label>
                  <input
                    type="text"
                    className={clsx(
                      "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                      {
                        "is-valid":
                          formik.touched.title && !formik.errors.title,
                      },
                      {
                        "is-invalid":
                          formik.touched.title && formik.errors.title,
                      }
                    )}
                    {...formik.getFieldProps("title")}
                  />
                  {formik.touched.title && formik.errors.title && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert" className="text-danger">
                          {formik.errors.title as string}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                {/* <div className="fv-row mb-7">
                  <label className="col-form-label fw-bold fs-6">
                    Tooltip Prioriety
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    {...formik.getFieldProps("priority")}
                  />
                </div> */}
                <div className="fv-row mb-7">
                  <label className="col-form-label fw-bold fs-6">
                    Tour Class
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    {...formik.getFieldProps("tooltipClass")}
                  />
                </div>
                <div className="fv-row mb-7">
                  <label className="col-form-label fw-bold fs-6">
                    Highlight Class
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    {...formik.getFieldProps("highlightClass")}
                  />
                </div>
                <div className="fv-row mb-7">
                  <label className="col-form-label fw-bold fs-6">
                    Tour Position
                  </label>
                  <select
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    {...formik.getFieldProps("position")}
                  >
                    <option value={""}>Select Position</option>
                    {positions.map((position, i) => (
                      <option value={position} key={position}>
                        {position}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="fv-row mb-y">
                  <label className="col-form-label fw-bold fs-6">
                    Tour Scroll-to
                  </label>
                  <select
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    {...formik.getFieldProps("scrollTo")}
                  >
                    <option value={""}>Select Scroll-To</option>
                    <option value={"element"}>Element</option>
                    <option value={"tooltip"}>Tour</option>
                  </select>
                </div>
                <div className="fv-row mb-7">
                  <label className="col-form-label fw-bold fs-6">
                    DisableScrolling
                  </label>
                  <div className="d-flex fv-row fv-row col-md-8">
                    <div className="col-lg-8 d-flex align-items-center">
                      <div className="form-check form-check-dark form-switch fv-row">
                        <input
                          className="form-check-input w-45px h-30px"
                          type="checkbox"
                          name="disableScrolling"
                          checked={formik.values?.disableScrolling}
                          {...formik.getFieldProps("disableScrolling")}
                        />
                        <label className="form-check-label"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {formik.values?.type === "hint" && (
              <>
                <div className="fv-row mb-7">
                  <label className="col-form-label required fw-bold fs-6">
                    Hint Text
                  </label>
                  <input
                    type="text"
                    className={clsx(
                      "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                      {
                        "is-valid": formik.touched.hint && !formik.errors.hint,
                      },
                      {
                        "is-invalid": formik.touched.hint && formik.errors.hint,
                      }
                    )}
                    {...formik.getFieldProps("hint")}
                  />
                  {formik.touched.hint && formik.errors.hint && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert" className="text-danger">
                          {formik.errors.hint as string}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div className="fv-row mb-7">
                  <label className="col-form-label fw-bold fs-6">
                    Hint Position
                  </label>
                  <select
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    {...formik.getFieldProps("hintPosition")}
                  >
                    <option value={""}>Select Hint position</option>
                    {HintPositions.map((position, i) => (
                      <option key={position} value={position}>
                        {position}
                      </option>
                    ))}
                  </select>
                </div>
              </>
            )}
          </div>
          <div className="p-4 d-flex gap-3 justify-content-center">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={
                isUserLoading ||
                formik.isSubmitting ||
                !formik.isValid ||
                !formik.touched
              }
              data-bs-dismiss="modal"
            >
              Save
              {(formik.isSubmitting || isUserLoading) && (
                <span className="indicator-progress">
                  please Wait ...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
            <button
              type="reset"
              onClick={() => cancel()}
              className="btn btn-light me-2"
              // data-kt-email-modal-action='cancel'
              disabled={formik.isSubmitting || isUserLoading}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
