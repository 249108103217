import React, { useEffect, useRef } from 'react';
import Select from "react-select";
import $ from 'jquery';
import 'select2';
import 'select2/dist/css/select2.css';
import 'jquery-ui-dist/jquery-ui.css';
import 'jquery-ui-dist/jquery-ui';

const options = [
    { value: '0', label: 'Sun' },
    { value: '1', label: 'Mon' },
    { value: '2', label: 'Tue' },
    { value: '3', label: 'Wed' },
    { value: '4', label: 'Thu' },
    { value: '5', label: 'Fri' },
    { value: '6', label: 'Sat' },
  ];

const planningPriority = [
    {value: 'Prioritize as fast as possible (dented, from start date', label: 'Prioritize as fast as possible (dented, from start date'},
    {value: 'Prioritize closest to end date (dented, from end/due date', label: 'Prioritize closest to end date (dented, from end/due date'},
    {value: 'Prioritize equal spreading', label: 'Prioritize equal spreading'},
    {value: 'Prioritize projects(tasks and [service calls])', label: 'Prioritize projects(tasks and [service calls])'},
    {value: 'Prioritize tickets', label: 'Prioritize tickets'},
    {value: 'Prioritize tasks', label: 'Prioritize tasks'},
]
const PlanningAISettings = ({formik} : any) => {
    const selectRef = useRef(null);

    useEffect(() => {
        const $select = $(selectRef.current).select2({
          placeholder: 'Select options',
          tags: true,
        });
    
        // Make Select2 selection list sortable
        const $select2container = $select.next('.select2-container');
        $select2container.find('ul.select2-selection__rendered').sortable({
          containment: 'parent',
          stop: function () {
            $(this)
              .find('.select2-selection__choice')
              .each(function (i) {
                var title = $(this).attr('title');
                var original = $select.find('option').filter(function () {
                  return $(this).text() === title;
                });
                original.detach();
                $select.append(original);
              });
            $select.trigger('change');
          },
        });
    
        // Handle Select2 change event
        $select.on('change', function (e) {
          formik.setFieldValue('PlannigDataArray.planningPriority.list', $(this).val()); // Update Formik field with selected options
        });
    
        return () => {
          $select.select2('destroy');
        };
      }, [formik]);
    
  return (
    <>

        <div className='card mb-6'>

            <div className="card-header">
                <div className="card-title">
                    <h3 className="text-dark fw-bold fs-18 m-0">Planning AI setting</h3>
                </div>
            </div>

            <div className='card-body'>

                <div className="row">
                    {/* Planning AI Settings 1 Start*/}
                    <div className="col-6">
                        <div style={{ border: '1px solid #DADCE0' }} className="table-responsive rounded-4 p-5 setCenter h-100">
                            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                <thead>
                                    <tr className="fw-bold text-muted">
                                        <th></th>
                                        <th className="w-100px fs-3 text-center">Setting</th>
                                        <th className="w-125px fs-3 text-center">Spank level</th>
                                        <th className="w-75px fs-3 text-center">Visible</th>
                                        <th className="w-80px fs-3 text-center">Editable</th>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <h4>Only Plan within shifts:</h4>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('PlannigDataArray.planWithinShift.Setting.value', e.target.checked)}
                                                    checked={formik.values?.PlannigDataArray?.planWithinShift?.Setting?.value}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-column">
                                            <input
                                                type="number"
                                                className="form-control h-35px p-0 px-4 fs-6 w-70px"
                                                value={formik.values?.PlannigDataArray?.planWithinShift?.SpankLevel}
                                                onChange={(e) => formik.setFieldValue('PlannigDataArray.planWithinShift.SpankLevel', e.target.value)}
                                                min={1}
                                                max={10}
                                            />

                                            {/* {!isValid && <span className="text-danger">{error}</span>} */}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('PlannigDataArray.planWithinShift.Visible', e.target.checked)}
                                                    checked={formik.values?.PlannigDataArray?.planWithinShift?.Visible}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('PlannigDataArray.planWithinShift.Editable', e.target.checked)}
                                                    checked={formik.values?.PlannigDataArray?.planWithinShift?.Editable}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className=''>
                                        <div className="d-flex align-items-center">
                                            <h4>Use buffer hours:</h4>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-column">
                                            <input
                                                type="number"
                                                className="form-control h-35px p-0 px-4 fs-6 w-70px"
                                                value={formik.values?.PlannigDataArray?.bufferHours?.Setting?.value}
                                                onChange={(e) => formik.setFieldValue('PlannigDataArray.bufferHours.Setting.value', e.target.value)}
                                                min={1}
                                                max={10}
                                            />

                                            {/* {!isValid && <span className="text-danger">{error}</span>} */}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-column">
                                            <input
                                                type="number"
                                                className="form-control h-35px p-0 px-4 fs-6 w-70px"
                                                value={formik.values?.PlannigDataArray?.bufferHours?.SpankLevel}
                                                onChange={(e) => formik.setFieldValue('PlannigDataArray.bufferHours.SpankLevel', e.target.value)}
                                                min={1}
                                                max={10}
                                            />

                                            {/* {!isValid && <span className="text-danger">{error}</span>} */}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('PlannigDataArray.bufferHours.Visible', e.target.checked)}
                                                    checked={formik.values?.PlannigDataArray?.bufferHours?.Visible}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('PlannigDataArray.bufferHours.Editable', e.target.checked)}
                                                    checked={formik.values?.PlannigDataArray?.bufferHours?.Editable}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className=''>
                                        <div className="d-flex align-items-center">
                                            <h4>Allow planning outside work hours if needed:</h4>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('PlannigDataArray.planningOutside.Setting.value', e.target.checked)}
                                                    checked={formik.values?.PlannigDataArray?.planningOutside?.Setting?.value}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-column">
                                            <input
                                                type="number"
                                                className="form-control h-35px p-0 px-4 fs-6 w-70px"
                                                value={formik.values?.PlannigDataArray?.planningOutside?.SpankLevel}
                                                onChange={(e) => formik.setFieldValue('PlannigDataArray.planningOutside.SpankLevel', e.target.value)}
                                                min={1}
                                                max={10}
                                            />

                                            {/* {!isValid && <span className="text-danger">{error}</span>} */}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('PlannigDataArray.planningOutside.Visible', e.target.checked)}
                                                    checked={formik.values?.PlannigDataArray?.planningOutside?.Visible}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('PlannigDataArray.planningOutside.Editable', e.target.checked)}
                                                    checked={formik.values?.PlannigDataArray?.planningOutside?.Editable}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className=''>
                                        <div className="d-flex align-items-center">
                                            <h4>Allow ticket planning before start date:</h4>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('PlannigDataArray.ticketPlanningBefore.Setting.value', e.target.checked)}
                                                    checked={formik.values?.PlannigDataArray?.ticketPlanningBefore?.Setting?.value}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-column">
                                            <input
                                                type="number"
                                                className="form-control h-35px p-0 px-4 fs-6 w-70px"
                                                value={formik.values?.PlannigDataArray?.ticketPlanningBefore?.SpankLevel}
                                                onChange={(e) => formik.setFieldValue('PlannigDataArray.ticketPlanningBefore.SpankLevel', e.target.value)}
                                                min={1}
                                                max={10}
                                            />

                                            {/* {!isValid && <span className="text-danger">{error}</span>} */}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('PlannigDataArray.ticketPlanningBefore.Visible', e.target.checked)}
                                                    checked={formik.values?.PlannigDataArray?.ticketPlanningBefore?.Visible}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('PlannigDataArray.ticketPlanningBefore.Editable', e.target.checked)}
                                                    checked={formik.values?.PlannigDataArray?.ticketPlanningBefore?.Editable}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className=''>
                                        <div className="d-flex align-items-center">
                                            <h4>Allow ticket planning after due/end date:</h4>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('PlannigDataArray.ticketPlanningAfter.Setting.value', e.target.checked)}
                                                    checked={formik.values?.PlannigDataArray?.ticketPlanningAfter?.Setting?.value}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-column">
                                            <input
                                                type="number"
                                                className="form-control h-35px p-0 px-4 fs-6 w-70px"
                                                value={formik.values?.PlannigDataArray?.ticketPlanningAfter?.SpankLevel}
                                                onChange={(e) => formik.setFieldValue('PlannigDataArray.ticketPlanningAfter.SpankLevel', e.target.value)}
                                                min={1}
                                                max={10}
                                            />

                                            {/* {!isValid && <span className="text-danger">{error}</span>} */}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('PlannigDataArray.ticketPlanningAfter.Visible', e.target.checked)}
                                                    checked={formik.values?.PlannigDataArray?.ticketPlanningAfter?.Visible}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('PlannigDataArray.ticketPlanningAfter.Editable', e.target.checked)}
                                                    checked={formik.values?.PlannigDataArray?.ticketPlanningAfter?.Editable}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className=''>
                                        <div className="d-flex align-items-center">
                                            <h4>Allow task planning before start date:</h4>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('PlannigDataArray.taskPlanningBefore.Setting.value', e.target.checked)}
                                                    checked={formik.values?.PlannigDataArray?.taskPlanningBefore?.Setting?.value}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-column">
                                            <input
                                                type="number"
                                                className="form-control h-35px p-0 px-4 fs-6 w-70px"
                                                value={formik.values?.PlannigDataArray?.taskPlanningBefore?.SpankLevel}
                                                onChange={(e) => formik.setFieldValue('PlannigDataArray.taskPlanningBefore.SpankLevel', e.target.value)}
                                                min={1}
                                                max={10}
                                            />

                                            {/* {!isValid && <span className="text-danger">{error}</span>} */}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('PlannigDataArray.taskPlanningBefore.Visible', e.target.checked)}
                                                    checked={formik.values?.PlannigDataArray?.taskPlanningBefore?.Visible}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('PlannigDataArray.taskPlanningBefore.Editable', e.target.checked)}
                                                    checked={formik.values?.PlannigDataArray?.taskPlanningBefore?.Editable}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className=''>
                                        <div className="d-flex align-items-center">
                                            <h4>Allow task planning after due/end date:</h4>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('PlannigDataArray.taskPlanningAfter.Setting.value', e.target.checked)}
                                                    checked={formik.values?.PlannigDataArray?.taskPlanningAfter?.Setting?.value}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-column">
                                            <input
                                                type="number"
                                                className="form-control h-35px p-0 px-4 fs-6 w-70px"
                                                value={formik.values?.PlannigDataArray?.taskPlanningAfter?.SpankLevel}
                                                onChange={(e) => formik.setFieldValue('PlannigDataArray.taskPlanningAfter.SpankLevel', e.target.value)}
                                                min={1}
                                                max={10}
                                            />

                                            {/* {!isValid && <span className="text-danger">{error}</span>} */}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('PlannigDataArray.taskPlanningAfter.Visible', e.target.checked)}
                                                    checked={formik.values?.PlannigDataArray?.taskPlanningAfter?.Visible}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('PlannigDataArray.taskPlanningAfter.Editable', e.target.checked)}
                                                    checked={formik.values?.PlannigDataArray?.taskPlanningAfter?.Editable}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>                        
                    </div>
                    {/* Planning AI Settings 1 end*/}

                    {/* Planning AI Settings 2 Start*/}
                    <div className="col-6">
                        <div style={{ border: '1px solid #DADCE0' }} className="table-responsive rounded-4 p-5 setCenter h-100">
                            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                <thead>
                                    <tr className="fw-bold text-muted">
                                        <th></th>
                                        <th className="w-125px fs-3">Setting</th>
                                        <th className="w-125px fs-3">Spank level</th>
                                        <th className="w-75px fs-3">Visible</th>
                                        <th className="w-80px fs-3">Editable</th>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <h4>Only Plan on set days:</h4>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='position-relative'>
                                            <div className="dropDown position-relative">
                                                <Select
                                                    name="PlannigDataArray.planOnSetDays.Setting.value"
                                                    closeMenuOnSelect={false}
                                                    isMulti
                                                    options={options}
                                                    className="basic-multi-select min-w-1px"
                                                    classNamePrefix="select"
                                                    isSearchable={false}
                                                    // defaultMenuIsOpen={true}
                                                    onChange={(selectedOptions: any) => formik.setFieldValue('PlannigDataArray.planOnSetDays.Setting.value', selectedOptions?.map((option: any) => option.value))}
                                                    value={options.filter((option: any) => formik.values?.PlannigDataArray?.planOnSetDays?.Setting?.value.includes(option.value))}
                                                />
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-column">
                                            <input
                                                type="number"
                                                className="form-control h-35px p-0 px-4 fs-6 w-70px"
                                                value={formik.values?.PlannigDataArray?.planOnSetDays?.SpankLevel}
                                                onChange={(e) => formik.setFieldValue('PlannigDataArray.planOnSetDays.SpankLevel', e.target.value)}
                                                min={1}
                                                max={10}
                                            />

                                            {/* {!isValid && <span className="text-danger">{error}</span>} */}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('PlannigDataArray.planOnSetDays.Visible', e.target.checked)}
                                                    checked={formik.values?.PlannigDataArray?.planOnSetDays?.Visible}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('PlannigDataArray.planOnSetDays.Editable', e.target.checked)}
                                                    checked={formik.values?.PlannigDataArray?.planOnSetDays?.Editable}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className=''>
                                        <div className="d-flex align-items-center">
                                            <h4>{`Minimum time per [Service call]:`}</h4>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-column">
                                            <input
                                                type="number"
                                                className="form-control h-35px p-0 px-4 fs-6 w-70px"
                                                value={formik.values?.PlannigDataArray?.minTimeServiceCall?.Setting?.value}
                                                onChange={(e) => formik.setFieldValue('PlannigDataArray.minTimeServiceCall.Setting.value', e.target.value)}
                                                min={1}
                                                max={10}
                                            />

                                            {/* {!isValid && <span className="text-danger">{error}</span>} */}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-column">
                                            <input
                                                type="number"
                                                className="form-control h-35px p-0 px-4 fs-6 w-70px"
                                                value={formik.values?.PlannigDataArray?.minTimeServiceCall?.SpankLevel}
                                                onChange={(e) => formik.setFieldValue('PlannigDataArray.minTimeServiceCall.SpankLevel', e.target.value)}
                                                min={1}
                                                max={10}
                                            />

                                            {/* {!isValid && <span className="text-danger">{error}</span>} */}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('PlannigDataArray.minTimeServiceCall.Visible', e.target.checked)}
                                                    checked={formik.values?.PlannigDataArray?.minTimeServiceCall?.Visible}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('PlannigDataArray.minTimeServiceCall.Editable', e.target.checked)}
                                                    checked={formik.values?.PlannigDataArray?.minTimeServiceCall?.Editable}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className=''>
                                        <div className="d-flex align-items-center">
                                            <h4>{`Maximum time per [Service call]:`}</h4>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-column">
                                                <input
                                                    type="number"
                                                    className="form-control h-35px p-0 px-4 fs-6 w-70px"
                                                    value={formik.values?.PlannigDataArray?.maxTimeServiceCall?.Setting?.value}
                                                    onChange={(e) => formik.setFieldValue('PlannigDataArray.maxTimeServiceCall.Setting.value', e.target.value)}
                                                    min={1}
                                                    max={10}
                                                />

                                                {/* {!isValid && <span className="text-danger">{error}</span>} */}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-column">
                                            <input
                                                type="number"
                                                className="form-control h-35px p-0 px-4 fs-6 w-70px"
                                                value={formik.values?.PlannigDataArray?.maxTimeServiceCall?.SpankLevel}
                                                onChange={(e) => formik.setFieldValue('PlannigDataArray.maxTimeServiceCall.SpankLevel', e.target.value)}
                                                min={1}
                                                max={10}
                                            />

                                            {/* {!isValid && <span className="text-danger">{error}</span>} */}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('PlannigDataArray.maxTimeServiceCall.Visible', e.target.checked)}
                                                    checked={formik.values?.PlannigDataArray?.maxTimeServiceCall?.Visible}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('PlannigDataArray.maxTimeServiceCall.Editable', e.target.checked)}
                                                    checked={formik.values?.PlannigDataArray?.maxTimeServiceCall?.Editable}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className=''>
                                        <div className="d-flex align-items-center">
                                            <h4>{`Time between [Service call]:`}</h4>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-column">
                                                <input
                                                    type="number"
                                                    className="form-control h-35px p-0 px-4 fs-6 w-70px"
                                                    value={formik.values?.PlannigDataArray?.timeBetweenServiceCall?.Setting?.value}
                                                    onChange={(e) => formik.setFieldValue('PlannigDataArray.timeBetweenServiceCall.Setting.value', e.target.value)}
                                                    min={1}
                                                    max={10}
                                                />

                                                {/* {!isValid && <span className="text-danger">{error}</span>} */}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-column">
                                            <input
                                                type="number"
                                                className="form-control h-35px p-0 px-4 fs-6 w-70px"
                                                value={formik.values?.PlannigDataArray?.timeBetweenServiceCall?.SpankLevel}
                                                onChange={(e) => formik.setFieldValue('PlannigDataArray.timeBetweenServiceCall.SpankLevel', e.target.value)}
                                                min={1}
                                                max={10}
                                            />

                                            {/* {!isValid && <span className="text-danger">{error}</span>} */}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('PlannigDataArray.timeBetweenServiceCall.Visible', e.target.checked)}
                                                    checked={formik.values?.PlannigDataArray?.timeBetweenServiceCall?.Visible}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('PlannigDataArray.timeBetweenServiceCall.Editable', e.target.checked)}
                                                    checked={formik.values?.PlannigDataArray?.timeBetweenServiceCall?.Editable}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className=''>
                                        <div className="d-flex align-items-center">
                                            <h4>Allow overlap:</h4>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('PlannigDataArray.allowOverlapForPlannig.Setting.value', e.target.checked)}
                                                    checked={formik.values?.PlannigDataArray?.allowOverlapForPlannig?.Setting?.value}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-column">
                                            <input
                                                type="number"
                                                className="form-control h-35px p-0 px-4 fs-6 w-70px"
                                                value={formik.values?.PlannigDataArray?.allowOverlapForPlannig?.SpankLevel}
                                                onChange={(e) => formik.setFieldValue('PlannigDataArray.allowOverlapForPlannig.SpankLevel', e.target.value)}
                                                min={1}
                                                max={10}
                                            />

                                            {/* {!isValid && <span className="text-danger">{error}</span>} */}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('PlannigDataArray.allowOverlapForPlannig.Visible', e.target.checked)}
                                                    checked={formik.values?.PlannigDataArray?.allowOverlapForPlannig?.Visible}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('PlannigDataArray.allowOverlapForPlannig.Editable', e.target.checked)}
                                                    checked={formik.values?.PlannigDataArray?.allowOverlapForPlannig?.Editable}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className=''>
                                        <div className="d-flex align-items-center">
                                            <h4>Allow replanning for future items:</h4>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('PlannigDataArray.allowReplanningFuture.Setting.value', e.target.checked)}
                                                    checked={formik.values?.PlannigDataArray?.allowReplanningFuture?.Setting?.value}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-column">
                                            <input
                                                type="number"
                                                className="form-control h-35px p-0 px-4 fs-6 w-70px"
                                                value={formik.values?.PlannigDataArray?.allowReplanningFuture?.SpankLevel}
                                                onChange={(e) => formik.setFieldValue('PlannigDataArray.allowReplanningFuture.SpankLevel', e.target.value)}
                                                min={1}
                                                max={10}
                                            />

                                            {/* {!isValid && <span className="text-danger">{error}</span>} */}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('PlannigDataArray.allowReplanningFuture.Visible', e.target.checked)}
                                                    checked={formik.values?.PlannigDataArray?.allowReplanningFuture?.Visible}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('PlannigDataArray.allowReplanningFuture.Editable', e.target.checked)}
                                                    checked={formik.values?.PlannigDataArray?.allowReplanningFuture?.Editable}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className=''>
                                        <div className="d-flex align-items-center">
                                            <h4>Only plan items if resource has the required:</h4>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('PlannigDataArray.onlyPlanItemsResource.Setting.value', e.target.checked)}
                                                    checked={formik.values?.PlannigDataArray?.onlyPlanItemsResource?.Setting?.value}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-column">
                                            <input
                                                type="number"
                                                className="form-control h-35px p-0 px-4 fs-6 w-70px"
                                                value={formik.values?.PlannigDataArray?.onlyPlanItemsResource?.SpankLevel}
                                                onChange={(e) => formik.setFieldValue('PlannigDataArray.onlyPlanItemsResource.SpankLevel', e.target.value)}
                                                min={1}
                                                max={10}
                                            />

                                            {/* {!isValid && <span className="text-danger">{error}</span>} */}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('PlannigDataArray.onlyPlanItemsResource.Visible', e.target.checked)}
                                                    checked={formik.values?.PlannigDataArray?.onlyPlanItemsResource?.Visible}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('PlannigDataArray.onlyPlanItemsResource.Editable', e.target.checked)}
                                                    checked={formik.values?.PlannigDataArray?.onlyPlanItemsResource?.Editable}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {/* Planning AI Settings 2 end*/}

                    {/* Planning AI Settings 3 start*/}
                    <div className="col-6 mt-6">
                        <div style={{ border: '1px solid #DADCE0' }} className="rounded-4 p-5 setCenter">
                            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                <thead>
                                <tr className="fw-bold text-muted">
                                    <th></th>
                                    <th className="w-75px fs-3">Visible</th>
                                    <th className="w-80px fs-3">Editable</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <h4 className='mb-0 me-2 min-w-125px'>Planning priority:</h4>
                                            <div className='position-relative flex-fill'>
                                                <div className="dropDown position-relative">
                                                    {/* <Select
                                                        ref={selectRef}
                                                        name="PlannigDataArray.planningPriority.list"
                                                        closeMenuOnSelect={false}
                                                        isMulti
                                                        options={planningPriority}
                                                        className="basic-multi-select value400 hideseachTxt"
                                                        classNamePrefix="select"
                                                        isSearchable={false}
                                                        onChange={(selectedOptions: any) => formik.setFieldValue('PlannigDataArray.planningPriority.list', selectedOptions?.map((option: any) => option.value))}
                                                        value={planningPriority.filter((option: any) => formik.values?.PlannigDataArray?.planningPriority?.list.includes(option.value))}
                                                    /> */}
                                                    <select 
                                                        ref={selectRef} 
                                                        multiple 
                                                        style={{ width: '100%' }}
                                                        name="PlannigDataArray.planningPriority.list"
                                                        {...formik.getFieldProps('PlannigDataArray.planningPriority.list')}
                                                    >
                                                        {planningPriority.map((item: any, index: number) => (
                                                            <option key={index} value={item?.value}>{item?.label}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td> 
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('PlannigDataArray.planningPriority.Visible', e.target.checked)}
                                                    checked={formik.values?.PlannigDataArray?.planningPriority?.Visible}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('PlannigDataArray.planningPriority.Editable', e.target.checked)}
                                                    checked={formik.values?.PlannigDataArray?.planningPriority?.Editable}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {/* Planning AI Settings 3 end*/}

                </div>


            </div>

        </div>

    </>
  )
}

export default PlanningAISettings