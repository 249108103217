import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from "react";
import { LayoutSplashScreen } from "../../../../_metronic/layout/core";
import { AuthModel, UserModel } from "./_models";
import * as authHelper from "./AuthHelpers";
import { getCognitoUserByToken, getSuperadminUserByToken, anyWordIncludedInURL } from "./_requests";
import { WithChildren } from "../../../../_metronic/helpers";
import { useMsal } from "@azure/msal-react";
import axios from "axios";
import { fetchAuthSession, signOut } from 'aws-amplify/auth';
import { AUTOTASK_ENTITY } from "../../../constants/global";
import { Error404 } from "../../errors/components/Error404";

//import {updateAlreadyLoggedInStatus} from '../core/_requests';
type AuthContextProps = {
  auth: AuthModel | undefined;
  saveAuth: (auth: AuthModel | undefined) => void;
  currentUser: UserModel | undefined;
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>;
  logout: () => void;
  /* generalSetting: any;
  setGeneralSetting: any, */
};

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => { },
  currentUser: undefined,
  setCurrentUser: () => { },
  logout: () => { },
  /*  generalSetting: undefined,
   setGeneralSetting: () => { }, */
};


const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider: FC<WithChildren> = ({ children }) => {

  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth());
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>();
  /* const [generalSetting, setGeneralSetting] = useState<any>(); */
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth);
    if (auth) {
      authHelper.setAuth(auth);
    } else {
      authHelper.removeAuth();
    }
  };
  const { instance } = useMsal();
  //detecing if microsoft logout is needed or not here
  const logout = async () => {
    let redirect = await anyWordIncludedInURL(AUTOTASK_ENTITY.URLConstant)
    let notRedirect = await anyWordIncludedInURL(AUTOTASK_ENTITY.URLConstantExclude)
    if (redirect && !notRedirect) {
      saveAuth(undefined);
      setCurrentUser(undefined);
      localStorage.clear();
      localStorage.setItem('currentStatusChange','loggedOutbyNormal');
      localStorage.setItem('justLoggedOut','true');
    }else{
      localStorage.clear();
      let domain = window.location.origin;
      let url = window.location.host.split('.')
      let remove = url.shift()
      let redirect = window.location.protocol + '//' + process.env.REACT_APP_AUTHENTICATION_SUBDOMAIN + '.' + process.env.REACT_APP_REDIRECT_URL + "/?continueurl=" + domain + "&signout"
      window.location.href = redirect
    }
  };

  return (
    <AuthContext.Provider
      value={{ auth, saveAuth, currentUser, setCurrentUser, logout }}
    /* generalSetting, setGeneralSetting */
    >
      {children}
    </AuthContext.Provider>
  );
};

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, logout, setCurrentUser } = useAuth();
  /* setGeneralSetting */
  const didRequest = useRef(false);
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  
  const [isShow, setIsShow] = useState(false)
  const { PUBLIC_URL } = process.env;
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    
    const requestUser = async () => {
      const search = await window.location.search
      const params = await new URLSearchParams(search);
      const token = params.get('accessToken') || localStorage.getItem('accessToken');
      const poolId = params.get('poolId') || localStorage.getItem('poolId');
      if (token || auth?.token) {
        localStorage.setItem('accessToken', token)
        localStorage.setItem('poolId', poolId)
        try {
          if (auth?.type === 'superadmin') {
            if (auth && auth.token) {
              const { data } = await getSuperadminUserByToken(auth.token);
              console.log("117 data ===",data);
              
              if (data) {
                setCurrentUser(data);
              }
            }
          } else {
            const { data } = await getCognitoUserByToken(token);
            setIsShow(data?.success)
            if (data?.success === false) {
              localStorage.setItem('login_error', data?.message)
              window.location.href = `${PUBLIC_URL}/error/403`;
            }
            if (data) {
              setCurrentUser(data);
              localStorage.setItem('organizationID', data?.data?.user?.organizationId)
              data?.data?.setting?.start_time && localStorage.setItem("starttime", data?.data?.setting?.start_time);
              data?.data?.setting?.end_time && localStorage.setItem("endtime", data?.data?.setting?.end_time);
              data?.data?.setting?.extended_morning_start && localStorage.setItem("extendedMorningStart", data?.data?.setting?.extended_morning_start);
              data?.data?.setting?.extended_morning_end && localStorage.setItem("extendedMorningEnd", data?.data?.setting?.extended_morning_end);
              data?.data?.setting?.extended_evening_start && localStorage.setItem("extendedEveningStart", data?.data?.setting?.extended_evening_start);
              data?.data?.setting?.extended_evening_end && localStorage.setItem("extendedEveningEnd", data?.data?.setting?.extended_evening_end);
              localStorage.setItem("time_format", data?.data?.setting?.time_format);
              localStorage.setItem("timezone", data?.data?.setting?.timezone);
              localStorage.setItem('timeline_view', data?.data?.setting?.planning_screen_view);
              if (!localStorage.getItem('show_companyinfo')) {
                localStorage.setItem("show_companyinfo", '1');
              }
            }
          }
          
        } catch (error) {
          console.error(error);
          if(isShow){
            logout();
          }
        } finally {
          setShowSplashScreen(false);
        }

      } else {
        logout();
        setShowSplashScreen(false);
      }
    };

    requestUser();
    // eslint-disable-next-line
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};

export { AuthProvider, AuthInit, useAuth };