/** @jsxRuntime classic */
/** @jsx createElement */
import { createElement } from '../../../../react/renderer';
import { MbscTimeIndicatorOptions, TimeIndicatorBase } from './time-indicator';

export function template(s: MbscTimeIndicatorOptions, inst: TimeIndicatorBase): any {
  const timezones = s.timezones;
  return (
    <div aria-hidden="true" className={inst._cssClass} style={inst._pos}>
      <div
        className={
          (timezones ? 'mbsc-flex ' : '') +
          'mbsc-schedule-time-indicator-time mbsc-schedule-time-indicator-time-' +
          s.orientation +
          inst._theme +
          inst._rtl
        }
      >
        {timezones
          ? timezones.map((t, i) => {
              return (
                <div key={i} className={'mbsc-schedule-time-indicator-tz' + inst._theme + inst._rtl}>
                  {inst._times[i]}
                </div>
              );
            })
          : inst._time}
      </div>
      {s.showDayIndicator && <div className={'mbsc-schedule-time-indicator-day' + inst._theme + inst._rtl} style={inst._dayPos} />}
    </div>
  );
}
// tslint:disable no-non-null-assertion

/**
 * The TimeIndicator component.
 *
 * Usage:
 *
 * ```
 * <TimeIndicator scheduleTimeIndicatorPosition="{}" />
 * ```
 */
export class TimeIndicator extends TimeIndicatorBase {
  protected _template(s: MbscTimeIndicatorOptions): any {
    return template(s, this);
  }
}
