/** @jsxRuntime classic */
/** @jsx createElement */
import { createElement, Fragment, isVue } from '../../../../react/renderer';
import { isString, UNDEFINED } from '../../../util/misc';
import { MbscWeekDayOptions, MbscWeekDayState, WeekDayBase } from './week-day';

export function template(s: MbscWeekDayOptions, state: MbscWeekDayState, inst: WeekDayBase): any {
  let content: any;

  if (s.renderDay) {
    content = s.renderDay(inst._data);
  }

  if (s.renderDayContent) {
    content = s.renderDayContent(inst._data);
  }

  if (isString(content)) {
    content = <div dangerouslySetInnerHTML={isVue ? UNDEFINED : inst._safeHtml(content)} />;
    inst._shouldEnhance = true;
  }

  return (
    <div ref={inst._setEl} className={inst._cssClass} onClick={inst._onClick} style={{ background: s.background }}>
      {s.renderDay ? (
        content
      ) : (
        <Fragment>
          <div
            aria-hidden="true"
            className={
              'mbsc-schedule-header-dayname' +
              inst._theme +
              (s.selected ? ' mbsc-selected' : '') +
              (s.isToday ? ' mbsc-schedule-header-dayname-curr' : '')
            }
          >
            {s.dayNames[inst._day]}
          </div>
          <div
            aria-hidden="true"
            className={
              'mbsc-schedule-header-day' +
              inst._theme +
              inst._rtl +
              (s.selected ? ' mbsc-selected' : '') +
              (s.isToday ? ' mbsc-schedule-header-day-today' : '') +
              (state.hasHover ? ' mbsc-hover' : '')
            }
          >
            {s.day}
          </div>
          {s.label && (
            <div
              className="mbsc-hidden-content"
              aria-pressed={s.selectable ? (s.selected ? 'true' : 'false') : UNDEFINED}
              role={s.selectable ? 'button' : UNDEFINED}
            >
              {s.label}
            </div>
          )}
          {s.renderDayContent && content}
        </Fragment>
      )}
    </div>
  );
}

export class WeekDay extends WeekDayBase {
  protected _template(s: MbscWeekDayOptions, state: MbscWeekDayState): any {
    return template(s, state, this);
  }
}
