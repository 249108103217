// URLCheckContext.tsx

import React, { createContext, useContext, useEffect, ReactNode } from 'react';
import { saveLayoutandfilters } from "../../_metronic/requests/PlanningRequest";
interface URLCheckProviderProps {
  children: ReactNode;
}

const URLCheckContext = createContext<null>(null);

export const URLCheckProvider: React.FC<URLCheckProviderProps> = ({ children }) => {
  useEffect(() => {
    const checkURL = async() => {
      let url = window.location.href;

      if (!url.includes('apps/planning/resources')) {
        let key = localStorage.getItem('planningSelectedLayout'); 

        // reset the layout if the user navigates away from the planning page
        let data = {
            "planning_filter" : {},
            "task_list_filter":{},
            "reset":true,
            "pageLayout":"planning"
          }
          
          // let res = await saveLayoutandfilters(data);
        // localStorage.removeItem('planningSelectedLayout'); // Replace with your key
      }
    };

    // Check URL on initial load
    checkURL();

    // Set up event listeners for URL changes
    window.addEventListener('popstate', checkURL);
    const originalPushState = window.history.pushState;
    const originalReplaceState = window.history.replaceState;

    window.history.pushState = function () {
      originalPushState.apply(window.history, arguments);
      checkURL();
    };

    window.history.replaceState = function () {
      originalReplaceState.apply(window.history, arguments);
      checkURL();
    };

    // Cleanup event listeners
    return () => {
      window.removeEventListener('popstate', checkURL);
      window.history.pushState = originalPushState;
      window.history.replaceState = originalReplaceState;
    };
  }, []);

  return <URLCheckContext.Provider value={null}>{children}</URLCheckContext.Provider>;
};

export const useURLCheck = (): null => useContext(URLCheckContext);
