import { FC, useEffect } from "react";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { useAuth } from "../../auth/core/AuthCognito";


const Error403: FC = () => {
  const { logout } = useAuth();
  const loginError = localStorage.getItem('login_error')

  const disableSplashScreen = () => {
    const splashScreen = document.getElementById("splash-screen");
    if (splashScreen) {
      splashScreen.style.setProperty("display", "none");
    }
  };

  useEffect(() => {
    disableSplashScreen()
  }, [])

  return (
    <div className="d-flex flex-column flex-root">
      <div className="d-flex flex-column flex-center flex-column-fluid p-10">
        {/* begin::Illustration */}
        {/* <img
          src={toAbsoluteUrl("/media/illustrations/sketchy-1/18.png")}
          alt=""
          className="mw-100 mb-10 h-lg-450px"
        /> */}
        <h1 className="text-danger" style={{ opacity: '0.8', fontSize: '350px' }}>403</h1>
        {/* end::Illustration */}
        {/* begin::Message */}
        <h1 className="fw-bold mb-10" style={{ color: "#A3A3C7" }}>
          {loginError}
        </h1>
        {/* end::Message */}
        {/* begin::Link */}
        <button className="btn btn-primary" onClick={() => logout()}>
          Logout
        </button>
        {/* end::Link */}
      </div>
    </div>
  );
};

export { Error403 };
