import { FC, useEffect, useState } from "react";
import { KTSVG } from "../../../../_metronic/helpers";
import ProjectTable from "./table/ProjectTable";
import { getGeneralSetting } from "../../superadmin/general-settings/core/_requests";
import { useQuery } from "react-query";
import PhaseCreate from "./PhaseCreate";
import { getPhase, getResourcesWorkType } from "../../../../_metronic/requests/PlanningRequest";
import { getResourceSetting } from "../../../../_metronic/requests/ResourceSettingRequest";
import TaskCreate from "./TaskCreate";
import { getIssues, getPriorities, getProjects, getSubIssues } from "../../../../_metronic/requests/FilterRequest";
type Props = {
    projectId?: number;
    companyId?: number;
    companies: any;
    tasks?: any;
    phases?: any;
    setTasks?: any;
    setPhases?: any;
    projectData?:any;
    setLoading?:any;
    setDisabled?:any;
    locations: any;
}
const ProjectBreakdown: FC<Props> = ({ setDisabled, locations, projectId, companyId, companies, tasks, phases, setTasks, setPhases, projectData }) => {
  
    const [isCreatePhaseOpen, setIsCreatePhaseOpen] = useState<any>(false);
    const [isCreateTaskOpen, setIsCreateTaskOpen] = useState<any>(false);
    const [isRefetchTable, setIsRefatchTable] = useState<any>(false);
    const [phaseId, setPhaseId] = useState<any>(0);
    const [localProjectList, setLocalProjectList] = useState<any[]>([]);
    const { isFetching: isfetchgeneralsetting, data: generalSetting } = useQuery(
        `project-page-resource-setting`,
        async () => {
            return await getGeneralSetting("");
        },
        {
            cacheTime: 0,
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        }
    );
    const { data: priorities } = useQuery(
        "project-Priorities",
        () => {
            // debugger
            return getPriorities();
        },
        { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
    );
    const {
        isFetching: isProjectFetch,
        data: projectList,
    } = useQuery(
        "project-screen-list",
        () => {
            return getProjects();
        },
        { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
    );

    const {
        // refetch: refetchphase,
        data: phaseList, refetch: refetch_phases,
    } = useQuery(
            "project-resources-phase",
            () => {
                return getPhase("");
            },
            { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
        );

    const { isFetching: isfetchresourcesetting, data: resource_settings } =
        useQuery(
            "resources-Settinglist",
            async () => {
                return await getResourceSetting("");
            },
            { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
        );

    const { data: worktype } = useQuery(
        "resources-worktype",
        () => {
            return getResourcesWorkType("");
        },
        { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
    );

    const { data: issues } = useQuery(
        "project-Issues",
        () => {
            // debugger
            return getIssues();
        },
        { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
    );

    const { data: subIssues } = useQuery(
        "project-sub-issues",
        () => {
            // debugger
            return getSubIssues();
        },
        { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
    );

    const responsivePopup = {
        medium: {
            display: "center",
            width: 500,
            fullScreen: false,
            touchUi: false,
            showOverlay: false,
        },
    };
  const locationShow = generalSetting?.isLocation ? true : false;

    useEffect(() => {
        if (projectList) {
          let updatedProjectList = [...projectList];
          if (projectData?.isDraft) {
            const newProjectItem = {
              id: projectData?.autotask_id ? projectData?.autotask_id : 0,
              name: projectData?.projectName,
              companyID: projectData?.companyID,
              value: projectData?.autotask_id,
              label: projectData?.projectName
            };
    
            // Check if the project already exists in the list
            const projectExists = updatedProjectList.some(
              (project) => project.id === newProjectItem.id
            );
    
            if (!projectExists) {
              updatedProjectList = [...projectList, newProjectItem];
            }
          }
            setLocalProjectList(updatedProjectList);
        }
    }, [projectData, projectList ,isRefetchTable]);

    useEffect(() => {
        refetch_phases();
    }, [isRefetchTable, refetch_phases]);

    return (
        <>
            <div className={`d-flex flex-column notifyHeight`}>
                <div className="p-main h-100">
                    <div className='projectTable'>

                        <div className='d-flex align-items-center justify-content-between mb-24px'>
                            <h3 className='fs-20px fw-bold mb-0'>Project breakdown</h3>
                            <div className='d-inline-flex align-items-center'>
                                <div>
                                    <button
                                        className="bg-white text-primary border-0 h-32px py-0 px-4 rounded-4px fs-16px fw-normal"
                                        type="button"
                                        disabled
                                        title="Functionality is in progress..."
                                        data-kt-menu-trigger="click"
                                        data-kt-menu-attach="parent"
                                        data-kt-menu-placement="bottom-start"
                                        data-kt-menu-flip="bottom"
                                    >
                                        <KTSVG
                                            path="/media/icons/duotune/new-icons/settings-trans.svg"
                                            className="text-primary me-2"
                                            svgClassName="w-16px h-auto"
                                        />
                                        <span>Manage Columns</span>
                                    </button>

                                    <div className='menu menu-sub menu-sub-dropdown bg-white p-0 w-240px' data-kt-menu="true">

                                        <div className='mb-4 form-check form-check-custom pt-5 px-5'>
                                            <div className='d-flex align-items-center justify-content-between pb-3 w-100' style={{ borderBottom: '1px solid #DADCE0' }}>
                                                <label htmlFor="" className='form-check-label fw-bold text-dark ms-0 pe-2 d-inline-flex align-items-center fs-16'>Columns</label>
                                                <span className='d-inline-flex align-items-center'>
                                                    <input className="form-check-input h-20px w-20px checkBoxClass rounded-6px border-primary" type="checkbox" data-id="project_status" id="" name="Acceptance" value="11" />
                                                </span>
                                            </div>
                                        </div>

                                        <div className='px-5 chkWrapper fs-14'>

                                            <div className='form-check form-check-custom d-flex align-items-center justify-content-between mb-3'>
                                                <label htmlFor="" className='form-check-label fw-normal text-dark ms-0 pe-2 d-inline-flex align-items-center'>Category</label>
                                                <span className='d-inline-flex align-items-center'>
                                                    <input className="form-check-input h-20px w-20px checkBoxClass rounded-6px border-primary" type="checkbox" data-id="project_status" id="" name="Acceptance" value="11" />
                                                </span>
                                            </div>
                                            <div className='form-check form-check-custom d-flex align-items-center justify-content-between mb-3'>
                                                <label htmlFor="" className='form-check-label fw-normal text-dark ms-0 pe-2 d-inline-flex align-items-center'>Type</label>
                                                <span className='d-inline-flex align-items-center'>
                                                    <input className="form-check-input h-20px w-20px checkBoxClass rounded-6px border-primary" type="checkbox" data-id="project_status" id="" name="Acceptance" value="11" />
                                                </span>
                                            </div>
                                            <div className='form-check form-check-custom d-flex align-items-center justify-content-between mb-3'>
                                                <label htmlFor="" className='form-check-label fw-normal text-dark ms-0 pe-2 d-inline-flex align-items-center'>Start Date</label>
                                                <span className='d-inline-flex align-items-center'>
                                                    <input className="form-check-input h-20px w-20px checkBoxClass rounded-6px border-primary" type="checkbox" data-id="project_status" id="" name="Acceptance" value="11" />
                                                </span>
                                            </div>

                                        </div>

                                        <div className="w-100">
                                            <button type="button" value="project_status" className="btn w-100 fs-14 border-gray-101 p-3 btn-active-primary rounded-4px">Done</button>
                                        </div>

                                    </div>

                                </div>

                                <div className='ms-4'>
                                    <button
                                        className="btn btn-primary d-inline-flex align-items-center p-0 px-5 h-32px rounded-4px fs-16"
                                        type="button"
                                        onClick={() => { setPhaseId(""); setIsCreatePhaseOpen(true) }}
                                    >
                                        <KTSVG
                                            path="/media/icons/duotune/new-icons/plus.svg"
                                            className="me-2"
                                            svgClassName="w-auto h-auto"
                                        />
                                        <small className="fs-100">Phase</small>
                                    </button>
                                </div>
                                <div className='ms-4'>
                                    <button
                                        className="btn btn-primary d-inline-flex align-items-center p-0 px-5 h-32px rounded-4px fs-16"
                                        type="button"
                                        onClick={() =>{ 
                                            setIsCreateTaskOpen(true)
                                            setPhaseId(null)
                                        }}
                                    >
                                        <KTSVG
                                            path="/media/icons/duotune/new-icons/plus.svg"
                                            className="me-2"
                                            svgClassName="w-auto h-auto"
                                        />
                                        <small className="fs-100">Task</small>
                                    </button>
                                </div>

                            </div>
                        </div>

                    </div>
                    {!isProjectFetch && <ProjectTable setDisabled ={setDisabled}  projectId={projectId} isRefetchTable={isRefetchTable} tasks={tasks} setTasks={setTasks} phases={phases} setPhases={setPhases} setIsCreatePhaseOpen={setIsCreatePhaseOpen} setIsCreateTaskOpen={setIsCreateTaskOpen} setIsRefatchTable={setIsRefatchTable} setPhaseId={setPhaseId}></ProjectTable>}
                </div>
            </div>

            {(!isfetchgeneralsetting && isCreatePhaseOpen) && (
                <PhaseCreate
                    isOpen={isCreatePhaseOpen}
                    setisCreatePhaseOpen={setIsCreatePhaseOpen}
                    time_format={generalSetting?.time_format}
                    timezone={generalSetting?.timezone}
                    projectId={projectId}
                    phaseId={phaseId}
                    phaseList={phaseList}
                    isEditScreen={true}
                    projectList={localProjectList}
                    setIsRefatchTable={setIsRefatchTable}
                    projectData={projectData}
                />
            )}

            {isCreateTaskOpen && (
                <TaskCreate
                    isOpen={isCreateTaskOpen}
                    setIsCreateTaskOpen={setIsCreateTaskOpen}
                    time_format={generalSetting?.time_format}
                    timezone={generalSetting?.timezone}
                    general_settings={generalSetting}
                    resource_settings={resource_settings}
                    issueslist={issues?.data}
                    subIssueslist={subIssues?.data}
                    worktype={worktype}
                    phase={phaseList}
                    prioritylist={priorities?.data}
                    isEditScreen={true}
                    projectId={projectId}
                    companies={companies}
                    projectList={localProjectList}
                    companyId={companyId}
                    phaseId={phaseId}
                    setIsRefatchTable={setIsRefatchTable}
                    locations={locations}
                    locationShow={locationShow}
                />
            )}

        </>


    );
}
export default ProjectBreakdown;