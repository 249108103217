import { fetchAuthSession } from "@aws-amplify/core";
import { decryptData } from "../components/encryptionUtils";
import { AuthModel } from "./_models";
import { useAuth } from "./AuthCognito";
import { alert } from "@mobiscroll/react";
import { anyWordIncludedInURL } from "./_requests";
import { redirectURL } from "../../../helpers/global";

const AUTH_LOCAL_STORAGE_KEY = "kt-auth-react-v";
const getAuth = (): AuthModel | undefined => {

  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const myParam: any = params.get('mydata');
  const userParams: any = params.get('user');
  const key: any = params.get('key');
  const token: any = params.get('token');

  const settingParams = JSON.parse(decodeURIComponent(userParams));

  if (myParam != null || myParam != undefined) {
    const encodedParam = decodeURIComponent(myParam);
    const encodedkey = decodeURIComponent(key);
    const decryptedAuth = decryptData(encodedParam, encodedkey);


    setAuth(decryptedAuth);
    localStorage.setItem("starttime", settingParams?.start_time);
    localStorage.setItem("endtime", settingParams?.end_time);
    localStorage.setItem("time_format", settingParams?.time_format);
    localStorage.setItem("timezone", settingParams?.timezone);
  }

  if (!localStorage) {

    return;
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  if (!lsValue) {

    return;
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel;
    if (auth) {
      // You can easily check auth_token expiration also
      return auth;
    }
  } catch (error) {
    console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
  }
};

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {

    return;
  }

  try {
    const lsValue = JSON.stringify(auth);
    let loginType = localStorage.getItem("loginType");
    let currentStatusChange = localStorage.getItem("currentStatusChange");
    let subdomainExists = localStorage.getItem("subdomainExists");

    if (subdomainExists === 'false') {
      localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue);
      localStorage.setItem("subdomainExists", 'true');
    } else if (loginType === "normallogin" && currentStatusChange === "loggedOutbyNormal") {

      //  localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
      localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue);

    } else if (currentStatusChange === 'loggedOutByMicrosoft') {
      // localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
      localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue);

    } else {
      localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue);
    }

    if (currentStatusChange === 'loggedOutByMicrosoft' && loginType === 'MFT') {
      localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
    }

    if (currentStatusChange === 'loggingIn') {
      localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue);
    }


  } catch (error) {
    console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
  }
};

const removeAuth = () => {
  if (!localStorage) {
    return;
  }
  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE REMOVE ERROR", error);
  }
};

// export function setupAxios(axios: any) {
//   axios.defaults.headers.Accept = "application/json";
//   axios.interceptors.request.use(
//     (config: { headers: { Authorization: string } }) => {
//       const auth = getAuth();
//       if (auth && auth.token) {
//         config.headers.Authorization = `${auth.token}`;
//       }
//       return config;
//     },
//     (err: any) => Promise.reject(err)
//   );
// }
export function setupAxios(axios: any) {
  const search = window.location.search
  const params = new URLSearchParams(search);
  const token = params.get('accessToken') || localStorage.getItem('accessToken');
  const poolId = params.get('poolId') || localStorage.getItem('poolId');
  let isSessionExpire = false;
  axios.interceptors.request.use(
    async (config: { headers: { Authorization: string, organization: string, poolid: string } }) => {
      if (isSessionExpire) {
        return Promise.reject(new Error("Session has expired"));
      }
      const auth = getAuth();
      if (auth && auth.token) {
        config.headers.Authorization = `${auth.token}`;
      } else {
        config.headers.Authorization = token;
      }
      config.headers.organization = localStorage.getItem('organizationID');
      config.headers.poolid = poolId;
      return config;
    },
    (err: any) => Promise.reject(err)
  );

  axios.interceptors.response.use(
    (response: any) => response,
    (error: any) => {
      console.log(error)
      if (error.response && error.response.status === 401) {
        const responseData = error.response?.data;
        if (responseData && responseData.success === false &&
          isSessionExpire === false &&
          window.location.href.includes('auth') === false &&
          window.location.href !== window.location.origin + '/') {
          isSessionExpire = true;
          console.log("in", "403");
          responseData.expire === true
            && alert({
              title: "Your session is expired, please click on the button below to refresh it.",
              okText: "Refresh",
              callback: function () {
                redirectURL(); // Reload the page if the button is clicked
              },
            });
        }
      }
      return Promise.reject(error);
    }
  );
}

export { getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY };
