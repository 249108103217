/** @jsxRuntime classic */
/** @jsx createElement */
import { Scroller } from '../../../react/components/scroller';
import { createElement } from '../../../react/renderer';
import { DatetimeBase, MbscDatetimeOptions } from './datetime';

import './datetime.scss';
export function template(s: MbscDatetimeOptions, inst: DatetimeBase): any {
  return (
    <Scroller
      disabled={s.disabled}
      endIcon={s.endIcon}
      endIconSrc={s.endIconSrc}
      endIconSvg={s.endIconSvg}
      error={s.error}
      errorMessage={s.errorMessage}
      inputStyle={s.inputStyle}
      label={s.label}
      labelStyle={s.labelStyle}
      placeholder={s.placeholder}
      name={s.name}
      startIcon={s.startIcon}
      startIconSrc={s.startIconSrc}
      startIconSvg={s.startIconSvg}
      anchor={s.anchor}
      animation={s.animation}
      buttons={s.buttons}
      cancelText={s.cancelText}
      clearText={s.clearText}
      closeOnOverlayClick={s.closeOnOverlayClick}
      context={s.context}
      display={s.display}
      focusOnClose={s.focusOnClose}
      focusTrap={s.focusTrap}
      headerText={s.headerText}
      height={s.height}
      setText={s.setText}
      showArrow={s.showArrow}
      showOverlay={s.showOverlay}
      width={s.width}
      circular={s.circular}
      displayStyle={s.displayStyle}
      formatValue={inst._formatDate}
      getValue={inst._getDate}
      itemHeight={s.itemHeight}
      maxWheelWidth={s.maxWheelWidth}
      minWheelWidth={inst._minWheelWidth}
      parseValue={inst._parseDate}
      ref={inst._setScroller}
      rows={s.rows}
      rtl={s.rtl}
      shouldValidate={inst._shouldValidate}
      showOnClick={s.showOnClick}
      showOnFocus={s.showOnFocus}
      theme={s.theme}
      themeVariant={s.themeVariant}
      touchUi={inst._touchUi}
      validate={inst._validate}
      value={inst._value}
      valueEquality={inst._valueEquals}
      wheels={inst._wheels}
      wheelWidth={s.wheelWidth}
      onChange={inst._onChange}

      // {...s}
      // formatValue={inst._formatDate}
      // getValue={inst._getDate}
      // minWheelWidth={inst._minWheelWidth}
      // parseValue={inst._parseDate}
      // shouldValidate={inst._shouldValidate}
      // validate={inst._validate}
      // value={inst._value}
      // valueEquality={inst._valueEquals}
      // wheels={inst._wheels}
      // onChange={inst._onChange}
    >
      {s.children}
    </Scroller>
  );
}

export class Date extends DatetimeBase {
  protected _template(s: MbscDatetimeOptions): any {
    return template(s, this);
  }
}
