/** @jsxRuntime classic */
/** @jsx createElement */
import { createElement, isVue } from '../../../react/renderer';
import { isString, UNDEFINED } from '../../util/misc';
import { IWheelItemProps, WheelItemBase } from './wheel-item';

/** @hidden */
export function template(s: IWheelItemProps, inst: WheelItemBase) {
  let content: any;
  if (s.renderItem && s.data !== UNDEFINED) {
    const cont = s.renderItem(s.data);
    const contentHtml = isString(cont) ? { __html: cont } : UNDEFINED;
    content = contentHtml ? <div dangerouslySetInnerHTML={isVue ? UNDEFINED : contentHtml} /> : <div>{cont}</div>;
  } else {
    content = s.text;
  }
  return (
    <div
      aria-disabled={s.disabled ? 'true' : UNDEFINED}
      aria-hidden={content === UNDEFINED || s.is3d ? 'true' : UNDEFINED}
      aria-selected={s.selected ? 'true' : UNDEFINED}
      ref={inst._setEl}
      tabIndex={s.active ? 0 : UNDEFINED}
      className={inst._cssClass}
      role="option"
      style={inst._style}
      onClick={inst._onClick}
    >
      {/* TRIAL */}
      {s.checkmark && <span className={inst._checkmarkClass} />}
      {content}
    </div>
  );
}

export class WheelItem extends WheelItemBase {
  protected _template(s: IWheelItemProps): any {
    return template(s, this);
  }
}
