/** @jsxRuntime classic */
/** @jsx createElement */
import { createElement, Fragment, isVue, ON_CONTEXT_MENU } from '../../../react/renderer';
import { CalendarLabel } from '../../../react/shared/calendar-label';
import { isString, UNDEFINED } from '../../util/misc';
import { CalendarDayBase, ICalendarDayProps } from './calendar-day';
import { ICalendarProcessedLabel } from './calendar-view.types';
import { computeEventResize } from './calendar-view.util';

function renderEvent(
  inst: CalendarDayBase,
  s: ICalendarDayProps,
  label: ICalendarProcessedLabel,
  showText?: boolean,
  hidden?: boolean,
  isUpdate?: boolean,
  key?: string | number,
) {
  return (
    <CalendarLabel
      key={key}
      amText={s.amText}
      count={label.count ? label.count + ' ' + (label.count > 1 ? s.eventsText : s.eventText) : UNDEFINED}
      date={s.date}
      dataTimezone={s.dataTimezone}
      displayTimezone={s.displayTimezone}
      drag={s.dragToMove}
      resize={computeEventResize(label.event && label.event.resize, s.dragToResize)}
      event={label.event}
      exclusiveEndDates={s.exclusiveEndDates}
      firstDay={s.firstDay}
      hidden={hidden}
      id={label.id}
      inactive={!isUpdate && label.event && s.dragData && s.dragData.draggedEvent && label.event.id === s.dragData.draggedEvent.id}
      isActiveMonth={s.isActiveMonth}
      isPicker={s.isPicker}
      isUpdate={isUpdate}
      label={label.label}
      lastDay={label.lastDay}
      more={label.more}
      pmText={s.pmText}
      resourcesMap={s.resourcesMap}
      rtl={s.rtl}
      selected={label.event && s.selectedEventsMap && !!(s.selectedEventsMap![label.id] || s.selectedEventsMap![label.event.id!])}
      showEventTooltip={s.showEventTooltip}
      showText={showText}
      theme={s.theme}
      timeFormat={s.timeFormat}
      timezonePlugin={s.timezonePlugin}
      render={s.renderLabel}
      renderContent={s.renderLabelContent}
      onClick={inst._onLabelClick}
      onDoubleClick={inst._onLabelDoubleClick}
      onRightClick={inst._onLabelRightClick}
      onHoverIn={inst._onLabelHoverIn}
      onHoverOut={inst._onLabelHoverOut}
      onDelete={s.onLabelDelete}
      onDragStart={s.onLabelUpdateStart}
      onDragMove={s.onLabelUpdateMove}
      onDragEnd={s.onLabelUpdateEnd}
      onDragModeOn={s.onLabelUpdateModeOn}
      onDragModeOff={s.onLabelUpdateModeOff}
    />
  );
}

function renderLabel(inst: CalendarDayBase, s: ICalendarDayProps, label: ICalendarProcessedLabel) {
  const key = label.id;

  if (label.placeholder) {
    return <div className="mbsc-calendar-text mbsc-calendar-text-placeholder" key={key} />;
  }

  if (label.more || label.count) {
    return renderEvent(inst, s, label, true, false, false, key);
  }

  return label.multiDay
    ? [
        <div className="mbsc-calendar-label-wrapper" style={{ width: label.width! + '%' }} key={key}>
          {renderEvent(inst, s, label, true)}
        </div>,
        renderEvent(inst, s, label, false, false, false, '-' + key),
      ]
    : renderEvent(inst, s, label, label.showText, false, false, key);
}

export function template(s: ICalendarDayProps, inst: CalendarDayBase): any {
  const draggedLabel = inst._draggedLabel;
  const draggedLabelOrig = inst._draggedLabelOrig;
  const theme = inst._theme;
  const rightClick = { [ON_CONTEXT_MENU]: inst._onRightClick };

  let content: any;

  if (s.renderDay) {
    content = s.renderDay(inst._data);
  }

  if (s.renderDayContent) {
    content = s.renderDayContent(inst._data);
  }

  if (isString(content)) {
    content = <div dangerouslySetInnerHTML={isVue ? UNDEFINED : inst._safeHtml(content)} />;
    inst._shouldEnhance = true;
  }

  return (
    <div
      ref={inst._setEl}
      className={inst._cssClass}
      onClick={inst._onClick}
      style={inst._cellStyles}
      tabIndex={s.disabled ? UNDEFINED : s.active ? 0 : -1}
      {...rightClick}
    >
      {/* TRIAL */}
      <div
        className={
          'mbsc-calendar-cell-inner mbsc-calendar-' +
          s.type +
          '-inner' +
          theme +
          (s.type === 'day' ? '' : inst._hb) +
          (s.display ? '' : ' mbsc-calendar-day-hidden')
        }
      >
        {s.renderDay ? (
          content
        ) : (
          <Fragment>
            {s.text === 1 && (
              <div aria-hidden="true" className={'mbsc-calendar-month-name' + theme + inst._rtl}>
                {s.monthShort}
              </div>
            )}
            <div
              aria-label={inst._ariaLabel}
              role="button"
              aria-pressed={s.selected}
              className={'mbsc-calendar-cell-text mbsc-calendar-' + s.type + '-text' + theme + inst._todayClass}
              style={inst._circleStyles}
            >
              {s.text}
            </div>

            {s.marks && ( // Extra div is needed in RTL, otherwise position is wrong in Chrome
              <div>
                <div className={'mbsc-calendar-marks' + theme + inst._rtl}>
                  {s.marks.map((mark, k: number) => (
                    <div className={'mbsc-calendar-mark ' + (mark.markCssClass || '') + theme} key={k} style={{ background: mark.color }} />
                  ))}
                </div>
              </div>
            )}
            {s.renderDayContent && content}
          </Fragment>
        )}
        {s.labels && ( // Extra div is needed in RTL, otherwise position is wrong in Chrome
          <div>
            {draggedLabelOrig && draggedLabelOrig.event && (
              <div className="mbsc-calendar-labels mbsc-calendar-labels-dragging">
                <div style={{ width: draggedLabelOrig.width + '%' || 100 + '%' }}>
                  {renderEvent(inst, s, { id: 0, event: draggedLabelOrig.event }, true, !!s.dragData!.draggedDates, true)}
                </div>
              </div>
            )}

            {draggedLabel && draggedLabel.event && (
              <div className="mbsc-calendar-labels mbsc-calendar-labels-dragging">
                <div className="mbsc-calendar-label-wrapper" style={{ width: draggedLabel.width + '%' || 100 + '%' }}>
                  {renderEvent(inst, s, { id: 0, event: draggedLabel.event }, true, false, true)}
                </div>
              </div>
            )}

            <div className="mbsc-calendar-labels">
              {s.labels.data.map((label: any) => {
                return renderLabel(inst, s, label);
              })}
            </div>
            <div className="mbsc-calendar-text mbsc-calendar-text-placeholder" />
          </div>
        )}
      </div>
    </div>
  );
}

export class CalendarDay extends CalendarDayBase {
  protected _template(s: ICalendarDayProps): any {
    return template(s, this);
  }
}
