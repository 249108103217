import {FC, useEffect} from 'react'
import { IntrojsEditModalFormWrapper } from './IntrojsEditModalFormWrapper'
import { IntrojsEditModalHeader } from './IntrojsEditModalHeader'
import React from 'react'

type Props = {
    openUserModel: (state: boolean) => any
  }

export const IntrojsEditModal: FC<Props> = ({openUserModel}) => {
    useEffect(() => {
      document.body.classList.add('modal-open')
      return () => {
        document.body.classList.remove('modal-open')
      }
    }, [])
   return (
        <>
          <div
            className='modal fade show d-block'
            id='kt_modal_add_user'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'
          >
            {/* begin::Modal dialog */}
            <div className='modal-dialog modal-dialog-centered mw-650px'>
              {/* begin::Modal content */}
              <div className='modal-content'>
                <IntrojsEditModalHeader />
                {/* begin::Modal body */}
                <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                  <IntrojsEditModalFormWrapper openUserModel={openUserModel} />
                </div>
                {/* end::Modal body */}
              </div>
              {/* end::Modal content */}
            </div>
            {/* end::Modal dialog */}
          </div>
          {/* begin::Modal Backdrop */}
          <div className='modal-backdrop fade show'></div>
          {/* end::Modal Backdrop */}
        </>
      )
    }
