import { useEffect, useState } from 'react'
import { MenuComponent } from '../../../_metronic/assets/ts/components'
import { initialQueryState, KTSVG } from '../../../_metronic/helpers'
import { useQueryRequest } from '../core/QueryRequestProvider'
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { useQuery } from 'react-query';
import { getEndpointsList } from '../../../_metronic/requests/ApiLogRequest';
import { Button, Datepicker, Input } from '@mobiscroll/react';
import moment from 'moment';

const ListFilter = () => {
  const { updateState } = useQueryRequest()
  const [name, setName] = useState<any>(Array)
  const [code, setCode] = useState<any>([])
  const [endpoints, setEndpoints] = useState<any>([])
  const [errorcode, setErrorcode] = useState<any>([])
  const [startdate, setStartDate] = useState<any>(null)
  const [enddate, setEndDate] = useState<any>(null)
  const [selecteddate, setSelectedDate] = useState<any>([]);
  const [openStartPicker, setStartOpenPicker] = useState(false);
  const [openEndPicker, setOpenEndPicker] = useState(false);
  const [openStartEndPicker, setOpenStartEndPicker] = useState(false);
  const [minstart, setminstart] = useState<any>(null);

  const {
    isFetching: isDepartmentFetch,
    // refetch,
    data: endpoint_data,
  } = useQuery(
    'endpoints-data',
    () => {
      // debugger
      return getEndpointsList();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [endpoint_data, selecteddate, startdate, enddate, minstart])
  let apis_type = [{
    "value": "*",
    "label": "All"
  }, {
    "id": 1,
    "value": "Tasks",
    "label": "Tasks"
  }, {
    "id": 2,
    "value": "Companies",
    "label": "Companies"
  }, {
    "id": 3,
    "value": "Projects",
    "label": "Projects"
  }];

  let response_codes = [{
    "value": "*",
    "label": "All"
  }, {
    "id": 1,
    "value": "200",
    "label": "200"
  }, {
    "id": 2,
    "value": "401",
    "label": "401"
  }, {
    "id": 3,
    "value": "500",
    "label": "500"
  }];

  let errorcode_data = [{
    "value": "*",
    "label": "All"
  }, {
    "id": 1,
    "value": "401",
    "label": "401"
  }, {
    "id": 2,
    "value": "500",
    "label": "500"
  }, {
    "id": 3,
    "value": "400",
    "label": "400"
  }];

  function onCodeChange(value: any, event: any) {
    if (event.action === "select-option" && event.option.value === "*") {
      setCode(response_codes);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      setCode([]);
    } else if (event.action === "deselect-option") {
      setCode(value.filter((o: any) => o.value !== "*"));
    } else if (value.length === response_codes.length - 1) {
      setCode(response_codes);
    } else {
      setCode(value);
    }
  }

  function onEndpointChange(value: any, event: any) {
    if (event.action === "select-option" && event.option.value === "*") {
      setEndpoints(endpoint_data?.data);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      setEndpoints([]);
    } else if (event.action === "deselect-option") {
      setEndpoints(value.filter((o: any) => o.value !== "*"));
    } else if (value.length === endpoint_data?.data.length - 1) {
      setEndpoints(endpoint_data?.data);
    } else {
      setEndpoints(value);
    }
  }

  const showstart = () => {
    setStartOpenPicker(true);
  };
  const showend = () => {
    setOpenEndPicker(true);
  };
  const showstartend = () => {
    setOpenStartEndPicker(true);
  };

  function onStartChange(e: any) {
    const start = moment(e.value).format('YYYY-MM-DD')
    setStartDate(start);
  }

  function onEndChange(e: any) {
    const end = moment(e.value).format('YYYY-MM-DD')
    setEndDate(end);
  }

  function onStartEndChange(e: any) {
    let start_date = e?.value[0] ? moment(e?.value[0]).format('YYYY-MM-DD') : null;
    let end_date = e?.value[1] ? moment(e?.value[1]).format('YYYY-MM-DD') : null;
    if (start_date != startdate) {
      end_date = null
    }
    const mindate = start_date != null && end_date == null
      ? start_date : null;
    setminstart(mindate);

    setSelectedDate([start_date, end_date]);
    setStartDate(start_date);
    setEndDate(end_date);
  }
  function onErrorCodeChange(value: any, event: any) {
    
    // console.log("value: ", value);
    
    if (event.action === "select-option" && event.option.value === "*") {
      setErrorcode(errorcode_data);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      setErrorcode([]);
    } else if (event.action === "deselect-option") {
      
      setErrorcode(value.filter((o: any) => o.value !== "*"));
    } else if (value.length === errorcode_data.length - 1) {
      setErrorcode(errorcode_data);
    } else {
      setErrorcode(value);
    }
  }

  function refreshPage(e: any) {
    setStartDate(null)
    setEndDate(null)
    setSelectedDate([])
    setEndpoints([])
    setCode([])
    setErrorcode([])
  }

  useEffect(() => {
    let selectedname = [];
    if (name) {
      selectedname = name?.map((x: any) => x.value.toString());
    }
    let selectedcode = [];
    if (code) {
      selectedcode = code?.map((x: any) => x.value.toString());
    }
    let selectedendpoints = [];
    if (endpoints) {
      selectedendpoints = endpoints?.map((x: any) => x.value.toString());
    }
    let selectederrorcode = [];
    if (errorcode) {
      selectederrorcode = errorcode?.map((x: any) => x.value.toString());
    }
    let startfilterdate = null;
    let endfilterdate = null;
    if (startdate && enddate) {
      startfilterdate = startdate;
      endfilterdate = enddate;
    }

    if (selectedname.length > 0 || selectedcode.length > 0 ||
      selectedendpoints.length > 0 || selectederrorcode.length > 0 ||
      startfilterdate != null || endfilterdate != null) {
      updateState({
        filter: { type: selectedname, code: selectedcode, endpoints: selectedendpoints, errorcode: selectederrorcode, startdate: startfilterdate, enddate: endfilterdate },
        ...initialQueryState,
      })
    }
    else {
      updateState({
        filter: {},
        ...initialQueryState,
      })
    }
  }, [name, code, endpoints, errorcode, startdate, enddate]);

  const resetData = () => {
    updateState({ filter: undefined, ...initialQueryState })
  }

  const onClose = () => {
    setStartOpenPicker(false);
    setOpenEndPicker(false);
    setOpenStartEndPicker(false);
  };

  return (
    <>

      {/* begin::Filter Button */}
      <div className="card-title customSelect m-0">
        {/* <input className='form-control form-control-solid fs-13 placeholder-primary h-40px p-0 px-4 h-ls-48 fs-ls-14 w-175px bg-prlight border-1 text-primary fw-bold border-themelight me-4 rounded-pill' 
        onFocus={(e) => (e.target.type = "date")}
          onBlur={(e) => (e.target.type = "text")} 
          name="startdate" 
          type='text' 
          onChange={onStartChange} placeholder="Select start date" />
        <input
          className='form-control form-control-solid fs-13 placeholder-primary h-40px p-0 px-4 h-ls-48 fs-ls-14 w-175px bg-prlight border-1 text-primary fw-bold border-themelight me-4 rounded-pill'
          onFocus={(e) => (e.target.type = "date")}
          onBlur={(e) => (e.target.type = "text")}
          name='enddate'
          type='text'
          onChange={onEndChange}
          placeholder="Select end date"
        /> */}
        {/*<button
          className='form-control form-control-solid fs-13 placeholder-primary h-40px p-0 px-4 h-ls-48 fs-ls-14 w-150px bg-prlight border-1 text-primary fw-bold border-themelight me-4 rounded-pill'
          onClick={showstart}
        >
          <Datepicker
            select="date"
            value={startdate}
            onChange={onStartChange}
            placeholder="Start Date"
            dateFormat="DD-MM-YYYY"
            isOpen={openStartPicker}
            onClose={onClose}
            max={enddate}
          />
          <KTSVG path="/media/icons/duotune/extra/calendar.svg" className='text-primary pe-none' svgClassName="w-20px h-20px" />
        </button>
         <button
          className='form-control form-control-solid fs-13 placeholder-primary h-40px p-0 px-4 h-ls-48 fs-ls-14 w-150px bg-prlight border-1 text-primary fw-bold border-themelight me-4 rounded-pill'
          onClick={showend}
        >
          <Datepicker
            select="date"
            value={enddate}
            onChange={onEndChange}
            placeholder="End Date"
            dateFormat="DD-MM-YYYY"
            isOpen={openEndPicker}
            onClose={onClose}
            min={startdate}
          />
          <KTSVG path="/media/icons/duotune/extra/calendar.svg" className='text-primary pe-none' svgClassName="w-20px h-20px" />
        </button> */}

        <button
          className='form-control form-control-solid bg-transparent px-4 border-primary rounded-pills'
          onClick={showstartend}
        >
          <Datepicker
            select="range"
            rangeHighlight={true}
            showRangeLabels={true}
            value={selecteddate}
            onChange={onStartEndChange}
            placeholder="Select Date"
            rangeStartLabel="Start date"
            rangeEndLabel="End date"
            rangeStartHelp=""
            rangeEndHelp=""
            //isOpen={openStartEndPicker}
            //onClose={onClose}
            class={'selectDate'}
            dateFormat="DD/MM/YYYY"
            min={minstart}
          />
          <KTSVG path="/media/icons/duotune/extra/calendar.svg" className='text-primary pe-none' svgClassName="w-20px h-20px" />
        </button>

        <ReactMultiSelectCheckboxes
          options={response_codes}
          placeholderButtonLabel="Status Codes"
          value={code}
          onChange={onCodeChange}
        />
        <ReactMultiSelectCheckboxes
          options={endpoint_data?.data}
          placeholderButtonLabel="EndPoints"
          value={endpoints}
          onChange={onEndpointChange}
        />
        <ReactMultiSelectCheckboxes
          options={errorcode_data}
          placeholderButtonLabel="Error Codes"
          value={errorcode}
          onChange={onErrorCodeChange}
        />
        {(code.length > 0 || errorcode.length > 0 || endpoints.length > 0 || startdate != null || enddate != null) &&
          <button
            className="p-0 bg-transparent border-0 w-auto h-auto"
            onClick={refreshPage}
          >
            <KTSVG path="/media/icons/duotune/extra/close.svg" className='text-primary pe-none' svgClassName="w-10px h-10px w-ls-14 h-ls-14" />
          </button>
        }

      </div>
      {/* end::SubMenu */}
    </>
  )
}

export { ListFilter }
