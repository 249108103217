/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
// import { MenuComponent } from "../../../../../../../_metronic/assets/ts/components";
// import { ID, KTSVG, QUERIES } from "../../../../../../../_metronic/helpers";
// import { useListView } from "../../../core/ListViewProvider";
// import { useQueryResponse } from "../../../core/QueryResponseProvider";
// import { deleteEmail } from "../../../core/_requests";
// import { EmailEdit } from "../../../email-manage/EmailEdit";
import { MenuComponent } from "../../../../../../_metronic/assets/ts/components";
import { ID, KTSVG, QUERIES } from "../../../../../../_metronic/helpers";
import { useListView } from "../../core/ListViewProvider";
import { useQueryResponse } from "../../core/QueryResponseProvider";
import { deleteIntrojsData, getTooltipData } from "../../core/_request";
import React from "react";

type Props = {
  id: ID;
  introjs: any;
};
let deleteID: ID;
const IntrojsActionCell: FC<Props> = ({ id }) => {
  const [modal, setModal] = useState(false);
  const { setItemIdForUpdate } = useListView();
  const { query, refetch } = useQueryResponse();
  const queryClient = useQueryClient();

  useEffect(() => {
    MenuComponent.reinitialization();
  }, [id]);

  const openEditModal = () => {
    setItemIdForUpdate(id);
  };
  const handleDelete = () => {
    deleteID = id;
  };
  const deleteItem = useMutation(async () => await deleteIntrojsData(deleteID), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`]);
    },
  });
  const deleteAction = async () => {
    await deleteItem.mutateAsync();
    refetch()
  };
  return (
    <>
      {/* <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a> */}
      {/* <ToolTip text='delete'  > */}
      {/* <Link to={{
        pathname: `/superadmin/mail-template/email/edit?email_id=${id}`,
      }} className='border-0 bg-transparent'
        data-bs-toggle="modal" data-bs-target="#kt_modal_3">
        <KTSVG path='/media/icons/duotune/general/gen055.svg' className='svg-icon-1 m-auto text-primary' />
      </Link> */}
      <div className="d-flex align-items-center ps-0">
        <button
          type="button"
          onClick={openEditModal}
          className="border-0 bg-transparent"
          data-bs-toggle="modal"
          data-bs-target="#kt_modal_3"
        >
          <KTSVG
            path="/media/icons/duotune/general/gen055.svg"
            className="svg-icon-1 m-auto text-primary"
          />
        </button>
        {/* <div className="modal fade" tabIndex={-1} id="kt_modal_3">
        <div className="modal-dialog modal-dialog-centered modal-lg mw-650px" >
          <div className="modal-content">
            <div className="modal-body text-start py-0">
            </div>
          </div>
        </div>
      </div> */}

        <button
          className="border-0 bg-transparent"
          onClick={handleDelete}
          data-bs-toggle="modal"
          data-bs-target="#kt_modal_1"
        >
          <KTSVG
            path="/media/icons/duotune/general/gen027.svg"
            className="svg-icon-1 m-auto text-danger"
          />
        </button>

        <div className="modal fade" id="kt_modal_1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Delete</h5>
                <div
                  className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr061.svg"
                    className="svg-icon svg-icon-2x"
                  />
                </div>
              </div>
              <div className="modal-body text-start">
                <p>Are you sure you want to delete this tour/hint entry?</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light"
                  data-bs-dismiss="modal"
                >
                  No
                </button>
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  className="btn btn-danger"
                  onClick={deleteAction}
                >
                  {/* onClick={async () => await deleteItem.mutateAsync()} */}
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        <div className='menu-item px-3'>
          <Link
            className='menu-link px-3'
            to={{
              pathname: `/apps/email/edit?email_id=${id}`,
            }}
          >
            Edit
          </Link>
        </div>
        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-email-table-filter='delete_row'
          >
            Delete
          </a>
        </div>
      </div> */}
      </div>
    </>
  );
};

export { IntrojsActionCell };
