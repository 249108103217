import { PureComponent, ReactNode } from 'react';
import { Options } from './options';

// tslint:disable no-empty

export function Directive(args: any) {
  return (ctor: any) => {
    return ctor;
  };
}

/** @hidden */
export class Base<PropsType, StateType> extends PureComponent<PropsType, StateType> {
  /** @hidden */
  public static propTypes: any;

  /** @hidden */
  public static contextType = Options;

  /** @hidden */
  public s!: PropsType;

  /** @hidden */
  public get value(): any {
    return this.__value;
  }
  /** @hidden */
  public set value(v: any) {
    this.__value = v;
  }

  /** @hidden */
  // tslint:disable variable-name
  public _el!: HTMLElement;

  /** @hidden */
  public _shouldEnhance?: HTMLElement | string | boolean | null;

  protected _opt: any;

  private __value: any;
  // tslint:enable variable-name

  /** @hidden */
  public componentDidMount() {
    this.__init(); // For base class
    this._init();
    this._mounted();
    // this._hook('onMarkupReady', { target: this._el });
    this._updated();
  }

  /** @hidden */
  public componentDidUpdate() {
    this._updated();
  }

  /** @hidden */
  public componentWillUnmount() {
    this._destroy();
    this.__destroy(); // For base class
  }

  /** @hidden */
  public render(): ReactNode {
    this._opt = this.context;
    this._willUpdate();
    return this._template(this.s, this.state);
  }

  /** @hidden */
  // tslint:disable-next-line: variable-name
  public _setEl = (el: any) => {
    this._el = el ? el._el || el : null;
  };

  /** @hidden */
  public _safeHtml(html: string): { __html: string } {
    return { __html: html };
  }

  protected _init(): void {}

  protected __init(): void {}

  protected _emit(name: string, args: any): void {}

  protected _mounted(): void {}

  protected _updated(): void {}

  protected _destroy(): void {}

  protected __destroy(): void {}

  protected _willUpdate(): void {}

  protected _template(s: PropsType, state: StateType): ReactNode {
    return;
  }
}
