
import { BaseComponent, IBaseProps } from '../../base';
import { createDate, getDateStr, isSameDay } from '../../util/datetime';
import { MbscTimezonePlugin } from '../../util/datetime.types.public';
import { closest, getTextColor } from '../../util/dom';
import { gestureListener } from '../../util/gesture';
import { ENTER, SPACE } from '../../util/keys';
import { UNDEFINED } from '../../util/misc';
import { ICalendarLabelData, ICalendarLabelDragArgs, MbscCalendarMarked } from './calendar-view.types';
import { ILabelDragData, ILabelDragDataSet, MbscCalendarEvent, MbscCalendarEventData, MbscResource } from './calendar-view.types';

// tslint:disable no-non-null-assertion
// tslint:disable directive-class-suffix
// tslint:disable directive-selector

/** @hidden */
export interface ICalendarDayProps extends IBaseProps {
  active?: boolean;
  amText?: string;
  colors?: any;
  clickToCreate?: boolean | 'double' | 'single';
  date: number;
  day?: string;
  disabled?: boolean;
  display?: boolean;
  dataTimezone?: string;
  displayTimezone?: string;
  dragData?: ILabelDragData;
  dragToCreate?: boolean;
  dragToMove?: boolean;
  dragToResize?: boolean;
  events?: MbscCalendarEvent[];
  exclusiveEndDates?: boolean;
  firstDay?: number;
  hasMarks?: boolean;
  hoverEnd?: number;
  hoverStart?: number;
  isActiveMonth?: boolean;
  isPicker?: boolean;
  labels?: ICalendarLabelData;
  pmText?: string;
  marks?: MbscCalendarMarked[];
  month?: string;
  monthShort?: string;
  outer?: boolean;
  rangeEnd?: number;
  rangeStart?: number;
  resourcesMap?: { [key: number]: MbscResource };
  rtl?: boolean;
  selected?: boolean;
  selectedEventsMap?: { [key: string]: MbscCalendarEvent };
  showEventTooltip?: boolean;
  text?: string | number;
  theme?: string;
  timeFormat?: string;
  timezonePlugin?: MbscTimezonePlugin;
  type?: 'day' | 'month' | 'year';
  year?: number;
  // update?: any;
  // Localization
  eventText?: string;
  eventsText?: string;
  todayText?: string;
  // Events
  dayTemplate?(args: MbscCalendarDayData): any;
  dayContentTemplate?(args: MbscCalendarDayData): any;
  onDayClick?(args: any, inst: any): void;
  onDayDoubleClick?(args: any, inst: any): void;
  onDayRightClick?(args: any, inst: any): void;
  onHoverIn?(args: any, inst: any): void;
  onHoverOut?(args: any, inst: any): void;
  onLabelClick?(args: any, inst: any): void;
  onLabelDoubleClick?(args: any, inst: any): void;
  onLabelHoverIn?(args: any, inst: any): void;
  onLabelHoverOut?(args: any, inst: any): void;
  onLabelRightClick?(args: any, inst: any): void;
  onLabelDelete?(args: any, inst: any): void;
  onLabelUpdateStart?(args: any): void;
  onLabelUpdateMove?(args: any): void;
  onLabelUpdateEnd?(args: any): void;
  onLabelUpdateModeOn?(args: any): void;
  onLabelUpdateModeOff?(args: any): void;
  // Customization settings
  renderDay?(args: MbscCalendarDayData): any;
  renderDayContent?(args: MbscCalendarDayData): any;
  renderLabel?(event: MbscCalendarEventData): any;
  renderLabelContent?(event: MbscCalendarEventData): any;
}

/** @hidden */
export interface ICalendarDayState {
  hasFocus?: boolean;
  hasHover?: boolean;
  dragShadow?: any;
}

// tslint:disable-next-line: interface-name
export interface MbscCalendarDayData {
  date: Date;
  endDate?: Date;
  events?: MbscCalendarEvent[];
  isActive?: boolean;
  selected?: boolean;
  resource?: number | string;
  startDate?: Date;
  weekNr?: number;
}

/** @hidden */

export class CalendarDayBase extends BaseComponent<ICalendarDayProps, ICalendarDayState> {
  // tslint:disable variable-name
  public _ariaLabel?: string;
  public _cssClass?: string;
  public _data!: MbscCalendarDayData;
  public _cellStyles?: any;
  public _circleStyles?: any;
  public _todayClass?: string;
  public _draggedLabel?: ILabelDragDataSet;
  public _draggedLabelOrig?: ILabelDragDataSet;

  private _unlisten?: () => void;
  // tslint:enable variable-name

  // tslint:disable-next-line variable-name
  public _onClick = (ev: any) => {
    this._cellClick('onDayClick', ev);
  };

  // tslint:disable-next-line variable-name
  public _onRightClick = (ev: any) => {
    this._cellClick('onDayRightClick', ev);
  };

  // tslint:disable-next-line variable-name
  public _onLabelClick = (args: any) => {
    this._labelClick('onLabelClick', args);
  };

  // tslint:disable-next-line variable-name
  public _onLabelDoubleClick = (args: any) => {
    this._labelClick('onLabelDoubleClick', args);
  };

  // tslint:disable-next-line variable-name
  public _onLabelRightClick = (args: any) => {
    this._labelClick('onLabelRightClick', args);
  };

  // tslint:disable-next-line variable-name
  public _onLabelHoverIn = (args: any) => {
    this._labelClick('onLabelHoverIn', args);
  };

  // tslint:disable-next-line variable-name
  public _onLabelHoverOut = (args: any) => {
    this._labelClick('onLabelHoverOut', args);
  };

  protected _mounted() {
    let allowCreate: boolean;
    let allowStart: boolean;
    let touchTimer: any;
    this._unlisten = gestureListener(this._el, {
      click: true,
      onBlur: () => {
        this.setState({ hasFocus: false });
      },
      onDoubleClick: (args) => {
        const s = this.s;
        if (s.clickToCreate && s.clickToCreate !== 'single' && s.labels && !s.disabled && s.display) {
          this._hook('onLabelUpdateStart', args);
          this._hook('onLabelUpdateEnd', args);
        }
        this._cellClick('onDayDoubleClick', args.domEvent);
      },
      onEnd: (args) => {
        if (allowCreate) {
          // Will prevent mousedown event on doc, which would exit drag mode
          args.domEvent.preventDefault();
          // args.target = this._el;
          this._hook('onLabelUpdateEnd', args);
          allowCreate = false;
        }
        clearTimeout(touchTimer);
        allowCreate = false;
        allowStart = false;
      },
      onFocus: () => {
        this.setState({ hasFocus: true });
      },
      onHoverIn: (ev: any) => {
        const s = this.s;
        if (!s.disabled) {
          this.setState({ hasHover: true });
        }
        this._hook('onHoverIn', {
          date: new Date(s.date),
          domEvent: ev,
          hidden: !s.display,
          outer: s.outer,
          target: this._el,
        });
      },
      onHoverOut: (ev: any) => {
        const s = this.s;
        this.setState({ hasHover: false });
        this._hook('onHoverOut', {
          date: new Date(s.date),
          domEvent: ev,
          hidden: !s.display,
          outer: s.outer,
          target: this._el,
        });
      },
      onKeyDown: (ev: any) => {
        switch (ev.keyCode) {
          case ENTER:
          case SPACE:
            ev.preventDefault();
            this._onClick(ev);
            break;
        }
      },
      onMove: (args) => {
        if (allowCreate && this.s.dragToCreate) {
          args.domEvent.preventDefault();
          this._hook('onLabelUpdateMove', args);
        } else if (allowStart && this.s.dragToCreate && (Math.abs(args.deltaX) > 7 || Math.abs(args.deltaY) > 7)) {
          allowCreate = !args.isTouch;
          this._hook('onLabelUpdateStart', args);
        } else {
          clearTimeout(touchTimer);
        }
      },
      onStart: (args: ICalendarLabelDragArgs) => {
        const s = this.s;
        args.create = true;
        if (!s.disabled && (s.dragToCreate || s.clickToCreate) && s.labels && !allowCreate) {
          // Check if we started on a label or not
          const label = closest(args.domEvent.target as HTMLElement, '.mbsc-calendar-text', this._el);
          if (!label) {
            if (args.isTouch && s.dragToCreate) {
              touchTimer = setTimeout(() => {
                this._hook('onLabelUpdateStart', args);
                this._hook('onLabelUpdateModeOn', args);
                allowCreate = true;
              }, 350);
            } else if (s.clickToCreate === 'single') {
              this._hook('onLabelUpdateStart', args);
              allowCreate = true;
            } else {
              allowStart = !args.isTouch;
            }
          }
        }
      },
    });
  }

  protected _render(s: ICalendarDayProps, state: ICalendarDayState) {
    const now = createDate(s);
    const d = s.date;
    const { colors, display, dragData, hoverEnd, hoverStart, labels, rangeEnd, rangeStart } = s;
    const date = new Date(d);
    const dateKey = getDateStr(date);
    const isToday = isSameDay(now, date);
    const events = labels && labels.events;
    const color = colors && colors[0];
    const background = color && color.background;
    const highlight = color && color.highlight;

    let cellClass = '';
    let highlightClass = '';

    this._draggedLabel = dragData && dragData.draggedDates && dragData.draggedDates[dateKey];
    this._draggedLabelOrig = dragData && dragData.originDates && dragData.originDates[dateKey];
    this._todayClass = isToday ? ' mbsc-calendar-today' : '';
    this._cellStyles = background && display ? { backgroundColor: background, color: getTextColor(background) } : UNDEFINED;
    this._circleStyles = highlight ? { backgroundColor: highlight, color: getTextColor(color.highlight) } : UNDEFINED;
    this._ariaLabel =
      s.type === 'day'
        ? (isToday ? s.todayText + ', ' : '') + s.day + ', ' + s.month + ' ' + s.text + ', ' + s.year
        : s.type === 'month'
        ? s.month!
        : '';

    // Only add highlight classes if the cell is actually displayed
    if (display) {
      // range selection can start with a rangeStart or with a rangeEnd without the other
      // the same classes are needed in both cases
      if (
        (rangeStart && d >= rangeStart && d <= (rangeEnd || rangeStart)) ||
        (rangeEnd && d <= rangeEnd && d >= (rangeStart || rangeEnd))
      ) {
        highlightClass =
          ' mbsc-range-day' +
          (d === (rangeStart || rangeEnd) ? ' mbsc-range-day-start' : '') +
          (d === (rangeEnd || rangeStart) ? ' mbsc-range-day-end' : '');
      }
      if (hoverStart && hoverEnd && d >= hoverStart && d <= hoverEnd) {
        highlightClass +=
          ' mbsc-range-hover' +
          (d === hoverStart ? ' mbsc-range-hover-start mbsc-hover' : '') +
          (d === hoverEnd ? ' mbsc-range-hover-end mbsc-hover' : '');
      }
    }

    if (s.marks) {
      s.marks.forEach((e: any) => {
        cellClass += e.cellCssClass ? ' ' + e.cellCssClass : '';
      });
    }

    if (colors) {
      colors.forEach((e: any) => {
        cellClass += e.cellCssClass ? ' ' + e.cellCssClass : '';
      });
    }

    if (events) {
      events.forEach((e: any) => {
        cellClass += e.cellCssClass ? ' ' + e.cellCssClass : '';
      });
    }

    this._cssClass =
      'mbsc-calendar-cell mbsc-flex-1-0-0 mbsc-calendar-' +
      s.type +
      this._theme +
      this._rtl +
      this._hb +
      cellClass +
      (labels ? ' mbsc-calendar-day-labels' : '') +
      (colors ? ' mbsc-calendar-day-colors' : '') +
      (s.outer ? ' mbsc-calendar-day-outer' : '') +
      (s.hasMarks ? ' mbsc-calendar-day-marked' : '') +
      (s.disabled ? ' mbsc-disabled' : '') +
      (display ? '' : ' mbsc-calendar-day-empty') +
      (s.selected ? ' mbsc-selected' : '') +
      (state.hasFocus ? ' mbsc-focus' : '') +
      // hover styling needed only on hoverStart and hoverEnd dates in the case of range hover
      // we can tell if no range hover is in place when neither hoverStart nor hoverEnd is there
      (state.hasHover && (d === hoverStart || d === hoverEnd || (!hoverStart && !hoverEnd)) ? ' mbsc-hover' : '') +
      (this._draggedLabel ? ' mbsc-calendar-day-highlight' : '') +
      highlightClass;

    this._data = {
      date,
      events: s.events || [],
      selected: s.selected,
    };
  }

  protected _destroy() {
    if (this._unlisten) {
      this._unlisten();
    }
  }

  private _cellClick(name: string, domEvent: any) {
    const s = this.s;
    if (s.display) {
      this._hook(name, {
        date: new Date(s.date),
        disabled: s.disabled,
        domEvent,
        outer: s.outer,
        selected: s.selected,
        source: 'calendar',
        target: this._el,
      });
    }
  }

  private _labelClick(name: string, args: any) {
    const s = this.s;
    args.date = new Date(s.date);
    args.labels = s.labels!.events;
    this._hook(name, args);
  }
}
