import { useEffect } from "react";
import { Navigate, Routes } from "react-router-dom";
import { useAuth } from "./core/AuthCognito";

export function LogoutCognito() {
  const { logout, currentUser } = useAuth();
  const redirect = (currentUser?.data.type === 'superadmin') ? '/superadmin' : "/auth/login";
  useEffect(() => {
    logout();
    document.location.reload();
  }, [logout]);

  return (
    <Routes>
      <Navigate to={redirect} />
    </Routes>
  );
}
