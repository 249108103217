import React, { useEffect, useRef } from 'react'
import Select from "react-select";
import $ from 'jquery';
import 'select2';
import 'select2/dist/css/select2.css';
import 'jquery-ui-dist/jquery-ui.css';
import 'jquery-ui-dist/jquery-ui';

const serviceCallList = [
    { value: 'Task title', label: 'Task title' },
    { value: 'Project title', label: 'Project title' },
    { value: 'Duration', label: 'Duration' },
  ];

  const planServiceCallList = [
    { value: 'based upon estimated hours, closest to start date of task and dented', label: 'based upon estimated hours, closest to start date of task and dented' },
    { value: 'based upon estimated hours, evenly spread over start and end date', label: 'based upon estimated hours, evenly spread over start and end date' },
    { value: 'based upon estimated hours, closest to end date of task and dented', label: 'based upon estimated hours, closest to end date of task and dented' },    
    { value: 'based upon estimated hours, most efficient planning by resource', label: 'based upon estimated hours, most efficient planning by resource' },
  ]

  const planningPriorityList = [
    { value: 'Prioritize as fast as possible', label: 'Prioritize as fast as possible' },
    { value: 'Prioritize closest to end date', label: 'Prioritize closest to end date' },
    { value: 'Prioritize equal spreading', label: 'Prioritize equal spreading' },
  ]

const ProjectAISettings = ({formik}: any) => {
    const selectRef = useRef(null);

    useEffect(() => {
        const $select = $(selectRef.current).select2({
          placeholder: 'Select options',
          tags: true,
        });
    
        // Make Select2 selection list sortable
        const $select2container = $select.next('.select2-container');
        $select2container.find('ul.select2-selection__rendered').sortable({
          containment: 'parent',
          stop: function () {
            $(this)
              .find('.select2-selection__choice')
              .each(function (i) {
                var title = $(this).attr('title');
                var original = $select.find('option').filter(function () {
                  return $(this).text() === title;
                });
                original.detach();
                $select.append(original);
              });
            $select.trigger('change');
          },
        });
    
        // Handle Select2 change event
        $select.on('change', function (e) {
          formik.setFieldValue('ProjectDataArray.plannigPriorityForProject.list', $(this).val()); // Update Formik field with selected options
        });
    
        return () => {
          $select.select2('destroy');
        };
      }, [formik]);

  return (
    <>

        <div className='card mb-4'>

            <div className="card-header">
                <div className="card-title">
                    <h3 className="text-dark fw-bold fs-18 m-0">Project AI setting</h3>
                </div>
            </div>

            <div className='card-body'>
                <div className="row">

                    {/* Project AI Settings 1 Start*/}
                    <div className="col-6">
                        <div style={{ border: '1px solid #DADCE0' }} className="table-responsive rounded-4 p-5 setCenter h-100">
                            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                <thead>
                                <tr className="fw-bold text-muted">
                                    <th></th>
                                    <th className="w-125px fs-3 text-center">Spank level</th>
                                    <th className="w-75px fs-3 text-center">Visible</th>
                                    <th className="w-80px fs-3 text-center">Editable</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <h4>Auto adjust phases and project start and end date:</h4>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-column">
                                            <input
                                                type="number"
                                                className="form-control h-35px p-0 px-4 fs-6 w-70px"
                                                value={formik.values?.ProjectDataArray?.phaseAndProjectStartEnd?.SpankLevel}
                                                onChange={(e) => formik.setFieldValue('ProjectDataArray.phaseAndProjectStartEnd.SpankLevel', e.target.value)}
                                                min={1}
                                                max={10}
                                            />

                                            {/* {!isValid && <span className="text-danger">{error}</span>} */}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('ProjectDataArray.phaseAndProjectStartEnd.Visible', e.target.checked)}
                                                    checked={formik.values?.ProjectDataArray?.phaseAndProjectStartEnd?.Visible}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('ProjectDataArray.phaseAndProjectStartEnd.Editable', e.target.checked)}
                                                    checked={formik.values?.ProjectDataArray?.phaseAndProjectStartEnd?.Editable}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className=''>
                                        <div className="d-flex align-items-center">
                                            <h4>Never plan before "don't start before date":</h4>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-column">
                                            <input
                                                type="number"
                                                className="form-control h-35px p-0 px-4 fs-6 w-70px"
                                                value={formik.values?.ProjectDataArray?.neverPlanBefore?.SpankLevel}
                                                onChange={(e) => formik.setFieldValue('ProjectDataArray.neverPlanBefore.SpankLevel', e.target.value)}
                                                min={1}
                                                max={10}
                                            />

                                            {/* {!isValid && <span className="text-danger">{error}</span>} */}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('ProjectDataArray.neverPlanBefore.Visible', e.target.checked)}
                                                    checked={formik.values?.ProjectDataArray?.neverPlanBefore?.Visible}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('ProjectDataArray.neverPlanBefore.Editable', e.target.checked)}
                                                    checked={formik.values?.ProjectDataArray?.neverPlanBefore?.Editable}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className=''>
                                        <div className="d-flex align-items-center">
                                            <h4>Never plan task before related tasks:</h4>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-column">
                                            <input
                                                type="number"
                                                className="form-control h-35px p-0 px-4 fs-6 w-70px"
                                                value={formik.values?.ProjectDataArray?.neverPlanTaskBefore?.SpankLevel}
                                                onChange={(e) => formik.setFieldValue('ProjectDataArray.neverPlanTaskBefore.SpankLevel', e.target.value)}
                                                min={1}
                                                max={10}
                                            />

                                            {/* {!isValid && <span className="text-danger">{error}</span>} */}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('ProjectDataArray.neverPlanTaskBefore.Visible', e.target.checked)}
                                                    checked={formik.values?.ProjectDataArray?.neverPlanTaskBefore?.Visible}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('ProjectDataArray.neverPlanTaskBefore.Editable', e.target.checked)}
                                                    checked={formik.values?.ProjectDataArray?.neverPlanTaskBefore?.Editable}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className=''>
                                        <div className="d-flex align-items-center">
                                            <h4>Never plan task defore previous phase is finished:</h4>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-column">
                                            <input
                                                type="number"
                                                className="form-control h-35px p-0 px-4 fs-6 w-70px"
                                                value={formik.values?.ProjectDataArray?.neverPlanTaskBeforePhase?.SpankLevel}
                                                onChange={(e) => formik.setFieldValue('ProjectDataArray.neverPlanTaskBeforePhase.SpankLevel', e.target.value)}
                                                min={1}
                                                max={10}
                                            />

                                            {/* {!isValid && <span className="text-danger">{error}</span>} */}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('ProjectDataArray.neverPlanTaskBeforePhase.Visible', e.target.checked)}
                                                    checked={formik.values?.ProjectDataArray?.neverPlanTaskBeforePhase?.Visible}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('ProjectDataArray.neverPlanTaskBeforePhase.Editable', e.target.checked)}
                                                    checked={formik.values?.ProjectDataArray?.neverPlanTaskBeforePhase?.Editable}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className=''>
                                        <div className="d-flex align-items-center">
                                            <h4>Only plan tasks for assigned resource:</h4>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-column">
                                            <input
                                                type="number"
                                                className="form-control h-35px p-0 px-4 fs-6 w-70px"
                                                value={formik.values?.ProjectDataArray?.taskForAssignedResource?.SpankLevel}
                                                onChange={(e) => formik.setFieldValue('ProjectDataArray.taskForAssignedResource.SpankLevel', e.target.value)}
                                                min={1}
                                                max={10}
                                            />

                                            {/* {!isValid && <span className="text-danger">{error}</span>} */}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('ProjectDataArray.taskForAssignedResource.Visible', e.target.checked)}
                                                    checked={formik.values?.ProjectDataArray?.taskForAssignedResource?.Visible}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('ProjectDataArray.taskForAssignedResource.Editable', e.target.checked)}
                                                    checked={formik.values?.ProjectDataArray?.taskForAssignedResource?.Editable}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className=''>
                                        <div className="d-flex align-items-center">
                                            <h4>Only plan tasks when resource has the needed label:</h4>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-column">
                                            <input
                                                type="number"
                                                className="form-control h-35px p-0 px-4 fs-6 w-70px"
                                                value={formik.values?.ProjectDataArray?.planTaskWithLabel?.SpankLevel}
                                                onChange={(e) => formik.setFieldValue('ProjectDataArray.planTaskWithLabel.SpankLevel', e.target.value)}
                                                min={1}
                                                max={10}
                                            />

                                            {/* {!isValid && <span className="text-danger">{error}</span>} */}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('ProjectDataArray.planTaskWithLabel.Visible', e.target.checked)}
                                                    checked={formik.values?.ProjectDataArray?.planTaskWithLabel?.Visible}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('ProjectDataArray.planTaskWithLabel.Editable', e.target.checked)}
                                                    checked={formik.values?.ProjectDataArray?.planTaskWithLabel?.Editable}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className=''>
                                        <div className="d-flex align-items-center">
                                            <h4>{`Plan [Service calls]:`}</h4>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-column">
                                            <input
                                                type="number"
                                                className="form-control h-35px p-0 px-4 fs-6 w-70px"
                                                value={formik.values?.ProjectDataArray?.planServiceCall?.SpankLevel}
                                                onChange={(e) => formik.setFieldValue('ProjectDataArray.planServiceCall.SpankLevel', e.target.value)}
                                                min={1}
                                                max={10}
                                            />

                                            {/* {!isValid && <span className="text-danger">{error}</span>} */}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('ProjectDataArray.planServiceCall.Visible', e.target.checked)}
                                                    checked={formik.values?.ProjectDataArray?.planServiceCall?.Visible}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('ProjectDataArray.planServiceCall.Editable', e.target.checked)}
                                                    checked={formik.values?.ProjectDataArray?.planServiceCall?.Editable}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>                        
                    </div>
                    {/* Project AI Settings 1 End*/}

                    {/* Project AI Settings 2 Start*/}
                    <div className="col-6">
                        <div style={{ border: '1px solid #DADCE0' }} className="table-responsive rounded-4 p-5 setCenter h-100">
                            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                <thead>
                                <tr className="fw-bold text-muted">
                                    <th></th>
                                    <th className="w-125px fs-3 text-center">Spank level</th>
                                    <th className="w-75px fs-3 text-center">Visible</th>
                                    <th className="w-80px fs-3 text-center">Editable</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <h4>Allow overlap:</h4>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-column">
                                            <input
                                                type="number"
                                                className="form-control h-35px p-0 px-4 fs-6 w-70px"
                                                value={formik.values?.ProjectDataArray?.allowOverlapForProject?.SpankLevel}
                                                onChange={(e) => formik.setFieldValue('ProjectDataArray.allowOverlapForProject.SpankLevel', e.target.value)}
                                                min={1}
                                                max={10}
                                            />

                                            {/* {!isValid && <span className="text-danger">{error}</span>} */}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('ProjectDataArray.allowOverlapForProject.Visible', e.target.checked)}
                                                    checked={formik.values?.ProjectDataArray?.allowOverlapForProject?.Visible}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('ProjectDataArray.allowOverlapForProject.Editable', e.target.checked)}
                                                    checked={formik.values?.ProjectDataArray?.allowOverlapForProject?.Editable}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className=''>
                                        <div className="d-flex align-items-center">
                                            <h4>{`Minimum time per [Service Call]`}:</h4>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-column">
                                            <input
                                                type="number"
                                                className="form-control h-35px p-0 px-4 fs-6 w-70px"
                                                value={formik.values?.ProjectDataArray?.minServiceCall?.SpankLevel}
                                                onChange={(e) => formik.setFieldValue('ProjectDataArray.minServiceCall.SpankLevel', e.target.value)}
                                                min={1}
                                                max={10}
                                            />

                                            {/* {!isValid && <span className="text-danger">{error}</span>} */}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('ProjectDataArray.minServiceCall.Visible', e.target.checked)}
                                                    checked={formik.values?.ProjectDataArray?.minServiceCall?.Visible}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('ProjectDataArray.minServiceCall.Editable', e.target.checked)}
                                                    checked={formik.values?.ProjectDataArray?.minServiceCall?.Editable}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className=''>
                                        <div className="d-flex align-items-center">
                                            <h4>{`Maximum time per [Service Call]`}:</h4>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-column">
                                            <input
                                                type="number"
                                                className="form-control h-35px p-0 px-4 fs-6 w-70px"
                                                value={formik.values?.ProjectDataArray?.maxServiceCall?.SpankLevel}
                                                onChange={(e) => formik.setFieldValue('ProjectDataArray.maxServiceCall.SpankLevel', e.target.value)}
                                                min={1}
                                                max={10}
                                            />

                                            {/* {!isValid && <span className="text-danger">{error}</span>} */}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('ProjectDataArray.maxServiceCall.Visible', e.target.checked)}
                                                    checked={formik.values?.ProjectDataArray?.maxServiceCall?.Visible}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('ProjectDataArray.maxServiceCall.Editable', e.target.checked)}
                                                    checked={formik.values?.ProjectDataArray?.maxServiceCall?.Editable}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className=''>
                                        <div className="d-flex align-items-center">
                                            <h4>Allow replanning for future service call and non-completed tasks and service call:</h4>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-column">
                                            <input
                                                type="number"
                                                className="form-control h-35px p-0 px-4 fs-6 w-70px"
                                                value={formik.values?.ProjectDataArray?.allowReplanningFutureSC?.SpankLevel}
                                                onChange={(e) => formik.setFieldValue('ProjectDataArray.allowReplanningFutureSC.SpankLevel', e.target.value)}
                                                min={1}
                                                max={10}
                                            />

                                            {/* {!isValid && <span className="text-danger">{error}</span>} */}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('ProjectDataArray.allowReplanningFutureSC.Visible', e.target.checked)}
                                                    checked={formik.values?.ProjectDataArray?.allowReplanningFutureSC?.Visible}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('ProjectDataArray.allowReplanningFutureSC.Editable', e.target.checked)}
                                                    checked={formik.values?.ProjectDataArray?.allowReplanningFutureSC?.Editable}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className=''>
                                        <div className="d-flex align-items-center">
                                            <h4>Auto adjust tasks start and end time:</h4>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-column">
                                            <input
                                                type="number"
                                                className="form-control h-35px p-0 px-4 fs-6 w-70px"
                                                value={formik.values?.ProjectDataArray?.autoAdjustTasks?.SpankLevel}
                                                onChange={(e) => formik.setFieldValue('ProjectDataArray.autoAdjustTasks.SpankLevel', e.target.value)}
                                                min={1}
                                                max={10}
                                            />

                                            {/* {!isValid && <span className="text-danger">{error}</span>} */}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('ProjectDataArray.autoAdjustTasks.Visible', e.target.checked)}
                                                    checked={formik.values?.ProjectDataArray?.autoAdjustTasks?.Visible}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('ProjectDataArray.autoAdjustTasks.Editable', e.target.checked)}
                                                    checked={formik.values?.ProjectDataArray?.autoAdjustTasks?.Editable}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className=''>
                                        <h4>Service call title:</h4>
                                        <div className='d-flex gap-2'>
                                            <input
                                                type="text"
                                                // className="form-control h-35px p-0 px-4 fs-6 w-70px"
                                                className="form-control h-32px px-4 rounded-4px py-0 bg-white border-graybor fw-normal fs-16 text-dark"
                                                value={formik.values?.ProjectDataArray?.serviceCallTitle?.text}
                                                onChange={(e) => formik.setFieldValue('ProjectDataArray.serviceCallTitle.text', e.target.value)}
                                                placeholder='Type plan text ...'
                                            />

                                            {/* {!isValid && <span className="text-danger">{error}</span>} */}
                                            <input
                                                type="text"
                                                className="form-control h-32px px-4 rounded-4px py-0 bg-white border-graybor fw-normal fs-16 text-dark"
                                                value={formik.values?.ProjectDataArray?.serviceCallTitle?.specialcharacter}
                                                onChange={(e) => {
                                                    const filteredValue = e.target.value?.replace(/[a-zA-Z0-9]/g, '');
                                                    formik.setFieldValue('ProjectDataArray.serviceCallTitle.specialcharacter', filteredValue)
                                                }}
                                                placeholder='Type specialcharacter...'
                                            />

                                            {/* {!isValid && <span className="text-danger">{error}</span>} */}

                                            <div className='position-relative'>
                                                <div className="dropDown position-relative">
                                                    <Select
                                                        name="ProjectDataArray.serviceCallTitle.list"
                                                        closeMenuOnSelect={true}
                                                        options={serviceCallList}
                                                        className="basic-multi-select w-130px"
                                                        classNamePrefix="select"
                                                        isSearchable={false}
                                                        onChange={(selectedOption: any) => formik.setFieldValue('ProjectDataArray.serviceCallTitle.list', selectedOption?.value)}
                                                        value={serviceCallList?.filter((option: any) => formik.values?.ProjectDataArray?.serviceCallTitle?.list?.includes(option?.value))}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td valign='bottom'>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-column">
                                            <input
                                                type="number"
                                                className="form-control h-35px p-0 px-4 fs-6 w-70px"
                                                value={formik.values?.ProjectDataArray?.serviceCallTitle?.SpankLevel}
                                                onChange={(e) => formik.setFieldValue('ProjectDataArray.serviceCallTitle.SpankLevel', e.target.value)}
                                                min={1}
                                                max={10}
                                            />

                                            {/* {!isValid && <span className="text-danger">{error}</span>} */}
                                            </div>
                                        </div>
                                    </td>
                                    <td valign='bottom'>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('ProjectDataArray.serviceCallTitle.Visible', e.target.checked)}
                                                    checked={formik.values?.ProjectDataArray?.serviceCallTitle?.Visible}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td valign='bottom'>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('ProjectDataArray.serviceCallTitle.Editable', e.target.checked)}
                                                    checked={formik.values?.ProjectDataArray?.serviceCallTitle?.Editable}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {/* Project AI Settings 2 End*/}

                    {/* Project AI Settings 3 Start*/}
                    <div className="col-6 mt-6">
                        <div style={{ border: '1px solid #DADCE0' }} className="rounded-4 p-5 setCenter">
                            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                <thead>
                                    <tr className="fw-bold text-muted">
                                        <th></th>
                                        <th className="w-125px fs-3 text-center">Spank level</th>
                                        <th className="w-75px fs-3 text-center">Visible</th>
                                        <th className="w-80px fs-3 text-center">Editable</th>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <h4 className='me-2 min-w-140px'>Plan Service call by:</h4>
                                            <div className='position-relative flex-fill'>
                                                <div className="dropDown position-relative">
                                                    <Select
                                                        name="ProjectDataArray.multiPlanServiceCall.list"
                                                        closeMenuOnSelect={false}
                                                        isMulti
                                                        options={planServiceCallList}
                                                        className="basic-multi-select value306 hideseachTxt"
                                                        classNamePrefix="select"
                                                        isSearchable={false}
                                                        onChange={(selectedOptions: any) => formik.setFieldValue('ProjectDataArray.multiPlanServiceCall.list', selectedOptions?.map((option: any) => option.value))}
                                                        value={planServiceCallList.filter((option: any) => formik.values?.ProjectDataArray?.multiPlanServiceCall?.list.includes(option.value))}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-column">
                                            <input
                                                type="number"
                                                className="form-control h-35px p-0 px-4 fs-6 w-70px"
                                                value={formik.values?.ProjectDataArray?.multiPlanServiceCall?.SpankLevel}
                                                onChange={(e) => formik.setFieldValue('ProjectDataArray.multiPlanServiceCall.SpankLevel', e.target.value)}
                                                min={1}
                                                max={10}
                                            />

                                            {/* {!isValid && <span className="text-danger">{error}</span>} */}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('ProjectDataArray.multiPlanServiceCall.Visible', e.target.checked)}
                                                    checked={formik.values?.ProjectDataArray?.multiPlanServiceCall?.Visible}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('ProjectDataArray.multiPlanServiceCall.Editable', e.target.checked)}
                                                    checked={formik.values?.ProjectDataArray?.multiPlanServiceCall?.Editable}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className=''>
                                        <div className="d-flex align-items-center">
                                            <h4 className='me-2 min-w-140px'>Planning priority:</h4>
                                            <div className='position-relative flex-fill'>
                                                <div className="dropDown position-relative">
                                                    {/* <Select
                                                        name="ProjectDataArray.plannigPriorityForProject.list"
                                                        closeMenuOnSelect={false}
                                                        isMulti
                                                        options={planningPriorityList}
                                                        className="basic-multi-select value306 hideseachTxt"
                                                        classNamePrefix="select"
                                                        isSearchable={false}
                                                        onChange={(selectedOptions: any) => formik.setFieldValue('ProjectDataArray.plannigPriorityForProject.list', selectedOptions?.map((option: any) => option.value))}
                                                        value={planningPriorityList.filter((option: any) => formik.values?.ProjectDataArray?.plannigPriorityForProject?.list.includes(option.value))}
                                                    /> */}
                                                    <select 
                                                        ref={selectRef} 
                                                        multiple
                                                        style={{ width: '100%' }}
                                                        name="ProjectDataArray.plannigPriorityForProject.list"
                                                        {...formik.getFieldProps('ProjectDataArray.plannigPriorityForProject.list')}
                                                    >
                                                        {planningPriorityList.map((item: any, index: number) => (
                                                            <option key={index} value={item?.value}>{item?.label}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-column">
                                            <input
                                                type="number"
                                                className="form-control h-35px p-0 px-4 fs-6 w-70px"
                                                value={formik.values?.ProjectDataArray?.plannigPriorityForProject?.SpankLevel}
                                                onChange={(e) => formik.setFieldValue('ProjectDataArray.plannigPriorityForProject.SpankLevel', e.target.value)}
                                                min={1}
                                                max={10}
                                            />

                                            {/* {!isValid && <span className="text-danger">{error}</span>} */}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('ProjectDataArray.plannigPriorityForProject.Visible', e.target.checked)}
                                                    checked={formik.values?.ProjectDataArray?.plannigPriorityForProject?.Visible}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex fv-row fv-row col-md-8">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <div className="form-check form-check-dark form-switch fv-row">
                                                <input
                                                    className="form-check-input w-45px h-30px"
                                                    type="checkbox"
                                                    onChange={(e) => formik.setFieldValue('ProjectDataArray.plannigPriorityForProject.Editable', e.target.checked)}
                                                    checked={formik.values?.ProjectDataArray?.plannigPriorityForProject?.Editable}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {/* Project AI Settings 3 End*/}

                </div>
            </div>

        </div>    
        
    </>
  )
}

export default ProjectAISettings