import moment from "moment";
import { createPopupvalues } from "../../_metronic/requests/PlanningRequest";
import { 
  confirm
} from "@mobiscroll/react";

export const checkBufferHours = async (event: any,addstarttime:any,addendtime:any,timezone:any) => {
  if(event?.isOvertimeShow && event?.isOvertimeShow === true){
    return [true, false];
  }
  let start = moment.tz(moment(event.start).tz(timezone).format("YYYY-MM-DDT") + addstarttime,timezone).format("YYYY-MM-DDTHH:mm:ss");
  let seconddaystart = moment
    .tz(
      moment(event.end).tz(timezone).format("YYYY-MM-DD") +
      "T" +
      addstarttime,
      timezone
    )
    .format("YYYY-MM-DDTHH:mm:ss");
  let end = moment
    .tz(
      moment(event.start).tz(timezone).format("YYYY-MM-DDT") + addendtime,
      timezone
    )
    .format("YYYY-MM-DDTHH:mm:ss");
  let seconddayend = moment.tz(moment(event.end).tz(timezone).format("YYYY-MM-DDT") + addendtime, timezone
    ).format("YYYY-MM-DDTHH:mm:ss");
  var e_start = moment(event.start).tz(timezone).format("YYYY-MM-DDTHH:mm:ss");
  var e_end = moment(event.end).tz(timezone).format("YYYY-MM-DDTHH:mm:ss");
  if (
    (moment(event.start).tz(timezone).format("YYYY-MM-DD") ==
    moment(event.end).tz(timezone).format("YYYY-MM-DD") &&
      (moment(e_start).isBefore(start) || moment(e_end).isAfter(end) ||  moment(e_end).isBefore(start))) ||
    (moment(event.start).tz(timezone).format("YYYY-MM-DD") !=
      moment(event.end).tz(timezone).format("YYYY-MM-DD") &&
      (moment(e_end).isSameOrBefore(seconddaystart) ||
        moment(e_start).isAfter(end) ||
        moment(e_end).isAfter(seconddayend)))
  ) {
    var confirmStatus = true;
    if ((moment(event.start).tz(timezone).format('YYYY-MM-DD')) != (moment(event.end).tz(timezone).format('YYYY-MM-DD'))) {
      confirmStatus = false;
    } else {
      confirmStatus = await confirm({
        title: "You plan outside working hours, do you want to proceed?",
        okText: "Yes",
        cancelText: "No",
      });
    }
    if (!confirmStatus) {
      return [false, false];
    } else {
      return [true, true];
    }
  } else {
    return [true, false];
  }
};

export const splitServicecallEvents = async (event: any,addstarttime:any,addendtime:any,timezone:any) => {
  let splitevents: any = [];
  let returnArray:any=[]
  if (
    moment(event.start).tz(timezone).format("YYYY-MM-DD") !=
    moment(event.end).tz(timezone).format("YYYY-MM-DD")
  ) {
    let start = moment(event.start).utc().format("YYYY-MM-DDTHH:mm:ss");
    let end = moment
      .tz(
        moment(event.start).tz(timezone).format("YYYY-MM-DDT") + addendtime,
        timezone
      )
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss");
    var day_two_start = moment
      .tz(
        moment(event.end).tz(timezone).format("YYYY-MM-DDT") + addstarttime,
        timezone
      )
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss");
    var day_two_end = moment(event.end).utc().format("YYYY-MM-DDTHH:mm:ss");
    if (day_two_start === day_two_end || start === end) {
      return true;
    }
    var startday: any = moment(moment(event.start).tz(timezone).format("YYYY-MM-DD"));
    var endday: any = moment(moment(event.end).tz(timezone).format("YYYY-MM-DD"));
    var day_diff = endday.diff(startday, "days");
    if (day_diff > 1) {
      for (let i = 1; i < day_diff; i++) {
        let split_date = moment(startday, "DD-MM-YYYY")
          .add(i, "days")
          .format("YYYY-MM-DD");
        let split_event = JSON.parse(JSON.stringify(event));
        split_event.start =
          moment
            .tz(
              moment(split_date).format("YYYY-MM-DDT") + addstarttime,
              timezone
            )
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss") + "Z";
        split_event.end =
          moment
            .tz(
              moment(split_date).format("YYYY-MM-DDT") + addendtime,
              timezone
            )
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss") + "Z";
        split_event.new_event =
          "split_" +
          i +
          event.id +
          (Math.random() + 1).toString(6).substring(6);
        split_event.id = (Math.random() + 1).toString(6).substring(6);
        split_event.split_generate_id = (Math.random() + 1).toString(6).substring(6);
        split_event.serviceCallId = "";
        split_event.url = "";
        let start_with_timezone = moment(split_event.start).tz(timezone).format("YYYY-MM-DDTHH:mm:ss");
        split_event.weeknumber = moment.utc(start_with_timezone).week()
        splitevents.push(split_event);
      }
    }
    const oldevent: any = [];
    let first_event = JSON.parse(JSON.stringify(event));
    first_event.start = start + "Z";
    first_event.end = end + "Z";
    let start_with_timezone1 = moment(first_event.start).tz(timezone).format("YYYY-MM-DDTHH:mm:ss");
    first_event.weeknumber = moment.utc(start_with_timezone1).week()
    oldevent.push(first_event);
    //loop
    let second_event = JSON.parse(JSON.stringify(event));
    second_event.start = day_two_start + "Z";
    second_event.end = day_two_end + "Z";
    second_event.new_event =
      "last_event+" +
      event.id +
      (Math.random() + 1).toString(6).substring(6);
    second_event.serviceCallId = "";
    second_event.id = (Math.random() + 1).toString(6).substring(6);
    second_event.split_generate_id = (Math.random() + 1).toString(6).substring(6);
    second_event.url = "";
    let start_with_timezone2 = moment(second_event.start).tz(timezone).format("YYYY-MM-DDTHH:mm:ss");
    second_event.weeknumber = moment.utc(start_with_timezone2).week()

    ///push all event array
    Array.from(splitevents).map(async (e_split: any) => {
      await oldevent.push(e_split);
    });

    oldevent.push(second_event);
    returnArray.event = first_event;
    returnArray.splitData = oldevent;
  }else{
    returnArray.event = event;
    returnArray.splitData = [];
  }
  return returnArray
};

export const splitedEventCall = async (split_event: any, resourceList:any, screenName = "") => {
  let splitArray:any = []
  if (split_event && split_event.length > 0) {

    for (let i = 1; i < split_event?.length; i++) {
      let e_split = split_event[i];
      if (i != 0) {
        let payloadsplitevent = JSON.parse(JSON.stringify(e_split))
        payloadsplitevent.isProjectScreen = false
        payloadsplitevent.id = ""
        let duration = moment.duration(
          moment(payloadsplitevent.end).diff(moment(payloadsplitevent.start))
        );
        payloadsplitevent.planned = duration.asHours();
        payloadsplitevent.duration = duration.asHours();
        if(payloadsplitevent.duration > 0){
          let res_for_spit1 = await createPopupvalues(payloadsplitevent);
          e_split.id = res_for_spit1.data?.data?.id
          e_split.split_generate_id = ""
          if(screenName == 'planning'){
            e_split.resource = e_split?.servicecall_resources 
          }else{
            e_split.resource = e_split?.related_tasks?.map((element:any) => element.id);
          }
          e_split.from_copy = false;
          e_split.total_ticket = e_split?.related_ticket?.length
          e_split.total_task = e_split?.related_tasks?.length
          
          e_split = await relatedResources(e_split, resourceList)
          splitArray.push(e_split);
        }
      }
    }
  }
  return splitArray;
}

export const relatedResources = (event1:any, resourceList:any) => {
  let event = JSON.parse(JSON.stringify(event1))
    let related_resources = [];
    // Iterate over each resourceID in servicecallResourceIds
    event?.servicecall_resources.forEach((resourceID) => {
        const resourceDetails = resourceList?.find(
          (res) => res.id === resourceID
        );
        if (resourceDetails) {
          related_resources.push({
            firstName: resourceDetails.firstName,
            lastName: resourceDetails.lastName,
            resourceID:resourceID
          });
        }
    });
    let uniqueResources = related_resources.reduce((map, obj) => {
        map[obj.resourceID] = obj;
        return map;
    }, {});
    event.related_resources = Object.values(uniqueResources);

    if(event.related_tasks?.length === 1 && event.related_tickets?.length === 0){
      let related_task_resources = [];
      const taskResourceIds = [
          ...new Set([
          ...(event.related_tasks[0].secondary_resources ?? []),
          ]),
      ].map((x) => x.resourceID);
      if(event.related_tasks[0]?.assignedResourceID){
          taskResourceIds.push(event.related_tasks[0]?.assignedResourceID)
      }
      // Iterate over each resourceID in taskResourceIds
      taskResourceIds.forEach((resourceID) => {
          const resourceDetails = resourceList.find(
          (res) => res.id === resourceID
          );
          if (resourceDetails) {
          related_task_resources.push({
              firstName: resourceDetails.firstName,
              lastName: resourceDetails.lastName,
              resourceID:resourceID
          });
          }
      });
      const uniqueMap = related_task_resources.reduce((map, obj) => {
          map[obj.resourceID] = obj;
          return map;
      }, {});
      related_task_resources = Object.values(uniqueMap);
      event.related_tasks[0].related_resources = related_task_resources;
  }

  let related_ticket_resources = [];
  if(event.related_tickets?.length === 1 && event.related_tasks?.length === 0){
      const ticketResourceIds = [
          ...new Set([
          ...(event.related_tickets[0].secondary_resources ?? []),
          ]),
      ].map((x) => x.resourceID);
      if(event.related_tickets[0]?.assignedResourceID){
          ticketResourceIds.push(event.related_tickets[0]?.assignedResourceID)
      }
      // Iterate over each resourceID in ticketResourceIds
      ticketResourceIds.forEach((resourceID) => {
          const resourceDetails = resourceList.find(
          (res) => res.id === resourceID
          );
          if (resourceDetails) {
          related_ticket_resources.push({
              firstName: resourceDetails.firstName,
              lastName: resourceDetails.lastName,
              resourceID:resourceID
          });
          }
      });
      const uniqueMap = related_ticket_resources.reduce((map, obj) => {
          map[obj.resourceID] = obj;
          return map;
      }, {});
      related_ticket_resources = Object.values(uniqueMap);
      event.related_tickets[0].related_resources = related_ticket_resources;
  }
  return event;
}


export const resourcehasNoLicense = async (resource: any, resourceArray:any) => {
    let findresource = resourceArray?.filter((r:any)=> r.id == resource)
    return findresource?.[0]?.has_license == true ? true : false;
}

export const resourcehasNoLicenseMoreMultiple = async (resource: any, resourceArray:any) => {
  if (
    resourceArray.filter((e: any) => resource.includes(parseInt(e.id)))?.filter((trole: any) => (trole.has_license == false && trole.resource_only != true))?.length != 0
  ) {
    return false
  }else{
    return true
  }
}

export const handleEntered = (tooltip,rectValue,mousePos) => {    

  const rect = tooltip.getBoundingClientRect();
  const hoverdivs = document.getElementsByClassName('mbsc-schedule-event-hover');    
  const windowHeight = window.innerHeight;
  const windowWidth = window.innerWidth;
  const arrowPos = (mousePos.x * 100) / windowWidth;
  const xValue = mousePos.x + (rect.width / 2);        

  for (let i = 0; i < 1; i++) {
      const hoverElement = hoverdivs[i] as HTMLElement;
      const hoverRect = hoverElement.getBoundingClientRect();        
      if (tooltip.classList.contains('bs-tooltip-top')) {            
          if (xValue >= windowWidth) {
              tooltip.style.transform = `translate(${windowWidth - rect.width}px,-${windowHeight - rectValue.x}px)`;
          } else {
              tooltip.style.transform = `translate(${mousePos.x - (rect.width / 2)}px,-${windowHeight - rectValue.x}px)`;
          }
      } else {            
          if (xValue >= windowWidth) {
              tooltip.style.transform = `translate(${windowWidth - rect.width}px,${rectValue.y}px)`;
          } else {
              tooltip.style.transform = `translate(${mousePos.x - (rect.width / 2)}px,${rectValue.y}px)`;
          }          
      }            
      tooltip.classList.add('opacity-100');  
  }

  const rectNew = tooltip.getBoundingClientRect();
  const newArrowvalue = mousePos.x - rectNew.left;

  // Find the tooltip-arrow associated with pronewTooltip
  const arrowDivs = tooltip.querySelectorAll('.tooltip-arrow');

  arrowDivs.forEach(arrowElement => {
      arrowElement.style.left = `${newArrowvalue}px`;      
      arrowElement.style.transform = 'translate(-100%,0)';
  }); 

};

export const handleMouseEnter = (e: React.MouseEvent, useProgressElement: boolean,setRect:any) => {
  const targetElement = e.currentTarget as HTMLElement;
  const rect = targetElement.getBoundingClientRect();
  setRect({ x: rect.top, y: rect.bottom, z: rect.width }); // Update the state with the new rect value    
}; 