import {useCallback, FC, useMemo, useState, useEffect } from "react";
import { KTSVG } from "../../../_metronic/helpers";
import React from "react";
import { Button, Datepicker, formatDate, Popup } from "@mobiscroll/react";
import { responsivePopup } from "../../helpers/popup";
import CommonFilter from "../../modules/apps/filters/MainFilter/CommonFilter";
import { getTaskTicketList } from "../../../_metronic/requests/PlanningRequest";
import { useQuery } from "react-query";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
import debounce from "../../helpers/debounce";

type Props = {
    taskList:any,
    setTaskList:any,
    eventDetail:any,
    seteventDetail:any,
    filterArray:any,
    view:any,
    openDropdown:any,
    setOpenDropdown:any,
    toggleDropdown:any,
    general_settings:any,
    taskListType:any,
    taskTicketId:any,
    setTaskTicketid:any,
    filterIds:any
    assignedresources:any
};

const TaskTicketList: FC<Props> = ({
    taskList,
    setTaskList,
    eventDetail,
    seteventDetail,
    filterArray,
    view,
    openDropdown,
    setOpenDropdown,
    toggleDropdown,
    general_settings,
    taskListType,
    taskTicketId,
    setTaskTicketid,
    filterIds,
    assignedresources
}) => {
    let [dateRange, setdateRange] = useState<any>([])
    const [buttonText, setButtonText] = useState<string>();
    let [payload, setPayload] = useState<any>({
        resources: [],
        projects: [],
        departments: [],
        companies: [eventDetail?.company_id],
        workgroups: [],
        start_date: filterIds?.start_date,
        end_date: filterIds?.end_date,
        roles: [],
        project_leads: [],
        queues: [],
        sla: [],
        contract_category: [],
        skills: [],
        lineOfBusiness: [],
        page: 1,
        items_per_page: 5,
        statuses: [],
        servicecall_statuses: [],
        types: [taskListType?.toLowerCase()],
        priority: [],
        task_categories: [],
        ticket_categories: [],
        ticket_types: [],
        search:""
      });

    const {
    isFetching: istoplanfetch,
    refetch:refetchtasklist,
    data: taskticketlist,
    } = useQuery(
    "task-ticket-list",
    () => {
        return getTaskTicketList(payload);
    },
    {
        cacheTime: 0,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
    }
    );

    const setRelatedtaskTicket = () => {
        let latestpayload = JSON.parse(JSON.stringify(eventDetail));
        if(taskListType === "Task"){
            let tasks = taskticketlist?.filter((t:any) => taskTicketId.includes((t._id)))
            const ids = latestpayload?.related_tasks?.map((element:any) => element.id);
            tasks = tasks.filter((t:any) => !ids.includes(t.id));
            latestpayload.related_tasks = [...latestpayload.related_tasks,...tasks]
        }else{
            let tickets = taskticketlist?.filter((t:any) => taskTicketId.includes((t._id)))
            const tids = latestpayload?.related_tickets?.map((element:any) => element.id);
            tickets = tickets.filter((t:any) => !tids.includes(t.id));
            latestpayload.related_tickets = [...latestpayload.related_tickets,...tickets]
        }
        assignedresources(latestpayload)
        setTaskList(false);
    };

    const popupButtons = useMemo<any>(() => {
        const buttons = [
            {
                handler: function () { 
                    setTaskList(false);
                },
                cssClass: `mbsc-popup-button-primary`,
                keyCode: "enter",
                text:"Cancel",
            },
            {
                handler: function () { 
                    setRelatedtaskTicket()
                },
                cssClass: `mbsc-popup-button-primary ${(taskTicketId?.length <= 0) && "isofflinebutton"}`,
                keyCode: "enter",
                disabled: (taskTicketId?.length <= 0),
                text:"Add "+(taskListType ==="Task" ? "Tasks" : "Tickets"),
            }
        ];
        return buttons;
      }, [taskTicketId,taskticketlist]);
  
    const taskListClose = useCallback(() => {
            setTaskList(false);
        },
        []
    );

    const handleChange = (e: any) => {
        if(e.target.value === "all"){
            if (e.target.checked) {
                setTaskTicketid(taskticketlist?.map(element => element._id));
            } else {
                setTaskTicketid([]);
            }
        }else{
            if (e.target.checked) {
                setTaskTicketid((taskTicketId: any) => [
                ...taskTicketId,
                e.target.value,
            ]);
            } else {
                setTaskTicketid(
                    taskTicketId.filter((item: any) => item !== e.target.value)
                );
            }
        }
    }

    let handleSearch = debounce((e) => {
        let latestPayload = Object.assign({}, payload, {
            search: e.target.value
          });
          setPayload(latestPayload);
    }, 1000);

    const onChange = useCallback((args: any, inst: any) => {
        const date = args.value;
        setdateRange(date);
        let latestpayload = JSON.parse(JSON.stringify(payload));
        if (date[0] && date[1]) {
            latestpayload.start_date = moment(date[0]).format('YYYY-MM-DD');
            latestpayload.end_date = moment(date[1]).format('YYYY-MM-DD');
            setPayload(latestpayload)
        }
    },[])

    useEffect(()=>{
        refetchtasklist()
    },[payload])

    const buttonProps = useMemo(() => {
        const content = (
          <span className="mbsc-calendar-title d-inline-flex align-items-center">
            {buttonText}
            <svg
              width="12"
              height="8"
              viewBox="0 0 12 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="ms-2"
            >
              <path
                d="M10.9498 1.75L6.87481 5.825C6.39355 6.30625 5.60605 6.30625 5.12481 5.825L1.0498 1.75"
                stroke="currentcolor"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        );
    
        return {
          children: content,
          className: "mbsc-calendar-button",
          variant: "flat",
        };
      }, [buttonText]);

    useEffect(() => {
        let duration:any = moment.duration(moment(payload.end_date).diff(moment(payload.start_date)));
        duration = duration.asDays();
        let btnText = (
            formatDate("MMM D, YYYY", new Date(payload.start_date)) +
            (payload.end_date && duration > 0
              ? " - " + formatDate("MMM D, YYYY", new Date(payload.end_date))
              : "")
          )
        setButtonText(btnText)
        setdateRange([payload.start_date,payload.end_date])
      },[payload.start_date,payload.end_date])

  return (
    <Popup
      display="bottom"
      fullScreen={true}
      contentPadding={false}
      buttons={popupButtons}
      isOpen={taskList}
      scrollLock={false}
      onClose={taskListClose}
      responsive={responsivePopup}
      cssClass="md-tooltip md-tooltip-detail plannPopup commonMidpopup assignPopup tasklist"
    >
      
        <div className="accordion addTaskform" id="kt_accordion_1">
        {/* Service call accordian start */}
        <div className={`fs-16 fontIner text-dark`}>
            <div className="min-h-65px"></div>
            <div
            className="md-tooltip-header d-flex justify-content-between px-7 position-absolute top-0 start-0 end-0 py-3 align-items-center bg-white min-h-65px"
            id="kt_accordion_1_header_1"
            style={{ zIndex: "1" }}
            >
            <div
                className="position-absolute start-0 top-0 w-100 h-100"
                style={{
                zIndex: "-1",
                backgroundColor: taskListType === "Ticket" ? "#DFDEFF" :"#FFE2E2",
                }}
            ></div>
            <div className="d-flex align-items-center text-ellipsis">
                <a
                href={"javascript:void(0)"}
                className="d-inline-flex align-items-center fw-bold text-primary fs-18 lh-1 align-left mw-100 pe-4"
                rel="noreferrer"
                style={{ cursor: "default" }}
                >
                <span className="text-ellipsis lh-sm">
                    Add {taskListType ==="Task" ? "Tasks" : "Tickets"}
                </span>
                </a>
            </div>
            </div>

            <div className="md-tooltip-info paddLR24 min-h-400px">   
            {/* First row start */}
            <div className="d-flex justify-content-between align-items-center mb-5">
                <p className="m-0 fs-20px text-dark fw-bold">Select {taskListType ==="Task" ? "tasks" : "tickets"} which you want to add in servicecall?</p>                  
                <div className="taskdPicker">
                    <Datepicker
                        select="range"
                        display="anchored"
                        showOverlay={false}
                        touchUi={true}
                        buttons={[]}
                        inputComponent={Button}
                        inputProps={buttonProps}
                        onChange={onChange}
                        value={dateRange}
                    />          
                </div>                  
            </div>
            {/* First row end */}
            {/* Search with filter div start */}
            <div className="d-flex align-items-center mb-10">

                {/* begin::Search */}
                <div className='d-flex align-items-center position-relative me-5'>
                    <KTSVG
                    path='/media/icons/duotune/new-icons/search.svg'
                    className='position-absolute top-50 translate-middle-y ms-4'
                    svgClassName='w-16px h-16px'
                    />
                    <input
                    type='text'  
                    onChange={(e:any) => handleSearch(e)}     
                    className='form-control w-285px bg-gray-100 ps-12 h-32px p-0 border-0 ps-10 fs-16 text-dark-new placeholderGray'
                    placeholder='Search'
                    />
                </div>
                {/* end::Search */}
                <div className="filterLeft d-flex align-items-center flex-wrap flex-1 row-gap-5">
                    <CommonFilter 
                        filterArray={filterArray} 
                        view={view} 
                        openDropdown={openDropdown} 
                        setOpenDropdown = {setOpenDropdown}
                        toggleDropdown={toggleDropdown} 
                        setPayload={setPayload}
                        payload={payload}
                        general_settings={general_settings}
                        taskListType={taskListType}
                    />
                </div>
            </div>
            {/* Search with filter div end */}

            {/* Table start */}
            <div className="overflow-auto mh-460px">
                <table cellPadding={0} cellSpacing={0} width={'100%'} className="table border-bottom border-gray-new-three mb-0">
                <thead className="bg-gray-100 fw-bold position-sticky top-0" style={{ zIndex: '4' }}>
                    <tr>
                    <th className="py-0 pe-0 ps-5 py-4px align-middle w-40px">
                        <div className="form-check form-check-custom">
                        <input type="checkbox" className="form-check-input w-16px h-16px rounded-4px" value={'all'} id="ALL" onChange={(e:any) => handleChange(e)} checked={taskTicketId?.length != 0 && taskTicketId?.length === taskticketlist?.length } />
                        </div>
                    </th> 
                    <th className="py-0 pe-0 ps-5 py-4px"> {taskListType ==="Task" ? "Tasks" : "Tickets"}</th>
                    <th className="py-0 pe-0 ps-5 py-4px">Project</th>
                    <th className="py-0 pe-0 ps-5 py-4px">Assign to</th>
                    <th className="py-0 ps-5 py-4px">Status</th>
                    </tr>
                </thead>
                <tbody>
                    {istoplanfetch && (
                        <tr>
                            <td colSpan={5} className="text-center">Loading...</td>
                        </tr>
                    )}
                    {!istoplanfetch && taskticketlist &&  Array.from(taskticketlist)?.map(
                        (task: any) => {
                            return (
                            <tr key ={task?._id}>
                            <td className="ps-5 pe-0 align-middle py-9px border-bottom border-gray-new-three">
                                <div className="form-check form-check-custom">
                                <input type="checkbox" className="form-check-input w-16px h-16px rounded-4px" onChange={(e:any) => handleChange(e)} id={task?._id} value={task?._id} checked={taskTicketId.includes((task?._id))} />
                                </div>                          
                            </td>
                            <td className="ps-5 pe-0 py-9px border-bottom border-gray-new-three align-middle">{task?.title}</td>
                            <td className="ps-5 pe-0 py-9px border-bottom border-gray-new-three align-middle">{task?.project_name || task?.project}</td>
                            <td className="ps-5 pe-0 py-9px border-bottom border-gray-new-three align-middle">
                            {task?.related_resources?.length > 0 &&
                                <div className="ms-auto h-24px">
                                    <OverlayTrigger
                                    key="copy-to-clipboard"
                                    placement="auto"
                                    //trigger="click"
                                    //defaultShow= {true}   //To set tooltip by default open
                                    overlay={
                                        <Tooltip
                                        id="tooltip-copy-to-clipboard"
                                        className="position-absolute assiresTooltip"
                                        >
                                        <div
                                            className="md-tooltip-info align-left"
                                            style={{ textAlign: "left", padding: "4px" }}
                                        >
                                            <div className={`fw-bold md-rect-bg-task pb-4`}>
                                            Assigned Resources
                                            </div>
                                            {task?.related_resources?.length > 0 &&
                                            Array.from(task?.related_resources)?.map(
                                                (resource1: any, key: any) => {
                                                return (
                                                    <>
                                                    <div className="d-flex align-items-center md-tooltip-title m-0 mb-3">
                                                        <strong className="fw-bold d-inline-flex align-items-center pe-3">
                                                        <span
                                                            className="rounded-circle bg-graydarkBg w-24px h-24px d-inline-flex align-items-center justify-content-center text-dark fs-14 fw-bold lh-sm"
                                                            style={{
                                                            textTransform: "uppercase",
                                                            }}
                                                        >
                                                            {resource1?.firstName
                                                            ?.trim()
                                                            ?.substring(0, 1)}
                                                            {resource1?.lastName
                                                            ?.trim()
                                                            ?.substring(0, 1)}
                                                        </span>
                                                        </strong>{" "}
                                                        <span className="md-tooltip-reason md-tooltip-text">
                                                        {resource1?.firstName}{" "}
                                                        {resource1?.lastName}
                                                        </span>
                                                    </div>
                                                    </>
                                                );
                                                }
                                            )}
                                        </div>
                                        </Tooltip>
                                    }
                                    >
                                    <div className="rounded-pill d-inline-flex align-items-center hasImgsspan lh-1 bg-resourcebg p-2px align-middle">
                                        {task?.related_resources?.length > 0 &&
                                        Array.from(task?.related_resources)?.map(
                                            (resource: any, key: any) => {
                                            return (
                                                <>
                                                {key < 2 && resource?.firstName && (
                                                    <span
                                                    style={{                                      
                                                    marginLeft: `${key > 0 ? "-4px" : "0"}`,
                                                    textTransform: "uppercase",
                                                    }}
                                                    className="rounded-circle w-24px h-24px d-inline-flex align-items-center justify-content-center text-dark fs-14 fw-bold lh-sm border border-resourcebg" >
                                                    {resource?.firstName
                                                        ?.trim()
                                                        ?.substring(0, 1)}
                                                    {resource?.lastName
                                                        ?.trim()
                                                        ?.substring(0, 1)}
                                                    </span>
                                                    // </span>
                                                )}
                                                </>
                                            );
                                            }
                                        )}
                                        {task?.related_resources?.length > 2 && (
                                        <span
                                            className="rounded-circle w-24px h-24px d-inline-flex align-items-center justify-content-center text-dark fs-16 fw-fw-normal lh-sm bg-resourcebg"
                                            style={{                              
                                            marginLeft: "-4px",
                                            }}
                                        >
                                            +{task?.related_resources?.length - 2}
                                        </span>
                                        )}
                                    </div>
                                    </OverlayTrigger>
                                </div>
                            }
                            </td>
                            <td className="ps-5 pe-0 py-9px border-bottom border-gray-new-three align-middle">{task?.status_name}</td>
                            </tr>
                        )
                    })
                    }
                </tbody>
                </table>
            </div>
            {/* Table end */}

            </div>                
        </div>
        </div>
    </Popup>
  );
};

export default TaskTicketList;