import React, { ChangeEventHandler, FC, useEffect, useState } from 'react';

interface IEventPerRowComponent  {
    view: any;
    currentPath: any;
    formik: any;
}

const EventPerRowComponent: FC<IEventPerRowComponent> = ({formik , view , currentPath  }) => {
    const newPath = currentPath ?  formik.values?.PlannigViewData?.[view]: formik.values?.ProjectViewData?.[view]
    const viewPage = currentPath ? 'PlannigViewData' : 'ProjectViewData' ; 
    const [selectedOption, setSelectedOption] = useState(newPath?.showAllEvents == true ?'isshowAllEvents':'showAmountOfEvents');
    useEffect(() => {
        setSelectedOption(newPath?.showAllEvents ? 'isshowAllEvents' : 'showAmountOfEvents');
    }, [newPath?.showAllEvents]);
    
    const handleOptionChange = (option) => {
        setSelectedOption(option);
        formik.setFieldValue(`${viewPage}.${view}.showAllEvents`, option == 'isshowAllEvents');
    };  


    return (
        <div className="d-flex flex-column mb-10">
            <h3 className="text-dark fw-bold fs-18 m-0 mb-4">Events per row</h3>
            <div className="bg-gray-100 rounded-8px p-5">
                <div className="form-check form-check-outline-primary form-check-custom form-check-solid h-30px fs-16">
                    <input
                        className="form-check-input me-2"
                        name="showEvents"
                        type="radio"
                        id="isshowAllEvents"
                        checked={true} //selectedOption =='isshowAllEvents'
                        onChange={() => handleOptionChange('isshowAllEvents')}
                    />
                    <label htmlFor="isshowAllEvents">Show all events</label>
                </div>
                <div className="form-check form-check-outline-primary form-check-custom form-check-solid h-30px fs-16 d-none" title='Functionality is in progress..'>
                    <input
                        className="form-check-input me-2"
                        name="showEvents"
                        type="radio"
                        disabled={true}
                        id="amountOfEvent"
                        checked={false}
                        // checked={selectedOption == 'showAmountOfEvents'}
                        onChange={() => handleOptionChange('showAmountOfEvents')}
                    />
                    <label htmlFor="showAmountOfEvents">Show amount of events</label>

                    {!newPath?.showAllEvents && (
                        <select
                            className="form-select fs-16 fw-normal h-32px p-0 px-4 ps-3 bg-white border-graybor w-80px text-gray-900 ms-auto d-none"
                            value={newPath?.numberOfEvents}
                            // onChange={handleNumberOfEventsChange}
                            onChange={(e) => {
                                formik.setFieldValue(`${viewPage}.${view}.numberOfEvents`, e.target.value);
                              }}
                        >
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                        </select>
                    )}
                </div>
            </div>
        </div>
    )
}

export default EventPerRowComponent;
