import { useIntl } from "react-intl";
import { AsideMenuItem } from "./AsideMenuItem";
import { AsideMenuItemWithSub } from "./AsideMenuItemWithSub";
import { useAuth } from "../../../../app/modules/auth/core/AuthCognito";

export function AsideMenuMain() {
  const intl = useIntl();
  const { currentUser } = useAuth();

  return (
    <>
      {currentUser?.data?.user?.permissions &&
        currentUser?.data?.user?.permissions?.some(
          (item: any) => item.name === "Dashboard" && item.value === true
        ) && (
          <AsideMenuItem
            to="/apps/dashboard-management/dashboard"
            icon="/media/icons/duotune/extra/chart.svg"
            title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
            fontIcon="bi-app-indicator"
          />
        )}
      {currentUser?.data?.user?.permissions &&
        currentUser?.data?.user?.permissions?.some(
          (item: any) => item.name === "Planning" && item.value === true
        ) && (
          <AsideMenuItem
            to="/apps/planning/resources"
            icon="/media/icons/duotune/extra/task-square.svg"
            title="Planning"
            fontIcon="bi-layers"
          />
        )}
      {currentUser?.data?.user?.permissions &&
        currentUser?.data?.user?.permissions?.some(
          (item: any) => item.name === "Users" && item.value === true
        ) && (
          <AsideMenuItem
            to="/apps/user-management"
            icon="/media/icons/duotune/art/users.svg"
            title="Users"
            fontIcon="bi-layers"
          />
        )}
      {currentUser?.data?.user?.permissions &&
        currentUser?.data?.user?.permissions?.some(
          (item: any) => item.name === "Projects" && item.value === true
        ) && (
          <AsideMenuItem
            to="/apps/projects/list"
            icon="/media/icons/duotune/new-icons/project-trans.svg"
            title="Projects"
            fontIcon="bi-layers"
          />
        )}
      {currentUser?.data?.user?.permissions &&
        currentUser?.data?.user?.permissions?.some(
          (item: any) => item.name === "Notification" && item.value === true
        ) && (
          <AsideMenuItem
            to="/apps/notifications"
            icon="/media/icons/duotune/extra/notification.svg"
            title="Notifications"
            fontIcon="bi-layers"
          />
        )}
      {/* <AsideMenuItem
        to="/apps/task/resources"
        icon="/media/icons/duotune/extra/archive.svg"
        title="Tasks"
        fontIcon="bi-layers"
      /> */}
      {currentUser?.data?.user?.permissions &&
        currentUser?.data?.user?.permissions?.some(
          (item: any) =>
            (item.name === "Logs" &&
              item.value === true &&
              item.subkey &&
              item?.subkey?.some(
                (subItem: any) =>
                  subItem?.name === "Login" && subItem?.value === true
              )) ||
            item?.subkey?.some(
              (subItem: any) =>
                subItem?.name === "APIs" && subItem?.value === true
            )
        ) && (
          <AsideMenuItemWithSub
            to="/apps/logs/"
            title="Logs"
            fontIcon="bi-layers"
            icon="/media/icons/duotune/extra/archive-book-new.svg"
          >
            {currentUser?.data?.user?.permissions &&
              currentUser?.data?.user?.permissions?.some(
                (item: any) =>
                  item.name === "Logs" &&
                  item.value === true &&
                  item.subkey &&
                  item.subkey.some(
                    (subItem: any) =>
                      subItem.name === "Login" && subItem.value === true
                  )
              ) && (
                <AsideMenuItem
                  to="/apps/logs/login"
                  title="Login"
                  hasBullet={true}
                />
              )}
            {currentUser?.data?.user?.permissions &&
              currentUser?.data?.user?.permissions?.some(
                (item: any) =>
                  item.name === "Logs" &&
                  item.value === true &&
                  item.subkey &&
                  item.subkey.some(
                    (subItem: any) =>
                      subItem.name === "Apis" && subItem.value === true
                  )
              ) && (
                <AsideMenuItem
                  to="/apps/logs/apis"
                  title="APIs"
                  hasBullet={true}
                />
              )}
          </AsideMenuItemWithSub>
        )}
      {currentUser?.data?.user?.permissions &&
        currentUser?.data?.user?.permissions?.some(
          (item: any) =>
            (item.name === "Setting" &&
              item.value === true &&
              item.subkey &&
              item?.subkey?.some(
                (subItem: any) =>
                  subItem?.name === "General" && subItem?.value === true
              )) ||
            item?.subkey?.some(
              (subItem: any) =>
                subItem?.name === "Resources" && subItem?.value === true
            ) ||
            item?.subkey?.some(
              (subItem: any) =>
                subItem?.name === "Emails" && subItem?.value === true
            ) ||
            item?.subkey?.some(
              (subItem: any) =>
                subItem?.name === "SSO" && subItem?.value === true
            )
        ) && (
          <AsideMenuItemWithSub
            to="/apps/settings/"
            title="Settings"
            fontIcon="bi-layers"
            icon="/media/icons/duotune/art/setting.svg"
          >
            {currentUser?.data?.user?.permissions &&
              currentUser?.data?.user?.permissions?.some(
                (item: any) =>
                  item.name === "Setting" &&
                  item.value === true &&
                  item.subkey &&
                  item.subkey.some(
                    (subItem: any) =>
                      subItem.name === "General" && subItem.value === true
                  )
              ) && (
                <AsideMenuItem
                  to="/apps/settings/general"
                  title="General"
                  hasBullet={true}
                />
              )}
            {/* {currentUser?.data?.user?.permissions &&
              currentUser?.data?.user?.permissions?.some(
                (item: any) =>
                  item.name === "Setting" &&
                  item.value === true &&
                  item.subkey &&
                  item.subkey.some(
                    (subItem: any) =>
                      subItem.name === "Resources" && subItem.value === true
                  )
              ) && (
                <AsideMenuItem
                  to="/apps/settings/resources"
                  title="Resources"
                  hasBullet={true}
                />
              )} */}

            {currentUser?.data?.user?.permissions &&
              currentUser?.data?.user?.permissions?.some(
                (item: any) =>
                  item.name === "Setting" &&
                  item.value === true &&
                  item.subkey &&
                  item.subkey.some(
                    (subItem: any) =>
                      subItem.name === "Emails" && subItem.value === true
                  )
              ) && (
                <AsideMenuItem
                  to="/apps/settings/outlooksync"
                  title="Emails"
                  hasBullet={true}
                />
              )}

            {currentUser?.data?.user?.permissions &&
              currentUser?.data?.user?.permissions?.some(
                (item: any) =>
                  item.name === "Setting" &&
                  item.value === true &&
                  item.subkey &&
                  item.subkey.some(
                    (subItem: any) =>
                      subItem.name === "SSO" && subItem.value === true
                  )
              ) && (
                <AsideMenuItem
                  to="/apps/settings/sso"
                  title="SSO"
                  hasBullet={true}
                />
              )}
          </AsideMenuItemWithSub>
        )}

        {currentUser?.data?.user?.permissions &&
          currentUser?.data?.user?.permissions?.some(
            (item: any) => item.name === "AI settings" && item.value === true
          ) && (
            <AsideMenuItem
              to="/apps/ai-settings"
              icon="/media/icons/duotune/art/setting.svg"
              title="AI settings"
              fontIcon="bi-layers"
            />
          )}


        {currentUser?.data?.user?.permissions &&
          currentUser?.data?.user?.permissions?.some(
            (item: any) => item.name === "My organisation" && item.value === true
          ) && (
            <AsideMenuItem
              to="/apps/organisation"
              icon="/media/icons/duotune/art/organisations.svg"
              title="My organisation"
              fontIcon="bi-layers"
            />
          )}

      {currentUser?.data?.user?.permissions &&
        currentUser?.data?.user?.permissions?.some(
          (item: any) =>
            item.name === "Billing & Subscription" &&
            item.value === true &&
            item.subkey &&
            item?.subkey?.some(
              (subItem: any) =>
                subItem?.name === "Invoice" && subItem?.value === true
            )
        ) && (
          <AsideMenuItemWithSub
            to="/apps/billing-subscription"
            title="Billing & Subscription"
            fontIcon="bi-layers"
            icon="/media/icons/duotune/finance/fin010.svg"
          >
            {currentUser?.data?.user?.permissions &&
              currentUser?.data?.user?.permissions?.some(
                (item: any) =>
                  item.name === "Billing & Subscription" &&
                  item.value === true &&
                  item.subkey &&
                  item.subkey.some(
                    (subItem: any) =>
                      subItem.name === "Invoice" && subItem.value === true
                  )
              ) && (
                <AsideMenuItem
                  to="/apps/billing-subscription/invoices/list"
                  title="Invoice"
                  hasBullet={true}
                />
              )}

            {currentUser?.data?.user?.permissions &&
              currentUser?.data?.user?.permissions?.some(
                (item: any) =>
                  item.name === "Billing & Subscription" &&
                  item.value === true &&
                  item.subkey &&
                  item.subkey.some(
                    (subItem: any) =>
                      subItem.name === "Payment" && subItem.value === true
                  )
              ) && (
                <AsideMenuItem
                  to="/apps/billing-subscription/payment"
                  title="Payment"
                  hasBullet={true}
                />
              )}

            {currentUser?.data?.user?.permissions &&
              currentUser?.data?.user?.permissions?.some(
                (item: any) =>
                  item.name === "Billing & Subscription" &&
                  item.value === true &&
                  item.subkey &&
                  item.subkey.some(
                    (subItem: any) =>
                      subItem.name === "Subscription" && subItem.value === true
                  )
              ) && (
                <AsideMenuItem
                  to="/apps/billing-subscription/subscription"
                  title="Subscription"
                  hasBullet={true}
                />
              )}
          </AsideMenuItemWithSub>
        )}
    </>
  );
}
