import { ID, Response } from "../../../../_metronic/helpers";

export type ProfileDetail = {
  [x: string]: unknown;
  id?: ID;
  // name?: string;
  username?: string;
  email?: string;
  avatar?: string;
  is_active?: boolean,
  phone_no?: string,
  mobile_no?: string,
  note?: string,
  showTooltipVisibility?: any;
};

export type ProfileDetailQueryResponse = Response<Array<ProfileDetail>>;

export const initialProfileDetail: ProfileDetail = {
  // name: "",
  username: "",
  email: "",
  is_active: false,
  phone_no: "",
  mobile_no: "",
  note: "",
};
