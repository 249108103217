/** @jsxRuntime classic */
/** @jsx createElement */
import { EventcalendarBase } from './eventcalendar';
import { MbscEventcalendarOptions, MbscEventcalendarState } from './eventcalendar.types';
import { ICalendarViewHost, MbscCalendarEventData } from '../../shared/calendar-view/calendar-view.types';
import { InstanceServiceBase } from '../../shared/instance-service';
import { List, ListHeader, ListItem } from '../../../react/components/list';
import { Popup } from '../../../react/components/popup';
import { Scheduler } from '../../../react/components/scheduler';
import { Timeline } from '../../../react/components/timeline';
import { createElement, Fragment, isVue } from '../../../react/renderer';
import { CalendarNav, CalendarNext, CalendarPrev, CalendarToday } from '../../../react/shared/calendar-header';
import { CalendarView } from '../../../react/shared/calendar-view';
import { PAGE_VIEW } from '../../shared/calendar-view/calendar-view.util';
import { isString, UNDEFINED } from '../../util/misc';

import './eventcalendar.scss';

export { CalendarNext, CalendarPrev, CalendarToday, CalendarNav };

function renderAgenda(s: MbscEventcalendarOptions, inst: EventcalendarBase, slots?: any) {
  const theme = s.theme!;
  const dayRefs = inst._listDays!;
  const events = inst.state.eventList || [];
  const renderCustomAgenda = slots ? slots.agenda : s.renderAgenda;
  const renderCustomAgendaEmpty = slots ? slots.agendaEmpty : s.renderAgendaEmpty;

  if (renderCustomAgenda) {
    if (inst._eventListHTML === UNDEFINED) {
      return renderCustomAgenda(events, s, dayRefs);
    }
  }

  let emptyAgendaContent: any;
  if (!events.length) {
    const customEmptyAgendaContent = renderCustomAgendaEmpty && renderCustomAgendaEmpty();
    const emptyAgendaContentHTML = isString(customEmptyAgendaContent) && { __html: customEmptyAgendaContent };
    if (emptyAgendaContentHTML) {
      emptyAgendaContent = <div dangerouslySetInnerHTML={emptyAgendaContentHTML} />;
      inst._shouldEnhance = inst._list;
    } else {
      emptyAgendaContent = (
        <div className={!customEmptyAgendaContent ? 'mbsc-event-list-empty' + inst._theme : ''}>
          {customEmptyAgendaContent || s.noEventsText}
        </div>
      );
    }
  }

  return (
    <List theme={theme} themeVariant={s.themeVariant} rtl={s.rtl}>
      {!events.length && emptyAgendaContent}
      {events.map((day, i) => (
        <div className={'mbsc-event-group' + inst._theme} key={i} ref={(el: any) => (dayRefs[day.timestamp] = el)}>
          {(inst._eventListType !== 'day' || inst._eventListSize > 1) && (
            <ListHeader theme={theme} themeVariant={s.themeVariant} className="mbsc-event-day">
              {day.date}
            </ListHeader>
          )}
          {day.events.map((event, j) => renderEvent(inst, event, j, day.timestamp, s, UNDEFINED, slots))}
        </div>
      ))}
    </List>
  );
}

function renderEvent(
  inst: EventcalendarBase,
  data: MbscCalendarEventData,
  key: number,
  date: number,
  s: MbscEventcalendarOptions,
  isPopup?: boolean,
  slots?: any,
) {
  const showColor = !inst._colorEventList;
  const source = isPopup ? 'popover' : 'agenda';
  const isVisible = !isPopup || inst.state.showPopover;
  const theme = inst._theme;
  const renderEventContent = slots ? slots.eventContent : s.renderEventContent;
  const renderCustomEvent = slots ? slots.event : s.renderEvent;
  const hostInst = isPopup ? inst.state.popoverHost : UNDEFINED;
  const event = data.original!;
  const eventTitleHTML = { __html: data.html };
  let eventHTML: { __html: string } | undefined;
  let eventContent = renderEventContent ? (
    renderEventContent(data)
  ) : (
    <div className={'mbsc-event-text ' + theme} title={data.tooltip} dangerouslySetInnerHTML={isVue ? UNDEFINED : eventTitleHTML}>
      {isVue ? data.html : UNDEFINED}
    </div>
  );
  // The extra wrapper div is needed for being consistent with other frameworks.
  // We need it in the case of jQuery and JavaScript because we need an element (div) to set the inner html to.
  // At this point the Fragment component does not support the dangerouslySetInnerHTML prop.
  if (isString(eventContent)) {
    const eventContentHTML = { __html: eventContent };
    eventContent = (
      <div className={'mbsc-event-content mbsc-flex-1-1 ' + theme} dangerouslySetInnerHTML={isVue ? UNDEFINED : eventContentHTML} />
    );
    inst._shouldEnhance = isVisible && source;
  } else {
    eventContent = <div className={'mbsc-event-content mbsc-flex-1-1 ' + theme}>{eventContent}</div>;
  }
  let eventInner = renderCustomEvent ? (
    renderCustomEvent(data)
  ) : (
    <Fragment>
      <div className={'mbsc-event-color' + theme + inst._rtl} style={data.style} />
      {eventContent}
      <div className={'mbsc-event-time' + (hostInst ? ' mbsc-event-date' : '') + theme + inst._rtl}>
        {data.allDayText && <div className={'mbsc-event-all-day' + theme}>{data.allDayText}</div>}
        {data.lastDay && <div className={'mbsc-event-until' + theme}>{data.lastDay}</div>}
        {data.start && <div className={'mbsc-event-start' + theme}>{data.start}</div>}
        {data.start && data.end && <div className={'mbsc-event-sep' + theme}>-</div>}
        {data.end && <div className={'mbsc-event-end' + theme}>{data.end}</div>}
      </div>
    </Fragment>
  );
  // In case of custom event listing, the renderer function might return string (for jQuery and plain JS)
  // In this case we will set the string as innerHTML of the list container
  if (isString(eventInner)) {
    eventHTML = { __html: eventInner };
    eventInner = UNDEFINED;
    inst._shouldEnhance = isVisible && source;
  }
  return (
    <ListItem
      className={'mbsc-event' + (showColor ? '' : ' mbsc-colored-event') + (event.cssClass ? ' ' + event.cssClass : '')}
      data-id={event.id}
      key={key}
      actionable={s.actionableEvents}
      dangerouslySetInnerHTML={isVue ? UNDEFINED : eventHTML}
      drag={isPopup && (inst._showEventLabels || hostInst) && (s.dragToMove || s.externalDrag)}
      event={event}
      eventData={data}
      rtl={s.rtl}
      selected={!!(inst._selectedEventsMap[data.uid!] || inst._selectedEventsMap[data.id])}
      style={showColor ? UNDEFINED : data.style}
      theme={s.theme}
      themeVariant={s.themeVariant}
      // tslint:disable jsx-no-lambda
      onClick={(ev: any) => inst._onEventClick({ date, domEvent: ev.domEvent, event, source })}
      onDoubleClick={(domEvent: any) => inst._onEventDoubleClick({ date, domEvent, event, source })}
      onContextMenu={(domEvent: any) => inst._onEventRightClick({ date, domEvent, event, source })}
      onHoverIn={({ domEvent }: any) => inst._onEventHoverIn({ date, domEvent, event, source })}
      onHoverOut={({ domEvent }: any) => inst._onEventHoverOut({ date, domEvent, event, source })}
      // tslint:enable jsx-no-lambda
      onDelete={inst._onEventDelete}
      onDragEnd={hostInst ? hostInst._onEventDragEnd : inst._onLabelUpdateEnd}
      onDragModeOff={hostInst ? hostInst._onEventDragModeOff : inst._onLabelUpdateModeOff}
      onDragModeOn={hostInst ? hostInst._onEventDragModeOn : inst._onLabelUpdateModeOn}
      onDragMove={hostInst ? hostInst._onEventDragMove : inst._onLabelUpdateMove}
      onDragStart={hostInst ? hostInst._onEventDragStart : inst._onLabelUpdateStart}
    >
      {eventInner}
    </ListItem>
  );
}

export function template(s: MbscEventcalendarOptions, state: MbscEventcalendarState, inst: EventcalendarBase, slots?: any): any {
  let eventList: any;

  if (!inst._listDays) {
    inst._listDays = {};
  }

  if (inst._showEventList) {
    eventList = renderAgenda(s, inst, slots);
    // In case of custom event listing, the renderer function might return string (for jQuery and plain JS)
    // In inst case we will set the string as innerHTML of the list container
    if (isString(eventList)) {
      inst._eventListHTML = { __html: eventList };
      // After the DOM is updated we should load the day wrapper based on the mbsc-timestamp attribute (if any)
      // It's needed for scrolling the list to the selected date
      inst._shouldLoadDays = true;
      inst._shouldEnhance = true;
      eventList = UNDEFINED;
    }
  }

  const commonProps = {
    amText: s.amText,
    clickToCreate: s.clickToCreate,
    dataTimezone: s.dataTimezone,
    dateFormat: s.dateFormat,
    dayNames: s.dayNames,
    dayNamesMin: s.dayNamesMin,
    dayNamesShort: s.dayNamesShort,
    displayTimezone: s.displayTimezone,
    dragBetweenResources: s.dragBetweenResources,
    dragInTime: s.dragInTime,
    dragToCreate: s.dragToCreate,
    dragToResize: s.dragToResize,
    eventMap: inst._eventMap,
    eventOrder: s.eventOrder,
    exclusiveEndDates: s.exclusiveEndDates,
    firstDay: s.firstDay,
    fromText: s.fromText,
    getDate: s.getDate,
    getDay: s.getDay,
    getMaxDayOfMonth: s.getMaxDayOfMonth,
    getMonth: s.getMonth,
    getWeekNumber: s.getWeekNumber,
    getYear: s.getYear,
    monthNames: s.monthNames,
    monthNamesShort: s.monthNamesShort,
    onActiveChange: inst._onActiveChange,
    onEventDragEnter: inst._onEventDragEnter,
    onEventDragLeave: inst._onEventDragLeave,
    pmText: s.pmText,
    refDate: inst._refDate,
    renderDay: slots ? slots.day : s.renderDay,
    rtl: s.rtl,
    selectedEventsMap: inst._selectedEventsMap,
    showEventTooltip: s.showEventTooltip,
    theme: s.theme,
    themeVariant: s.themeVariant,
    timeFormat: s.timeFormat,
    timezonePlugin: s.timezonePlugin,
    toText: s.toText,
  };

  const scheduleTimelineProps = {
    ...commonProps,
    allDayText: s.allDayText,
    checkSize: inst._checkSize,
    colorsMap: inst._colorsMap,
    currentTimeIndicator: inst._currentTimeIndicator,
    dateFormatFull: s.dateFormatFull,
    dateFormatLong: s.dateFormatLong,
    dragTimeStep: inst._dragTimeStep,
    dragToMove: s.dragToMove,
    eventDragEnd: inst._onEventDragStop,
    eventOverlap: s.eventOverlap,
    extendDefaultEvent: s.extendDefaultEvent,
    externalDrag: s.externalDrag,
    externalDrop: s.externalDrop,
    groupBy: s.groupBy,
    height: state.height,
    immutableData: s.immutableData,
    invalidateEvent: s.invalidateEvent,
    invalidsMap: inst._invalidsMap,
    maxDate: inst._maxDate,
    minDate: inst._minDate,
    moreEventsPluralText: s.moreEventsPluralText,
    moreEventsText: s.moreEventsText,
    navService: inst._navService,
    navigateToEvent: inst._navigateToEvent,
    newEventText: s.newEventText,
    onCellClick: inst._onCellClick,
    onCellDoubleClick: inst._onCellDoubleClick,
    onCellRightClick: inst._onCellRightClick,
    onEventClick: inst._onEventClick,
    onEventDelete: inst._onEventDelete,
    onEventDoubleClick: inst._onEventDoubleClick,
    onEventDragEnd: inst._onEventDragEnd,
    onEventDragStart: inst._onEventDragStart,
    onEventHoverIn: inst._onEventHoverIn,
    onEventHoverOut: inst._onEventHoverOut,
    onEventRightClick: inst._onEventRightClick,
    onMoreClick: inst._onMoreClick,
    onPopoverClose: inst._onPopoverClose,
    renderBufferAfter: slots ? slots.bufferAfter : s.renderBufferAfter,
    renderBufferBefore: slots ? slots.bufferBefore : s.renderBufferBefore,
    renderEvent: slots ? slots.scheduleEvent : s.renderScheduleEvent,
    renderEventContent: slots ? slots.scheduleEventContent : s.renderScheduleEventContent,
    renderResource: slots ? slots.resource : s.renderResource,
    resources: s.resources,
    scroll: inst._shouldScrollSchedule,
    selected: inst._selectedDateTime,
    showEventBuffer: s.showEventBuffer,
    width: state.width,
  };

  return (
    <CalendarView
      ref={inst._setEl}
      {...commonProps}
      activeDate={inst._active}
      calendarScroll={inst._calendarScroll}
      calendarType={inst._calendarType}
      colors={s.colors}
      context={s.context}
      cssClass={inst._cssClass}
      downIcon={s.downIcon}
      dragData={state.labelDragData}
      dragToMove={s.dragToMove || s.externalDrag}
      endDay={inst._rangeEndDay}
      eventRange={inst._rangeType}
      eventRangeSize={inst._showSchedule ? inst._scheduleSize : inst._showTimeline ? inst._timelineSize : inst._eventListSize}
      hasContent={inst._showEventList || inst._showSchedule || inst._showTimeline}
      hasPicker={true}
      height={s.height}
      invalid={s.invalid}
      instanceService={inst._instanceService}
      labels={s.labels}
      labelList={inst._calendarLabelList}
      labelsMap={inst._labelsMap}
      marked={s.marked}
      marksMap={inst._marksMap}
      max={s.max}
      min={s.min}
      mouseSwipe={(!s.dragToCreate && s.clickToCreate !== 'single') || (!inst._showEventLabels && !inst._showEventCount)}
      mousewheel={s.mousewheel}
      navService={inst._navService}
      navView={inst._navView}
      nextIconH={s.nextIconH}
      nextIconV={s.nextIconV}
      nextPageText={s.nextPageText}
      noOuterChange={!inst._showEventList}
      onCellHoverIn={inst._onCellHoverIn}
      onCellHoverOut={inst._onCellHoverOut}
      onDayClick={inst._onDayClick}
      onDayDoubleClick={inst._onDayDoubleClick}
      onDayRightClick={inst._onDayRightClick}
      onGestureStart={inst._onGestureStart}
      onLabelClick={inst._onLabelClick}
      onLabelDoubleClick={inst._onLabelDoubleClick}
      onLabelRightClick={inst._onLabelRightClick}
      onLabelHoverIn={inst._onLabelHoverIn}
      onLabelHoverOut={inst._onLabelHoverOut}
      onLabelDelete={inst._onEventDelete}
      onLabelUpdateStart={inst._onLabelUpdateStart}
      onLabelUpdateMove={inst._onLabelUpdateMove}
      onLabelUpdateEnd={inst._onLabelUpdateEnd}
      onLabelUpdateModeOn={inst._onLabelUpdateModeOn}
      onLabelUpdateModeOff={inst._onLabelUpdateModeOff}
      onPageChange={inst._onPageChange}
      onPageLoaded={inst._onPageLoaded}
      onPageLoading={inst._onPageLoading}
      onResize={inst._onResize}
      pageLoad={inst._pageLoad}
      prevIconH={s.prevIconH}
      prevIconV={s.prevIconV}
      prevPageText={s.prevPageText}
      resourcesMap={inst._resourcesMap}
      responsiveStyle={true}
      renderHeader={slots ? slots.header : s.renderHeader}
      renderDayContent={slots ? slots.dayContent : s.renderDayContent}
      renderLabel={slots ? slots.label : s.renderLabel}
      renderLabelContent={slots ? slots.labelContent : s.renderLabelContent}
      selectedDates={inst._selectedDates}
      selectView={PAGE_VIEW}
      showCalendar={inst._showCalendar}
      showControls={s.showControls}
      showLabelCount={inst._showEventCount}
      showOuterDays={inst._showOuterDays}
      showSchedule={inst._showSchedule || inst._showTimeline}
      showToday={s.showToday}
      showWeekNumbers={inst._showWeekNumbers}
      size={inst._calendarSize}
      startDay={inst._rangeStartDay}
      swipe={!state.isTouchDrag}
      upIcon={s.upIcon}
      valid={s.valid}
      weeks={inst._calendarSize}
      width={s.width}
      // Localization
      eventText={s.eventText}
      eventsText={s.eventsText}
      fromText={s.fromText}
      moreEventsPluralText={s.moreEventsPluralText}
      moreEventsText={s.moreEventsText}
      todayText={s.todayText}
      toText={s.toText}
      weekText={s.weekText}
      yearSuffix={s.yearSuffix}
    >
      {inst._showDate && (
        <div className={'mbsc-schedule-date-header mbsc-flex' + inst._theme + inst._hb}>
          {inst._showSchedule && !inst._showCalendar && s.resources && <div className="mbsc-schedule-time-col" />}
          <div className={'mbsc-schedule-date-header-text mbsc-flex-1-1' + inst._theme}>{inst._selectedDateHeader}</div>
          {inst._showSchedule && !inst._showCalendar && s.resources && <div className="mbsc-schedule-fake-scroll-y" />}
        </div>
      )}

      {inst._showEventList && (
        <div
          className={'mbsc-flex-1-1 mbsc-event-list' + (state.isListScrollable ? ' mbsc-event-list-scroll' : '')}
          dangerouslySetInnerHTML={isVue ? UNDEFINED : inst._eventListHTML}
          onScroll={inst._onScroll}
          ref={inst._setList}
        >
          {eventList}
        </div>
      )}

      {inst._showSchedule && (
        <Scheduler
          {...scheduleTimelineProps}
          endDay={inst._scheduleEndDay}
          endTime={inst._scheduleEndTime}
          maxEventStack={inst._scheduleMaxEventStack}
          minEventWidth={inst._scheduleMinEventWidth}
          renderDayContent={slots ? slots.dayContent : s.renderDayContent}
          showAllDay={inst._showScheduleAllDay}
          showDays={inst._showScheduleDays}
          size={inst._scheduleSize}
          startDay={inst._scheduleStartDay}
          startTime={inst._scheduleStartTime}
          timeCellStep={inst._scheduleTimeCellStep}
          timeLabelStep={inst._scheduleTimeLabelStep}
          timezones={inst._scheduleTimezones}
          type={inst._scheduleType}
          onWeekDayClick={inst._onWeekDayClick}
        />
      )}

      {inst._showTimeline && (
        <Timeline
          {...scheduleTimelineProps}
          connections={s.connections}
          downIcon={s.chevronIconDown}
          dragBetweenSlots={s.dragBetweenSlots}
          dragToCreate={s.slots ? false : s.dragToCreate}
          dragToResize={s.slots ? false : s.dragToResize}
          endDay={inst._timelineEndDay}
          endTime={inst._timelineEndTime}
          eventList={inst._timelineListing}
          maxEventStack={inst._timelineMaxEventStack}
          nextIcon={s.nextIconH}
          nextIconRtl={s.prevIconH}
          onResourceCollapse={inst._proxy}
          onResourceExpand={inst._proxy}
          quarterText={s.quarterText}
          renderDayFooter={slots ? slots.dayFooter : s.renderDayFooter}
          renderHour={slots ? slots.hour : s.renderHour}
          renderHourFooter={slots ? slots.hourFooter : s.renderHourFooter}
          renderMonth={slots ? slots.month : s.renderMonth}
          renderMonthFooter={slots ? slots.monthFooter : s.renderMonthFooter}
          renderQuarter={slots ? slots.quarter : s.renderQuarter}
          renderQuarterFooter={slots ? slots.quarterFooter : s.renderQuarterFooter}
          renderWeek={slots ? slots.week : s.renderWeek}
          renderWeekFooter={slots ? slots.weekFooter : s.renderWeekFooter}
          renderYear={slots ? slots.year : s.renderYear}
          renderYearFooter={slots ? slots.yearFooter : s.renderYearFooter}
          renderResourceFooter={slots ? slots.resourceFooter : s.renderResourceFooter}
          renderResourceHeader={slots ? slots.resourceHeader : s.renderResourceHeader}
          renderSidebar={slots ? slots.sidebar : s.renderSidebar}
          renderSidebarFooter={slots ? slots.sidebarFooter : s.renderSidebarFooter}
          renderSidebarHeader={slots ? slots.sidebarHeader : s.renderSidebarHeader}
          renderSlot={slots ? slots.slot : s.renderSlot}
          resolution={inst._timelineResolution}
          resolutionVertical={inst._timelineResolutionVertical}
          rowHeight={inst._timelineRowHeight}
          weekNumbers={inst._showTimelineWeekNumbers}
          weekText={s.weekText}
          size={inst._timelineSize}
          slots={s.slots}
          startDay={inst._timelineStartDay}
          startTime={inst._timelineStartTime}
          timeCellStep={inst._timelineTimeCellStep}
          timeLabelStep={inst._timelineTimeLabelStep}
          type={inst._timelineType}
          virtualScroll={!inst._print && inst._timelineVirtualScroll}
        />
      )}

      <Popup
        anchor={inst._anchor}
        closeOnScroll={true}
        contentPadding={false}
        context={state.popoverContext || s.context}
        cssClass={'mbsc-calendar-popup ' + (state.popoverHidden ? 'mbsc-popover-hidden ' : '') + inst._popoverClass}
        display="anchored"
        isOpen={state.showPopover}
        locale={s.locale}
        maxHeight="24em"
        onClose={inst._onPopoverClose}
        rtl={s.rtl}
        scrollLock={false}
        showOverlay={false}
        theme={s.theme}
        themeVariant={s.themeVariant}
      >
        {state.popoverList && (
          <List ref={inst._setPopoverList} theme={s.theme} themeVariant={s.themeVariant} rtl={s.rtl} className="mbsc-popover-list">
            {state.popoverList.map((event, i) => renderEvent(inst, event, i, state.popoverDate!, s, true, slots))}
          </List>
        )}
      </Popup>
      {state.labelDragData && state.labelDragData.draggedEvent && !state.isTouchDrag && <div className="mbsc-calendar-dragging" />}
    </CalendarView>
  );
}

/**
 * The Eventcalendar component.
 *
 * Usage:
 *
 * ```
 * <Eventcalendar />
 * ```
 */
export class Eventcalendar extends EventcalendarBase implements ICalendarViewHost {
  /** @hidden */
  // tslint:disable-next-line: variable-name
  public _instanceService: InstanceServiceBase = new InstanceServiceBase();

  protected _template(s: MbscEventcalendarOptions, state: MbscEventcalendarState): any {
    return template(s, state, this);
  }
}
