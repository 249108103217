/** @jsxRuntime classic */
/** @jsx createElement */
import { ScrollerBase } from './scroller';
import { MbscScrollerOptions } from './scroller.types';
import { createElement, Fragment } from '../../../react/renderer';
import { Wheel } from '../../../react/shared/wheel';
import { pickerTemplate } from '../../shared/picker/picker.common';

import './scroller.scss';

export function template(s: MbscScrollerOptions, inst: ScrollerBase): any {
  const preContent = s.renderPreContent ? s.renderPreContent(s.preContentData) : '';
  const inContent = s.renderInContent ? s.renderInContent(s.preContentData) : '';
  return (
    <Fragment>
      {preContent}
      <div
        className={
          'mbsc-scroller mbsc-scroller-' +
          inst._displayStyle +
          inst._theme +
          inst._rtl +
          (inst._touchUi ? ' mbsc-scroller-touch' : ' mbsc-scroller-pointer') +
          (s.display === 'inline' ? ' mbsc-font ' : ' ') +
          inst._className
        }
      >
        {inContent}
        {inst._wheels.map((wheelGroup, i) => {
          return (
            <div
              key={i}
              className={'mbsc-scroller-wheel-group-cont' + (s.scroll3d ? ' mbsc-scroller-wheel-group-cont-3d' : '') + inst._theme}
            >
              {s.selectOnScroll && <div className={'mbsc-scroller-wheel-line' + inst._theme} style={inst._lineStyle} />}
              <div className={'mbsc-flex mbsc-scroller-wheel-group' + (s.scroll3d ? ' mbsc-scroller-wheel-group-3d' : '') + inst._theme}>
                <div
                  className={'mbsc-scroller-wheel-overlay mbsc-scroller-wheel-overlay-' + inst._displayStyle + inst._theme}
                  style={inst._overlayStyle}
                />
                {wheelGroup.map((wheel, j) => (
                  <Wheel
                    activeIndex={inst._activeIndexes[wheel._key!]}
                    disabled={inst._disabled && inst._disabled[wheel._key!]}
                    display={inst._displayStyle}
                    key={j}
                    itemHeight={s.itemHeight!}
                    onActiveChange={inst._onActiveChange}
                    onIndexChange={inst._onWheelIndexChange}
                    onSet={inst._onSet}
                    maxIndex={wheel.max!}
                    maxWheelWidth={s.maxWheelWidth}
                    minIndex={wheel.min!}
                    minWheelWidth={s.minWheelWidth}
                    multiple={wheel.multiple!}
                    renderItem={s.renderItem}
                    rows={inst._rows}
                    scroll3d={inst._scroll3d}
                    selectedIndex={inst._indexes[wheel._key!]}
                    selectedValues={inst._tempValueRep[wheel._key!]}
                    selectOnScroll={s.selectOnScroll}
                    theme={s.theme!}
                    touchUi={s.touchUi}
                    rtl={s.rtl}
                    wheel={wheel}
                    wheelWidth={s.wheelWidth}
                  />
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </Fragment>
  );
}

/**
 * The Scroller component.
 *
 * Usage:
 *
 * ```
 * <Scroller />
 * ```
 */
export class Scroller extends ScrollerBase {
  protected _template(s: MbscScrollerOptions): any {
    return pickerTemplate(this, s, template(s, this));
  }
}
