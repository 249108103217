/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, FC, useEffect } from "react";
import * as Yup from "yup";
import { Loading } from "../../../../components/loading/Loading";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { isNotEmpty, KTSVG } from "../../../../../_metronic/helpers";
// import { createGeneralSetting, getGeneralSetting, updateGeneralSetting } from "../../../../../_metronic/requests/GeneralSettingRequest";
import { GeneralSetting, initialGeneralSetting} from "../core/_models"
import { isInteger, useFormik } from "formik";
import { useListView } from "../../../../components/core/ListViewProvider";
import { alert, Datepicker } from "@mobiscroll/react";
import { getResourcesWorkType, getToPlanServiceCallStatus, getToPlanServiceCallStatusAdmin, getToPlanStatus, getToPlanTaskStatus, getToPlanTaskStatusAdmin } from "../../../../../_metronic/requests/PlanningRequest";
import { createGeneralSetting, getGeneralSetting, updateGeneralSetting } from "../core/_requests";
import { AUTOTASK_ENTITY } from "../../../../constants/global";

const generalSettingSchema = Yup.object().shape({

  daily_availability: Yup.number()
    .required("Resource daily availability (hours) is required")
    .moreThan(-1, "Resource daily availability (hours) should not be '0' ")
    // .test('maxDigitsAfterDecimal','Please enter a valid number. The maximum number of digits after the decimal point is two.',(num:any) => /^\d+(\.\d{1,2})?$/.test(num))
    // .lessThan(14.01, "Daily availability should not be more than 14 hours")
    .max(14.00,'Resource daily availability (hours) not be more than 14 hours'),


    // .integer("Invalid Value"),

  task_duration: Yup.number()
    .required("Task duration (hours) is required")
    .moreThan(-1, "Task duration (hours) should not be '0' ")
    // .lessThan(40.01, "Task duration (hours) should not be more than 40 hours")
    // .test('maxDigitsAfterDecimal','use only two digits after decimal',(num:any) => /^\d+(\.\d{1,2})?$/.test(num))
    .max(40.00,'Task duration (hours) should not be more than 40 hours'),
    // .integer("Invalid Value"),



  ticket_duration: Yup.number()
    .required("Ticket duration (hours) is required")
    .moreThan(-1, "Ticket duration (hours) should not be '0' ")
    .max(40.00,"Ticket duration (hours) should not be more than 40 hours"),
    // .lessThan(40.01, "Ticket duration should not be more than 40 hours"),
    // .integer('Invalid Value'),


  planning_screen_view: Yup.string()
    .required("Planning screen view is required")
    .oneOf(['day', 'week', 'month', 'quarter', 'half_year', 'year'], 'Planning screen view is required'),

  task_screen_view: Yup.string()
    .required("Task screen view is required")
    .oneOf(['day', 'week', 'month', 'quarter', 'half_year', 'year'], 'Task screen view is required'),

  service_call_duration: Yup.number()
    .required(`${ AUTOTASK_ENTITY.ServiceCall.title } duration (hours) is required`)
    .moreThan(-1, `${ AUTOTASK_ENTITY.ServiceCall.title } duration (hours) should not be '0' `)
    // .test('maxDigitsAfterDecimal','use only two digits after decimal',(num:any) => /^\d+(\.\d{1,2})?$/.test(num))
    .max(40.00,`${ AUTOTASK_ENTITY.ServiceCall.title } duration (hours) should not be more than 40 hours`),
    // .lessThan(40.01, "Todo duration should not be more than 40 hours"),
    // .integer('Invalid Value'),
  // service_call_duration: Yup.string()
  //   .required("Todo duration is required")
  //   .oneOf(['15', '30', '45', '60'], 'Todo duration is required'),

  planning_first_day_of_week: Yup.string()
    .required("Planning first day of week is required")
    .oneOf(['0', '1', '2', '3', '4', '5', '6'], 'Planning first day of week is required'),

  task_first_day_of_week: Yup.string()
    .required("Task first day of week is required")
    .oneOf(['0', '1', '2', '3', '4', '5', '6'], 'Task first day of week is required'),

    timezone: Yup.string()
    .required("Timezone is required"),

});

const GeneralSettingView: FC = () => {

  const navigate = useNavigate();
  //const [task_type, setTasktype] = useState<GeneralSetting>(initialGeneralSetting);

  const {
    refetch,
    data: generalSetting } = useQuery(
      `resource-setting`,
      async () => {
        return await getGeneralSetting('');
      },
      {
        cacheTime: 0,
        onError: (err) => {
          console.error(err);
        },
      }
    );

  const {
    isFetching: isfetchstatus,
    refetch: refetchstatus,
    data: todo_status_taskticket,
  } = useQuery(
    'todo-status',
    async () => {
      return await getToPlanTaskStatusAdmin('');
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const {
    isFetching: isfetchservicecallstatus,
    refetch: refetchservicecallstatus,
    data: todo_status_serviceCall,
  } = useQuery(
    'todo-status-serviceCall',
    async () => {
      return await getToPlanServiceCallStatusAdmin('');
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const {
    isFetching: isfetchworkgroup,
    refetch: refetchworkgroup,
    data: workgroups,
  } = useQuery(
    'todo-workgroup',
    async () => {
      return await getResourcesWorkTypeAdmin('');
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  let generalSettingData = generalSetting ? generalSetting : initialGeneralSetting;
  const [selected, setSelected] = useState<any>(true);
  const [task_type, setTasktype] = useState<any>(null);
  const [starttime, setStarttime] = useState<any>(null)
  const [endtime, setEndtime] = useState<any>(null)
  const [showSkill, setShowskill] = useState<any>(true);

  useEffect(() => {
    setSelected(generalSetting?.allow_overlapping);
    if (task_type === null || task_type === undefined) {
      setTasktype(generalSetting?.show_task_type);
    }
    setStarttime(generalSetting?.start_time)
    setEndtime(generalSetting?.end_time)

  }, [generalSetting, generalSettingData, task_type, generalSetting?.start_time, generalSetting?.end_time]);

  const handleAllowOverlapChange = (event: any) => {
    if (event.target.value == 1) {
      setSelected(true)
    } else {
      setSelected(false)
    }
  };

  const handleShowSkill = (event: any) => {
    if (event.target.value == 1) {
      setShowskill(true)
    } else {
      setShowskill(false)
    }
  };
  const updateTasktypeData = (fieldsToUpdate: Partial<GeneralSetting>): void => {
    const updatedData = Object.assign(generalSettingData, fieldsToUpdate);
    setTasktype(fieldsToUpdate?.show_task_type);
  };

  /* Moment timezone */
  var moment = require('moment-timezone');
  var timeZones = moment.tz.names();
  var offsetTmz = [];
  for (var i in timeZones) {
    offsetTmz.push(timeZones[i]);
  }

  const onStarttimeChange = (e: any) => {
    const start_time = moment(e.value).format('HH:mm');
    setStarttime(start_time);
  }

  const onEndtimeChange = (e: any) => {
    const end_time = moment(e.value).format('HH:mm');
    setEndtime(end_time);
  }
  /* Timezone end */

  const { setItemIdForUpdate } = useListView();

  const preventPasteNegative = (ev: any) => {
    const clipboardData = ev.clipboardData || window.Clipboard;
    const pastedData = parseFloat(clipboardData.getData('text'));
    const str = ev.clipboardData.getData('text');
    const newStr = str.replace(/[+-.]/g, '');

    if (pastedData < 0 || str !== newStr || str === 'e') {
      ev.preventDefault();
    }

  };
  const preventMinus = (ev: any) => {
    if (ev.code === 'Minus' || ev.code === 'Equal' || ev.code === 'KeyE') {
      ev.preventDefault();
    }
  };

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch();
    }
    setItemIdForUpdate(undefined);
  };

  const [loading, setLoading] = useState(false);
  const formik = useFormik<GeneralSetting>({
    enableReinitialize: true,
    initialValues: generalSettingData,
    validationSchema: generalSettingSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      setLoading(true);
      try {
        values.show_task_type = task_type;
        values.allow_overlapping = selected;
        values.start_time = starttime;
        values.end_time = endtime;
        values.show_resource_skills = showSkill
        if (isNotEmpty(values._id)) {
          await updateGeneralSetting(values);
        } else {
          await createGeneralSetting(values);
        }
        localStorage.setItem("starttime", starttime);
        localStorage.setItem("endtime", endtime);
        localStorage.setItem("time_format", values?.time_format);
      } catch (ex) {
        setLoading(false);
      } finally {
        navigate("/superadmin/general-settings/general");
        setSubmitting(true);
        setLoading(false);
        cancel(true);
        alert({
          message: 'Changes saved successfully!',
          callback: function (result: any) {
              window.location.reload();
          }
        });
      }
    },
  });

  const handleinput = (tis:any) => {
    // console.log(tis)
    if (tis) {
      var prev = tis?.getAttribute("data-prev");
      prev = (prev != '') ? prev : '';
      if (Math.round(tis?.value*100)/100!=tis?.value) {
        tis.value=prev;
      }
      tis.setAttribute("data-prev",tis.value)
    }
  }

  return (

    <>
      <div className="col">
      <div className='card card-custom card-stretch h-100'>
        <div id='kt_general_setting_details' className="h-100 bg-white rounded">
          <form
            onSubmit={formik.handleSubmit}
            noValidate className='form d-flex flex-column h-100 overflow-auto'>
            <div
              className='card-header border-0'
            >
              <div className='card-title m-0 w-100 justify-content-between'>
                <h1 className='fw-bolder m-0 fs-4 fs-lg-2'>General settings</h1>
                <button
                  type="submit"
                  className="btn btn-primary h-40px fs-6 py-0 px-5 rounded-pill fs-ls-14"
                  disabled={loading}

                >
                  {!loading && "Save changes"}
                  <Loading isLoading={loading} text={"Please wait..."}></Loading>
                </button>
              </div>
            </div>
            <div className='card-body border-top py-5 overflow-auto mh-100'>
              <div className='row mb-6'>
                <label className='col-form-label required fw-bold fs-6 mw-300px mw-xxl-400px fs-ls-14'>
                  Resource daily availability (hours)
                </label>

                <div className='fv-row col-md-8'>

                  <input
                    type='number'
                    max={14.00}
                    min={0}
                    onInput={()=>handleinput(this)}
                    // pattern="^\d+(?:\.\d{1,2})?$"
                    className='form-control form-control-solid fs-6 h-45px p-0 px-4 h-ls-48 fs-ls-14'
                    {...formik.getFieldProps("daily_availability")}
                    onPaste={preventPasteNegative}
                    onKeyPress={preventMinus}

                  />
                  {formik.touched.daily_availability && formik.errors.daily_availability && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block fs-8">{formik.errors.daily_availability}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* ALlow overlap*/}
              <div className='row mb-6'>
                <label className='col-form-label required fw-bold fs-6 mw-300px mw-xxl-400px fs-ls-14'>
                  Allow overlap scheduling</label>
                <div className='d-flex fv-row fv-row col-md-8'>
                  <div className='form-check form-check-custom form-check-solid'>
                    <input
                      className="form-check-input me-2"
                      type="radio"
                      name="allow_overlapping"
                      value={1}
                      checked={selected === true ? true : false}
                      /* onChange={() => {
                        updateData({ allow_overlapping: true });
                      }} */
                      onChange={handleAllowOverlapChange}
                    ></input>
                    <label className='form-check-label me-5'>
                      <div className='fw-bolder text-gray-800'>Yes</div>
                    </label>

                    <input
                      className="form-check-input me-2"
                      type="radio"
                      name="allow_overlapping"
                      value={0}
                      checked={selected === false ? true : false}
                      onChange={handleAllowOverlapChange}
                    ></input>
                    < label className='form-check-label'>
                      <div className='fw-bolder text-gray-800'>No</div>
                    </label>
                  </div>
                </div>
              </div>
              {/* end ALlow overlap */}
              {/* Show task type */}
              <div className='row mb-6'>
                <label className='col-form-label fw-bold fs-6 mw-300px mw-xxl-400px fs-ls-14'>
                  Show plan item type in front of task?
                </label>
                <div className='d-flex fv-row fv-row col-md-8'>

                  <div className="col-lg-8 d-flex align-items-center">
                    <div className="form-check form-check-dark form-switch fv-row">
                      <input
                        className="form-check-input w-45px h-30px"
                        type="checkbox"
                        id="show_task_type"
                        defaultChecked={task_type === null ? generalSetting?.show_task_type : task_type}
                        onClick={() => {
                          updateTasktypeData({ show_task_type: task_type === null ? !generalSetting?.show_task_type : !task_type });
                        }}

                      >
                      </input>
                      <label className="form-check-label"></label>
                    </div>
                  </div>

                </div>
              </div>
              {/* end show task type */}
              <div className='row mb-6'>
                <label className='col-form-label required fw-bold fs-6 mw-300px mw-xxl-400px fs-ls-14'>
                  Planning screen view
                </label>

                <div className='fv-row col-md-8'>
                  <select
                    className='form-select form-select-solid h-45px p-0 px-4 fs-6 h-ls-48 fs-ls-14'
                    {...formik.getFieldProps("planning_screen_view")}
                  >
                    
                    <option value='day'>Day</option>
                    <option value='week' selected>Week</option>
                    <option value='month'>Month</option>
          
                  </select>
                  {formik.touched.planning_screen_view && formik.errors.planning_screen_view && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block fs-8">{formik.errors.planning_screen_view}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-form-label required fw-bold fs-6 mw-300px mw-xxl-400px fs-ls-14'>Tasks screen view</label>
                <div className='fv-row col-md-8'>
                  <select
                    className='form-select form-select-solid h-45px p-0 px-4 fs-6 h-ls-48 fs-ls-14'
                    {...formik.getFieldProps("task_screen_view")}
                  >
                    <option value=''>Select task screen view...</option>
                    <option value='day'>Day</option>
                    <option value='week'>Week</option>
                    <option value='month'>Month</option>
                   
                  </select>
                  {formik.touched.task_screen_view && formik.errors.task_screen_view && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block fs-8">{formik.errors.task_screen_view}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* <div className='row mb-6'>
                <label className='col-form-label required fw-bold fs-6 mw-300px mw-xxl-400px fs-ls-14'>Todo duration (minutes)</label>

                <div className='fv-row col-md-8'>
                  <select
                    className='form-select form-select-solid h-45px p-0 px-4 fs-6 h-ls-48 fs-ls-14'
                    {...formik.getFieldProps("service_call_duration")}
                  >
                    <option value=''>Select service call duration...</option>
                    <option value='15'>15</option>
                    <option value='30'>30</option>
                    <option value='45'>45</option>
                    <option value='60'>60</option>
                  </select>
                  {formik.touched.service_call_duration && formik.errors.service_call_duration && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block fs-8">{formik.errors.service_call_duration}</div>
                    </div>
                  )}
                </div>
              </div> */}

              <div className='row mb-6'>
                <label className='col-form-label required fw-bold fs-6 mw-300px mw-xxl-400px fs-ls-14'>{ AUTOTASK_ENTITY.ServiceCall.title } duration (hours)</label>

                <div className='fv-row col-md-8'>
                  <input
                    type='number'
                    className='form-control form-control-solid fs-6 h-45px p-0 px-4 h-ls-48 fs-ls-14'
                    onPaste={preventPasteNegative}
                    onKeyPress={preventMinus}
                    min={0}
                    {...formik.getFieldProps("service_call_duration")}
                  />
                  {formik.touched.service_call_duration && formik.errors.service_call_duration && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block fs-8">{formik.errors.service_call_duration}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-form-label required fw-bold fs-6 mw-300px mw-xxl-400px fs-ls-14'>Task duration (hours)</label>

                <div className='fv-row col-md-8'>
                  <input
                    type='number'
                    className='form-control form-control-solid fs-6 h-45px p-0 px-4 h-ls-48 fs-ls-14'
                    onPaste={preventPasteNegative}
                    onKeyPress={preventMinus}
                    min={0}
                    max={40}
                    {...formik.getFieldProps("task_duration")}
                  />
                  {formik.touched.task_duration && formik.errors.task_duration && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block fs-8">{formik.errors.task_duration}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-form-label required fw-bold fs-6 mw-300px mw-xxl-400px fs-ls-14'>Ticket duration (hours)</label>

                <div className='fv-row col-md-8'>
                  <input
                    type='number'
                    className='form-control form-control-solid fs-6 h-45px p-0 px-4 h-ls-48 fs-ls-14'
                    onPaste={preventPasteNegative}
                    onKeyPress={preventMinus}
                    min={0}
                    {...formik.getFieldProps("ticket_duration")} />
                  {formik.touched.ticket_duration && formik.errors.ticket_duration && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block fs-8">{formik.errors.ticket_duration}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-form-label fw-bold fs-6 mw-300px mw-xxl-400px fs-ls-14'>Task colour</label>

                <div className='fv-row col-md-8'>
                  <input
                    type="color"
                    className="h-ls-48 w-65px h-35px"
                    {...formik.getFieldProps("task_color")} />
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-form-label fw-bold fs-6 mw-300px mw-xxl-400px fs-ls-14'>Ticket colour</label>

                <div className='fv-row col-md-8'>
                  <input
                    type="color"
                    className="h-ls-48 w-65px h-35px"
                    {...formik.getFieldProps("ticket_color")} />
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-form-label fw-bold fs-6 mw-300px mw-xxl-400px fs-ls-14'>{ AUTOTASK_ENTITY.ServiceCall.title } colour</label>

                <div className='fv-row col-md-8'>
                  <input
                    type="color"
                    className="h-ls-48 w-65px h-35px"
                    {...formik.getFieldProps("service_call_color")} />
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-form-label required fw-bold fs-6 mw-300px mw-xxl-400px fs-ls-14'>Planning first day of week</label>

                <div className='fv-row col-md-8'>
                  <select
                    className='form-select form-select-solid h-45px p-0 px-4 fs-6 h-ls-48 fs-ls-14'
                    {...formik.getFieldProps("planning_first_day_of_week")}
                  >
                    <option value=''>Select first day...</option>
                    <option value='0'>Sunday</option>
                    <option value='1'>Monday</option>
                    <option value='2'>Tuesday</option>
                    <option value='3'>Wednesday</option>
                    <option value='4'>Thursday</option>
                    <option value='5'>Friday</option>
                    <option className="disabled-option"   disabled value='6'>Saturday</option>
                  </select>
                  {formik.touched.planning_first_day_of_week && formik.errors.planning_first_day_of_week && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block fs-8">{formik.errors.planning_first_day_of_week}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-form-label required fw-bold fs-6 mw-300px mw-xxl-400px fs-ls-14'>Task first day of week</label>

                <div className='fv-row col-md-8'>
                  <select
                    className='form-select form-select-solid h-45px p-0 px-4 fs-6 h-ls-48 fs-ls-14'
                    {...formik.getFieldProps("task_first_day_of_week")}
                  >
                    <option value=''>Select first day...</option>
                    <option value='0'>Sunday</option>
                    <option value='1'>Monday</option>
                    <option value='2'>Tuesday</option>
                    <option value='3'>Wednesday</option>
                    <option value='4'>Thursday</option>
                    <option value='5'>Friday</option>
                    <option value='6'>Saturday</option>
                  </select>
                  {formik.touched.task_first_day_of_week && formik.errors.task_first_day_of_week && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block fs-8">{formik.errors.task_first_day_of_week}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-form-label fw-bold fs-6 mw-300px mw-xxl-400px fs-ls-14'>
                  Default status for plan item task
                </label>
                <div className='fv-row col-md-8'>
                  <select
                    className='form-select form-select-solid h-45px p-0 px-4 fs-6 h-ls-48 fs-ls-14'
                    {...formik.getFieldProps("default_task_status")}
                  >
                    <option value=''>Keep Current</option>
                    {todo_status_taskticket?.map((option: any, i:number) => (
                      <option key={i} value={option?.id}>{option?.name}</option>
                    ))}
                  </select>

                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-form-label fw-bold fs-6 mw-300px mw-xxl-400px fs-ls-14'>
                  Default status for plan item ticket
                </label>
                <div className='fv-row col-md-8'>
                  <select
                    className='form-select form-select-solid h-45px p-0 px-4 fs-6 h-ls-48 fs-ls-14'
                    {...formik.getFieldProps("default_ticket_status")}
                  >
                    <option value=''>Keep Current</option>
                    {todo_status_taskticket?.map((option: any, i:number) => (
                      <option key={i} value={option?.id}>{option?.name}</option>
                    ))}
                  </select>

                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-form-label fw-bold fs-6 mw-300px mw-xxl-400px fs-ls-14'>
                  Default status for plan item servicecall
                </label>
                <div className='fv-row col-md-8'>
                  <select
                    className='form-select form-select-solid h-45px p-0 px-4 fs-6 h-ls-48 fs-ls-14'
                    {...formik.getFieldProps("default_servicecall_status")}
                  >
                    <option value=''>Keep Current</option>
                    {todo_status_serviceCall?.map((option: any, i:number) => (
                      <option key={i} value={option?.id}>{option?.name}</option>
                    ))}
                  </select>

                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-form-label fw-bold fs-6 mw-300px mw-xxl-400px fs-ls-14'>
                  Default worktype for a task
                </label>
                <div className='fv-row col-md-8'>
                  <select className='form-select form-select-solid h-45px p-0 px-4 fs-6 h-ls-48 fs-ls-14'
                    {...formik.getFieldProps("default_task_worktype")}
                  >
                    <option value=''>Keep Current</option>
                    {workgroups?.map((option: any, i:number) => (
                      <option key={i}  value={option?.value}>{option?.text}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-form-label fw-bold fs-6 mw-300px mw-xxl-400px fs-ls-14'>
                  Default worktype for a ticket
                </label>
                <div className='fv-row col-md-8'>
                  <select className='form-select form-select-solid h-45px p-0 px-4 fs-6 h-ls-48 fs-ls-14'
                    {...formik.getFieldProps("default_ticket_worktype")}
                  >
                    <option value=''>Keep Current</option>
                    {workgroups?.map((option: any, i:number) => (
                      <option key={i} value={option?.value}>{option?.text}</option>
                    ))}
                  </select>
                </div>
              </div>

              {/* Timezone field */}
              <div className='row mb-6'>
                <label className='col-form-label fw-bold fs-6 mw-300px mw-xxl-400px fs-ls-14'>
                  Time zone
                </label>
                <div className='fv-row col-md-8'>
                  <select
                    className='form-select form-select-solid h-45px p-0 px-4 fs-6 h-ls-48 fs-ls-14'
                    {...formik.getFieldProps("timezone")}
                  >
                    <option value=''>Select timezone...</option>
                    {offsetTmz?.map((option: any, i:number) => (
                      <option key={i} >{option}</option>
                    ))}
                  </select>
                  {formik.touched.timezone && formik.errors.timezone && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block fs-8">{formik.errors.timezone}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-form-label fw-bold fs-6 mw-300px mw-xxl-400px fs-ls-14'>
                  Start time for planning view
                </label>
                <div className='fv-row col-md-8'>
                  <div className='form-control h-45px p-0 fs-6 h-ls-48 fs-ls-14 seletPicker'>
                    <Datepicker
                      controls={['time']}
                      value={starttime}
                      onChange={onStarttimeChange}
                      touchUi={false}
                      timeFormat={generalSetting?.time_format}
                      class={'styledPicker'}
                      max={endtime}
                    /></div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-form-label fw-bold fs-6 mw-300px mw-xxl-400px fs-ls-14'>
                  End time for planning view
                </label>
                <div className='fv-row col-md-8'>
                  <div className='form-control h-45px p-0 fs-6 h-ls-48 fs-ls-14 seletPicker'>
                    <Datepicker
                      controls={['time']}
                      value={endtime}
                      onChange={onEndtimeChange}
                      touchUi={false}
                      timeFormat={generalSetting?.time_format}
                      class={'styledPicker'}
                      min={starttime}
                    /></div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-form-label fw-bold fs-6 mw-300px mw-xxl-400px fs-ls-14'>
                  Time format for planning view
                </label>

                <div className='fv-row col-md-8'>
                  <select
                    className='form-select form-select-solid h-45px p-0 px-4 fs-6 h-ls-48 fs-ls-14'
                    {...formik.getFieldProps("time_format")}
                  >
                    <option value='HH:mm'>24 hour</option>
                    <option value='hh:mm A'>12 hour</option>
                  </select>
                </div>
              </div>
              {/* Timezone field */}

              {/* Todo filter */}

              <div className='row mb-6'>
                <label className='col-form-label fw-bold fs-6 mw-300px mw-xxl-400px fs-ls-14'>Default filter for type on todolist</label>
                <div className='d-flex fv-row fv-row col-md-8 flex-column'>

                  <div className='form-check form-check-custom form-check-solid mb-2 mt-5'>
                    <input
                      className='form-check-input me-2'
                      {...formik.getFieldProps('default_todofilter')}
                      name='default_todofilter'
                      type='checkbox'
                      value='task'
                      id="task"
                      checked={formik.values?.default_todofilter?.includes("task") ? true : false}

                    />
                    <label className='form-check-label me-5'>
                      <div className='fw-bolder text-gray-800'>Task</div>
                    </label>
                  </div>

                  <div className='form-check form-check-custom form-check-solid mb-2'>
                    <input
                      className='form-check-input me-2'
                      {...formik.getFieldProps('default_todofilter')}
                      name='default_todofilter'
                      type='checkbox'
                      value='ticket'
                      id="ticket"
                      checked={formik.values?.default_todofilter?.includes("ticket") ? true : false}
                    />
                    <label className='form-check-label me-5'>
                      <div className='fw-bolder text-gray-800'>Ticket</div>
                    </label>
                  </div>

                  <div className='form-check form-check-custom form-check-solid mb-2'>
                    <input
                      className='form-check-input me-2'
                      {...formik.getFieldProps('default_todofilter')}
                      name='default_todofilter'
                      type='checkbox'
                      value='serviceCall'
                      id="serviceCall"
                      checked={formik.values?.default_todofilter?.includes("serviceCall") ? true : false}
                    />
                    <label className='form-check-label me-5'>
                      <div className='fw-bolder text-gray-800'>{ AUTOTASK_ENTITY.ServiceCall.title }</div>
                    </label>
                  </div>

                </div>
              </div>

              <div className='row mb-6'>
                    <label className='col-form-label fw-bold fs-16 text-dark mw-300px mw-xxl-400px'>
                    Show skills in planningscreen</label>
                    <div className='d-flex fv-row fv-row col-md-8'>
                      <div className='form-check form-check-custom form-check-solid fs-16'>
                        <input
                          className="form-check-input me-2"
                          type="radio"
                          name="show_skill"
                          value={1}
                          checked={showSkill === true ? true : false}
                          /* onChange={() => {
                            updateData({ allow_overlapping: true });
                          }} */
                          onChange={handleShowSkill}
                        ></input>
                        <label className='form-check-label me-5'>
                          <div className='fw-bolder text-gray-800'>Yes</div>
                        </label>

                        <input
                          className="form-check-input me-2"
                          type="radio"
                          name="show_skill"
                          value={0}
                          checked={showSkill === false ? true : false}
                          onChange={handleShowSkill}
                        ></input>
                        < label className='form-check-label'>
                          <div className='fw-bolder text-gray-800'>No</div>
                        </label>
                      </div>
                    </div>
                  </div>

                  {/* <div className='row mb-6'>
                    <label className='col-form-label fw-bold fs-16 text-dark mw-300px mw-xxl-400px'>Display hours on month/year</label>
                      <div className='d-flex fv-row fv-row col-md-8 flex-column'>
                          {todo_status_serviceCall?.map((option: any) => (
                              <div className='form-check form-check-custom form-check-solid mb-3 mt-5 fs-16'>
                                <><input
                                      className='form-check-input me-2'
                                      {...formik.getFieldProps('default_monthyearhours')}
                                      name='default_monthyearhours'
                                      type='checkbox'
                                      value={option?.status}
                                      id="task"
                                      checked={formik.values?.default_monthyearhours?.includes(option?.status) ? true : false}
                                  />
                                  <label>{option?.status}</label>
                                </>
                              </div>
                          ))}
                      </div>
                  </div> */}

            </div>
          </form>
        </div>
      </div >
      </div></>
  );
}
export {GeneralSettingView}
