import { Datepicker, alert } from '@mobiscroll/react';
import moment from 'moment-timezone';
import React, { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import Select from "react-select";
import { KTSVG } from '../../../../_metronic/helpers';
import { getCompany, getDepartments, getlineOfBusiness } from '../../../../_metronic/requests/FilterRequest';
import { getGeneralSetting, getLocationDetails } from '../../../../_metronic/requests/GeneralSettingRequest';
import { CopyProjectDetails, createProjectApi, getContracts, getOpportunities, getProjectDetails, getProjectType, getResources, updateProjectApi } from '../../../../_metronic/requests/PlanningRequest';
import { Loading } from '../../../components/loading/Loading';
import SelectCustomLockOption, { formatOptionLabel } from '../../../components/modals/selectCustomLockOption';
import ProjectBreakdown from './ProjectBreakdown';

const EditProjectPage = () => {
   
  const navigate = useNavigate();
  let { id } = useParams();
  const { data: contractList } = useQuery(
        "project-contracts",
        () => {
          return getContracts("");
        },
        { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
      );
    

  const {
        data: peoples,
      } = useQuery(
        "project-people",
        () => {
          // debugger
          return getResources("");
        },
        { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
      );


    const {
        data: departments,
      } = useQuery(
        "project-department-filter",
        () => {
          // debugger
          return getDepartments();
        },
        { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
      );
    
    const { data: opportunitiesList } = useQuery(
        "project-opportunities",
        () => {
          return getOpportunities("");
        },
        { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
    );

    const { data: projectTypeList } = useQuery(
        "project-type",
        () => {
          return getProjectType("");
        },
        { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
      );
    

  const {
    data: companies,
    isLoading: isCompanyLoading,
      } = useQuery(
        "project-company",
        () => {
          // debugger
          return getCompany();
        },
        { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
      );

  const { data: lineOfBusiness } = useQuery(
        "lineOfBusiness",
        () => {
          // debugger
          return getlineOfBusiness();
        },
        { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
      );


  const { data: generalSetting } = useQuery(
        `planning-resource-setting`,
        async () => {
          return await getGeneralSetting("");
        },
        {
          cacheTime: 0,
          keepPreviousData: true,
          refetchOnWindowFocus: false,
        }
      );

      const { data: location } = useQuery(
        "location-details",
        async () => {
          return await getLocationDetails();
        },
        { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
      );
      

const [isdisabled, setDisabled] = useState<any>(false);
const [isloading, setLoading] = useState<boolean>(false);

  const [tasks, setTasks] = useState<any>([]);
  const [phases, setPhases] = useState<any>([]);
  const [showCreateError, setshowCreateError] = useState<any>(false);
  const [projectData, setProjectData] = useState<any>({
    projectName: "",
    companyID: null,
    startDateTime: "",
    endDateTime: "",
    organizationalLevelAssociationID: null,
    projectType: null,
    purchaseOrderNumber: "",
    extProjectNumber: "",
    contractID: null,
    opportunityID: null,
    department: null,
    projectLeadResourceID: null,
    laborEstimatedCosts: 0,
    laborEstimatedMarginPercentage: 0,
    laborEstimatedRevenue: 0,
    projectCostEstimatedMarginPercentage: 0,
    projectCostsBudget: 0,
    projectCostsRevenue: 0,
    totalRevenue: 0,
    totalCost: 0,
    totalProfit: 0,
    totalMargin: 0,
    isDraft :false,
  });

  const time_format: any = generalSetting?.time_format;
  const timezone: any = generalSetting?.timezone;

//Work  when change the project feilds details 
const createpopupvalidation = (event: any,internal=false) => {
    let valid: any = false;
    
    if(event?.has_license === true){
      valid = true;
    }
    if (!event.startDateTime) {
      valid = true;
    }
    if (!event.endDateTime) {
      valid = true;
    }
    if (event.extProjectNumber && event.extProjectNumber?.length > 50) {
      valid = true;
    }

    if (event.purchaseOrderNumber && event.purchaseOrderNumber?.length > 50) {
      valid = true;
    }

    if (!event.projectName || event?.projectName?.length > 255 || event.has_license || event.projectName.trim() ==="") {
      valid = true;
    }
    if (!event.projectType) {
      valid = true;
    }
    if (!event.companyID && event.companyID !== 0) {
      valid = true;
    }
    setshowCreateError(true);
    if(internal === true){
        return valid
    }
  };

  let hideOptions = generalSetting?.hide_options;
  const handleHideOptionalFunc = (item: any, value: any) => {
    const options = !hideOptions
      ? item
      : item?.filter(
        (trole: any) =>
          parseInt(trole.value) === parseInt(value) || trole.isActive === true
      );

    return options;
  };
  
const changeCreateProjectValue = useCallback((e: any, type: any, phase_id: any = 0) => {
  let event: any = JSON.parse(JSON.stringify(projectData));

  switch (type) {
    case "projectLeadResourceID": {
      let tworktype = e[e.length - 1];
      event.projectLeadResourceID = tworktype ? tworktype.value : "";
      event.has_license = tworktype
        ? tworktype.has_license === false
          ? true
          : false
        : false;
      break;
    }

    case "startDateTime": {
      if (e.value != null) {
        let start = moment
          .tz(moment(e.value).format("YYYY-MM-DDTHH:mm"), timezone)
          .format("YYYY-MM-DD");
        event.startDateTime = start;
      }
      break;
    }

    case "endDateTime": {
      if (e.value != null) {
        let end = moment
          .tz(moment(e.value).format("YYYY-MM-DDTHH:mm"), timezone)
          .format("YYYY-MM-DD");
        event.endDateTime = end;
      }
      break;
    }

    case "projectName": {
      if (e.target.value?.length <= 255) {
        event.projectName = e.target.value;
      } else {
        event.projectName = e.target.value?.substr(0, 255);
      }
      break;
    }

    case "company": {
      let tpriority = e[e.length - 1];
      event.companyID = tpriority ? tpriority.value : null;
      event.opportunityID = "";
      event.contractID = "";
      break;
    }

    case "purchaseOrderNumber": {
      if (e.target.value?.length <= 255) {
        event.purchaseOrderNumber = e.target.value.trim();
      } else {
        event.purchaseOrderNumber = e.target.value?.substr(0, 50).trim();
      }
      break;
    }

    case "lineofbusiness": {
      let tpriority = e[e.length - 1];
      event.organizationalLevelAssociationID = tpriority ? tpriority.value : "";
      break;
    }

    case "projectType": {
      let tworktype = e[e.length - 1];
      event.projectType = tworktype ? tworktype.value : "";
      break;
    }

    case "extProjectNumber": {
      if (e.target.value?.length <= 255) {
        event.extProjectNumber = e.target.value.trim();
      } else {
        event.extProjectNumber = e.target.value?.substr(0, 50).trim();
      }
      break;
    }

    case "contractID": {
      let tworktype = e[e.length - 1];
      event.contractID = tworktype ? tworktype.value : "";
      break;
    }

    case "opportunityID": {
      let tworktype = e[e.length - 1];
      event.opportunityID = tworktype ? tworktype.value : "";
      break;
    }

    case "department": {
      let tworktype = e[e.length - 1];
      event.department = tworktype ? tworktype.value : "";
      break;
    }

    case "laborEstimatedRevenue": {
      event.laborEstimatedRevenue = parseFloat(e.target.value);
      break;
    }

    case "laborEstimatedCosts": {
      event.laborEstimatedCosts = parseFloat(e.target.value);
      break;
    }

    case "projectCostsRevenue": {
      event.projectCostsRevenue = parseFloat(e.target.value);
      break;
    }

    case "projectCostsBudget": {
      event.projectCostsBudget = parseFloat(e.target.value);
      break;
    }
  }

  // labor calculation
  event.laborEstimatedRevenue = parseFloat(event.laborEstimatedRevenue || 0);
  event.laborEstimatedCosts = parseFloat(event.laborEstimatedCosts || 0);
  event.projectCostsRevenue = parseFloat(event.projectCostsRevenue || 0);
  event.projectCostsBudget = parseFloat(event.projectCostsBudget || 0);

  event.laborProfit = parseFloat(event.laborEstimatedRevenue || 0) - parseFloat(event.laborEstimatedCosts || 0);
  event.laborEstimatedMarginPercentage = event.laborProfit
    ? ((event.laborEstimatedRevenue - event.laborEstimatedCosts) / event.laborEstimatedRevenue) * 100
    : 0;

  event.chargesProfit = parseFloat(event.projectCostsRevenue || 0) - parseFloat(event.projectCostsBudget || 0);
  event.projectCostEstimatedMarginPercentage = event.chargesProfit
    ? ((event.projectCostsRevenue - event.projectCostsBudget) / event.projectCostsRevenue) * 100
    : 0;

  event.totalRevenue = parseFloat(event.laborEstimatedRevenue || 0) + parseFloat(event.projectCostsRevenue || 0);
  event.totalCost = parseFloat(event.laborEstimatedCosts || 0) + parseFloat(event.projectCostsBudget || 0);
  event.totalProfit = event.totalRevenue - event.totalCost;
  event.totalMargin = event.totalProfit
    ? ((event.totalRevenue - event?.totalCost) / event.totalRevenue) * 100
    : 0;

  // Convert to two decimal places
  event.laborEstimatedRevenue = Math.round(event.laborEstimatedRevenue * 100) / 100;
  event.laborEstimatedCosts = Math.round(event.laborEstimatedCosts * 100) / 100;
  event.projectCostsRevenue = Math.round(event.projectCostsRevenue * 100) / 100;
  event.projectCostsBudget = Math.round(event.projectCostsBudget * 100) / 100;
  event.laborProfit = Math.round(event.laborProfit * 100) / 100;
  event.laborEstimatedMarginPercentage = Math.round((event.laborEstimatedMarginPercentage || 0) * 100) / 100;
  event.chargesProfit = Math.round(event.chargesProfit * 100) / 100;
  event.projectCostEstimatedMarginPercentage = Math.round((event.projectCostEstimatedMarginPercentage || 0) * 100) / 100;
  event.totalRevenue = Math.round(event.totalRevenue * 100) / 100;
  event.totalCost = Math.round(event.totalCost * 100) / 100;
  event.totalProfit = Math.round(event.totalProfit * 100) / 100;
  event.totalMargin = Math.round(event.totalMargin * 100) / 100;

  createpopupvalidation(event);
  setshowCreateError(true);
  setProjectData(event);
}, [projectData, generalSetting, timezone]);


useEffect(() => {
  if (id) {
    const fetchProjectDetails = async () => {
      try {
        setLoading(true);
        const response = await getProjectDetails(id);
        setLoading(false);
        let fetchedData = {
          ...response?.data?.data,
          laborEstimatedRevenue: parseFloat(response?.data?.data?.laborEstimatedRevenue || 0),
          laborEstimatedCosts: parseFloat(response?.data?.data?.laborEstimatedCosts || 0),
          projectCostsRevenue: parseFloat(response?.data?.data?.projectCostsRevenue || 0),
          projectCostsBudget: parseFloat(response?.data?.data?.projectCostsBudget || 0)
        };
        
        fetchedData.id = fetchedData?.autotask_id;
        fetchedData.laborProfit = fetchedData?.laborEstimatedRevenue - fetchedData?.laborEstimatedCosts;
        fetchedData.laborEstimatedMarginPercentage = fetchedData?.laborProfit ? (fetchedData?.laborProfit / fetchedData?.laborEstimatedRevenue) * 100 : 0;
        fetchedData.chargesProfit = fetchedData?.projectCostsRevenue - fetchedData?.projectCostsBudget;
        fetchedData.projectCostEstimatedMarginPercentage = fetchedData?.chargesProfit ? (fetchedData?.chargesProfit / fetchedData?.projectCostsRevenue) * 100 : 0;
        fetchedData.totalRevenue = fetchedData.laborEstimatedRevenue + fetchedData?.projectCostsRevenue;
        fetchedData.totalCost = fetchedData?.laborEstimatedCosts + fetchedData?.projectCostsBudget;
        fetchedData.totalProfit = fetchedData?.totalRevenue - fetchedData?.totalCost;
        fetchedData.totalMargin = fetchedData?.totalProfit ? (fetchedData?.totalProfit / fetchedData?.totalRevenue) * 100 : 0;

        setProjectData(prevState => ({
          ...prevState,
          ...fetchedData
        }));
      } catch (error) {
        setLoading(false);
      }
    };

    fetchProjectDetails();
  }
}, [id]);

const CustomOption = ({ data, ...props }: any) => {
    return (<SelectCustomLockOption
    option={data}
    props={props}
    />)
  };
  const CreateProjectAutotask = useCallback(async () => {
    const projectDataWithTasksAndPhases = {
      ...projectData,
      tasks,
      phases,
    };
      if(!createpopupvalidation(projectDataWithTasksAndPhases, true)){
        setDisabled(true)
        setLoading(true)
        if (projectDataWithTasksAndPhases.projectName !== "") {
            let res: any;
            if (id) {
              if (projectDataWithTasksAndPhases?.isDraft) {
                res = await createProjectApi(projectDataWithTasksAndPhases);
                let alertMessages = "Project created successfully."
                if (res?.data && res?.data?.message) {
                  alertMessages = "Project creation failed.";
                } 

                  alert({
                    message: <h2>{alertMessages}</h2>,
                      cssClass: "mbsc-alert-title",
                      callback: function (result) {
                          navigate("/apps/projects/list");
                      },
                  });
              } else {
                res = await updateProjectApi(projectDataWithTasksAndPhases);
                let alertMessages = "Project updated successfully."
                if (res?.data && res?.data?.message) {
                  alertMessages = "Project update failed."
                } 
                  alert({
                    message: <h2>{alertMessages}</h2>,
                      cssClass: "mbsc-alert-title",
                      callback: function (result) {
                          navigate("/apps/projects/list");
                      },
                  });
              }
          } else {
              res = await createProjectApi(projectDataWithTasksAndPhases);
              let alertMessages = "Project created successfully."
              if (res?.data && res?.data?.message) {
                alertMessages = "Project creation failed."
              } 

              alert({
                message: <h2>{alertMessages}</h2>, 
                  cssClass: "mbsc-alert-title",
                  callback: function (result) {
                      navigate("/apps/projects/list");
                  },
              });
          }
            setDisabled(false)
            setLoading(false)
        }
        else {
        setDisabled(false)
        setLoading(false)
        }
    }
  } , [projectData]);

    const checkValidation = (peoples, projectLeadResourceID) => {
      return peoples?.some(
        (twork) => twork.isActive && twork.id === projectLeadResourceID && twork.has_license
      );
    };

    useEffect(() => {
       if (id) {  
        const leadResourceID = projectData?.projectLeadResourceID;
        const isInvalidLead = leadResourceID == "" || leadResourceID == null;    
         const isValid = !isInvalidLead && checkValidation(peoples, leadResourceID);
        setDisabled(isInvalidLead ? false : !isValid);
    }
    }, [id, peoples, projectData?.projectLeadResourceID]);
    
  const handlecopyData = async (id:any) => {
    try {
      setLoading(true);
      if (id) {
          const response = await CopyProjectDetails(id);
           const responseID =  response?.data?.id ; 
            if (responseID) { 
              navigate(`/apps/projects/list/edit/${responseID}`, { replace: true });
            } 
          setLoading(false);
        }
    } catch (error) {
      setLoading(false);
        console.error("Error in handleCopyData:", error);
    } finally {
      setLoading(false);
      console.log("finally----------------------->"); 
    }
}


  return (
    <div id="kt_project_list_edit" className="p-0 notifyHeight h-100">
        <Loading
        isLoading={isloading}
        isHideSpinner={true}
        className="splash-screen"
      />
      <div className='card '>
            {/* Back button & End Project text & Save changes button */}
            <div className="card-header d-flex align-items-center justify-content-between min-h-65px">
                <div className="card-title">
                    <div className='d-flex align-items-center'>
                        <button
                        className="w-32px h-32px rounded-8px bg-gray-100 border-0 p-0"
                        style={{ border: "1px solid #E4E4E4", borderStyle: "solid" }}
                        onClick={() => {
                            navigate("/apps/projects/list");
                        }}
                        >
                            <KTSVG
                            path="/media/icons/duotune/new-icons/back-arrow.svg"
                            className="text-darkblue"
                            svgClassName="w-16px h-auto"
                            />
                        </button>
                        <h3 className="text-dark fw-bold ms-5 fs-20px">{id ? "Edit Project" : "Create  Project"}</h3>
                       {projectData?.isDraft && <h3 className="text-dark fw-bold ms-3 fs-20px">[Draft]</h3>}
                    </div>
                </div>
                <div>
                    <button
                        type="submit"
                        className={`btn btn-primary h-32px py-0 px-5 rounded-8px fs-16px fw-bold flex-1 ${isdisabled ?  "isofflinebutton" : ""}`}
                        onClick={CreateProjectAutotask}
                        disabled={isdisabled}
                    >
                    Save Changes
                    </button>
                </div>
            </div>

            {/* 1 Project selected */}
            <div className='card-body pt-0 bg-gray-100' style={{ maxHeight: 'calc(100% - 65px)' }}>
                

                {/* 1 Project selected & Make a copy button start */}
                <div className="d-flex py-7 align-items-center justify-content-between">
                    <div className='d-inline-flex align-items-center'>
                        <span className="me-4 fs-20px fw-bold">1 Project Selected</span>
                        <span>
                            <KTSVG
                                path="/media/icons/duotune/new-icons/pencil-icon.svg"
                                className="text-primary"
                                svgClassName="w-16px h-auto"
                            />
                        </span>
                    </div>
                   
                   {(!projectData?.isDraft && id) && (
                    <div>
                        <button
                        className="bg-white text-primary border-0 h-32px py-0 px-4 rounded-4px fs-16px fw-normal"
                        onClick={() => handlecopyData(id)}
                        disabled={isdisabled}
                        >
                            <KTSVG
                            path="/media/icons/duotune/new-icons/copy-new.svg"
                            className="text-primary me-2"
                            svgClassName="w-16px h-auto"
                            />
                            <span>Make a copy</span>
                        </button>
                    </div>
                   )} 
                </div>
                {/* 1 Project selected & Make a copy button end */}

                <div className='bg-white rounded-8px px-12 py-10 overflow-auto' style={{ boxShadow: '0 0 2px 0 rgba(0,0,0,0.1)', maxHeight: 'calc(100% - 80px)' }}>
                    <div className='accordion' id='kt_accordion_proDetails'>
                        <div className='accordion-item bg-transparent border-0 shadow-none'>

                            <h2 className="accordion-header mb-0" id="kt_accordion_1_header_1">
                                <button className="border-0 p-0 bg-transparent text-dark d-inline-flex align-items-center" type="button" data-bs-toggle="collapse" data-bs-target="#kt_accordion_1_body_1" aria-expanded="true" aria-controls="kt_accordion_1_body_1">                                        
                                    <KTSVG
                                        path="/media/icons/duotune/new-icons/down-arrow-trans.svg"
                                        className="text-dark w-16px me-1"
                                        svgClassName="w-12px h-auto"
                                    />
                                    <span className='fs-16px'>Project Details</span>                                    
                                </button>
                            </h2>

                            <div id="kt_accordion_1_body_1" className="accordion-collapse collapse show border-0" aria-labelledby="kt_accordion_1_header_1" data-bs-parent="#kt_accordion_1">
                                <div className="accordion-body p-0 pt-8">

                                {/* Form box 1 start */}
                                <div className="row">
                                    <div className={`step-1 fs-16 mw-1000px`}>
                                        <div className="row row40 row-cols-2">
                                            <div className="col d-flex flex-column mb-23px">
                                                <strong className="fw-bold mb-4px lh-24px d-block required">
                                                Project name
                                                </strong>
                                                <input
                                                  value={projectData?.projectName}
                                                type="text"
                                                placeholder='Project name'
                                                onChange={(e: any) =>
                                                    changeCreateProjectValue(e, "projectName")
                                                }
                                                id="create-project-projectName"
                                                className="form-control form-control-solid h-32px px-8px rounded-4px py-0 bg-white border-graybor fw-normal fs-16 text-dark"
                                                />
                                                {showCreateError &&
                                                (!projectData?.projectName ||
                                                    projectData?.projectName.trim() === "") && (
                                                    <>
                                                    <div className="w-100" style={{ color: "#f00" }}>
                                                        Please enter project name.
                                                    </div>
                                                    </>
                                                )}
                                            </div>

                                            <div className={`col d-flex flex-column mb-23px`}>
                                                <strong className="fw-bold mb-4px lh-24px d-block required">
                                                Company
                                                </strong>
                                                {companies && (
                                                <Select
                                                    isDisabled ={(id && !projectData?.isDraft) ? true : false}
                                                    value={companies?.filter(
                                                    (twork: any) => twork.value === projectData?.companyID
                                                    )}
                                                    isMulti
                                                    isClearable={false}
                                                    name="Issues"
                                                    id="create-project-company"
                                                    placeholder="Select company"
                                                    options={handleHideOptionalFunc(
                                                      companies,
                                                      projectData?.companyID
                                                    )}                                                    
                                                    className={`basic-multi-select ${(id && !projectData?.isDraft) ? 'disablecursor' : ""}`}
                                                    classNamePrefix="select"
                                                    isOptionDisabled={(option: any) => !option.isActive}
                                                    onChange={(e: any) =>
                                                    changeCreateProjectValue(e, "company")
                                                    }
                                                    
                                                />
                                                 )} 
                                                {showCreateError &&
                                                (typeof projectData?.companyID === "undefined" ||
                                                    projectData?.companyID === "" ||
                                                    projectData?.companyID === null) && (
                                                    <>
                                                    <div className="w-100" style={{ color: "#f00" }}>
                                                        Please select company.
                                                    </div>
                                                    </>
                                                )}
                                            </div>

                                            <div className="col">
                                                <div className="row row-cols-2 row40">
                                                    <div className={`col d-flex flex-column`}>
                                                        <strong className="fw-bold mb-4px lh-24px d-block required">
                                                        Start date
                                                        </strong>
                                                        <span
                                                        className="align-items-center position-relative flex-fill creatDatepicker"
                                                        id="create-project-startDateTime"
                                                        >
                                                        <Datepicker
                                                            controls={["calendar"]}
                                                            touchUi={true}
                                                            buttons={[]}
                                                            disabled={id ? true : false}
                                                            display="anchored"
                                                            id="create-project-datepicker-startDateTime"
                                                            showOverlay={false}
                                                            dateFormat="DD-MM-YYYY"
                                                            onChange={(e: any) =>
                                                            changeCreateProjectValue(e, "startDateTime")
                                                            }
                                                            timeFormat={time_format}
                                                            max={
                                                            projectData?.endDateTime
                                                                ? moment(projectData?.endDateTime).format(
                                                                "YYYY-MM-DD"
                                                                )
                                                                : ""
                                                            }
                                                            value={
                                                            projectData?.startDateTime
                                                                ? moment(projectData?.startDateTime).format(
                                                                "YYYY-MM-DD"
                                                                )
                                                                : ""
                                                            }
                                                            className={`${id ? 'disablecursor' : ''}`}
                                                        />
                                                        <KTSVG
                                                            path="/media/icons/duotune/new-icons/event-calendar.svg"
                                                            className="m-0 position-absolute end-0 top-0 bottom-0 pe-none w-21px d-inline-flex align-items-center mh-32px"
                                                            svgClassName="w-13px h-auto"
                                                        />
                                                        </span>
                                                        {showCreateError &&
                                                        (!projectData?.startDateTime ||
                                                            projectData?.startDateTime == "") && (
                                                            <>
                                                            <div className="w-100" style={{ color: "#f00" }}>
                                                                Please select start date.
                                                            </div>
                                                            </>
                                                        )}
                                                    </div>

                                                    <div className="col d-flex flex-column">
                                                        <strong className="fw-bold mb-4px lh-24px d-block required">
                                                        End date
                                                        </strong>
                                                        <span
                                                        className="align-items-center position-relative flex-fill creatDatepicker"
                                                        id="create-project-endDateTime"
                                                        >
                                                        <Datepicker
                                                            controls={["calendar"]}
                                                            touchUi={true}
                                                            buttons={[]}
                                                            display="anchored"
                                                            showOverlay={false}
                                                            dateFormat="DD-MM-YYYY"
                                                            id="create-project-datepicker-endDateTime"
                                                            timeFormat={time_format}
                                                            onChange={(e: any) =>
                                                            changeCreateProjectValue(e, "endDateTime")
                                                            }
                                                            min={
                                                            projectData?.startDateTime
                                                                ? moment(projectData?.startDateTime).format(
                                                                "YYYY-MM-DD"
                                                                )
                                                                : ""
                                                            }
                                                            value={
                                                            projectData?.endDateTime
                                                                ? moment(projectData?.endDateTime).format(
                                                                "YYYY-MM-DD"
                                                                )
                                                                : ""
                                                            }
                                                        />
                                                        <KTSVG
                                                            path="/media/icons/duotune/new-icons/event-calendar.svg"
                                                            className="m-0 position-absolute end-0 top-0 bottom-0 pe-none w-21px d-inline-flex align-items-center mh-32px"
                                                            svgClassName="w-13px h-auto"
                                                        />
                                                        </span>
                                                        {showCreateError &&
                                                        (!projectData?.endDateTime ||
                                                            projectData?.endDateTime == "") && (
                                                            <>
                                                            <div className="w-100" style={{ color: "#f00" }}>
                                                                Please select end date.
                                                            </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col d-flex flex-column mb-23px">
                                                <strong className="fw-bold mb-4px lh-24px d-block">
                                                Line of Business
                                                </strong>
                                                {lineOfBusiness?.data && (
                                                <Select
                                                    value={lineOfBusiness?.data?.filter(
                                                    (twork: any) =>
                                                        twork.value ===
                                                        projectData?.organizationalLevelAssociationID
                                                    )}
                                                    isMulti
                                                    isClearable={false}
                                                    name="Issues"
                                                    id="create-project-issue"
                                                    placeholder="Select line of business"
                                                    options={handleHideOptionalFunc(
                                                      lineOfBusiness?.data,
                                                      projectData?.organizationalLevelAssociationID
                                                    )}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    isOptionDisabled={(option: any) => !option.isActive}
                                                    onChange={(e: any) =>
                                                    changeCreateProjectValue(e, "lineofbusiness")
                                                    }
                                                />
                                                 )} 
                                                {/* {showCreateError && projectData?.lineofbusiness == "" && (
                                                    <span style={{ color: "red" }}>Please select line of business.</span>
                                                    )}                    */}
                                            </div>

                                            <div className="col d-flex flex-column mb-23px">
                                                <strong className="fw-bold mb-4px lh-24px d-block required">
                                                Type
                                                </strong>
                                                {projectTypeList && (
                                                <Select
                                                    value={projectTypeList?.filter(
                                                    (twork: any) =>
                                                        twork.value === projectData?.projectType
                                                    )}
                                                    isMulti
                                                    isClearable={false}
                                                    name="project type"
                                                    id="create-project-type"
                                                    placeholder="Select project type"
                                                    options={handleHideOptionalFunc(
                                                      projectTypeList,
                                                      projectData?.projectType
                                                    )}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    isOptionDisabled={(option: any) => !option.isActive}
                                                    onChange={(e: any) =>
                                                        changeCreateProjectValue(e, "projectType")
                                                      }
                                                />
                                                  )} 
                                                {showCreateError &&
                                                (projectData?.projectType === "" ||
                                                    !projectData?.projectType) && (
                                                    <span style={{ color: "red" }}>
                                                    Please select project type.
                                                    </span>
                                                )}
                                            </div>

                                            <div className="col">
                                                <div className="row row-cols-2 row40">
                                                    <div className="col d-flex flex-column mb-23px">
                                                        <strong className="fw-bold mb-4px lh-24px d-block">
                                                        Purchase order number
                                                        </strong>
                                                        <input
                                                        type="text"
                                                        className="form-control form-control-solid h-32px px-8px rounded-4px py-0 bg-white border-graybor fw-normal fs-16 text-dark"
                                                        placeholder="Enter purchase order number"
                                                        id="create-project-purchaseOrderNumber"
                                                        onChange={(e: any) =>
                                                            changeCreateProjectValue(e, "purchaseOrderNumber")
                                                        }
                                                        value={projectData?.purchaseOrderNumber}
                                                        />
                                                        {/* {showCreateError &&
                                                        projectData?.purchaseOrderNumber !== "" &&
                                                        projectData?.purchaseOrderNumber?.length > 50 && (
                                                            <span style={{ color: "red" }}>
                                                            Should not contain more than 50 characters.
                                                            </span>
                                                        )} */}
                                                    </div>

                                                    <div className="col d-flex flex-column mb-23px">
                                                        <strong className="fw-bold mb-4px lh-24px d-block">
                                                        External project number
                                                        </strong>
                                                        <input
                                                        type="text"
                                                        className="form-control form-control-solid h-32px px-8px rounded-4px py-0 bg-white border-graybor fw-normal fs-16 text-dark"
                                                        placeholder="Enter external project number"
                                                        id="create-project-externalOrderNumber"
                                                        value={projectData?.extProjectNumber}

                                                        onChange={(e: any) =>
                                                            changeCreateProjectValue(e, "extProjectNumber")
                                                        }

                                                        />
                                                        {/* {showCreateError &&
                                                        projectData?.extProjectNumber?.length > 50 && (
                                                            <>
                                                            <span style={{ color: "red" }}>
                                                                Should not contain more than 50 characters.
                                                            </span>
                                                            </>
                                                        )} */}
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Border column start */}
                                            <div className="col col-12 borderCol h-1px mb-16px"><span className='d-block h-100' style={{ backgroundColor: "#E2E2E2" }}></span></div>
                                            {/* Border column end */}

                                            <div className="col">
                                                <div className="row row-cols-2 row40">
                                                    <div className="col d-flex flex-column mb-23px">
                                                        <strong className="fw-bold mb-4px lh-24px d-block">
                                                        Contract
                                                        </strong>
                                {(contractList !== null && contractList !== undefined) && (
                                                        <Select
                                    value={contractList?.filter(
                                                            (twork: any) =>
                                        twork.value === parseInt(projectData?.contractID)
                                                            )}
                                                            isMulti
                                                            isClearable={false}
                                                            name="contract"
                                                            id="create-project-contract"
                                                            placeholder="Select contract"
                                                            options={
                                                              hideOptions
                                                                ? contractList?.filter(
                                                                  (trole: any) =>
                                                                  ((parseInt(trole.value) ===
                                                                    parseInt(projectData?.contractID)) || (trole.companyID === projectData?.companyID &&
                                                                    trole.isActive === true)
                                                                ))
                                                                : contractList?.filter(
                                                                  (twork: any) =>
                                                                      twork.companyID == projectData?.companyID
                                                                  )
                                                            }
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                            isOptionDisabled={(option: any) => !option.isActive}
                                                            onChange={(e: any) =>
                                                            changeCreateProjectValue(e, "contractID")
                                                            }
                                                        />
                                )}  
                                                    </div>

                                                    <div className="col d-flex flex-column mb-23px">
                                                        <strong className="fw-bold mb-4px lh-24px d-block">
                                                        Opportunity
                                                        </strong>
                                                        {opportunitiesList && (
                                                        <Select
                                                            value={opportunitiesList?.filter(
                                                            (twork: any) =>
                                                                twork.value == projectData?.opportunityID
                                                            )}
                                                            isMulti
                                                            isClearable={false}
                                                            name="opportunity"
                                                            placeholder="Select opportunity"
                                                            id="create-project-opportunity"
                                                            options={
                                                              hideOptions
                                                                ? opportunitiesList?.filter(
                                                                  (trole: any) =>
                                                                    ((parseInt(trole.value) ===
                                                                      parseInt(projectData?.opportunityID)) || (trole.companyID === projectData?.companyID && trole.isActive === true))
                                                                )
                                                                : opportunitiesList?.filter(
                                                                  (twork: any) =>
                                                                      twork.companyID === projectData?.companyID
                                                                  )
                                                            }
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                            isOptionDisabled={(option: any) => !option.isActive}
                                                            onChange={(e: any) =>
                                                            changeCreateProjectValue(e, "opportunityID")
                                                            }
                                                        />
                                                        )} 
                                                    </div>

                                                </div>
                                            </div>                                                    

                                            <div className="col d-flex flex-column mb-23px">
                                                <strong className="fw-bold mb-4px lh-24px d-block">
                                                Department
                                                </strong>
                                                {departments?.items && (
                                                <Select
                                                    value={departments?.items?.filter(
                                                    (twork: any) =>
                                                        twork.value == projectData?.department
                                                    )}
                                                    isMulti
                                                    isClearable={false}
                                                    name="project type"
                                                    placeholder="Select department"
                                                    id="create-project-department"
                                                    options={departments?.items}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    onChange={(e: any) =>
                                                    changeCreateProjectValue(e, "department")
                                                    }
                                                />
                                                    )} 
                                                {/* {showCreateError && projectData?.department == "" && (
                                                    <span style={{ color: "red" }}>Please select department.</span>
                                                )}                                     */}
                                            </div>

                                            <div className="col d-flex flex-column mb-23px">
                                                <strong className="fw-bold mb-4px lh-24px d-block ">
                                                Project lead
                                                </strong>
                                                {peoples && (
                                                <Select
                                                    value={peoples ? peoples?.filter(
                                                    (twork: any) =>
                                                        twork.value == projectData?.projectLeadResourceID
                                                    ) :null}
                                                    isMulti
                                                    isClearable={false}
                                                    name="project type"
                                                    id="create-project-type"
                                                    placeholder="Select project lead"
                                                    options={handleHideOptionalFunc(
                                                      peoples,
                                                      projectData?.projectLeadResourceID
                                                    )}
                                                    className="basic-multi-select"
                                                    components={{ Option: CustomOption }}
                                                    formatOptionLabel={formatOptionLabel}
                                                    classNamePrefix="select"
                                                   isOptionDisabled={(option: any) => !option.isActive || !option.has_license}

                                                    onChange={(e: any) =>
                                                        changeCreateProjectValue(
                                                          e,
                                                          "projectLeadResourceID"
                                                        )
                                                      }
                                                />
                                                 )} 
                                            </div>

                                            {/* Border column start */}
                                            <div className="col col-12 borderCol h-1px mb-36px"><span className='d-block h-100' style={{ backgroundColor: "#E2E2E2" }}></span></div>
                                          
                                            {/* Estimated revenue/costs start */}
                                            <div className="col">

                                                {/* Top label start */}
                                                <div className='d-flex align-items-center'>
                                                    <strong className="fw-bold lh-24px d-block min-w-75px"></strong>
                                                    <div className='flex-fill'>
                                                        <div className='row row24 row-cols-4'>
                                                            <div className="col">
                                                                <strong className="fw-bold mb-4px lh-24px d-block">Revenue</strong>
                                                            </div>
                                                            <div className="col">
                                                                <strong className="fw-bold mb-4px lh-24px d-block">Costs</strong>
                                                            </div>
                                                            <div className="col">
                                                                <strong className="fw-bold mb-4px lh-24px d-block">Profit</strong>
                                                            </div>
                                                            <div className="col">
                                                                <strong className="fw-bold mb-4px lh-24px d-block">Margin</strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>        
                                                {/* Top label end */}

                                                {/* Labor fields start */}
                                                <div className='d-flex align-items-center mb-24px'>
                                                    <strong className="fw-bold lh-24px d-block min-w-75px">Labor</strong>
                                                    <div className='flex-fill'>
                                                        <div className='row row24 row-cols-4'>
                                                            <div className="col">
                                                                <input
                                                                type="number"
                                                                id="create-project-laborEstimatedRevenue"
                                                                onChange={(e: any) =>
                                                                    changeCreateProjectValue(e, "laborEstimatedRevenue")
                                                                }
                                                                value={projectData.laborEstimatedRevenue}
                                                                className="form-control form-control-solid h-32px px-8px rounded-4px py-0 bg-white border-graybor fw-normal fs-16 text-dark"
                                                                placeholder="0.00"
                                                                onBlur={(e: any) => changeCreateProjectValue(e, "")}
                                                                />
                                                            </div>
                                                            <div className="col">
                                                                <input
                                                                type="number"
                                                                id="create-project-laborEstimatedCosts"
                                                                onChange={(e: any) =>
                                                                    changeCreateProjectValue(e, "laborEstimatedCosts")
                                                                }
                                                                value={projectData?.laborEstimatedCosts}
                                                                className="form-control form-control-solid h-32px px-8px rounded-4px py-0 bg-white border-graybor fw-normal fs-16 text-dark"
                                                                placeholder="0.00"
                                                                onBlur={(e: any) => changeCreateProjectValue(e, "")}
                                                                />
                                                            </div>
                                                            <div className="col">
                                                                <input
                                                                value={
                                                                    isNaN(projectData?.laborProfit)
                                                                    ? 0
                                                                    : projectData?.laborProfit
                                                                }
                                                                className="form-control form-control-solid h-32px px-8px rounded-4px py-0 bg-white border-graybor fw-normal fs-16 text-dark"
                                                                id="create-project-laborProfit"
                                                                disabled
                                                                placeholder="0.00"
                                                                onBlur={(e: any) => changeCreateProjectValue(e, "")}
                                                                />
                                                            </div>
                                                            <div className="col">
                                                                <input
                                                                value={
                                                                    projectData?.laborEstimatedMarginPercentage ==
                                                                    "-Infinity"
                                                                    ? 0
                                                                    : projectData?.laborEstimatedMarginPercentage
                                                                }
                                                                className="form-control form-control-solid h-32px px-8px rounded-4px py-0 bg-white border-graybor fw-normal fs-16 text-dark"
                                                                id="create-project-laborEstimatedMarginPercentage"
                                                                disabled
                                                                placeholder="0.00"
                                                                onBlur={(e: any) => changeCreateProjectValue(e, "")}
                                                                />
                                                            </div>                                                            
                                                        </div>
                                                    </div>

                                                </div>    
                                                {/* Labor fields end */}          

                                                {/* Charges fields start */}
                                                <div className='d-flex align-items-center mb-24px'>
                                                    <strong className="fw-bold lh-24px d-block min-w-75px">Charges</strong>
                                                    <div className='flex-fill'>
                                                        <div className='row row24 row-cols-4'>
                                                            <div className="col">
                                                                <input
                                                                type="number"
                                                                id="create-project-projectCostsRevenue"
                                                                onChange={(e: any) =>
                                                                    changeCreateProjectValue(e, "projectCostsRevenue")
                                                                }
                                                                value={projectData?.projectCostsRevenue}
                                                                className="form-control form-control-solid h-32px px-8px rounded-4px py-0 bg-white border-graybor fw-normal fs-16 text-dark"
                                                                placeholder="0.00"
                                                                />
                                                            </div>
                                                        <div className="col">
                                                            <input
                                                            type="number"
                                                            id="create-project-projectCostsBudget"
                                                            onChange={(e: any) =>
                                                                changeCreateProjectValue(e, "projectCostsBudget")
                                                            }
                                                            value={projectData?.projectCostsBudget}
                                                            className="form-control form-control-solid h-32px px-8px rounded-4px py-0 bg-white border-graybor fw-normal fs-16 text-dark"
                                                            placeholder="0.00"
                                                            />
                                                        </div>
                                                        <div className="col">
                                                            <input
                                                            value={
                                                                isNaN(projectData?.chargesProfit)
                                                                ? 0
                                                                : projectData?.chargesProfit
                                                            }
                                                            className="form-control form-control-solid h-32px px-8px rounded-4px py-0 bg-white border-graybor fw-normal fs-16 text-dark"
                                                            id="create-project-chargesProfit"
                                                            disabled
                                                            placeholder="0.00"
                                                            />
                                                        </div>
                                                        <div className="col">
                                                            <input
                                                            value={
                                                                projectData?.projectCostEstimatedMarginPercentage ==
                                                                "-Infinity"
                                                                ? 0
                                                                : projectData?.projectCostEstimatedMarginPercentage
                                                            }
                                                            className="form-control form-control-solid h-32px px-8px rounded-4px py-0 bg-white border-graybor fw-normal fs-16 text-dark"
                                                            id="create-project-projectCostEstimatedMarginPercentage"
                                                            disabled
                                                            placeholder="0.00"
                                                            />
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>                      
                                                {/* Charges fields end */}   

                                                {/* Total fields start */}
                                                <div className='d-flex align-items-center mb-24px'>
                                                    <strong className="fw-bold lh-24px d-block min-w-75px">Total</strong>
                                                    <div className='flex-fill'>
                                                        <div className='row row24 row-cols-4'>
                                                            <div className="col">
                                                                <input
                                                                value={projectData?.totalRevenue}
                                                                id="create-project-totalRevenue"
                                                                disabled
                                                                className="form-control form-control-solid h-32px px-8px rounded-4px py-0 bg-white border-graybor fw-normal fs-16 text-dark"
                                                                placeholder="0.00"
                                                                />
                                                            </div>
                                                            <div className="col">
                                                                <input
                                                                value={projectData?.totalCost}
                                                                id="create-project-totalCost"
                                                                disabled
                                                                className="form-control form-control-solid h-32px px-8px rounded-4px py-0 bg-white border-graybor fw-normal fs-16 text-dark"
                                                                placeholder="0.00"
                                                                />
                                                            </div>
                                                            <div className="col">
                                                                <input
                                                                value={
                                                                    isNaN(projectData?.totalProfit)
                                                                    ? 0
                                                                    : projectData?.totalProfit
                                                                }
                                                                id="create-project-totalProfit"
                                                                disabled
                                                                className="form-control form-control-solid h-32px px-8px rounded-4px py-0 bg-white border-graybor fw-normal fs-16 text-dark"
                                                                placeholder="0.00"
                                                                />
                                                            </div>
                                                            <div className="col">
                                                                <input
                                                                value={
                                                                    projectData?.totalMargin == "-Infinity"
                                                                    ? 0
                                                                    : projectData?.totalMargin
                                                                }
                                                                disabled
                                                                className="form-control form-control-solid h-32px px-8px rounded-4px py-0 bg-white border-graybor fw-normal fs-16 text-dark"
                                                                id="create-project-totalMargin"
                                                                placeholder="0.00"
                                                                />
                                                            </div>                                                            
                                                        </div>
                                                    </div>
                                                </div>        
                                                {/* Total fields end */}

                                            </div>
                                            {/* Estimated revenue/costs end */}
                                        </div>
                                    </div>                                       
                                </div>
                                {/* Form box 1 end */}

                                </div>
                            </div>

                        </div>

                    </div>
                </div>

          {/* Project Table end */}

        </div>
      </div>        

      <div className='card'>
        <div className='card-body pt-0 bg-gray-100' style={{ maxHeight: 'calc(100% - 65px)' }}>

          {(projectData?.autotask_id && !isloading) &&
            <ProjectBreakdown projectId={parseInt(projectData?.autotask_id)} companyId={projectData?.companyID} projectData={projectData} companies={companies} tasks={tasks} setTasks={setTasks} phases={phases} setPhases={setPhases} setLoading={setLoading} setDisabled={setDisabled} locations={location} />}
        </div>
      </div>

    </div>


  )
}

export default EditProjectPage; 