/* eslint-disable @typescript-eslint/no-unused-vars */

import { Route, Routes, Outlet, Navigate } from "react-router-dom";
import { PageLink, PageTitle } from "../../../../_metronic/layout/core";
import { IntrojsListWrapper } from "./table/IntroList";
// import { EmailListWrapper } from "./email-list/EmailList";
// import { EmailEdit } from "./email-manage/EmailEdit";
// import {EmailView} from './email-manage/EmailView'

const introjsBreadcrumbs: Array<PageLink> = [
  {
    title: "Introjs Management",
    path: "/superadmin/introjs/introjs-setting",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
];
type Props = {
  openUserModal: (state: boolean) => void;
};
const IntrojsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="introjs-setting"
          element={
            <>
              <PageTitle breadcrumbs={introjsBreadcrumbs}>Introjs</PageTitle>
              <IntrojsListWrapper />
            </>
          }
        />
      </Route>
      <Route
        index
        element={<Navigate to="/superadmin/introjs/introjs-setting" />}
      />
    </Routes>
  );
};

export default IntrojsPage;
