/** @jsxRuntime classic */
/** @jsx createElement */
import { createElement, Fragment, isVue, ON_CONTEXT_MENU, ON_DOUBLE_CLICK, ON_MOUSE_LEAVE, ON_MOUSE_MOVE } from '../../../../react/renderer';
import { ScheduleEvent } from '../../../../react/shared/schedule-event';
import { TimeIndicator } from '../../../../react/shared/time-indicator';
import { WeekDay } from '../../../../react/shared/week-day';
import { MbscCalendarEventData, MbscResource } from '../../../shared/calendar-view/calendar-view.types';
import { computeEventResize } from '../../../shared/calendar-view/calendar-view.util';
import { isString, UNDEFINED } from '../../../util/misc';
import { DEF_ID, getCellDate /*, IDailyEvents */ } from '../shared/schedule-timeline-base.util';
import { ISchedulerOptions, ISchedulerState, SchedulerBase } from './scheduler';

import '../shared/schedule-timeline-base.scss';
import './scheduler.scss';

export function template(s: ISchedulerOptions, state: ISchedulerState, inst: SchedulerBase): any {
  const colors = inst._colors;
  const dragData = state.dragData;
  const draggedEventId = dragData && dragData.draggedEvent && dragData.draggedEvent.id;
  const events = inst._events;
  const invalids = inst._invalids;
  const hb = inst._hb;
  const rtl = inst._rtl;
  const times = inst._times;
  const startTime = inst._startTime;
  const endTime = inst._endTime;
  const startCellStyle = inst._startCellStyle;
  const endCellStyle = inst._endCellStyle;
  const stepLabel = inst._stepLabel;
  const theme = inst._theme;
  const isSingleResource = inst._isSingleResource;
  const eventMap = s.eventMap || {};
  const source = 'schedule';
  const groupClass = ' mbsc-flex-1-0 mbsc-schedule-resource-group' + theme + rtl;
  const timezones = inst._timezones;
  const groupByResource = inst._groupByResource;
  const days = inst._days;
  const resources = inst._resources;
  const handlers = { [ON_MOUSE_MOVE]: inst._onMouseMove, [ON_MOUSE_LEAVE]: inst._onMouseLeave };
  const weekDayProps = {
    dayNames: inst._dayNames,
    largeNames: inst._largeDayNames,
    onClick: s.onWeekDayClick,
    renderDay: s.renderDay,
    renderDayContent: s.renderDayContent,
    rtl: s.rtl!,
    theme: s.theme!,
  };

  const renderResource = (resource: MbscResource) => {
    let content = resource.name;
    let html: any;
    if (s.renderResource) {
      content = s.renderResource(resource);
      if (isString(content)) {
        html = inst._safeHtml(content);
        inst._shouldEnhance = true;
      }
    }
    return (
      content && (
        <div
          key={resource.id}
          className={
            'mbsc-schedule-resource ' +
            (resource.cssClass || '') +
            theme +
            rtl +
            hb +
            (!groupByResource || (s.type === 'day' && s.size === 1) ? ' mbsc-flex-1-0 mbsc-schedule-col-width' : '')
          }
          style={{ background: resource.background }}
        >
          {/* eslint-disable-next-line react/no-danger-with-children */}
          <div dangerouslySetInnerHTML={isVue ? UNDEFINED : html} className="mbsc-schedule-resource-title">
            {content}
          </div>
        </div>
      )
    );
  };

  const renderEvents = (data: MbscCalendarEventData[], dateKey: string, resource: string | number, allDay?: boolean) => {
    const resourceResize = inst._resourcesMap[resource].eventResize;
    const dragKey = resource + '__' + dateKey;
    const dragResize = computeEventResize(
      dragData && dragData.draggedEvent && dragData.draggedEvent.original!.resize,
      s.dragToResize,
      resourceResize,
    );
    const eventProps = {
      displayTimezone: s.displayTimezone,
      drag: s.dragToMove || s.externalDrag,
      endDay: s.endDay,
      exclusiveEndDates: s.exclusiveEndDates,
      gridEndTime: endTime,
      gridStartTime: startTime,
      lastDay: +inst._lastDay,
      render: s.renderEvent,
      renderBufferAfter: s.renderBufferAfter,
      renderBufferBefore: s.renderBufferBefore,
      renderContent: s.renderEventContent,
      resource,
      rtl: s.rtl,
      singleDay: !groupByResource,
      slot: DEF_ID,
      startDay: s.startDay,
      theme: s.theme,
      timezonePlugin: s.timezonePlugin,
    };
    return (
      <Fragment>
        {data.map((event) => {
          return event.showText ? (
            event.position && (
              <ScheduleEvent
                {...eventProps}
                event={event}
                key={event.uid}
                inactive={draggedEventId === event.id}
                resize={computeEventResize(event.original!.resize, s.dragToResize, resourceResize)}
                selected={!!(s.selectedEventsMap[event.uid!] || s.selectedEventsMap[event.id])}
                onClick={inst._onEventClick}
                onDoubleClick={s.onEventDoubleClick}
                onRightClick={s.onEventRightClick}
                onDelete={s.onEventDelete}
                onHoverIn={s.onEventHoverIn}
                onHoverOut={s.onEventHoverOut}
                onDragStart={inst._onEventDragStart}
                onDragMove={inst._onEventDragMove}
                onDragEnd={inst._onEventDragEnd}
                onDragModeOn={inst._onEventDragModeOn}
                onDragModeOff={inst._onEventDragModeOff}
              />
            )
          ) : (
            <div key={event.uid} className="mbsc-schedule-event mbsc-schedule-event-all-day mbsc-schedule-event-all-day-placeholder">
              <div className={'mbsc-schedule-event-all-day-inner' + theme} />
            </div>
          );
        })}
        {dragData && dragData.originDates && dragData.originDates[dragKey] && !!dragData.originDates[dragKey].allDay === !!allDay && (
          <ScheduleEvent
            {...eventProps}
            event={dragData.originDates[dragKey]}
            hidden={dragData && !!dragData.draggedDates}
            isDrag={true}
            resize={dragResize}
            onDragStart={inst._onEventDragStart}
            onDragMove={inst._onEventDragMove}
            onDragEnd={inst._onEventDragEnd}
            onDragModeOff={inst._onEventDragModeOff}
          />
        )}
        {dragData && dragData.draggedDates && dragData.draggedDates[dragKey] && !!dragData.draggedDates[dragKey].allDay === !!allDay && (
          <ScheduleEvent {...eventProps} event={dragData.draggedDates[dragKey]} isDrag={true} resize={dragResize} />
        )}
      </Fragment>
    );
  };

  const renderTimes = (timezone?: string) => {
    return times.map((v, i) => {
      const first = !i;
      const last = i === times.length - 1;
      return (
        <div
          key={i}
          className={
            'mbsc-flex-col mbsc-flex-1-0 mbsc-schedule-time-wrapper' +
            theme +
            rtl +
            (last ? ' mbsc-schedule-time-wrapper-end' : '') +
            ((first && !last && startCellStyle) || (last && !first && endCellStyle) ? ' mbsc-flex-none' : '')
          }
          style={first && !last ? startCellStyle : last && !first ? endCellStyle : UNDEFINED}
        >
          <div className={'mbsc-flex-1-1 mbsc-schedule-time' + theme + rtl}>
            {first || v % stepLabel === 0 ? inst._formatTime(first ? startTime : v, timezone) : ''}
          </div>
          {inst._timesBetween.map((t, j) => {
            const ms = v + (j + 1) * stepLabel;
            return (
              ms > startTime &&
              ms < endTime && (
                <div key={j} className={'mbsc-flex-1-1 mbsc-schedule-time' + theme + rtl}>
                  {inst._formatTime(ms, timezone)}
                </div>
              )
            );
          })}
          {last && (
            <div className={'mbsc-schedule-time mbsc-schedule-time-end' + theme + rtl}>{inst._formatTime(endTime + 1, timezone)}</div>
          )}
        </div>
      );
    });
  };

  const renderAllDayData = (res: MbscResource, dateKey: string, i: number, timestamp: number) => {
    const resource = res.id;
    const invalid = invalids[resource][DEF_ID][dateKey] && invalids[resource][DEF_ID][dateKey].allDay[0];
    const color = colors[resource][DEF_ID][dateKey] && colors[resource][DEF_ID][dateKey].allDay[0];
    const dayEvents = events[resource][DEF_ID][dateKey] && events[resource][DEF_ID][dateKey].allDay;
    return (
      <div
        key={i + '-' + timestamp}
        className={'mbsc-schedule-all-day-item mbsc-schedule-col-width mbsc-flex-1-0 ' + (res.cssClass || '') + theme + rtl + hb}
        style={{ background: res.background }}
      >
        {renderEvents(dayEvents || [], dateKey, resource, true)}
        {invalid && (
          <div className={'mbsc-schedule-invalid mbsc-schedule-invalid-all-day' + invalid.cssClass + theme}>
            <div className="mbsc-schedule-invalid-text">{invalid.title}</div>
          </div>
        )}
        {color && (
          <div className={'mbsc-schedule-color mbsc-schedule-color-all-day' + color.cssClass + theme} style={color.position}>
            <div className="mbsc-schedule-color-text">{color.title}</div>
          </div>
        )}
      </div>
    );
  };

  const renderDayData = (res: MbscResource, dateKey: string, i: number, timestamp: number) => {
    const resource = res.id;
    const dayInvalids = invalids[resource][DEF_ID][dateKey] && invalids[resource][DEF_ID][dateKey].data;
    const dayColors = colors[resource][DEF_ID][dateKey] && colors[resource][DEF_ID][dateKey].data;
    const dayData = events[resource][DEF_ID][dateKey];
    const dayEvents = dayData && dayData.data;

    return (
      <div
        key={i + '-' + timestamp}
        className={'mbsc-flex-col mbsc-flex-1-0 mbsc-schedule-column mbsc-schedule-col-width ' + (res.cssClass || '') + theme + rtl + hb}
        style={{ background: res.background }}
      >
        <div className={'mbsc-flex-col mbsc-flex-1-1 mbsc-schedule-column-inner' + theme + rtl + hb}>
          <div className={'mbsc-schedule-events' + (dayData && dayData.hasMore ? ' mbsc-schedule-events-more' : '') + rtl}>
            {renderEvents(dayEvents || [], dateKey, resource)}
          </div>
          {dayInvalids &&
            dayInvalids.map((invalid, j) => {
              return (
                invalid.position && (
                  <div key={j} className={'mbsc-schedule-invalid' + invalid.cssClass + theme} style={invalid.position}>
                    <div className="mbsc-schedule-invalid-text">{invalid.allDay ? '' : invalid.title || ''}</div>
                  </div>
                )
              );
            })}
          {dayColors &&
            dayColors.map((color, j) => {
              return (
                <div key={j} className={'mbsc-schedule-color' + color.cssClass + theme} style={color.position}>
                  <div className="mbsc-schedule-color-text">{color.title}</div>
                </div>
              );
            })}

          {times.map((v, j) => {
            const date = getCellDate(timestamp, v);
            const first = !j;
            const last = j === times.length - 1;
            const cellHandlers = {
              [ON_DOUBLE_CLICK]: (domEvent: any) => s.onCellDoubleClick({ date, domEvent, resource, source }),
              [ON_CONTEXT_MENU]: (domEvent: any) => s.onCellRightClick({ date, domEvent, resource, source }),
            };
            return (
              <div
                key={j}
                className={
                  'mbsc-schedule-item mbsc-flex-1-0' +
                  theme +
                  hb +
                  (last ? ' mbsc-schedule-item-last' : '') +
                  ((first && !last && startCellStyle) || (last && !first && endCellStyle) ? ' mbsc-flex-none' : '')
                }
                // tslint:disable-next-line: jsx-no-lambda
                onClick={(domEvent: any) => s.onCellClick({ date, domEvent, resource, source })}
                style={first && !last ? startCellStyle : last && !first ? endCellStyle : UNDEFINED}
                {...cellHandlers}
              />
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div
      ref={inst._setEl}
      className={'mbsc-flex-col mbsc-flex-1-1 mbsc-schedule-wrapper' + theme + (inst._daysNr > 7 ? ' mbsc-schedule-wrapper-multi' : '')}
    >
      <div className={'mbsc-schedule-header mbsc-flex mbsc-flex-none' + theme + hb}>
        <div className={'mbsc-schedule-time-col mbsc-schedule-time-col-empty' + theme + rtl + hb} style={inst._timeWidth} />
        <div className="mbsc-flex-1-1 mbsc-schedule-header-wrapper">
          <div ref={inst._setHeaderCont} className="mbsc-flex">
            {s.type === 'day' && s.size === 1 ? (
              <div className={groupClass}>
                <div className="mbsc-flex">
                  {s.showDays &&
                    inst._headerDays.map((dayData) => {
                      const timestamp = dayData.timestamp;
                      return (
                        <WeekDay
                          {...weekDayProps}
                          key={timestamp}
                          cssClass="mbsc-flex-1-1"
                          day={dayData.day}
                          events={eventMap[dayData.dateKey]}
                          isToday={inst._isToday(timestamp)}
                          label={dayData.label}
                          selectable={true}
                          selected={inst._selectedDay === timestamp}
                          timestamp={timestamp}
                        />
                      );
                    })}
                </div>
                {s.resources && <div className="mbsc-flex">{resources.map(renderResource)}</div>}
              </div>
            ) : groupByResource ? (
              resources.map((resource, i) => {
                return (
                  <div key={i} className={groupClass}>
                    {renderResource(resource)}
                    <div className="mbsc-flex">
                      {s.showDays &&
                        days.map((dayData) => {
                          const timestamp = dayData.timestamp;
                          return (
                            <WeekDay
                              {...weekDayProps}
                              background={resource.background}
                              key={timestamp}
                              cssClass={'mbsc-flex-1-0 mbsc-schedule-col-width ' + (resource.cssClass || '')}
                              day={dayData.day}
                              events={eventMap[dayData.dateKey]}
                              isToday={isSingleResource && inst._isToday(timestamp)}
                              label={dayData.label}
                              resource={resource.id}
                              selectable={false}
                              selected={isSingleResource && inst._isToday(timestamp)}
                              timestamp={timestamp}
                            />
                          );
                        })}
                    </div>
                  </div>
                );
              })
            ) : (
              days.map((dayData, i) => {
                const timestamp = dayData.timestamp;
                return (
                  <div key={i} className={groupClass}>
                    {s.showDays && (
                      <WeekDay
                        {...weekDayProps}
                        key={timestamp}
                        day={dayData.day}
                        events={eventMap[dayData.dateKey]}
                        isToday={isSingleResource && inst._isToday(timestamp)}
                        label={dayData.label}
                        selectable={false}
                        selected={inst._isToday(timestamp)}
                        timestamp={timestamp}
                      />
                    )}
                    {s.resources && <div className="mbsc-flex">{resources.map(renderResource)}</div>}
                  </div>
                );
              })
            )}
          </div>
        </div>
        <div className="mbsc-schedule-fake-scroll-y" />
      </div>
      <div className={'mbsc-schedule-all-day-cont' + (state.showShadow ? ' mbsc-schedule-all-day-wrapper-shadow' : '') + theme}>
        {timezones && (
          <div className="mbsc-flex mbsc-schedule-timezone-labels" style={inst._timeWidth}>
            {timezones.map((tz, i) => {
              return (
                <div key={i} className={'mbsc-flex-1-0-0 mbsc-schedule-timezone-label' + theme + rtl}>
                  {tz.label}
                </div>
              );
            })}
          </div>
        )}
        {s.showAllDay && (
          <div className={'mbsc-schedule-all-day-wrapper mbsc-flex-none' + theme + hb}>
            <div className={'mbsc-flex mbsc-schedule-all-day' + theme}>
              <div className={'mbsc-schedule-time-col' + theme + rtl} style={inst._timeWidth}>
                {!timezones && <div className={'mbsc-schedule-all-day-text' + theme + rtl}>{s.allDayText}</div>}
              </div>
              <div className="mbsc-flex-col mbsc-flex-1-1 mbsc-schedule-all-day-group-wrapper">
                <div ref={inst._setAllDayCont} className="mbsc-flex mbsc-flex-1-1">
                  {groupByResource
                    ? resources.map((resource, i) => {
                        return (
                          <div key={i} className={'mbsc-flex' + groupClass}>
                            {days.map((day, j) => {
                              return renderAllDayData(resource, day.dateKey, j, day.timestamp);
                            })}
                          </div>
                        );
                      })
                    : days.map((day, i) => {
                        return (
                          <div key={i} className={'mbsc-flex' + groupClass}>
                            {resources.map((resource, j) => {
                              return renderAllDayData(resource, day.dateKey, j, day.timestamp);
                            })}
                          </div>
                        );
                      })}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={'mbsc-flex mbsc-flex-1-1 mbsc-schedule-grid-wrapper' + theme}>
        {/* TRIAL */}
        <div
          aria-hidden="true"
          className={'mbsc-flex-col mbsc-schedule-time-col mbsc-schedule-time-cont' + theme + rtl}
          style={inst._timeWidth}
          ref={inst._setTimeCont}
        >
          <div className="mbsc-flex mbsc-schedule-time-cont-inner">
            <div className="mbsc-flex-col mbsc-flex-1-1">
              <div
                className={
                  'mbsc-flex-1-1 mbsc-schedule-time-cont-pos' +
                  theme +
                  (timezones ? ' mbsc-flex' : ' mbsc-flex-col mbsc-schedule-time-col-last')
                }
              >
                {timezones
                  ? timezones.map((tz, i) => {
                      return (
                        <div
                          key={i}
                          className={'mbsc-flex-col' + theme + (i === timezones.length - 1 ? ' mbsc-schedule-time-col-last' : '')}
                        >
                          {renderTimes(tz.timezone)}
                        </div>
                      );
                    })
                  : renderTimes()}
                {inst._showTimeIndicator && (
                  <TimeIndicator
                    amText={s.amText}
                    displayedTime={inst._time}
                    displayedDays={inst._daysNr}
                    displayTimezone={s.displayTimezone}
                    endDay={s.endDay}
                    firstDay={inst._firstDayTz}
                    orientation="x"
                    pmText={s.pmText}
                    rtl={s.rtl}
                    showDayIndicator={isSingleResource && !inst._isMulti && s.type === 'week'}
                    startDay={s.startDay}
                    startTime={startTime}
                    theme={s.theme}
                    timeFormat={s.timeFormat}
                    timezones={timezones}
                    timezonePlugin={s.timezonePlugin}
                  />
                )}
                {inst._showCursorTime && (
                  <div ref={inst._setCursorTimeCont} className={'mbsc-schedule-cursor-time mbsc-schedule-cursor-time-x' + theme + rtl} />
                )}
              </div>
              {state.hasScrollX && <div className="mbsc-schedule-fake-scroll-x" />}
            </div>
            <div className="mbsc-schedule-fake-scroll-y" />
          </div>
        </div>
        <div ref={inst._setCont} className={'mbsc-flex-col mbsc-flex-1-1 mbsc-schedule-grid-scroll' + theme} onScroll={inst._onScroll}>
          {/* The extra flex div is needed for the div width to expand correctly */}
          <div className="mbsc-flex mbsc-flex-1-1">
            <div className="mbsc-flex mbsc-flex-1-0 mbsc-schedule-grid" ref={inst._setGridCont} {...handlers}>
              {groupByResource
                ? resources.map((resource, i) => {
                    return (
                      <div key={i} className={'mbsc-flex' + groupClass}>
                        {days.map((day, j) => {
                          return renderDayData(resource, day.dateKey, j, day.timestamp);
                        })}
                      </div>
                    );
                  })
                : days.map((day, i) => {
                    return (
                      <div key={i} className={'mbsc-flex' + groupClass}>
                        {resources.map((resource, j) => {
                          return renderDayData(resource, day.dateKey, j, day.timestamp);
                        })}
                      </div>
                    );
                  })}
            </div>
          </div>
        </div>
      </div>
      {dragData && !state.isTouchDrag && <div className="mbsc-calendar-dragging" />}
    </div>
  );
}

export class Scheduler extends SchedulerBase {
  protected _template(s: ISchedulerOptions, state: ISchedulerState): any {
    return template(s, state, this);
  }
}
