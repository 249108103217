import { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { requestPassword } from "../core/_requests";
import { Loading } from "../../../components/loading/Loading";

const initialValues = {
  username: "",
};

const forgotPasswordSchema = Yup.object().shape({
  username: Yup.string()
    // .email("Wrong email format")
    .required("Username is required")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols"),
});

export function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setHasErrors(undefined);
      setTimeout(() => {
        requestPassword(values.username)
          .then(({ data: { result } }) => {
            setHasErrors(false);
            setLoading(false);
            setSubmitting(false);
          })
          .catch(() => {
            setHasErrors(true);
            setLoading(false);
            setSubmitting(false);
            setStatus("E-Mail address or password is not correct");
          });
      }, 1000);
    },
  });

  return (
    <div className="w-100 w-sm-350px">
      <form
        className="form w-100"
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_login_password_reset_form"
      >
        <div className="mb-8">
          {/* begin::Title */}
          <h1 className="text-dark fw-bold mb-1 h2 fs-1 fs-ls-30 text-center">
            Password Forgotten?
          </h1>
          {/* end::Title  */}
        </div>

        {/* begin::Message */}
        {hasErrors === true && (
          <div className="alert alert-danger">
            <div className="alert-text font-weight-bold">
              Username doesn’t exist.
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className="alert alert-success">
            <div className="alert-text font-weight-bold">
              Instruction for password reset has been sent to your email
              address.
            </div>
          </div>
        )}
        {/* end::Message */}

        {/* begin::Form group */}
        <div className="fv-row mb-8">
          <input
            placeholder="Username"
            {...formik.getFieldProps("username")}
            className={clsx(
              "form-control h-45px px-6 fs-6 rounded-1",
              { "is-invalid": formik.touched.username && formik.errors.username },
              {
                "is-valid": formik.touched.username && !formik.errors.username,
              }
            )}
            type="text"
            name="username"
            autoComplete="off"
          />
          {formik.touched.username && formik.errors.username && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.username}</span>
              </div>
            </div>
          )}
        </div>
        {/* End::Form group */}

        <div className="d-flex flex-wrap justify-content-center pb-lg-0">
          {/* begin::Action */}
          <button
            type="submit"
            id="kt_password_reset_submit"
            className="btn btn-primary h-40px fs-6 px-10 fs-ls-20 h-ls-60 col-lg-12"
            disabled={formik.isSubmitting}
            /* !formik.isValid */
          >
            {!loading && <span className="indicator-label">Send</span>}
            <Loading isLoading={loading} text={"Please wait..."}></Loading>
          </button>
          {/* begin::Action */}
        </div>
        <div className="d-flex justify-content-center mt-5">
          <Link
            to="/auth/login"
            id="kt_login_password_reset_form_cancel_button"
            className="text-decoration-underline fs-4"
            // disabled={formik.isSubmitting}
          >
            Back to login screen
          </Link>
        </div>
      </form>
    </div>
  );
}
