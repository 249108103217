import React, { createContext, useEffect, useRef, useState } from "react";
import { useAuth } from "../modules/auth/core/AuthCognito";
import useWebSocket from "react-use-websocket";
export const webSocketContext = createContext({});
let WSURL = process.env.REACT_APP_WSURL || "ws://localhost:6068";
export const WebsocketProvider = ({ children }: any) => {
  const { currentUser } = useAuth();
  const [socketMessage,setSocketMessage] = useState<any>(null)
  const userId = currentUser?.data?.user?._id || ""
  const organizationId = currentUser?.data?.user?.organizationId || ""  
  useWebSocket(`${WSURL}?dataId=${organizationId}-${userId}`, {
    onOpen() {
    },
    onMessage(event) {
      setSocketMessage(event)
    },
    shouldReconnect: (closeEvent) => true,
  });
  // const ws = useRef<any>(null)
  // useEffect(() => {
  //   let WSURL = process.env.REACT_APP_WSURL || "ws://localhost:6068";
  //   const userId = currentUser?.data?.user?._id || ""
  //   const organizationId = currentUser?.data?.user?.organizationId || ""  
  //   ws.current = new WebSocket(WSURL + `?dataId=${organizationId}-${userId}`)
  //   ws.current.onopen = () => { console.log('WS open ===') }
  //   ws.current.onclose = () => { console.log('WS closed') 
  //       //reconnect
  //       ws.current = new WebSocket(WSURL + `?dataId=${organizationId}-${userId}`)
  //    }
  //   ws.current.onmessage = (message:any) => {
  //     setSocketMessage(message)
  // }
  //   return () => { ws.current.close() }
  // }, []);
  const ret = {
    //ws,
    socketMessage
  };

  return (
    <webSocketContext.Provider value={ret}>
      {children}
    </webSocketContext.Provider>
  );
};
