import React from "react"
import { FC, useCallback, useEffect, useState } from "react";
import { KTSVG } from "../../../../_metronic/helpers";
import { settodoCount } from "../../../counterSlice";
import { useAppDispatch } from "../../../hook";
import FilterCapacity from "../filters/CapacityFilter/FilterCapacity";
import { OverlayTrigger, Pagination, Popover, Tooltip } from "react-bootstrap";
import { deleteServicecall, getTaskTicketNotes } from "../../../../_metronic/requests/PlanningRequest";
import { setplanningCache } from "../../../counterSlice";
import FilterTooltip from "../filters/FilterTooltip";
import TicketnotePopup from "./components/TicketnotePopup";
import BucketTask from "./components/BucketTask";
import PlanBucketSettings from "./components/PlanBucketSettings";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
type Props = {
  toplanlist: any;
  isFetching: any;
  showtodo_onclick: any;
  inputHandler: any;
  handleTodoStatusChange?: any;
  selectedStatus: any;
  todo_status: any;
  filteredData: any;
  general_settings: any;
  sortTodoData: any;
  todosorticon: any;
  selectedsortCompany: any;
  selectedsortMenu: any;
  status_type: any;
  handleTypeChange?: any;
  todo_status_serviceCall?: any;
  handleServiceCallStatusChange?: any;
  handleAllClear: any;
  filterIds: any;
  peoples: any;
  filterCapacityArray: any;
  linkIcon: any;
  pagination: any;
  updatePage: any;
  changeitemperpage: any;
  offlineCount: any;
  refreshtodo: any;
  hasShowMore: any;
  setHasShowMore: any;
  isfetchinggeneralsetting: any;
  handleAllSelectCheckbox: any
  handleAllTypeSelectCheckbox: any;
  selectedTask: any
  handleAllServiceCallChecked: any
  planningTask: any,
  setPlanningTask: any,
  componentName: any,
  prioritylist:any,
  queues:any,
  selectedServicecallStatus:any
  storefilterValues:any,
  openDropdown:any,
  toggleDropdown:any,
  setOpenDropdown:any

};

const TodoPlan: FC<Props> = ({
  toplanlist,
  isFetching,
  showtodo_onclick,
  handleTodoStatusChange,
  selectedStatus,
  todo_status,
  todo_status_serviceCall,
  filteredData,
  general_settings,
  sortTodoData,
  todosorticon,
  selectedsortMenu,
  status_type,
  handleTypeChange,
  handleServiceCallStatusChange,
  handleAllClear,
  filterIds,
  filterCapacityArray,
  linkIcon,
  pagination,
  updatePage,
  changeitemperpage,
  offlineCount,
  refreshtodo,
  hasShowMore,
  setHasShowMore,
  isfetchinggeneralsetting,
  inputHandler,
  handleAllSelectCheckbox,
  handleAllTypeSelectCheckbox,
  handleAllServiceCallChecked,
  planningTask,
  setPlanningTask,
  prioritylist,
  queues,
  selectedTask,
  selectedServicecallStatus,
  storefilterValues,
  toggleDropdown,
  openDropdown,
  setOpenDropdown
}) => {
  let active_status = todo_status
    ?.filter((twork: any) => twork.isActive === true)
    ?.map((item1: any) => parseInt(item1?.id));
  let active_servicecall_status = todo_status_serviceCall
    ?.filter((twork: any) => twork.isActive === true)
    ?.map((item1: any) => parseInt(item1?.id));
  const str1 = ["task", "ticket"];
  const ViewsettingFunction = useSelector((state: RootState) => state.viewSettings);
  const defaultPlanBucketSettings: string[] = ["title"];
  const planBucketSettingsFromData = (ViewsettingFunction?.PlanBucketData || [])?.filter(item => item.value)?.map(item => item.id);

  // Merge with default settings and remove duplicates
  const planBucketSettings: string[] = [
    ...new Set([
      ...defaultPlanBucketSettings,
      ...planBucketSettingsFromData,
    ]),
  ];

  const [eventDetail, seteventDetail] = useState<any>(null);
  const [istooltipOpen, settooltipOpen] = useState<any>(false);

  const closeDropdown = (e: any) => {
    storefilterValues()
    toggleDropdown("task_status","no")
  };
  const closeSortbyDropdown = (e: any) => {
    let btnClick = document.getElementById("SORTBY");
    btnClick?.click();
  };
  const closeTypeDropdown = (e: any) => {
    storefilterValues()
    toggleDropdown("type","no")
  };
  const closeServicecallDropdown = (e: any) => {
    storefilterValues()
    toggleDropdown("pservicecall_status","no")
  };

  const dispatch = useAppDispatch();

  let links: any = [];
  const linkCount: any = pagination?.last_page;
  let start = pagination?.page - 3 > 0 ? pagination?.page - 3 : 0;
  let end = pagination?.page + 2 < linkCount ? pagination?.page + 2 : linkCount;
  for (let i = start; i < end; i++) {
    links[i] = {
      active: false,
      label: "" + (i + 1) + "",
      page: i + 1,
      url: "/?page=" + (i + 1),
    };
  }
  links = links.filter(function (el: any) {
    return el !== null;
  });

  const mappedLabel = (label: string): string => {
    if (label === "&laquo; Previous") {
      return "Previous";
    }
    if (label === "Next &raquo;") {
      return "Next";
    }
    return label;
  };
  const [myRef, setMyref] = useState<any>(null);
  const [showNotes, setShowNotes] = useState<any>("d-none");
  let [noteCount, setNoteCount] = useState<any>(0);

  useEffect(() => {
    dispatch(settodoCount(filteredData?.length));
    myRef?.current.scrollTo(0, 0);
  }, [filteredData, toplanlist, isFetching, dispatch, myRef]);

  const onEventClick = useCallback(
    async (e: any, args: any) => {
      if (args.type === "task") {
        args.color = "#FFE2E2";
      }
      if (args.type === "ticket") {
        args.color = "#DFDEFF";
      }
      if (args.type === "servicecall" || args.type === "serviceCall") {
        args.color = "#CEECCE";
      }
      if (
        general_settings?.show_internal_notes ||
        general_settings?.show_notes
      ) {
        setShowNotes("");
      }
      if (args?.type === 'task' || args?.type === 'ticket') {
        let res: any = await getTaskTicketNotes({ autotask_id: args?.autotask_id, type: args?.type });
        args.notes = res?.data ? res?.data?.notes : []
      }
      args.project_name = filterCapacityArray?.projects?.filter((p: any) => parseInt(p.id) === parseInt(args.projectId))?.[0]?.name;
      setNoteCount(0);
      seteventDetail(args);
      settooltipOpen(true);
    },
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [eventDetail, showNotes, general_settings, noteCount]
  );

  const showHideNotes = useCallback(() => {
    if (showNotes === "") {
      setShowNotes("d-none");
    } else {
      setShowNotes("");
    }
  }, [showNotes]);

  const onLinkClick = useCallback((e: any, args: any) => {
    e.stopPropagation();
    if (args) {
      window.open(args, "_blank", "noreferrer");
    }
  }, []);

  const ontooptipClose = useCallback(() => {
    settooltipOpen(false);
  }, []);

  const [isReadMore, setIsReadMore] = useState(true);

  const removeServicecall = useCallback(
    async (ev: any, args: any) => {
      settooltipOpen(false);
      let res: any = [];
      // NotificationManager.warning('Syncing in background to Autotask. Changes will be visible soon.', '');
      res = await deleteServicecall({
        id: args?.autotask_id,
        type: args?.type,
        mongo_id: args?.id,
      });
      dispatch(setplanningCache(false));

      if (!res || res?.status !== 200) {
        if (res.length !== 0 && res?.status !== 200) {
          settooltipOpen(false);
        }
        return false;
      } else {
        refreshtodo(args, "delete");
        let planTask: any = planningTask.filter(
          (item: any) => !(item.id === args?.id)
        );
        setPlanningTask(planTask);
        return true;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredData, planningTask]
  );
  const [ticketTaskinputText, setTicketTaskinputText] = useState("");
  const [serviceCallinputText, setServiceCallinputText] = useState("");
  const [typeinputText, setTypeinputText] = useState("");
  const [showSettings, setShowSettings] = useState(false);


  const inputChange = (e: any) => {
    setTicketTaskinputText(e.target.value);
  }

  const inputServiceCallChange = (e: any) => {
    setServiceCallinputText(e.target.value);
  }

  const inputTypeChange = (e: any) => {
    setTypeinputText(e.target.value)
  }

  const filteredStatus = todo_status
    ?.filter((twork: any) => twork.isActive === true && twork.name.toLowerCase().includes(ticketTaskinputText.toLowerCase()));

  const filteredServiceCallStatus = todo_status_serviceCall
    ?.filter((twork: any) => twork.isActive === true && twork.name.toLowerCase().includes(serviceCallinputText.toLowerCase()));

  const filteredTaskStatus = status_type?.data?.filter((twork: any) => twork.name.toLowerCase().includes(typeinputText.toLowerCase()))

  useEffect(() => {
    if (filterCapacityArray.isfetchlayoutandfilters) {
      const inputElement:any = document.getElementById('search-planbucket');
      if (inputElement) {
        inputElement.value = '';
      }
    }
  }, [filterCapacityArray.isfetchlayoutandfilters])

  const handleViewSetting = () => {
    toggleDropdown("")
    setShowSettings(!showSettings)
  }
  const renderPlanningBucketTooltip = (
    <Popover id="renderAddUserTooltip">
      <div className="p-5 bg-white card z-index-plan-bucket">
        <div>
        Customize how compact your Plan bucket is.
          <a className="text-decoration-underline ms-2"
            href="https://knowledgebase.proxuma.io/article/plan-bucket-view-settings"
            target="_blank"
          >
            Learn more.
          </a>
        </div>
      </div>
    </Popover>
  )
  return (
    <div
      className="bg-body todoSidebar activitySidebar position-relative"
      style={{ width: "30%", float: "right" }}
    >
      <div
        className="d-flex flex-column bg-white h-100 shadow"
        style={{ borderColor: "#DADCE0" }}
      >
        {/* Toolbar */}

        <div className="border-bottom paddLR24 d-flex align-items-center justify-content-between w-100 grayBorder min-h-65px">
          <h3 className="text-dark fw-bold fs-18 m-0 d-inline-flex align-items-center">
            Plan bucket{" "}
            <small className="badge rounded-pill badge-primary bg-yellow text-dark fs-16 px-3 top-0 ms-3 d-inline-block min-h-24px min-w-24px">
              {!isFetching ? (pagination?.total && pagination?.total + offlineCount > 0
                ? pagination?.total + offlineCount
                : "0") : <span className="spinner-border spinner-border-sm align-middle"></span>}
            </small>
          </h3>

          <div className="d-flex align-items-center">
            <div
              className="cursor-pointer d-flex align-items-center me-1 fs-16"
              id="kt_todo_plan_close"
              onClick={handleViewSetting}
            >
              <KTSVG
                path="/media/icons/duotune/new-icons/settings.svg"
                className="me-1"
                stylecode={{ color: "#5C5C71" }}
                svgClassName="w-auto h-auto"
              />
              <span>Settings</span>
            </div>
            <div className="h-24px d-flex align-items-center">
                <OverlayTrigger
                  trigger="click"
                  rootClose
                  placement="bottom-end"
                  overlay={renderPlanningBucketTooltip}
                >
                  <button className="btn p-0 text-center rounded-3px border lh-1 border-primary text-primary fs-6 w-10px color-white infoBtn me-5 ms-2" type="button">i</button>
                </OverlayTrigger>
            </div>


            <div
              className="cursor-pointer"
              id="kt_todo_plan_close"
              onClick={showtodo_onclick}
            >
              <KTSVG
                path="/media/icons/duotune/new-icons/close.svg"
                className=""
                stylecode={{ color: "#5C5C71" }}
                svgClassName="w-auto h-auto"
              />
            </div>
          </div>

        </div>


        <div className="w-100 paddLR24 pt-4 d-flex align-items-start">
          <div className="d-flex align-items-center flex-wrap flex-fill">
            <FilterCapacity
              filterCapacityArray={filterCapacityArray}
              general_settings={general_settings}
              toggleDropdown = {toggleDropdown}
              openDropdown = {openDropdown}
              setOpenDropdown = {setOpenDropdown}
            />
            <div
              className={`dropDown d-flex align-items-center me-2 mb-2
              ${Array.isArray(selectedTask) && selectedTask?.some((el1: any) => str1.includes(el1))
                  ? "d-flex"
                  : "d-none"
                }`}
            >
              <button
                id="cTicketTask-CID"
                className="btn btn-outline btn-outline-primary fw-normal border-primary rounded-pill px-4 py-1 min-h-36px fs-14 d-flex align-items-center"
                type="button"
                data-kt-menu-trigger="click"
                data-kt-menu-attach="parent"
                data-kt-menu-placement="bottom-start"
                data-kt-menu-flip="bottom"
                onClick={() => toggleDropdown("task_status")}
              >
                <KTSVG
                  path="/media/icons/duotune/new-icons/filter.svg"
                  className="me-2"
                  svgClassName="w-16px h-14px"
                  stylecode={{ color: "inherit" }}
                />
                <span className="lh-1">
                  {"Ticket/Task"} <br />
                  {"status"}
                </span>
                &nbsp;
                {selectedStatus?.length > 0 && (
                  <span className="badge rounded-pill badge-primary bg-yellow text-dark fs-16 px-3 top-0 mx-1 d-inline-block min-h-24px min-w-24px">
                    +
                    {
                      selectedStatus?.filter((id: any) =>
                        active_status?.includes(id)
                      )?.length
                    }
                  </span>
                )}
                {selectedStatus?.length > 0 && (
                  <div className="closeDiv ms-2 me-n4 triggerTooltip position-relative">
                    <button
                      value="All"
                      id=""
                      data-name=""
                      onClick={(e)=>{
                        e.stopPropagation();
                        handleTodoStatusChange(e)}}
                      className="p-0 bg-transparent border-0 w-20px h-auto"
                    >
                      <KTSVG
                        path="/media/icons/duotune/extra/close.svg"
                        className="text-primary pe-none"
                        svgClassName="w-10px h-auto"
                      />
                    </button>
                    {/* Code start - Set this in component */}
                    <FilterTooltip />
                    {/* Code end */}
                  </div>
                )}
              </button>
              {openDropdown === 'task_status' && 
              <div
                className="menu menu-sub menu-sub-dropdown bg-white py-5 w-160px fs-7 pb-0 show"
                data-kt-menu="true"
              >
                <div className="searchBox d-flex align-items-center border-bottom mb-3 px-3">
                  <KTSVG path="/media/icons/duotune/extra/search.svg" className='me-2 opacity-50' svgClassName="w-12px h-12px" stylecode={{ color: "#292D32" }} />
                  <input type="text" value={ticketTaskinputText} className="form-control form-control-flush p-0 fw-normal fs-14 h-30px pe-2" id={`cTicketTask-csearch`} name="search" onChange={inputChange} placeholder="Search..." autoComplete="off" ></input>
                  <div className="form-check form-check-custom d-flex align-items-center justify-content-between">
                    {filteredStatus && filteredStatus?.length > 0 &&
                      <input
                        className="form-check-input h-15px w-15px"
                        style={{ borderRadius: "5px" }}
                        type="checkbox"
                        value=""
                        id="cTicketTask-checked"
                        checked={selectedStatus?.length !== 0 && filteredStatus?.length !== 0 && selectedStatus?.length === filteredStatus?.length}
                        onChange={handleAllSelectCheckbox}
                      />
                    }
                  </div>
                </div>
                <div className="chkWrapper px-3 fs-14 mh-250px overflow-auto">
                  {filteredStatus?.map((e: any, key: any) => {
                    let keylabel = (Math.random() + 1)
                      .toString(36)
                      .substring(7);
                    return (
                      <>
                        <div
                          key={key}
                          className="form-check form-check-custom d-flex align-items-center justify-content-between mb-3"
                          style={{
                            width: "100%",
                            background: `${e?.id === selectedStatus ? "#f0f1f6" : ""
                              }`,
                          }}
                          id={e?.id}
                          data-name={e?.name}
                        >
                          <label
                            htmlFor={keylabel}
                            className="form-check-label fw-semibold text-black ms-0 pe-2"
                          >
                            {e?.name}{" "}
                          </label>
                          <input
                            className="form-check-input h-15px w-15px checkBoxClass"
                            style={{ borderRadius: "5px" }}
                            type="checkbox"
                            checked={selectedStatus?.includes(parseInt(e.id))}
                            onChange={(event: any) => {
                              handleTodoStatusChange(event);
                            }}
                            id={keylabel}
                            data-id={'cTicketTask-checked'}
                            value={`${e.id}`}
                            name={e?.name}
                          />
                        </div>
                      </>
                    );
                  })}
                </div>
                <div className="w-100">
                {selectedStatus?.length === 0 ? (<OverlayTrigger
                    key="copy-to-clipboard"
                    placement="bottom"
                    trigger={['hover', 'focus']}
                    overlay={<Tooltip id="tooltip-copy-to-clipboard" className="position-absolute"><span className="pe-2">Please select at least one item</span></Tooltip>}
                  >
                    <button type="button" value={"status"} className="btn w-100 border-gray-101 p-3 fs-14" >Done</button>
                  </OverlayTrigger>) : (<button type="button" value={"status"} onClick={(e: React.MouseEvent<HTMLButtonElement>) => closeDropdown(e)} className="btn w-100 fs-14 border-gray-101 p-3 btn-active-primary rounded-4px" >Done</button>)
                  }
                  
                </div>
              </div>
              }
            </div>

            {/* servicecall filter */}
            <div
              className={`dropDown d-flex align-items-center me-2 mb-2 ${selectedTask?.includes("servicecall") ? "d-flex" : "d-none"
                }`}
            >
              <button
                id="cServiceCall-CID"
                className="btn btn-outline btn-outline-primary fw-normal border-primary rounded-pill px-4 py-1 min-h-36px fs-14 d-flex align-items-center"
                type="button"
                data-kt-menu-trigger="click"
                data-kt-menu-attach="parent"
                data-kt-menu-placement="bottom-start"
                data-kt-menu-flip="bottom"
                onClick={() => toggleDropdown("pservicecall_status")}
              >
                <KTSVG
                  path="/media/icons/duotune/new-icons/filter.svg"
                  className="me-2"
                  svgClassName="w-16px h-14px"
                  stylecode={{ color: "inherit" }}
                />
                <span className="lh-1">
                  {"Servicecall"} <br />
                  {"status"}{" "}
                </span>
                &nbsp;
                {selectedServicecallStatus?.length > 0 && (
                  <span className="badge rounded-pill badge-primary bg-yellow text-dark fs-16 px-3 top-0 mx-1 d-inline-block min-h-24px min-w-24px">
                    +
                    {
                      selectedServicecallStatus?.filter((id: any) =>
                        active_servicecall_status?.includes(id)
                      )?.length
                    }
                  </span>
                )}
                {selectedServicecallStatus?.length > 0 && (
                  <div className="closeDiv ms-2 me-n4 triggerTooltip position-relative">
                    <button
                      value="All"
                      id=""
                      data-name=""
                      onClick={(e)=>{
                        e.stopPropagation();
                        handleServiceCallStatusChange(e)}}
                      className="p-0 bg-transparent border-0 w-20px h-auto"
                    >
                      <KTSVG
                        path="/media/icons/duotune/extra/close.svg"
                        className="text-primary pe-none"
                        svgClassName="w-10px h-auto"
                      />
                    </button>
                    {/* Code start - Set this in component */}
                    <FilterTooltip />
                    {/* Code end */}
                  </div>
                )}
              </button>
              {openDropdown === 'pservicecall_status' && 
                <div
                  className="menu menu-sub menu-sub-dropdown bg-white py-5 w-160px fs-7 pb-0 show"
                  data-kt-menu="true"
                >
                  <div className="searchBox d-flex align-items-center border-bottom mb-3 px-3">
                    <KTSVG path="/media/icons/duotune/extra/search.svg" className='me-2 opacity-50' svgClassName="w-12px h-12px" stylecode={{ color: "#292D32" }} />
                    <input type="text" value={serviceCallinputText} className="form-control form-control-flush p-0 fw-normal fs-14 h-30px pe-2" id={`cServiceCall-csearch`} name="search" onChange={inputServiceCallChange} placeholder="Search..." autoComplete="off" ></input>
                    <div className="form-check form-check-custom d-flex align-items-center justify-content-between">
                      {filteredServiceCallStatus && filteredServiceCallStatus?.length > 0 &&
                        <input
                          className="form-check-input h-15px w-15px"
                          style={{ borderRadius: "5px" }}
                          type="checkbox"
                          value=""
                          id="cServiceCall-checked"
                          checked={selectedServicecallStatus?.length !== 0 && filteredServiceCallStatus?.length !== 0 && selectedServicecallStatus?.length === filteredServiceCallStatus?.length}
                          onChange={handleAllServiceCallChecked}
                        />
                      }
                    </div>
                  </div>
                  <div className="chkWrapper px-3 fs-14 mh-250px overflow-auto">
                    {filteredServiceCallStatus?.map((el: any, elkey: any) => {
                      let keylabel1 = (Math.random() + 1)
                        .toString(36)
                        .substring(7);
                      return (
                        <>
                          <div
                            key={elkey}
                            className="form-check form-check-custom d-flex align-items-center justify-content-between mb-3"
                            style={{
                              width: "100%",
                              background: `${el?.id === selectedServicecallStatus ? "#f0f1f6" : ""
                                }`,
                            }}
                            id={el?.id}
                            data-name={el?.name}
                          >
                            <label
                              htmlFor={keylabel1}
                              className="form-check-label fw-semibold text-black ms-0 pe-2"
                            >
                              {el?.name}{" "}
                            </label>
                            <input
                              className="form-check-input h-15px w-15px checkBoxClass"
                              style={{ borderRadius: "5px" }}
                              type="checkbox"
                              checked={selectedServicecallStatus?.includes(
                                el.id
                              )}
                              onChange={(event: any) => {
                                handleServiceCallStatusChange(event);
                              }}
                              id={keylabel1}
                              data-id={'cServiceCall-checked'}
                              value={`${el.id}`}
                              name={el?.name}
                            />
                          </div>
                        </>
                      );
                    })}
                  </div>
                  <div className="w-100">
                    {selectedServicecallStatus?.length === 0 ? (<OverlayTrigger
                    key="copy-to-clipboard"
                    placement="bottom"
                    trigger={['hover', 'focus']}
                    overlay={<Tooltip id="tooltip-copy-to-clipboard" className="position-absolute"><span className="pe-2">Please select at least one item</span></Tooltip>}
                  >
                    <button type="button" value={"status"} className="btn w-100 border-gray-101 p-3 fs-14" >Done</button>
                  </OverlayTrigger>) : (<button type="button" value={"status"} onClick={(e: React.MouseEvent<HTMLButtonElement>) => closeServicecallDropdown(e)} className="btn w-100 fs-14 border-gray-101 p-3 btn-active-primary rounded-4px" >Done</button>)
                  }
                  </div>
                </div>
              }
            </div>
            {/* end:servicecall filter */}

            {/* type filter */}
            <div className="dropDown d-flex align-items-center me-2 mb-2">
              <button
                id="TO-StatusFilter-ID"
                className="btn btn-outline btn-outline-primary fw-normal border-primary rounded-pill px-4 py-1 min-h-36px fs-14 d-flex align-items-center"
                type="button"
                data-kt-menu-trigger="click"
                data-kt-menu-attach="parent"
                data-kt-menu-placement="bottom-start"
                data-kt-menu-flip="bottom"
                onClick={() => toggleDropdown("type")}
              >
                <KTSVG
                  path="/media/icons/duotune/new-icons/filter.svg"
                  className="me-2"
                  svgClassName="w-16px h-14px"
                  stylecode={{ color: "inherit" }}
                />
                <span>Type</span>&nbsp;
                {selectedTask && selectedTask?.length > 0 && (
                  <span className="badge rounded-pill badge-primary bg-yellow text-dark fs-16 px-3 top-0 mx-1 d-inline-block min-h-24px min-w-24px">
                    +{selectedTask?.length}
                  </span>
                )}
                {selectedTask && selectedTask.length > 0 && (
                  <div className="closeDiv ms-2 me-n4 triggerTooltip position-relative">
                    <button
                      value="All"
                      id=""
                      data-id=""
                      data-name=""
                      onClick={(e)=>{
                        e.stopPropagation();
                        handleTypeChange(e)}}
                      className="p-0 bg-transparent border-0 w-20px h-auto"
                    >
                      <KTSVG
                        path="/media/icons/duotune/extra/close.svg"
                        className="text-primary pe-none"
                        svgClassName="w-10px h-auto"
                      />
                    </button>
                    {/* Code start - Set this in component */}
                    <FilterTooltip />
                    {/* Code end */}
                  </div>
                )}
              </button>
              {openDropdown === 'type' && 
              <div
                className="menu menu-sub menu-sub-dropdown bg-white py-5 w-160px fs-7 pb-0 show"
                data-kt-menu="true"
              >
                <div className="searchBox d-flex align-items-center border-bottom mb-3 px-3">
                  <KTSVG path="/media/icons/duotune/extra/search.svg" className='me-2 opacity-50' svgClassName="w-12px h-12px" stylecode={{ color: "#292D32" }} />
                  <input type="text" value={typeinputText} className="form-control form-control-flush p-0 fw-normal fs-14 h-30px pe-2" id={`cType-csearch`} name="search" onChange={inputTypeChange} placeholder="Search..." autoComplete="off" ></input>
                  <div className="form-check form-check-custom d-flex align-items-center justify-content-between">
                    {filteredTaskStatus && filteredTaskStatus?.length > 0 &&
                      <input
                        className="form-check-input h-15px w-15px"
                        style={{ borderRadius: "5px" }}
                        type="checkbox"
                        value=""
                        id="cType-checked"
                        checked={filteredTaskStatus?.length > 0 && filteredTaskStatus.every((e: any) => selectedTask?.includes(e?.id))}
                        onChange={handleAllTypeSelectCheckbox}
                      />
                    }
                  </div>
                </div>
                <div className="chkWrapper px-3 fs-14 mh-250px overflow-auto">
                  {filteredTaskStatus?.map((e: any, key: any) => {
                    let keylabel2 = (Math.random() + 1)
                      .toString(36)
                      .substring(7);
                    return (
                      <>
                        <div
                          key={key}
                          className="form-check form-check-custom d-flex align-items-center justify-content-between mb-3"
                          style={{
                            width: "100%",
                            background: `${e?.id === selectedStatus ? "#f0f1f6" : ""
                              }`,
                          }}
                          id={e?.type_id}
                          data-name={e?.type}
                        >
                          <label
                            htmlFor={keylabel2}
                            className="form-check-label fw-semibold text-black ms-0 pe-2"
                          >
                            {e?.title}{" "}
                          </label>
                          <input
                            className="form-check-input h-15px w-15px checkBoxClass"
                            style={{ borderRadius: "5px" }}
                            type="checkbox"
                            checked={selectedTask?.includes(e?.id)}
                            onChange={(event: any) => {
                              handleTypeChange(event);
                            }}
                            id={keylabel2}
                            data-id={e?.type_id}
                            value={`${e.id}`}
                            name={e?.type}
                          />
                        </div>
                      </>
                    );
                  })}
                </div>
                <div className="w-100">
                  {selectedTask?.length === 0 ? (<OverlayTrigger
                    key="copy-to-clipboard"
                    placement="bottom"
                    trigger={['hover', 'focus']}
                    overlay={<Tooltip id="tooltip-copy-to-clipboard" className="position-absolute"><span className="pe-2">Please select at least one item</span></Tooltip>}
                  >
                    <button type="button" value={"status"} className="btn w-100 border-gray-101 p-3 fs-14" >Done</button>
                  </OverlayTrigger>) : (<button type="button" value={"status"} onClick={(e: React.MouseEvent<HTMLButtonElement>) => closeTypeDropdown(e)} className="btn w-100 fs-14 border-gray-101 p-3 btn-active-primary rounded-4px" >Done</button>)
                  }
                </div>
              </div>
              }
            </div>
            {/* end type filter */}
          </div>

          <div className="d-flex align-items-center min-h-32px ms-auto">
            <button
              value="All"
              id=""
              data-name=""
              onClick={handleAllClear}
              className="d-inline-flex align-items-center p-0 bg-transparent border-0 w-auto h-auto text-primary fs-16 fw-normal text-nowrap"
            >
              <KTSVG
                path="/media/icons/duotune/new-icons/close.svg"
                className="text-primary pe-none"
                svgClassName="w-15px h-auto me-1"
              />
              Clear filters
            </button>
          </div>
        </div>

        <div className="border-bottom pt-2 pb-4 paddLR24 grayBorder d-flex align-items-center flex-wrap w-100">
          <div className="dropDown d-flex align-items-center">
            <button
              id="SORTBY"
              className="btn btn-outline btn-outline-primary fw-normal border-primary rounded-pill px-4 py-0 min-h-32px fs-14 d-inline-flex align-items-center"
              type="button"
              data-kt-menu-trigger="click"
              data-kt-menu-attach="parent"
              data-kt-menu-placement="bottom-start"
              data-kt-menu-flip="bottom"
            >
              <KTSVG
                path={todosorticon}
                className="me-2"
                svgClassName="w-16px h-auto"
                stylecode={{ color: "inherit" }}
              />
              <span>{selectedsortMenu ? selectedsortMenu : "Sort by"}</span>
              {selectedsortMenu !== "" && (
                <div className="closeDiv ms-2 me-n4 triggerTooltip position-relative">
                  <button
                    value="All"
                    id=""
                    data-name=""
                    onClick={sortTodoData}
                    className="p-0 bg-transparent border-0 w-20px h-auto"
                  >
                    <KTSVG
                      path="/media/icons/duotune/extra/close.svg"
                      className="text-primary pe-none"
                      svgClassName="w-10px h-auto"
                    />
                  </button>
                  {/* Code start - Set this in component */}
                  <FilterTooltip />
                  {/* Code end */}
                </div>
              )}
            </button>
            <div
              className="menu menu-sub menu-sub-dropdown bg-white py-5 px-3 w-180px fs-14"
              data-kt-menu="true"
            >
              <a
                className="d-flex align-items-center text-black fw-semibold text-hover-primary mb-3"
                data-name="duedate_asc"
                onClick={(event: any) => {
                  sortTodoData(event);
                  closeSortbyDropdown(event);
                }}
                href="javascript:void(0)"
              >
                <KTSVG
                  path="/media/icons/duotune/extra/sort-up.svg"
                  className="me-2 text-primary"
                  svgClassName="w-auto h-auto mw-14px mh-14px"
                  stylecode={{ color: "inherit" }}
                />
                Due date
              </a>
              <a
                onClick={(event: any) => {
                  sortTodoData(event);
                  closeSortbyDropdown(event);
                }}
                data-name="duedate_desc"
                className="d-flex align-items-center text-black fw-semibold text-hover-primary mb-3"
                href="javascript:void(0)"
              >
                <KTSVG
                  path="/media/icons/duotune/extra/sort-down.svg"
                  className="me-2 text-primary"
                  svgClassName="w-auto h-auto mw-14px mh-14px"
                  stylecode={{ color: "inherit" }}
                />
                Due date
              </a>
              <a
                onClick={(event: any) => {
                  sortTodoData(event);
                  closeSortbyDropdown(event);
                }}
                data-name="company_asc"
                className="d-flex align-items-center text-black fw-semibold text-hover-primary mb-3"
                href="javascript:void(0)"
              >
                <KTSVG
                  path="/media/icons/duotune/extra/sort-up.svg"
                  className="me-2 text-primary"
                  svgClassName="w-auto h-auto mw-14px mh-14px"
                  stylecode={{ color: "inherit" }}
                />
                Company
              </a>
              <a
                onClick={(event: any) => {
                  sortTodoData(event);
                  closeSortbyDropdown(event);
                }}
                data-name="company_desc"
                className="d-flex align-items-center text-black fw-semibold text-hover-primary mb-3"
                href="javascript:void(0)"
              >
                <KTSVG
                  path="/media/icons/duotune/extra/sort-down.svg"
                  className="me-2 text-primary"
                  svgClassName="w-auto h-auto mw-14px mh-14px"
                  stylecode={{ color: "inherit" }}
                />
                Company
              </a>
              <a
                onClick={(event: any) => {
                  sortTodoData(event);
                  closeSortbyDropdown(event);
                }}
                data-name="status_asc"
                className="d-flex align-items-center text-black fw-semibold text-hover-primary mb-3"
                href="javascript:void(0)"
              >
                <KTSVG
                  path="/media/icons/duotune/extra/sort-up.svg"
                  className="me-2 text-primary"
                  svgClassName="w-auto h-auto mw-14px mh-14px"
                  stylecode={{ color: "inherit" }}
                />
                Status
              </a>
              <a
                onClick={(event: any) => {
                  sortTodoData(event);
                  closeSortbyDropdown(event);
                }}
                data-name="status_desc"
                className="d-flex align-items-center text-black fw-semibold text-hover-primary  mb-3"
                href="javascript:void(0)"
              >
                <KTSVG
                  path="/media/icons/duotune/extra/sort-down.svg"
                  className="me-2 text-primary"
                  svgClassName="w-auto h-auto mw-14px mh-14px"
                  stylecode={{ color: "inherit" }}
                />
                Status
              </a>
              <a
                onClick={(event: any) => {
                  sortTodoData(event);
                  closeSortbyDropdown(event);
                }}
                data-name="priority_asc"
                className="d-flex align-items-center text-black fw-semibold text-hover-primary mb-3"
                href="javascript:void(0)"
              >
                <KTSVG
                  path="/media/icons/duotune/extra/sort-up.svg"
                  className="me-2 text-primary"
                  svgClassName="w-auto h-auto mw-14px mh-14px"
                  stylecode={{ color: "inherit" }}
                />
                Priority
              </a>
              <a
                onClick={(event: any) => {
                  sortTodoData(event);
                  closeSortbyDropdown(event);
                }}
                data-name="priority_desc"
                className="d-flex align-items-center text-black fw-semibold text-hover-primary"
                href="javascript:void(0)"
              >
                <KTSVG
                  path="/media/icons/duotune/extra/sort-down.svg"
                  className="me-2 text-primary"
                  svgClassName="w-auto h-auto mw-14px mh-14px"
                  stylecode={{ color: "inherit" }}
                />
                Priority
              </a>
            </div>
          </div>

          <div
            className="flex-fill searchBox d-flex align-items-center ms-4 rounded-1"
            style={{ background: "#EEF0F4" }}
          >
            <button className="p-0 border-0 bg-transparent w-40px h-32px d-inline-flex align-items-center justify-content-center">
              <KTSVG
                path="/media/icons/duotune/new-icons/search.svg"
                className=""
                svgClassName="w-16px h-auto"
              />
            </button>
            <input
              type="text"
              className="form-control form-control-flush p-0 fw-normal fs-16 h-32px text-black ps-0 pe-2 placeholderGray"
              style={{ color: "#5C5C71" }}
              name="search"
              id="search-planbucket"
              placeholder="Search..."
              data-kt-search-element="input"
              onChange={inputHandler}
            ></input>
          </div>

          <div className="w-100 d-flex align-items-center justify-content-between pt-4">
            {links.length > 0 && (
              <div className="d-flex align-items-center">
                <label
                  htmlFor="pagedropdown"
                  className="col-form-label fw-bold fs-14 p-0 pe-2"
                >
                  Items per page:{" "}
                </label>
                <select
                  id="pagedropdown"
                  className="form-select form-select-solid bg-white form-select-small h-32px py-0 px-3 fw-normal fs-16 text-dark w-auto min-w-70px"
                  value={filterIds?.items_per_page}
                  onChange={changeitemperpage}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </select>
              </div>
            )}

            <div className="d-flex align-items-center ps-2">
              {links.length > 0 && (
                <Pagination>
                  <Pagination.First
                    disabled={pagination?.page === 1}
                    onClick={() => updatePage(1)}
                  />
                  <Pagination.Prev
                    disabled={pagination?.page === 1}
                    onClick={() => updatePage(pagination?.page - 1)}
                  />
                  {links?.map((link: any) => (
                    <Pagination.Item
                      active={link?.page === pagination?.page}
                      onClick={() => updatePage(link?.page)}
                    >
                      {mappedLabel(link.label)}
                    </Pagination.Item>
                  ))}
                  {pagination?.page < pagination?.last_page && (
                    <span style={{ marginTop: "4%" }}>...</span>
                  )}
                  <Pagination.Next
                    disabled={pagination?.page === pagination?.last_page}
                    onClick={() => updatePage(pagination?.page + 1)}
                  />
                  <Pagination.Last
                    disabled={pagination?.page === pagination?.last_page}
                    onClick={() => updatePage(pagination?.last_page)}
                  />
                </Pagination>
              )}
            </div>
          </div>
        </div>

        {!showSettings ? <div className="pt-4 paddLR24 overflow-auto mh-100 position-relative flex-1">

          {filteredData?.length === 0 && (
            <div className="d-flex flex-column fw-normal fs-16 text-center">
              No data available.
            </div>
          )}
          {!isfetchinggeneralsetting &&
            filteredData?.map((task: any, key: any) => (
              <BucketTask
                key={key}
                data={task}
                planBucketSettings={planBucketSettings}
                hasShowMore={hasShowMore}
                setHasShowMore={setHasShowMore}
                general_settings={general_settings}
                linkIcon={linkIcon}
                isReadMore={isReadMore}
                setIsReadMore={setIsReadMore}
                onEventClick={onEventClick}
                onLinkClick={onLinkClick}
                removeServicecall={removeServicecall}
                projectList={filterCapacityArray?.projects}
                prioritylist={prioritylist}
                queuesList={queues}
              />
            ))}


        </div> :
          <PlanBucketSettings
            handleViewSetting={handleViewSetting}
            show={showSettings}
            general_settings={general_settings}
          />}


        {/* End:Toolbar */}
      </div>


      {istooltipOpen && (
        <TicketnotePopup
          onEventClick={onEventClick}
          istooltipOpen={istooltipOpen}
          ontooptipClose={ontooptipClose}
          general_settings={general_settings}
          showNotes={showNotes}
          showHideNotes={showHideNotes}
          removeServicecall={removeServicecall}
          eventDetail={eventDetail}
        />
      )}
    </div>
  );
};

export default TodoPlan;
