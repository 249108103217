import { Datepicker, alert } from "@mobiscroll/react";
import { useFormik } from "formik";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { isNotEmpty } from "../../../../../_metronic/helpers";
import {
  createGeneralSetting,
  getGeneralSetting,
  updateGeneralSetting,
} from "../../../../../_metronic/requests/GeneralSettingRequest";
import {
  getResourcesWorkType,
  getToPlanServiceCallStatus,
  getToPlanTaskStatus,
} from "../../../../../_metronic/requests/PlanningRequest";
import { Loading } from "../../../../components/loading/Loading";
import { AUTOTASK_ENTITY } from "../../../../constants/global";
import { setisSaved } from "../../../../counterSlice";
import { useAppDispatch } from "../../../../hook";
import { GeneralSetting, initialGeneralSetting } from "./_models";
import { OverlayTrigger, Popover } from "react-bootstrap";

const generalSettingSchema = Yup.object().shape({
  daily_availability: Yup.number()
    .required("Daily availability is required")
    .moreThan(-1, "Daily availability should not have a negative value")
    .lessThan(15, "Daily availability should not be more than 14 hours"),

  task_duration: Yup.number()
    .required("Task duration is required")
    .moreThan(-1, "Task duration should not have a negative value")
    .lessThan(41, "Task duration should not be more than 40 hours"),

  ticket_duration: Yup.number()
    .required("Ticket duration is required")
    .moreThan(-1, "Ticket duration should not have a negative value")
    .lessThan(41, "Ticket duration should not be more than 40 hours"),

  planning_screen_view: Yup.string()
    .required("Planning screen view is required")
    .oneOf(
      ["day", "week", "month", "quarter", "half_year", "year"],
      "Planning screen view is required"
    ),

  // task_screen_view: Yup.string()
  //   .required("Task screen view is required")
  //   .oneOf(['day', 'week', 'month', 'quarter', 'half_year', 'year'], 'Task screen view is required'),
  

  service_call_duration: Yup.number()
    .required(`${ AUTOTASK_ENTITY.ServiceCall.title } duration is required`)
    .moreThan(-1, `${ AUTOTASK_ENTITY.ServiceCall.title } duration should not have a negative value`)
    .lessThan(41, `${ AUTOTASK_ENTITY.ServiceCall.title } duration should not be more than 40 hours`),
  // service_call_duration: Yup.string()
  //   .required("Todo duration is required")
  //   .oneOf(['15', '30', '45', '60'], 'Todo duration is required'),

  planning_first_day_of_week: Yup.string()
    .required("Planning first day of week is required")
    .oneOf(
      ["0", "1", "2", "3", "4", "5", "6"],
      "Planning first day of week is required"
    ),

  // task_first_day_of_week: Yup.string()
  //   .required("Task first day of week is required")
  //   .oneOf(['0', '1', '2', '3', '4', '5', '6'], 'Task first day of week is required'),
  timezone: Yup.string().required("Timezone is required"),
});

const GeneralSettingView: FC = () => {
  const navigate = useNavigate();

  const {
    isFetching: isfetchgeneralSetting,
    refetch: refetchgeneralSetting,
    data: generalSetting,
  } = useQuery(
    `general-resource-setting`,
    async () => {
      return await getGeneralSetting("");
    },
    {
      cacheTime: 0,
      onError: (err) => {
        console.error(err);
      },
    }
  );

  const {
    data: todo_status_taskticket,
  } = useQuery(
    "todo-status",
    async () => {
      return await getToPlanTaskStatus("");
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const {
    data: todo_status_serviceCall,
  } = useQuery(
    "todo-status-serviceCall",
    async () => {
      return await getToPlanServiceCallStatus("");
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const {
    data: workgroups,
  } = useQuery(
    "todo-workgroup",
    async () => {
      return await getResourcesWorkType("");
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  let generalSettingData = generalSetting
    ? generalSetting
    : initialGeneralSetting;
  const [selected, setSelected] = useState<Boolean>(true);
  const [task_type, setTasktype] = useState<Boolean>(true);
  const [outlooksync_type, setoutlooksync_type] = useState<String>('');
  const [starttime, setStarttime] = useState("");
  const [endtime, setEndtime] = useState("");
  const [extendedMorningStart, setextendedMorningStart] = useState("");
  const [extendedMorningEnd, setextendedMorningEnd] = useState("");
  const [extendedEveningEnd, setextendedEveningEnd] = useState("");
  const [extendedEveningStart, setextendedEveningStart] = useState("");
  const [showSkill, setShowskill] = useState<Boolean>(true);
  const [showAppointment, setShowAppointment] = useState<Boolean>(true);
  const [showAppointmentProject, setShowAppointmentProject] = useState<Boolean>(true);
  const [showNotes, setShowNotes] = useState<Boolean>(true);
  const [showInternalNotes, setShowInternalNotes] = useState<Boolean>(true);
  const [hideOption, setHideOption] = useState<Boolean>(true);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setSelected(generalSetting?.allow_overlapping);
    if (generalSetting?.hasOwnProperty("show_resource_skills")) {
      setShowskill(generalSetting?.show_resource_skills);
    }
    if (generalSetting?.hasOwnProperty("show_appointments")) {
      setShowAppointment(generalSetting?.show_appointments);
    }
    if (generalSetting?.hasOwnProperty("show_appointments_project")) {
      setShowAppointmentProject(generalSetting?.show_appointments_project);
    }
    if (generalSetting?.hasOwnProperty("show_notes")) {
      setShowNotes(generalSetting?.show_notes);
    }
    if (generalSetting?.hasOwnProperty("hide_options")) {
      setHideOption(generalSetting?.hide_options);
    }
    if (generalSetting?.hasOwnProperty("show_internal_notes")) {
      setShowInternalNotes(generalSetting?.show_internal_notes);
    }
   

    setTasktype(generalSetting?.show_task_type);
    setoutlooksync_type(generalSetting?.outlook_sync_type ? generalSetting?.outlook_sync_type : 'both');
    setStarttime(generalSetting?.start_time);
    setEndtime(generalSetting?.end_time);
    setextendedMorningStart(
      generalSetting?.extended_morning_start
        ? generalSetting?.extended_morning_start
        : generalSetting?.start_time
    );
    setextendedMorningEnd(
      generalSetting?.extended_morning_end
        ? generalSetting?.extended_morning_end
        : generalSetting?.start_time
    );
    setextendedEveningStart(
      generalSetting?.extended_evening_start
        ? generalSetting?.extended_evening_start
        : generalSetting?.end_time
    );
    setextendedEveningEnd(
      generalSetting?.extended_evening_end
        ? generalSetting?.extended_evening_end
        : generalSetting?.end_time
    );
  }, [generalSetting, generalSettingData, isfetchgeneralSetting]);

  const handleAllowOverlapChange = (event: any) => {
    if (parseInt(event.target.value) === 1) {
      setSelected(true);
    } else {
      setSelected(false);
    }
    dispatch(setisSaved(true));
  };

  const handleShowSkill = (event: any) => {
    if (parseInt(event.target.value) === 1) {
      setShowskill(true);
    } else {
      setShowskill(false);
    }
    dispatch(setisSaved(true));
  };

  const handleShowAppointment = (event: any) => {
    if (parseInt(event.target.value) === 1) {
      setShowAppointment(true);
    } else {
      setShowAppointment(false);
    }
    dispatch(setisSaved(true));
  };

  const handleShowAppointmentProject = (event: any) => {
    if (parseInt(event.target.value) === 1) {
      setShowAppointmentProject(true);
    } else {
      setShowAppointmentProject(false);
    }
    dispatch(setisSaved(true));
  };

  const handleShowNotes = (event: any, type: any) => {
    if (type === 'notes') {
      if (parseInt(event.target.value) === 1) {
        setShowNotes(true);
      } else {
        setShowNotes(false);
      }
      dispatch(setisSaved(true));
    } else {
      if (event.target.value == 1) {
        setShowInternalNotes(true);
      } else {
        setShowInternalNotes(false);
      }
      dispatch(setisSaved(true));
    }
  };


  const handleHideOption = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedValue = parseInt(event.target.value, 10);

    if (selectedValue === 0) {
      setHideOption(false);
    } else {
      setHideOption(true);
    }
    dispatch(setisSaved(true));
  };

  const updateTasktypeData = (event: any) => {
    // setTasktype(fieldsToUpdate?.show_task_type);

    if (parseInt(event.target.value) === 1) {
      setTasktype(true);
    } else {
      setTasktype(false);
    }
    dispatch(setisSaved(true));
  };

  const update365Data = (event: any) => {
    setoutlooksync_type(event.target.value);
    dispatch(setisSaved(true));
  };

  /* Moment timezone */
  var moment = require("moment-timezone");
  var timeZones = moment.tz.names();
  var offsetTmz = [];
  for (var i in timeZones) {
    offsetTmz.push(timeZones[i]);
  }

  const onChangeExtendedMorningStart = (e: any) => {
    const start_time = moment(e.value).format("HH:mm");
    setextendedMorningStart(start_time);
    dispatch(setisSaved(true));
  };

  const onChangeExtendedMorningEnd = (e: any) => {
    const start_time = moment(e.value).format("HH:mm");
    setextendedMorningEnd(start_time);
    setStarttime(start_time);
    dispatch(setisSaved(true));
  };

  const onChangeExtendedEveningStart = (e: any) => {
    const start_time = moment(e.value).format("HH:mm");
    setextendedEveningStart(start_time);
    setEndtime(start_time);
    dispatch(setisSaved(true));
  };

  const onChangeExtendedEveningEnd = (e: any) => {
    const start_time = moment(e.value).format("HH:mm");
    setextendedEveningEnd(start_time);
    dispatch(setisSaved(true));
  };

  const onStarttimeChange = (e: any) => {
    const start_time = moment(e.value).format("HH:mm");
    setStarttime(start_time);
    setextendedMorningEnd(start_time);
    dispatch(setisSaved(true));
  };

  const onEndtimeChange = (e: any) => {
    const end_time = moment(e.value).format("HH:mm");
    setEndtime(end_time);
    setextendedEveningStart(end_time);
    dispatch(setisSaved(true));
  };
  /* Timezone end */

  const preventPasteNegative = (ev: any) => {
    const clipboardData = ev.clipboardData || window.Clipboard;
    const pastedData = parseFloat(clipboardData.getData("text"));
    const str = ev.clipboardData.getData("text");
    const newStr = str.replace(/[+-.]/g, "");

    if (pastedData < 0 || str !== newStr || str === "e") {
      ev.preventDefault();
    }
  };
  const preventMinus = (ev: any) => {
    if (ev.code === "Minus" || ev.code === "Equal" || ev.code === "KeyE") {
      ev.preventDefault();
    }
  };

  const [loading, setLoading] = useState(false);
  const formik = useFormik<GeneralSetting>({
    enableReinitialize: true,
    initialValues: generalSettingData,
    validationSchema: generalSettingSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      setLoading(true);
      try {
        values.default_monthyearhours_highlight = currentDefaultHighlight;
        values.show_task_type = task_type;
        values.outlook_sync_type = outlooksync_type;
        values.allow_overlapping = selected;
        values.start_time = starttime;
        values.end_time = endtime;
        values.extended_morning_start = extendedMorningStart;
        values.extended_morning_end = extendedMorningEnd;
        values.extended_evening_start = extendedEveningStart;
        values.extended_evening_end = extendedEveningEnd;
        values.show_resource_skills = showSkill;
        values.show_appointments = showAppointment;
        values.show_appointments_project = showAppointmentProject;
        values.show_notes = showNotes;
        values.hide_options = hideOption;
        values.show_internal_notes = showInternalNotes;
        
        if (isNotEmpty(values._id)) {
          await updateGeneralSetting(values);
        } else {
          await createGeneralSetting(values);
        }
        localStorage.setItem("starttime", starttime);
        localStorage.setItem("endtime", endtime);
        localStorage.setItem("extendedMorningStart", extendedMorningStart);
        localStorage.setItem("extendedMorningEnd", extendedMorningEnd);
        localStorage.setItem("extendedEveningStart", extendedEveningStart);
        localStorage.setItem("extendedEveningEnd", extendedEveningEnd);
        localStorage.setItem("time_format", values?.time_format);
        localStorage.setItem("timezone", values.timezone);
        localStorage.setItem('timeline_view',values?.planning_screen_view);
        localStorage.setItem("viewSettingIsShow",'0');
        dispatch(setisSaved(false));
      } catch (ex) {
        setLoading(false);
        dispatch(setisSaved(false));
      } finally {
        navigate("/apps/settings/general");
        setSubmitting(true);
        setLoading(false);
        // cancel(true);
        alert({
          message: "Changes saved successfully!",
          callback: function (result) {
            window.location.reload();
          },
        });
      }
    },
  });

  const handleLocationOption = (event: any) => {
    const selectedValue = event.target.value === 'true';
    formik.setFieldValue('isLocation', selectedValue); 
    dispatch(setisSaved(true));
  };

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetchgeneralSetting();
      setSelected(generalSetting?.allow_overlapping);
      setShowskill(generalSetting?.show_resource_skills);
      setShowAppointment(
        generalSetting?.show_appointments
          ? generalSetting?.show_appointments
          : true
      );
      setTasktype(generalSetting?.show_task_type);
      setoutlooksync_type(generalSetting?.outlook_sync_type ? generalSetting?.outlook_sync_type : 'both');
      setStarttime(generalSetting?.start_time);
      setextendedMorningStart(
        generalSetting?.extended_morning_start
          ? generalSetting?.extended_morning_start
          : extendedMorningStart
      );
      setextendedMorningEnd(
        generalSetting?.extended_morning_end
          ? generalSetting?.extended_morning_end
          : extendedMorningEnd
      );
      setextendedEveningStart(
        generalSetting?.extended_evening_start
          ? generalSetting?.extended_evening_start
          : extendedEveningStart
      );
      setextendedEveningEnd(
        generalSetting?.extended_evening_end
          ? generalSetting?.extended_evening_end
          : extendedEveningEnd
      );
      setEndtime(generalSetting?.end_time);
      setCurrentDefaultHighlight(generalSetting?.default_monthyearhours_highlight);
      formik.resetForm();
      dispatch(setisSaved(false));
    }
  };

  useEffect(() => {
    if (formik.dirty === true) {
      dispatch(setisSaved(true));
    } else {
      dispatch(setisSaved(false));
    }
  }, [formik.dirty, generalSetting]);

  const [currentDefaultHighlight, setCurrentDefaultHighlight] = useState('');

  useEffect(() => {
    if (generalSetting?.default_monthyearhours_highlight) {
      setCurrentDefaultHighlight(generalSetting?.default_monthyearhours_highlight);  
    } else {
      setCurrentDefaultHighlight('1')
    }
  }, [generalSetting])
  
  const handleDefaultHighlight = (event) => {
    const value = event.target.value;
    const { default_monthyearhours } = formik.values;
    
    if (!default_monthyearhours.includes(value)) {
      const updatedDefaultMonthYearHours = [...default_monthyearhours, value];
      formik.setFieldValue('default_monthyearhours', updatedDefaultMonthYearHours);
    }
    setCurrentDefaultHighlight(value);
  }

  const renderGeneralSettingsTooltip = (
    <Popover id="renderGeneralSettingsTooltip">
      <div className="p-5 bg-white card">
        <div> Adjust your settings to align with your workflow. Changes made here will be applied across the entire organizational account.
           <a className="text-decoration-underline ms-2" href="https://knowledgebase.proxuma.io/article/general-settings" target="_blank">Learn more.</a>
        </div>
      </div>
    </Popover>
  )
  const renderTimezoneTooltip = (
    <Popover id="renderTimezoneTooltip">
      <div className="p-5 bg-white card">
        <div> Select your timezone, as this is not synced from Autotask.</div>
      </div>
    </Popover>
  )
  const renderResourceAvailabilityTooltip = (
    <Popover id="renderResourceAvailabilityTooltip">
      <div className="p-5 bg-white card">
        <div> This is the length of a workday for newly added or synced resources from Autotask. It impacts the capacity calculation.</div>
      </div>
    </Popover>
  )
  const renderOverlapShedulingTooltip = (
    <Popover id="renderOverlapShedulingTooltip">
      <div className="p-5 bg-white card">
        <div> Allow planners to double book items or not.</div>
      </div>
    </Popover>
  )
  const renderShowPlanItemTypeTooltip = (
    <Popover id="renderShowPlanItemTypeTooltip">
      <div className="p-5 bg-white card">
        <div> Do you want to see "task" or "ticket" before the title on your Planning screen items? (most customers don't as the colors already show this information)</div>
      </div>
    </Popover>
  )
  const renderOutlookSyncTooltip = (
    <Popover id="renderOutlookSyncTooltip">
      <div className="p-5 bg-white card">
        <div> Select how you want our Outlook integration to work. We recommend bidirectional and turning off Autotask's own integration with Outlook.</div>
      </div>
    </Popover>
  )
  const renderPlanningScreenViewSettingTooltip = (
    <Popover id="renderPlanningScreenViewSettingTooltip">
      <div className="p-5 bg-white card">
        <div> Choose what time your regular day starts and ends. Extended working hours are hours where you might plan service calls outside the regular hours (The striped background on your planning screen represents these hours).
          <a className="text-decoration-underline ms-2" href="https://knowledgebase.proxuma.io/article/planning-screen-view-and-time" target="_blank">Learn more</a>
        </div>
      </div>
    </Popover>
  )
  const renderPlanningScreenDefaultSettingTooltip = (
    <Popover id="renderPlanningScreenDefaultSettingTooltip">
      <div className="p-5 bg-white card">
        <div> Select what automations you want the Planning screen to do for you upon dragging an item into a calendar.
          <a className="text-decoration-underline ms-2" href="https://knowledgebase.proxuma.io/article/planning-screen-default" target="_blank">Learn more</a>
        </div>
      </div>
    </Popover>
  )
  const renderServiceCallDurationTooltip = (
    <Popover id="renderServiceCallDurationTooltip">
      <div className="p-5 bg-white card">
        <div> The default duration of the service call when you re-plan a service call from the Plan bucket.</div>
      </div>
    </Popover>
  )
  const renderTaskDurationTooltip = (
    <Popover id="renderTaskDurationTooltip">
      <div className="p-5 bg-white card">
        <div> The default duration of the service call when you plan a Task from the Plan bucket. 
        </div>
      </div>
    </Popover>
  )
  const renderTicketDurationTooltip = (
    <Popover id="renderTicketDurationTooltip">
      <div className="p-5 bg-white card">
        <div> The default duration of the service call when you plan a Ticket from the Plan bucket. 
        </div>
      </div>
    </Popover>
  )
  const renderShowSkillInPlanningScreenTooltip = (
    <Popover id="renderShowSkillInPlanningScreenTooltip">
      <div className="p-5 bg-white card">
        <div>This option determines whether the category "skills" is displayed on your Planning Screen Filter.
        </div>
      </div>
    </Popover>
  )
  const renderAppointmentsTooltip = (
    <Popover id="renderAppointmentsTooltip">
      <div className="p-5 bg-white card">
        <div>This option determines whether Outlook Appointments are displayed in your Planning Screen (yellow items)
        </div>
      </div>
    </Popover>
  )
  const renderAppointmentsInProjectTooltip = (
    <Popover id="renderAppointmentsInProjectTooltip">
      <div className="p-5 bg-white card">
        <div>This option determines whether Outlook Appointments are displayed in your Project Screen (yellow items).
        </div>
      </div>
    </Popover>
  )
  const renderShowNotesTooltip = (
    <Popover id="renderShowNotesTooltip">
      <div className="p-5 bg-white card">
        <div>This option determines whether notes from Autotask are displayed in the Plan Bucket.
        </div>
      </div>
    </Popover>
  )
  const renderIntarnalNotesTooltip = (
    <Popover id="renderIntarnalNotesTooltip">
      <div className="p-5 bg-white card">
        <div>This option determines whether internal notes from Autotask are displayed in the Plan Bucket.
        </div>
      </div>
    </Popover>
  )
  const renderHideOptionsTooltip = (
    <Popover id="renderHideOptionsTooltip">
      <div className="p-5 bg-white card">
        <div>
        This option removes inactive items from the drop-down menu, such as inactive resources or statuses, etc. Recommended to turn on.
        </div>
      </div>
    </Popover>
  )
  const renderShowLocationsTooltip = (
    <Popover id="renderShowLocationsTooltip">
      <div className="p-5 bg-white card">
        <div>This is to show the physical location of the service call in the Plan bucket and Planning Screen Items. 
        </div>
      </div>
    </Popover>
  )
  const renderPlanBucketDefaultFilterTooltip = (
    <Popover id="renderPlanBucketDefaultFilterTooltip">
      <div className="p-5 bg-white card">
        <div>
          This option selects which item-type will appear by default in your Plan bucket.
          <a className="text-decoration-underline ms-2" href="https://knowledgebase.proxuma.io/article/to-do-list-default-filters" target="_blank">Learn more.</a>
        </div>
      </div>
    </Popover>
  )
  const renderPlanningScreenMonthYearSettingTooltip = (
    <Popover id="renderPlanningScreenMonthYearSettingTooltip">
      <div className="p-5 bg-white card">
        <div>
        Select which items with a specific status will appear in your Planning Screen- Month/Year view. Use the toggle button to select which status will be highlighted.
        <a className="text-decoration-underline ms-2" href="https://knowledgebase.proxuma.io/article/planning-screen-month-and-year" target="_blank">Learn more.</a>
        </div>
      </div>
    </Popover>
  )
  
  return (
    <>
      <div
        id="kt_general_setting_details"
        className="px-4 py-6 notifyHeight overflow-auto"
      >
        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <div className="row mx-0">
            {/* General settings card start */}
            <div className="col-6 mb-6">
              <div className="card h-100">
                <div className="card-header">
                  <div className="card-title">
                    <h3 className="text-dark fw-bold fs-18 m-0">
                      General settings
                    </h3>
                    <div className="h-24px d-flex align-items-center">
                      <OverlayTrigger
                        trigger="click"
                        rootClose
                        placement="right"
                        overlay={renderGeneralSettingsTooltip}
                      >
                        <button className="btn p-0 text-center rounded-3px border lh-1 border-primary text-primary fs-6 w-10px color-white infoBtn ms-2" type="button">i</button>
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  {/* Timezone field */}
                  <div className="mb-6">
                  <div className="d-flex">
                      <label className="col-form-label fw-bold fs-16 text-dark pt-0">
                        Time zone
                      </label>
                      <div className="h-24px d-flex align-items-center">
                        <OverlayTrigger
                          trigger="click"
                          rootClose
                          placement="right"
                          overlay={renderTimezoneTooltip}
                        >
                          <button className="btn p-0 text-center rounded-3px border lh-1 border-primary text-primary fs-6 w-10px color-white infoBtn ms-2" type="button">i</button>
                        </OverlayTrigger>
                      </div>
                    </div>
                    <div className="fv-row">
                      <select
                        className="form-select fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                        {...formik.getFieldProps("timezone")}
                      >
                        <option value="">Select timezone...</option>
                        {offsetTmz?.map((option: any) => (
                          <option>{option}</option>
                        ))}
                      </select>
                      {formik.touched.timezone && formik.errors.timezone && (
                        <div className="fv-plugins-message-container">
                          {/* <div className="fv-help-block fs-14">{formik.errors?.timezone}</div> */}
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Resource daily availability field */}
                  <div className="row mb-6">
                    <div className="d-flex">
                      <label className="col-form-label fw-bold fs-16 text-dark pt-0">
                        Resource daily availability (hours)
                      </label>
                      <div className="h-24px d-flex align-items-center">
                        <OverlayTrigger
                          trigger="click"
                          rootClose
                          placement="right"
                          overlay={renderResourceAvailabilityTooltip}
                        >
                          <button className="btn p-0 text-center rounded-3px border lh-1 border-primary text-primary fs-6 w-10px color-white infoBtn ms-2" type="button">i</button>
                        </OverlayTrigger>
                      </div>
                    </div>
                    <div className="fv-row">
                      <input
                        type="number"
                        className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                        {...formik.getFieldProps("daily_availability")}
                        onPaste={preventPasteNegative}
                        onKeyPress={preventMinus}
                        min={0}
                      />
                      {formik.touched.daily_availability &&
                        formik.errors.daily_availability && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block fs-14">
                              {formik.errors.daily_availability}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>

                  {/* ALlow overlap*/}
                  <div className="mb-6">
                    <div className="d-flex">
                      <label className="col-form-label fw-bold fs-16 text-dark pt-0">
                        Allow overlap scheduling
                      </label>
                      <div className="h-24px d-flex align-items-center">
                        <OverlayTrigger
                          trigger="click"
                          rootClose
                          placement="right"
                          overlay={renderOverlapShedulingTooltip}
                        >
                          <button className="btn p-0 text-center rounded-3px border lh-1 border-primary text-primary fs-6 w-10px color-white infoBtn ms-2" type="button">i</button>
                        </OverlayTrigger>
                      </div>
                    </div>
                    <div className="fv-row">
                      <div className="form-check form-check-custom form-check-solid fs-16">
                        <input
                          className="form-check-input me-2"
                          type="radio"
                          name="allow_overlapping"
                          value={1}
                          checked={selected === true ? true : false}
                          /* onChange={() => {
                            updateData({ allow_overlapping: true });
                          }} */
                          onChange={handleAllowOverlapChange}
                        ></input>
                        <label className="form-check-label me-10">
                          <div className="fw-bolder text-gray-800">Yes</div>
                        </label>

                        <input
                          className="form-check-input me-2"
                          type="radio"
                          name="allow_overlapping"
                          value={0}
                          checked={selected === false ? true : false}
                          onChange={handleAllowOverlapChange}
                        ></input>
                        <label className="form-check-label">
                          <div className="fw-bolder text-gray-800">No</div>
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* end ALlow overlap */}

                  {/* Show task type */}
                  <div className="mb-6">
                    <div className="d-flex">
                      <label className="col-form-label fw-bold fs-16 text-dark pt-0">
                        Show plan item type in front of task?
                      </label>
                      <div className="h-24px d-flex align-items-center">
                        <OverlayTrigger
                          trigger="click"
                          rootClose
                          placement="right"
                          overlay={renderShowPlanItemTypeTooltip}
                        >
                          <button className="btn p-0 text-center rounded-3px border lh-1 border-primary text-primary fs-6 w-10px color-white infoBtn ms-2" type="button">i</button>
                        </OverlayTrigger>
                      </div>
                    </div>
                    <div className="fv-row">
                      <div className="form-check form-check-custom form-check-solid fs-16">
                        <input
                          className="form-check-input me-2"
                          type="radio"
                          name="show_task_type"
                          value={1}
                          checked={task_type === true ? true : false}
                          onChange={updateTasktypeData}
                        ></input>
                        <label className="form-check-label me-10">
                          <div className="fw-bolder text-gray-800">Yes</div>
                        </label>

                        <input
                          className="form-check-input me-2"
                          type="radio"
                          name="show_task_type"
                          value={0}
                          checked={task_type === false ? true : false}
                          onChange={updateTasktypeData}
                        ></input>
                        <label className="form-check-label">
                          <div className="fw-bolder text-gray-800">No</div>
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* end show task type */}

                  {/* o365_proxuma */}
                  <div className="mb-6">
                    <div className="d-flex">
                      <label className="col-form-label fw-bold fs-16 text-dark pt-0">
                        Outlook sync configuration
                      </label>
                      <div className="h-24px d-flex align-items-center">
                        <OverlayTrigger
                          trigger="click"
                          rootClose
                          placement="right"
                          overlay={renderOutlookSyncTooltip}
                        >
                          <button className="btn p-0 text-center rounded-3px border lh-1 border-primary text-primary fs-6 w-10px color-white infoBtn ms-2" type="button">i</button>
                        </OverlayTrigger>
                      </div>
                    </div>
                    <div className="fv-row">
                      <div className="form-check form-check-custom form-check-solid fs-16">
                        <input
                          className="form-check-input me-2"
                          type="radio"
                          name="o365_proxuma"
                          value={"o365_proxuma"}
                          checked={
                            outlooksync_type === "o365_proxuma" ? true : false
                          }
                          onChange={update365Data}
                        ></input>
                        <label className="form-check-label me-10">
                          <div className="fw-bolder text-gray-800">
                            O365 → Proxuma
                          </div>
                        </label>

                        <input
                          className="form-check-input me-2"
                          type="radio"
                          name="o365_proxuma"
                          value={"proxuma_o365"}
                          checked={
                            outlooksync_type === "proxuma_o365" ? true : false
                          }
                          onChange={update365Data}
                        ></input>
                        <label className="form-check-label me-10">
                          <div className="fw-bolder text-gray-800">
                            Proxuma → O365
                          </div>
                        </label>

                        <input
                          className="form-check-input me-2"
                          type="radio"
                          name="o365_proxuma"
                          value={"both"}
                          checked={outlooksync_type === "both" ? true : false}
                          onChange={update365Data}
                        ></input>
                        <label className="form-check-label">
                          <div className="fw-bolder text-gray-800">
                            Bidirectional
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* end o365_proxuma */}
                </div>
              </div>
            </div>
            {/* General settings card end */}

            {/* Planning screen view/time settings card start */}
            <div className="col-6 mb-6">
              <div className="card h-100">
                <div className="card-header">
                  <div className="card-title">
                    <h3 className="text-dark fw-bold fs-18 m-0">
                      Planning screen view/time settings
                    </h3>
                    <div className="h-24px d-flex align-items-center">
                      <OverlayTrigger
                        trigger="click"
                        rootClose
                        placement="right"
                        overlay={renderPlanningScreenViewSettingTooltip}
                      >
                        <button className="btn p-0 text-center rounded-3px border lh-1 border-primary text-primary fs-6 w-10px color-white infoBtn ms-2" type="button">i</button>
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  {/* Default view field */}
                  <div className="mb-6">
                    <label className="col-form-label fw-bold fs-16 text-dark pt-0">
                      Planning screen view
                    </label>
                    <div className="fv-row">
                      <select
                        className="form-select fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                        {...formik.getFieldProps("planning_screen_view")}
                      >
                        <option value="day">Day</option>
                        <option value="week" selected>
                          Week
                        </option>
                        <option value="month">Month</option>
                      </select>
                      {formik.touched.planning_screen_view &&
                        formik.errors.planning_screen_view && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block fs-14">
                              {formik.errors.planning_screen_view}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                  {/* Start time view field */}
                  <div className="mb-6">
                    <label className="col-form-label fw-bold fs-16 text-dark pt-0">
                      Start time for planning view
                    </label>
                    <div className="fv-row">
                      {starttime && (
                        <div className="form-control bg-transparent h-40px fs-16 fw-normal h-40px p-0 seletPicker">
                          <Datepicker
                            controls={["time"]}
                            value={starttime}
                            onChange={onStarttimeChange}
                            touchUi={false}
                            timeFormat={generalSetting?.time_format}
                            class={"styledPicker"}
                            max={endtime}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  {/* End time view field */}
                  <div className="mb-6">
                    <label className="col-form-label fw-bold fs-16 text-dark pt-0">
                      End time for planning view
                    </label>
                    <div className="fv-row">
                      {endtime && (
                        <div className="form-control bg-transparent h-40px fs-16 fw-normal h-40px p-0 seletPicker">
                          <Datepicker
                            controls={["time"]}
                            value={endtime}
                            onChange={onEndtimeChange}
                            touchUi={false}
                            timeFormat={generalSetting?.time_format}
                            class={"styledPicker"}
                            min={starttime}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  {/* start buffer time*/}
                  <div className="mb-6">
                    <label className="col-form-label fw-bold fs-16 text-dark pt-0">
                      Extended hours morning
                    </label>
                    <div className="row fv-row">
                      <div className="col-6">
                        {extendedMorningStart && (
                          <div className="form-control bg-transparent h-40px fs-16 fw-normal h-40px p-0 seletPicker">
                            <Datepicker
                              controls={["time"]}
                              value={extendedMorningStart}
                              onChange={onChangeExtendedMorningStart}
                              touchUi={false}
                              timeFormat={generalSetting?.time_format}
                              class={"styledPicker"}
                              max={starttime}
                            />
                          </div>
                        )}
                      </div>
                      <div className="col-6">
                        {extendedMorningEnd && (
                          <div className="form-control bg-transparent h-40px fs-16 fw-normal h-40px p-0 seletPicker">
                            <Datepicker
                              controls={["time"]}
                              value={extendedMorningEnd}
                              onChange={onChangeExtendedMorningEnd}
                              touchUi={false}
                              timeFormat={generalSetting?.time_format}
                              class={"styledPicker"}
                              max={starttime}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* end buffer time*/}
                  <div className="mb-6">
                    <label className="col-form-label fw-bold fs-16 text-dark pt-0">
                      Extended hours evening
                    </label>
                    <div className="row fv-row">
                      <div className="col-6">
                        {extendedEveningStart && (
                          <div className="form-control bg-transparent h-40px fs-16 fw-normal h-40px p-0 seletPicker">
                            <Datepicker
                              controls={["time"]}
                              value={extendedEveningStart}
                              onChange={onChangeExtendedEveningStart}
                              touchUi={false}
                              timeFormat={generalSetting?.time_format}
                              class={"styledPicker"}
                              min={endtime}
                            />
                          </div>
                        )}
                      </div>
                      <div className="col-6">
                        {extendedEveningEnd && (
                          <div className="form-control bg-transparent h-40px fs-16 fw-normal h-40px p-0 seletPicker">
                            <Datepicker
                              controls={["time"]}
                              value={extendedEveningEnd}
                              onChange={onChangeExtendedEveningEnd}
                              touchUi={false}
                              timeFormat={generalSetting?.time_format}
                              class={"styledPicker"}
                              min={endtime}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Time format field */}
                  <div className="mb-6">
                    <label className="col-form-label fw-bold fs-16 text-dark pt-0">
                      Time format for planning view
                    </label>

                    <div className="fv-row">
                      <select
                        className="form-select fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                        {...formik.getFieldProps("time_format")}
                      >
                        <option value="HH:mm">24 hour</option>
                        <option value="hh:mm A">12 hour</option>
                      </select>
                    </div>
                  </div>

                  {/* Planning first day of week field */}
                  <div className="mb-3">
                    <label className="col-form-label fw-bold fs-16 text-dark pt-0">
                      Planning first day of week
                    </label>
                    <div className="fv-row">
                      <select
                        className="form-select fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor custom-select"
                        {...formik.getFieldProps("planning_first_day_of_week")}
                      >
                        <option value="">Select first day...</option>
                        <option value="0">Sunday</option>
                        <option value="1">Monday</option>
                        <option value="2">Tuesday</option>
                        <option value="3">Wednesday</option>
                        <option value="4">Thursday</option>
                        <option value="5">Friday</option>
                        <option value="6">
                          Saturday
                        </option>
                      </select>
                      {formik.touched.planning_first_day_of_week &&
                        formik.errors.planning_first_day_of_week && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block fs-14">
                              {formik.errors.planning_first_day_of_week}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Planning screen view/time settings card end */}

            {/* Planning defaults card start */}
            <div className="col-6 mb-6">
              <div className="card h-100">
                <div className="card-header">
                  <div className="card-title">
                    <h3 className="text-dark fw-bold fs-18 m-0">
                      Planning defaults
                    </h3>
                  </div>
                </div>
                <div className="card-body">
                  {/* Servicecall duration field */}
                  <div className="mb-6">
                    <div className="d-flex">
                      <label className="col-form-label fw-bold fs-16 text-dark pt-0">
                        {AUTOTASK_ENTITY.ServiceCall.title} duration (hours)
                      </label>
                      <div className="h-24px d-flex align-items-center">
                        <OverlayTrigger
                          trigger="click"
                          rootClose
                          placement="right"
                          overlay={renderServiceCallDurationTooltip}
                        >
                          <button className="btn p-0 text-center rounded-3px border lh-1 border-primary text-primary fs-6 w-10px color-white infoBtn ms-2" type="button">i</button>
                        </OverlayTrigger>
                      </div>
                    </div>

                    <div className="fv-row">
                      <input
                        type="number"
                        className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                        onPaste={preventPasteNegative}
                        onKeyPress={preventMinus}
                        min={0}
                        {...formik.getFieldProps("service_call_duration")}
                      />
                      {formik.touched.service_call_duration &&
                        formik.errors.service_call_duration && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block fs-14">
                              {formik.errors.service_call_duration}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>

                  {/* Task duration field */}
                  <div className="mb-6">
                    <div className="d-flex">
                      <label className="col-form-label fw-bold fs-16 text-dark pt-0">
                        Task duration (hours)
                      </label>
                      <div className="h-24px d-flex align-items-center">
                        <OverlayTrigger
                          trigger="click"
                          rootClose
                          placement="right"
                          overlay={renderTaskDurationTooltip}
                        >
                          <button className="btn p-0 text-center rounded-3px border lh-1 border-primary text-primary fs-6 w-10px color-white infoBtn ms-2" type="button">i</button>
                        </OverlayTrigger>
                      </div>
                    </div>

                    <div className="fv-row">
                      <input
                        type="number"
                        className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                        onPaste={preventPasteNegative}
                        onKeyPress={preventMinus}
                        min={0}
                        {...formik.getFieldProps("task_duration")}
                      />
                      {formik.touched.task_duration &&
                        formik.errors.task_duration && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block fs-14">
                              {formik.errors.task_duration}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>

                  {/* Ticket duration field */}
                  <div className="mb-6">
                    <div className="d-flex">
                      <label className="col-form-label fw-bold fs-16 text-dark pt-0">
                        Ticket duration (hours)
                      </label>
                      <div className="h-24px d-flex align-items-center">
                        <OverlayTrigger
                          trigger="click"
                          rootClose
                          placement="right"
                          overlay={renderTicketDurationTooltip}
                        >
                          <button className="btn p-0 text-center rounded-3px border lh-1 border-primary text-primary fs-6 w-10px color-white infoBtn ms-2" type="button">i</button>
                        </OverlayTrigger>
                      </div>
                    </div>

                    <div className="fv-row">
                      <input
                        type="number"
                        className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                        onPaste={preventPasteNegative}
                        onKeyPress={preventMinus}
                        min={0}
                        {...formik.getFieldProps("ticket_duration")}
                      />
                      {formik.touched.ticket_duration &&
                        formik.errors.ticket_duration && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block fs-14">
                              {formik.errors.ticket_duration}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>

                  {/* Show skills in planningscreen field */}
                  <div className="mb-6">
                    <div className="d-flex">
                      <label className="col-form-label fw-bold fs-16 text-dark pt-0">
                        Show skills in planningscreen
                      </label>
                      <div className="h-24px d-flex align-items-center">
                        <OverlayTrigger
                          trigger="click"
                          rootClose
                          placement="right"
                          overlay={renderShowSkillInPlanningScreenTooltip}
                        >
                          <button className="btn p-0 text-center rounded-3px border lh-1 border-primary text-primary fs-6 w-10px color-white infoBtn ms-2" type="button">i</button>
                        </OverlayTrigger>
                      </div>
                    </div>
                    <div className="fv-row">
                      <div className="form-check form-check-custom form-check-solid fs-16">
                        <input
                          className="form-check-input me-2"
                          type="radio"
                          name="show_skill"
                          value={1}
                          checked={showSkill === true ? true : false}
                          /* onChange={() => {
                            updateData({ allow_overlapping: true });
                          }} */
                          onChange={handleShowSkill}
                        ></input>
                        <label className="form-check-label me-10">
                          <div className="fw-bolder text-gray-800">Yes</div>
                        </label>

                        <input
                          className="form-check-input me-2"
                          type="radio"
                          name="show_skill"
                          value={0}
                          checked={showSkill === false ? true : false}
                          onChange={handleShowSkill}
                        ></input>
                        <label className="form-check-label">
                          <div className="fw-bolder text-gray-800">No</div>
                        </label>
                      </div>
                    </div>
                  </div>

                  {/* Show appointments in planningscreen */}
                  <div className="mb-6">
                    <div className="d-flex">
                      <label className="col-form-label fw-bold fs-16 text-dark pt-0">
                        Show appointments in planningscreen
                      </label>
                      <div className="h-24px d-flex align-items-center">
                        <OverlayTrigger
                          trigger="click"
                          rootClose
                          placement="right"
                          overlay={renderAppointmentsTooltip}
                        >
                          <button className="btn p-0 text-center rounded-3px border lh-1 border-primary text-primary fs-6 w-10px color-white infoBtn ms-2" type="button">i</button>
                        </OverlayTrigger>
                      </div>
                    </div>
                    <div className="fv-row">
                      <div className="form-check form-check-custom form-check-solid fs-16">
                        <input
                          className="form-check-input me-2"
                          type="radio"
                          name="show_appointment"
                          value={1}
                          checked={showAppointment === true ? true : false}
                          onChange={handleShowAppointment}
                        ></input>
                        <label className="form-check-label me-10">
                          <div className="fw-bolder text-gray-800">Yes</div>
                        </label>

                        <input
                          className="form-check-input me-2"
                          type="radio"
                          name="show_appointment"
                          value={0}
                          checked={showAppointment === false ? true : false}
                          onChange={handleShowAppointment}
                        ></input>
                        <label className="form-check-label">
                          <div className="fw-bolder text-gray-800">No</div>
                        </label>
                      </div>
                    </div>
                  </div>

                  {/* Show appointments in project screen */}
                  <div className="mb-6">
                    <div className="d-flex">
                      <label className="col-form-label fw-bold fs-16 text-dark pt-0">
                        Show appointments in project screen
                      </label>
                      <div className="h-24px d-flex align-items-center">
                        <OverlayTrigger
                          trigger="click"
                          rootClose
                          placement="right"
                          overlay={renderAppointmentsInProjectTooltip}
                        >
                          <button className="btn p-0 text-center rounded-3px border lh-1 border-primary text-primary fs-6 w-10px color-white infoBtn ms-2" type="button">i</button>
                        </OverlayTrigger>
                      </div>
                    </div>
                    <div className="fv-row">
                      <div className="form-check form-check-custom form-check-solid fs-16">
                        <input
                          className="form-check-input me-2"
                          type="radio"
                          name="show_appointment_project"
                          value={1}
                          checked={
                            showAppointmentProject === true ? true : false
                          }
                          onChange={handleShowAppointmentProject}
                        ></input>
                        <label className="form-check-label me-10">
                          <div className="fw-bolder text-gray-800">Yes</div>
                        </label>

                        <input
                          className="form-check-input me-2"
                          type="radio"
                          name="show_appointment_project"
                          value={0}
                          checked={
                            showAppointmentProject === false ? true : false
                          }
                          onChange={handleShowAppointmentProject}
                        ></input>
                        <label className="form-check-label">
                          <div className="fw-bolder text-gray-800">No</div>
                        </label>
                      </div>
                    </div>
                  </div>

                  {/* Show notes in task/ticket */}
                  <div className="mb-6">
                    <div className="d-flex">
                      <label className="col-form-label fw-bold fs-16 text-dark pt-0">
                        Show Notes
                      </label>
                      <div className="h-24px d-flex align-items-center">
                        <OverlayTrigger
                          trigger="click"
                          rootClose
                          placement="right"
                          overlay={renderShowNotesTooltip}
                        >
                          <button className="btn p-0 text-center rounded-3px border lh-1 border-primary text-primary fs-6 w-10px color-white infoBtn ms-2" type="button">i</button>
                        </OverlayTrigger>
                      </div>
                    </div>
                    <div className="fv-row">
                      <div className="form-check form-check-custom form-check-solid fs-16">
                        <input
                          className="form-check-input me-2"
                          type="radio"
                          name="show_notes"
                          value={1}
                          checked={showNotes === true ? true : false}
                          onChange={(e: any) => handleShowNotes(e, "notes")}
                        ></input>
                        <label className="form-check-label me-10">
                          <div className="fw-bolder text-gray-800">Yes</div>
                        </label>

                        <input
                          className="form-check-input me-2"
                          type="radio"
                          name="show_notes"
                          value={0}
                          checked={showNotes === false ? true : false}
                          onChange={(e: any) => handleShowNotes(e, "notes")}
                        ></input>
                        <label className="form-check-label">
                          <div className="fw-bolder text-gray-800">No</div>
                        </label>
                      </div>
                    </div>
                  </div>

                  {/* Show internal notes in task/ticket */}
                  <div className="mb-6">
                    <div className="d-flex">
                    <label className="col-form-label fw-bold fs-16 text-dark pt-0">
                      Show Internal Notes
                    </label>
                      <div className="h-24px d-flex align-items-center">
                        <OverlayTrigger
                          trigger="click"
                          rootClose
                          placement="right"
                          overlay={renderIntarnalNotesTooltip}
                        >
                          <button className="btn p-0 text-center rounded-3px border lh-1 border-primary text-primary fs-6 w-10px color-white infoBtn ms-2" type="button">i</button>
                        </OverlayTrigger>
                      </div>
                      </div>
                    <div className="fv-row">
                      <div className="form-check form-check-custom form-check-solid fs-16">
                        <input
                          className="form-check-input me-2"
                          type="radio"
                          name="show_internal_notes"
                          value={1}
                          checked={showInternalNotes === true ? true : false}
                          onChange={(e: any) =>
                            handleShowNotes(e, "internal_note")
                          }
                        ></input>
                        <label className="form-check-label me-10">
                          <div className="fw-bolder text-gray-800">Yes</div>
                        </label>

                        <input
                          className="form-check-input me-2"
                          type="radio"
                          name="show_internal_notes"
                          value={0}
                          checked={showInternalNotes === false ? true : false}
                          onChange={(e: any) =>
                            handleShowNotes(e, "internal_note")
                          }
                        ></input>
                        <label className="form-check-label">
                          <div className="fw-bolder text-gray-800">No</div>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="mb-6">
                    <div className="d-flex">
                    <label className="col-form-label fw-bold fs-16 text-dark pt-0">
                      Hide Options That Can't Be Selected
                    </label>
                      <div className="h-24px d-flex align-items-center">
                        <OverlayTrigger
                          trigger="click"
                          rootClose
                          placement="right"
                          overlay={renderHideOptionsTooltip}
                        >
                          <button className="btn p-0 text-center rounded-3px border lh-1 border-primary text-primary fs-6 w-10px color-white infoBtn ms-2" type="button">i</button>
                        </OverlayTrigger>
                      </div>
                    </div>
                    <div className="fv-row">
                      <div className="form-check form-check-custom form-check-solid fs-16">
                        <input
                          className="form-check-input me-2"
                          type="radio"
                          name="hide_options"
                          value={1}
                          checked={hideOption}
                          onChange={handleHideOption}
                        />
                        <label className="form-check-label me-10">
                          <div className="fw-bolder text-gray-800">Yes</div>
                        </label>

                        <input
                          className="form-check-input me-2"
                          type="radio"
                          name="hide_options"
                          value={0}
                          checked={!hideOption}
                          onChange={handleHideOption}
                        />
                        <label className="form-check-label">
                          <div className="fw-bolder text-gray-800">No</div>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="mb-6">
                    <div className="d-flex">
                    <label className="col-form-label fw-bold fs-16 text-dark pt-0">
                     Show Location 
                    </label>
                      <div className="h-24px d-flex align-items-center">
                        <OverlayTrigger
                          trigger="click"
                          rootClose
                          placement="right"
                          overlay={renderShowLocationsTooltip}
                        >
                          <button className="btn p-0 text-center rounded-3px border lh-1 border-primary text-primary fs-6 w-10px color-white infoBtn ms-2" type="button">i</button>
                        </OverlayTrigger>
                      </div>
                    </div>
                    <div className="fv-row">
                      <div className="form-check form-check-custom form-check-solid fs-16">
                        <input
                          className="form-check-input me-2"
                          type="radio"
                          name="show_location"
                          value={true}
                          checked={formik.values.isLocation}
                          onChange={handleLocationOption}
                        />
                        <label className="form-check-label me-10">
                          <div className="fw-bolder text-gray-800">Yes</div>
                        </label>

                        <input
                          className="form-check-input me-2"
                          type="radio"
                          name="show_location"
                          value={false}
                          checked={!formik.values.isLocation}
                          onChange={handleLocationOption}
                        />
                        <label className="form-check-label">
                          <div className="fw-bolder text-gray-800">No</div>
                        </label>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            {/* Planning defaults card end */}

            {/* Planning screen defaults settings card start */}
            <div className="col-6 mb-6">
              <div className="card h-100">
                <div className="card-header">
                  <div className="card-title">
                    <h3 className="text-dark fw-bold fs-18 m-0">
                      Planning screen default settings
                    </h3>
                    <div className="h-24px d-flex align-items-center">
                      <OverlayTrigger
                        trigger="click"
                        rootClose
                        placement="right"
                        overlay={renderPlanningScreenDefaultSettingTooltip}
                      >
                        <button className="btn p-0 text-center rounded-3px border lh-1 border-primary text-primary fs-6 w-10px color-white infoBtn ms-2" type="button">i</button>
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="mb-6">
                    <label className="col-form-label fw-bold fs-16 text-dark pt-0">
                      Default status for plan item task
                    </label>
                    <div className="fv-row">
                      <select
                        className="form-select fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                        {...formik.getFieldProps("default_task_status")}
                      >
                        <option value="">Keep Current</option>
                        {todo_status_taskticket?.map((option: any) => (
                          <option value={option?.id}>{option?.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="mb-6">
                    <label className="col-form-label fw-bold fs-16 text-dark pt-0">
                      Default status for plan item ticket
                    </label>
                    <div className="fv-row">
                      <select
                        className="form-select fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                        {...formik.getFieldProps("default_ticket_status")}
                      >
                        <option value="">Keep Current</option>
                        {todo_status_taskticket?.map((option: any) => (
                          <option value={option?.id}>{option?.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="mb-6">
                    <label className="col-form-label fw-bold fs-16 text-dark pt-0">
                      Default status for plan item servicecall
                    </label>
                    <div className="fv-row">
                      <select
                        className="form-select fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                        {...formik.getFieldProps("default_servicecall_status")}
                      >
                        <option value="">Keep Current</option>
                        {todo_status_serviceCall?.map((option: any) => (
                          <option value={option?.id}>{option?.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="mb-6">
                    <label className="col-form-label fw-bold fs-16 text-dark pt-0">
                      Default worktype for a task
                    </label>
                    <div className="fv-row">
                      <select
                        className="form-select fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                        {...formik.getFieldProps("default_task_worktype")}
                      >
                        <option value="">Keep Current</option>
                        {workgroups?.map((option: any) => (
                          <option value={option?.value}>{option?.text}</option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="col-form-label fw-bold fs-16 text-dark pt-0">
                      Default worktype for a ticket
                    </label>
                    <div className="fv-row">
                      <select
                        className="form-select fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                        {...formik.getFieldProps("default_ticket_worktype")}
                      >
                        <option value="">Keep Current</option>
                        {workgroups?.map((option: any) => (
                          <option value={option?.value}>{option?.text}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Planning screen defaults settings card end */}

            {/* Todo list - Default filters card start */}
            <div className="col-6 mb-6">
              <div className="card h-100">
                <div className="card-header">
                  <div className="card-title">
                    <h3 className="text-dark fw-bold fs-18 m-0">
                      Plan bucket - Default filters
                    </h3>
                    <div className="h-24px d-flex align-items-center">
                      <OverlayTrigger
                        trigger="click"
                        rootClose
                        placement="right"
                        overlay={renderPlanBucketDefaultFilterTooltip}
                      >
                        <button className="btn p-0 text-center rounded-3px border lh-1 border-primary text-primary fs-6 w-10px color-white infoBtn ms-2" type="button">i</button>
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="mb-6">
                    <label className="col-form-label fw-bold fs-16 text-dark pt-0">
                      Default filter for type on Plan bucket list
                    </label>
                    <div className="fv-row">
                      <div className="form-check form-check-custom form-check-solid mb-4 fs-16">
                        <input
                          className="form-check-input me-2"
                          {...formik.getFieldProps("default_todofilter")}
                          name="default_todofilter"
                          type="checkbox"
                          value="task"
                          id="task"
                          checked={
                            formik.values?.default_todofilter?.includes("task")
                              ? true
                              : false
                          }
                        />
                        <label className="form-check-label me-5">
                          <div className="fw-bolder text-gray-800">Task</div>
                        </label>
                      </div>

                      <div className="form-check form-check-custom form-check-solid mb-4 fs-16">
                        <input
                          className="form-check-input me-2"
                          {...formik.getFieldProps("default_todofilter")}
                          name="default_todofilter"
                          type="checkbox"
                          value="ticket"
                          id="ticket"
                          checked={
                            formik.values?.default_todofilter?.includes(
                              "ticket"
                            )
                              ? true
                              : false
                          }
                        />
                        <label className="form-check-label me-5">
                          <div className="fw-bolder text-gray-800">Ticket</div>
                        </label>
                      </div>

                      <div className="form-check form-check-custom form-check-solid fs-16">
                        <input
                          className="form-check-input me-2"
                          {...formik.getFieldProps("default_todofilter")}
                          name="default_todofilter"
                          type="checkbox"
                          value="servicecall"
                          id="servicecall"
                          checked={
                            formik.values?.default_todofilter?.includes(
                              "servicecall"
                            )
                              ? true
                              : false
                          }
                        />
                        <label className="form-check-label me-5">
                          <div className="fw-bolder text-gray-800">
                            {AUTOTASK_ENTITY.ServiceCall.title}
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Todo list - Default filters card end */}

            {/* Planning screen Month/Year settings card start */}
            <div className="col-6 mb-6">
              <div className="card h-100">
                <div className="card-header">
                  <div className="card-title">
                    <h3 className="text-dark fw-bold fs-18 m-0">
                      Planning screen Month/Year settings
                    </h3>
                    <div className="h-24px d-flex align-items-center">
                      <OverlayTrigger
                        trigger="click"
                        rootClose
                        placement="right"
                        overlay={renderPlanningScreenMonthYearSettingTooltip}
                      >
                        <button className="btn p-0 text-center rounded-3px border lh-1 border-primary text-primary fs-6 w-10px color-white infoBtn ms-2" type="button">i</button>
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  {/* Month/year view filter */}
                  <div className="mb-3">
                    <div className="row">
                      <div className="col-7">
                        <label className="col-form-label fw-bold fs-16 text-dark pt-0">
                          Display hours on Servicecall status, month/year
                        </label>
                      </div>
                      <div className="col-5">
                        <label className="col-form-label fw-bold fs-16 text-dark pt-0">
                          Highlight
                        </label>
                      </div>
                    </div>
                    <div className="row overflow-auto mh-160px">
                      <div className="col-7">
                        {todo_status_serviceCall?.map((option: any) => (
                          <div className="form-check form-check-custom form-check-solid h-30px mb-4 fs-16">
                            <>
                              <input
                                className="form-check-input me-2"
                                {...formik.getFieldProps(
                                  "default_monthyearhours"
                                )}
                                name="default_monthyearhours"
                                type="checkbox"
                                value={option?.id}
                                id="default_monthyearhours"
                                disabled={option?.id == currentDefaultHighlight}
                                checked={
                                  formik.values?.default_monthyearhours?.includes(
                                    JSON.stringify(option?.id)
                                  )
                                    ? true
                                    : false
                                }
                              />
                              <label>{option?.name}</label>
                            </>
                          </div>
                        ))}
                      </div>

                      <div className="col-5">
                        {todo_status_serviceCall?.map((option: any) => (
                          <>
                            <div className="form-check form-switch form-check-custom form-check-solid mb-4 h-30px">
                              <input
                                {...formik.getFieldProps(
                                  "default_monthyearhours_highlight"
                                )}
                                className="form-check-input"
                                type="radio"
                                name="default_monthyearhours_highlight"
                                value={option?.id}
                                checked={
                                  currentDefaultHighlight == option?.id
                                    ? true
                                    : false
                                }
                                onClick={handleDefaultHighlight}
                              />
                            </div>
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                  {/* Month/year view filter */}
                </div>
              </div>
            </div>
            {/* Planning screen Month/Year settings card end */}

            <div className="col-12 text-start">
              <button
                type="reset"
                onClick={() => cancel(true)}
                className="btn btn-dark me-3 h-35px py-0 px-5 rounded-4px fs-16"
                data-kt-role-modal-action="close"
              >
                Cancel
              </button>

              <button
                type="submit"
                className="btn btn-primary h-35px py-0 px-5 rounded-4px fs-16 me-10"
                disabled={loading}
              >
                {!loading && "Save changes"}
                <Loading isLoading={loading} text={"Please wait..."}></Loading>
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default GeneralSettingView;
