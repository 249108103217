
import { BaseComponent, IBaseProps } from '../../base';
import { addDays, formatDate, getEndDate, getFirstDayOfWeek, isSameDay, makeDate } from '../../util/datetime';
import { MbscTimezonePlugin } from '../../util/datetime.types.public';
import { getDocument, getTextColor, htmlToText, listen, unlisten } from '../../util/dom';
import { MOUSE_DOWN, TOUCH_START } from '../../util/events';
import { gestureListener } from '../../util/gesture';
import { BACKSPACE, DELETE, ENTER, SPACE } from '../../util/keys';
import { isArray, isString, UNDEFINED } from '../../util/misc';
import { Observable } from '../../util/observable';
import {
  ICalendarLabelDragArgs,
  ILabelDragEndEvent,
  ILabelDragMoveEvent,
  ILabelDragOnEvent,
  ILabelDragStartEvent,
  MbscCalendarEventData,
  MbscCalendarLabel,
  MbscResource,
} from './calendar-view.types';

const stateObservables: { [key: string]: Observable<any> } = {};

// tslint:disable directive-class-suffix
// tslint:disable directive-selector
// tslint:disable no-non-null-assertion

/** @hidden */
export interface ICalendarLabelProps extends IBaseProps {
  amText?: string;
  count?: string;
  date?: number;
  dataTimezone?: string;
  displayTimezone?: string;
  drag?: boolean;
  event?: MbscCalendarLabel;
  exclusiveEndDates?: boolean;
  firstDay?: number;
  hidden?: boolean;
  id?: any;
  inactive?: boolean;
  isActiveMonth?: boolean;
  isPicker?: boolean;
  isUpdate?: boolean;
  label?: string;
  lastDay?: Date;
  more?: string;
  pmText?: string;
  resourcesMap?: { [key: string]: MbscResource };
  rtl?: boolean;
  selected?: boolean;
  showEventTooltip?: boolean;
  showText?: boolean;
  theme?: string;
  timeFormat?: string;
  timezonePlugin?: MbscTimezonePlugin;
  contentTemplate?: any;
  template?: any;
  resize?: boolean;
  width?: number;
  renderContent?(event: MbscCalendarEventData): any;
  render?(event: MbscCalendarEventData): any;
  // Events
  onClick?(args: any): void;
  onDoubleClick?(args: any): void;
  onRightClick?(args: any): void;
  onHoverIn?(args: any): void;
  onHoverOut?(args: any): void;
  onDelete?(args: any, inst: any): void;
  onDragStart?(args: any): void;
  onDragMove?(args: any): void;
  onDragEnd?(args: any): void;
  onDragModeOn?(args: any): void;
  onDragModeOff?(args: any): void;
}

/** @hidden */
export interface ICalendarLabelState {
  hasFocus?: boolean;
  hasHover?: boolean;
}

/** @hidden */

export class CalendarLabelBase extends BaseComponent<ICalendarLabelProps, any> {
  // tslint:disable variable-name
  public _color?: string;
  public _content?: any;
  public _cssClass?: string;
  public _data?: any;
  public _hasResizeStart?: boolean;
  public _hasResizeEnd?: boolean;
  public _html?: any;
  public _textColor?: string;
  public _title?: string;
  public _tabIndex?: number;

  private _doc?: Document;
  private _isDrag?: boolean;
  private _text?: string;
  private _touchTimer?: any;
  private _unlisten?: () => void;
  private _unsubscribe?: number;
  // tslint:enable variable-name

  // tslint:disable-next-line: variable-name
  public _onClick = (ev: any) => {
    if (this._isDrag) {
      ev.stopPropagation();
    } else {
      this._triggerEvent('onClick', ev);
      const s = this.s;
      const observable = stateObservables[s.id];
      if (observable && s.selected) {
        observable.next({ hasFocus: false });
      }
    }
  };

  // tslint:disable-next-line: variable-name
  public _onRightClick = (ev: any) => {
    this._triggerEvent('onRightClick', ev);
  };

  // tslint:disable-next-line: variable-name
  protected _onDocTouch = (ev: any) => {
    unlisten(this._doc, TOUCH_START, this._onDocTouch);
    unlisten(this._doc, MOUSE_DOWN, this._onDocTouch);
    this._isDrag = false;
    this._hook('onDragModeOff', {
      domEvent: ev,
      event: this.s.event,
    });
  };

  protected _mounted() {
    const opt = this.s;
    const el = this._el;
    const id = opt.id;
    const isPicker = opt.isPicker;
    let resizeDir: 'start' | 'end' | undefined;
    let observable = stateObservables[id];

    if (!observable) {
      observable = new Observable<any>();
      stateObservables[id] = observable;
    }

    this._unsubscribe = observable.subscribe(this._updateState);

    this._doc = getDocument(el);
    this._unlisten = gestureListener(el, {
      keepFocus: true,
      onBlur: () => {
        if (!isPicker) {
          observable.next({ hasFocus: false });
        }
      },
      onDoubleClick: (ev) => {
        // Prevent event creation on label double click
        ev.domEvent.stopPropagation();

        this._hook('onDoubleClick', {
          domEvent: ev.domEvent,
          label: this.s.event,
          target: el,
        });
      },
      onEnd: (ev) => {
        if (this._isDrag) {
          const s = this.s;
          const args: ICalendarLabelDragArgs = { ...ev };

          // Will prevent mousedown event on doc
          args.domEvent.preventDefault();
          args.event = s.event;
          // args.target = el;

          if (s.resize && resizeDir) {
            args.resize = true;
            args.direction = resizeDir;
          } else if (s.drag) {
            args.drag = true;
          }

          this._hook<ILabelDragEndEvent>('onDragEnd', args);

          // Turn off update, unless we're in touch update mode
          if (!s.isUpdate) {
            this._isDrag = false;
          }

          if (el && args.moved) {
            el.blur();
          }
        }
        clearTimeout(this._touchTimer);
        resizeDir = UNDEFINED;
      },
      onFocus: () => {
        if (!isPicker) {
          observable.next({ hasFocus: true });
        }
      },
      onHoverIn: (ev) => {
        if (this._isDrag || isPicker) {
          return;
        }
        observable.next({ hasHover: true });
        this._triggerEvent('onHoverIn', ev);
      },
      onHoverOut: (ev) => {
        observable.next({ hasHover: false });
        this._triggerEvent('onHoverOut', ev);
      },
      onKeyDown: (ev) => {
        const event = this.s.event;
        switch (ev.keyCode) {
          case ENTER:
          case SPACE:
            el.click();
            ev.preventDefault();
            break;
          case BACKSPACE:
          case DELETE:
            if (event && event.editable !== false) {
              this._hook('onDelete', {
                domEvent: ev,
                event,
                source: 'calendar',
              });
            }
            break;
        }
      },
      onMove: (ev) => {
        const s = this.s;
        const args: ICalendarLabelDragArgs = { ...ev };

        args.event = s.event;

        if (resizeDir) {
          args.resize = true;
          args.direction = resizeDir;
        } else if (s.drag) {
          args.drag = true;
        } else {
          return;
        }

        if (!s.event || s.event.editable === false) {
          return;
        }

        if (this._isDrag) {
          // Prevent page scroll
          args.domEvent.preventDefault();
          this._hook<ILabelDragMoveEvent>('onDragMove', args);
        } else if (Math.abs(args.deltaX) > 7 || Math.abs(args.deltaY) > 7) {
          clearTimeout(this._touchTimer);
          if (!args.isTouch) {
            this._isDrag = true;
            this._hook<ILabelDragStartEvent>('onDragStart', args);
          }
        }
      },
      onStart: (ev) => {
        const s = this.s;
        const args: ICalendarLabelDragArgs = { ...ev };
        const target: HTMLElement = args.domEvent.target as HTMLElement;

        args.event = s.event;

        if (s.resize && target.classList.contains('mbsc-calendar-label-resize')) {
          resizeDir = target.classList.contains('mbsc-calendar-label-resize-start') ? 'start' : 'end';
          args.resize = true;
          args.direction = resizeDir;
        } else if (s.drag) {
          args.drag = true;
        } else {
          return;
        }

        if (!s.event || s.event.editable === false) {
          return;
        }

        if (this._isDrag || !args.isTouch) {
          // Prevent exiting drag mode in case of touch,
          // prevent calendar swipe in case of mouse drag
          args.domEvent.stopPropagation();
        }

        if (this._isDrag) {
          this._hook<ILabelDragStartEvent>('onDragStart', args);
        } else if (args.isTouch) {
          this._touchTimer = setTimeout(() => {
            this._hook<ILabelDragOnEvent>('onDragModeOn', args);
            this._hook<ILabelDragStartEvent>('onDragStart', args);
            this._isDrag = true;
          }, 350);
        }
      },
    });

    if (this._isDrag) {
      listen(this._doc, TOUCH_START, this._onDocTouch);
      listen(this._doc, MOUSE_DOWN, this._onDocTouch);
    }
  }

  protected _destroy() {
    if (this._el) {
      this._el.blur();
    }
    if (this._unsubscribe) {
      const id = this.s.id;
      const observable = stateObservables[id];
      if (observable) {
        observable.unsubscribe(this._unsubscribe);
        if (!observable.nr) {
          delete stateObservables[id];
        }
      }
    }
    if (this._unlisten) {
      this._unlisten();
    }
    unlisten(this._doc, TOUCH_START, this._onDocTouch);
    unlisten(this._doc, MOUSE_DOWN, this._onDocTouch);
  }

  protected _render(s: ICalendarLabelProps, state: ICalendarLabelState) {
    const event = s.event;
    const d = new Date(s.date!);
    const render = s.render || s.renderContent;

    let start: Date | undefined;
    let end: Date | undefined;
    let isMultiDay: boolean | undefined = false;
    let isStart: boolean | undefined;
    let isEnd: boolean | undefined;
    let isEndStyle: boolean | undefined;
    let text: string | undefined;

    this._isDrag = this._isDrag || s.isUpdate;
    this._content = UNDEFINED;
    this._title = s.more || s.count || !s.showEventTooltip ? UNDEFINED : htmlToText(event!.tooltip || event!.title || event!.text);
    this._tabIndex = s.isActiveMonth && s.showText && !s.count && !s.isPicker ? 0 : -1;

    if (event) {
      const allDay = event.allDay;
      const tzOpt = allDay ? UNDEFINED : s;

      start = event.start ? makeDate(event.start, tzOpt) : null;
      end = event.end ? makeDate(event.end, tzOpt) : null;

      const endTime = start && end && getEndDate(s, allDay, start, end, true);
      const firstDayOfWeek = getFirstDayOfWeek(d, s);
      const lastDayOfWeek = addDays(firstDayOfWeek, 7);
      const lastDay = s.lastDay && s.lastDay < lastDayOfWeek ? s.lastDay : lastDayOfWeek;

      isMultiDay = start && endTime && !isSameDay(start, endTime);
      isStart = !isMultiDay || (start && isSameDay(start, d));
      isEnd = !isMultiDay || (endTime && isSameDay(endTime, d));
      isEndStyle = !isMultiDay || (s.showText ? endTime! < lastDay : isEnd);

      this._hasResizeStart = s.resize && isStart;
      this._hasResizeEnd = s.resize && isEndStyle;

      let color = event.color;

      if (!color && event.resource && s.resourcesMap) {
        const resource = s.resourcesMap[isArray(event.resource) ? event.resource[0] : event.resource];
        color = resource && resource.color;
      }

      if (s.showText) {
        this._textColor = color ? getTextColor(color) : UNDEFINED;
      }

      this._color = s.render || s.template ? UNDEFINED : event.textColor && !color ? 'transparent' : color;
    }

    if (event && s.showText && (render || s.contentTemplate || s.template)) {
      const fillsAllDay = event.allDay || !start || (isMultiDay && !isStart && !isEnd);
      this._data = {
        end: !fillsAllDay && isEnd && end ? formatDate(s.timeFormat!, end, s) : '',
        id: event.id,
        isMultiDay,
        original: event,
        start: !fillsAllDay && isStart && start ? formatDate(s.timeFormat!, start, s) : '',
        title: this._title,
      };

      if (render) {
        const content = render(this._data);
        if (isString(content)) {
          text = content;
        } else {
          this._content = content;
        }
      }
    } else {
      text = s.more || s.count || (s.showText ? event!.title || event!.text || '' : '');
    }

    if (text !== this._text) {
      this._text = text;
      this._html = text ? this._safeHtml(text) : UNDEFINED;
      this._shouldEnhance = text && event && s.showText && !!render;
    }

    this._cssClass =
      'mbsc-calendar-text' +
      this._theme +
      this._rtl +
      ((state.hasFocus && !s.inactive && !s.selected) || (s.selected && s.showText) ? ' mbsc-calendar-label-active ' : '') +
      (state.hasHover && !s.inactive && !this._isDrag ? ' mbsc-calendar-label-hover' : '') +
      (s.more ? ' mbsc-calendar-text-more' : s.render || s.template ? ' mbsc-calendar-custom-label' : ' mbsc-calendar-label') +
      (s.inactive ? ' mbsc-calendar-label-inactive' : '') +
      (s.isUpdate ? ' mbsc-calendar-label-dragging' : '') +
      (s.hidden ? ' mbsc-calendar-label-hidden' : '') +
      (isStart ? ' mbsc-calendar-label-start' : '') +
      (isEndStyle ? ' mbsc-calendar-label-end' : '') +
      (event && event.editable === false ? ' mbsc-readonly-event' : '') +
      (event && event.cssClass ? ' ' + event.cssClass : '');
  }

  // tslint:disable-next-line: variable-name
  private _updateState = (args: ICalendarLabelState) => {
    if (this.s.showText) {
      this.setState(args);
    }
  };

  // tslint:disable-next-line: variable-name
  private _triggerEvent = (name: string, ev: any) => {
    this._hook(name, {
      domEvent: ev,
      label: this.s.event,
      target: this._el,
    });
  };
}
