
import { BaseComponent } from '../../base';
import { options as globals } from '../../commons';
import { IPopupButton, PopupBase, processButtons } from '../../components/popup/popup';
import { matches, trigger, win } from '../../util/dom';
import { CHANGE, INPUT } from '../../util/events';
import { isEmpty, UNDEFINED } from '../../util/misc';
import { os, touchUi } from '../../util/platform';
import { resizeObserver } from '../../util/resize-observer';
import {
  IPickerCancelEvent,
  IPickerChangeEvent,
  IPickerCloseEvent,
  IPickerOpenEvent,
  IPickerProps,
  IPickerState,
  IPickerTempChangeEvent,
} from './picker.types';
import { getNativeElement, initPickerElement } from './picker.util';

// tslint:disable no-non-null-assertion
// tslint:disable directive-class-suffix
// tslint:disable directive-selector

/** @hidden */

export class PickerBase<
  PropType extends IPickerProps = IPickerProps,
  StateType extends IPickerState = IPickerState,
  ValueType = any,
  ValueRepType = any,
> extends BaseComponent<PropType, StateType> {
  public static defaults: IPickerProps = {
    cancelText: 'Cancel',
    closeText: 'Close',
    focusOnClose: os !== 'android', // Don't focus on Android, because the picker won't open again with TalkBack on
    okText: 'Ok',
    setText: 'Set',
    showOnFocus: touchUi, // Need this, because click is not fired on Android with TalkBack on
  };

  // tslint:disable variable-name

  /** @hidden */
  public _allowTyping?: boolean;
  /** @hidden */
  public _anchor?: HTMLElement;
  /** @hidden */
  public _anchorAlign!: 'start' | 'end' | 'center';
  /** @hidden */
  public _buttons?: IPopupButton[];
  /** @hidden */
  public _cssClass?: string;
  /** @hidden */
  public _focusElm!: HTMLElement;
  /** @hidden */
  public _headerText?: string;
  /** @hidden */
  public _isOpen?: boolean;
  /** @hidden */
  public _live?: boolean;
  /** @hidden */
  public _maxWidth?: string | number;
  /** @hidden */
  public _popup!: PopupBase | null;
  /** @hidden */
  public _preventShow?: boolean;
  /** @hidden */
  public _scrollLock?: boolean;
  /** @hidden */
  public _showInput?: boolean;
  /** @hidden */
  public _tempValueRep!: ValueRepType;
  /** @hidden */
  public _tempValueText!: string;
  /** @hidden */
  public _value?: ValueType;
  /** @hidden */
  public _valueRep!: ValueRepType;
  /** @hidden */
  public _valueText!: string;
  /** @hidden */
  public _wrapper!: HTMLElement | null;

  /**
   * In case of angular directives, this property will hold the dynamically
   * create instance of the component. In other cases this will be undefined.
   */
  protected _inst?: PickerBase<PropType, StateType, ValueType, ValueRepType>;
  protected _valueTextChange?: boolean;
  protected _oldValueText?: string;
  protected _shouldInitInput?: boolean;
  /** Flag for skipping the value parsing on open. When the tempValue is set programmatically, the selected value
   * shouldn't be parsed and put to the temp, because it would overwrite the temporarily set value.
   */
  protected _tempValueSet?: boolean;
  /** Does the picker support the null value
   * If the null value is not supported by the picker, it will trigger a change when the value differs after parse.
   * If the null value is supported by the picker, it will not trigger a change when the tempValueRep changes after parse.
   */
  protected _nullSupport = true;

  protected _preventChange?: boolean;

  private _handler: any;
  private _input?: HTMLInputElement;
  private _observer: any;
  private _tempValue?: ValueType;
  private _resetEl?: () => void;

  /**
   * Opens the component.
   */
  public open() {
    if (this._inst) {
      this._inst.open();
      return;
    }
    if (this.s.isOpen === UNDEFINED) {
      this.setState({ isOpen: true });
    }
  }

  /**
   * Closes the component.
   */
  public close() {
    if (this.s.display === 'inline') {
      return;
    }

    if (this._inst) {
      this._inst.close();
      return;
    }

    const args = {
      value: this.value,
      valueText: this._valueText,
    };

    if (this.s.isOpen === UNDEFINED) {
      this.setState({ isOpen: false });
    }

    this._hook<IPickerCloseEvent>('onClose', args);
  }

  /** @hidden */
  public set() {
    this._valueRep = this._copy(this._tempValueRep);
    this._valueText = this._tempValueText;
    this._value = this.value = this._get(this._valueRep);
    this._valueChange(this.value);
  }

  /**
   * Recalculates the position of the component (if not inline).
   */
  public position() {
    if (this._inst) {
      this._inst.position();
      return;
    }
    if (this._popup) {
      this._popup.position();
    }
  }

  /** Returns a boolean indicating whether the component is visible or not. */
  public isVisible(): boolean {
    if (this._inst) {
      return this._inst.isVisible();
    }
    return !!this._popup && this._popup.isVisible();
  }

  /** @hidden */
  public getVal() {
    return this._nullSupport && isEmpty(this._value) ? (this.s.selectMultiple ? [] : null) : this._get(this._valueRep);
  }

  /** @hidden */
  public setVal(value: ValueType) {
    this.value = value;
    this.setState({ value });
  }

  /**
   * Returns the temporary value that's selected on the picker.
   *
   * Depending on how the picker is [displayed](#opt-display), the selection might be in a temporary state
   * that hasn't been set yet. This temporary value can be acquired calling the getTempVal method on the
   * picker instance.
   * @returns The return value type depends on the [returnFormat](#opt-returnFormat) and the
   * [select](#opt-select) option.
   */
  public getTempVal(): ValueType {
    return this._get(this._tempValueRep);
  }

  /**
   * Sets the Picker temporary value. This temp value is shown on the picker until the selection.
   * In the case of inline mode or when the [touchUi](#opt-touchUi) option is false the value will be set to the Model as well,
   * since in these cases there's no temporary value.
   * @param value The value to set to the Datepicker as temporary value
   */
  public setTempVal(value: ValueType) {
    this._tempValueSet = true;
    this._tempValueRep = this._parse(value);
    this._setOrUpdate(true);
  }

  /** @hidden */
  public _onInputChange = (ev: any, val?: string) => {
    // In case of tag input the value will come in the event detail, when tag clear is clicked
    const value = ev.detail || (val !== UNDEFINED ? val : ev.target.value);
    if (value !== this._tempValueText && !this._preventChange) {
      this._readValue(value, true);
      // Make sure to write the correct value to the input, if validation changed it
      this._valueTextChange = value !== this._tempValueText;
      const newValue = isEmpty(value) ? null : this._get(this._tempValueRep);
      this.value = newValue;
      this._valueChange(newValue);
    }
    this._preventChange = false;
  };

  /** @hidden */
  public _onResize = (args: any) => {
    this._hook('onResize', args);
  };

  /** @hidden */
  public _onWrapperResize = () => {
    if (this._wrapper) {
      this._onResize({ windowWidth: this._wrapper.offsetWidth });
    }
  };

  /** @hidden */
  public _onPopupClose = (args: any) => {
    // Trigger the onCancel event if close happened from Cancel button click,
    // Esc key, overlay click or page scroll
    if (/cancel|esc|overlay|scroll/.test(args.source)) {
      this._hook<IPickerCancelEvent>('onCancel', {
        value: this.value,
        valueText: this._valueText,
      });
    }
    this.close();
  };

  /** @hidden */
  public _onPopupClosed = (args: any) => {
    if (args.focus) {
      this._preventShow = true;
    }
    this._hook<IPickerCloseEvent>('onClosed', args);
    this._onClosed();
  };

  /** @hidden */
  public _onPopupKey = (args: any) => {
    if (args.keyCode === 13) {
      this._onEnterKey(args);
    }
  };

  /** @hidden */
  public _onPopupOpen = (args: any) => {
    args.value = this.value;
    args.valueText = this._valueText;
    this._hook<IPickerOpenEvent>('onOpen', args);
  };

  /** @hidden */
  public _onButtonClick = ({ domEvent, button }: { domEvent: any; button: IPopupButton }) => {
    if (button.name === 'set') {
      this.set();
    }
    if (this._popup) {
      this._popup._onButtonClick({ domEvent, button });
    }
  };

  /** @hidden */
  public _setInput = (inp: any) => {
    this._el = inp && inp.nativeElement ? (inp.nativeElement as HTMLInputElement) : inp;
  };

  /** @hidden */
  public _setPopup = (popup: any) => {
    this._popup = popup;
  };

  /** @hidden */
  public _setWrapper = (wrapper: any) => {
    this._wrapper = wrapper;
  };

  /** @hidden */
  public _shouldValidate(s: IPickerProps, prevS: IPickerProps) {
    return false;
  }

  /** @hidden */
  public _valueEquals(v1?: ValueType, v2?: ValueType): boolean {
    return v1 === v2;
  }

  /** @hidden */
  // tslint:disable-next-line: no-empty
  public _change(value: ValueType | null) {}

  // tslint:enable variable-name

  protected _render(s: IPickerProps, state: IPickerState) {
    const props: IPickerProps = this.props || {};
    const resp: IPickerProps = this._respProps || {};
    const prevS = this._prevS;

    if (!this._touchUi) {
      s.display = resp.display || props.display || globals.display || 'anchored';
      s.showArrow = resp.showArrow || props.showArrow || false;
    }

    // 'bubble' is deprecated, renamed to 'anchored'
    if (s.display === 'bubble') {
      s.display = 'anchored';
    }

    this._scrollLock = s.scrollLock;

    const isOpen = s.isOpen !== UNDEFINED ? s.isOpen : state.isOpen!;
    const modelValue = s.modelValue !== UNDEFINED ? s.modelValue : s.value;
    const value =
      modelValue !== UNDEFINED
        ? modelValue // Controlled
        : state.value === UNDEFINED
        ? s.defaultValue
        : state.value; // Uncontrolled

    this._showInput = s.showInput !== UNDEFINED ? s.showInput : s.display !== 'inline' && s.element === UNDEFINED;

    if (
      !this._buttons ||
      s.buttons !== prevS.buttons ||
      s.display !== prevS.display ||
      s.setText !== prevS.setText ||
      s.cancelText !== prevS.cancelText ||
      s.closeText !== prevS.closeText ||
      s.touchUi !== prevS.touchUi
    ) {
      // If no buttons given, in inline mode and desktop anchored mode defaults to no buttons,
      // all other cases will have set and cancel by default
      this._buttons = processButtons(
        this,
        s.buttons || (s.display !== 'inline' && (s.display !== 'anchored' || this._touchUi) ? ['cancel', 'set'] : []),
      );
      // If no set button is found, live mode is activated
      this._live = true;
      if (this._buttons && this._buttons.length) {
        for (const b of this._buttons) {
          if (b.name === 'ok' || b.name === 'set') {
            this._live = false;
          }
        }
      }
    }

    // Parse and validate the value when needed:
    // - when the value changed
    // - when there's no value yet
    // - when _shouldValidate returns true, depending on the picker implementation,
    // e.g. in case of datetime scroller value should be parsed again if wheels are changed
    // - when invalid, valid, or defaultSelection options are changed
    // Skip parse if value is changed from the UI - e.g. wheel scroll,
    // in this case validation runs on change and the value representation is updated in place.
    const valueChange = !this._valueEquals(value, this._value);
    if (
      valueChange ||
      this._tempValueRep === UNDEFINED ||
      this._shouldValidate(s, prevS) ||
      s.defaultSelection !== prevS.defaultSelection ||
      s.invalid !== prevS.invalid ||
      s.valid !== prevS.valid
    ) {
      // we need to save the tempValue, for later checks if the onTempValueChange should be raised
      // const oldTempValueRep = this._tempValueRep ? this._copy(this._tempValueRep) : null;
      // const oldTempValue = this._tempValueRep ? this._get(oldTempValueRep) : UNDEFINED;
      this._readValue(value);

      // Trigger onChange if validation changed the value again
      const newValue = this._get(this._tempValueRep);
      const validationChange = !this._valueEquals(value, newValue) && (!this._nullSupport || !isEmpty(value));

      this._setHeader();

      clearTimeout(this._handler);
      this._handler = setTimeout(() => {
        this.value = value;
        if (validationChange) {
          this._valueChange(newValue);
        }
        // in the case of angular directives, there will be two value changes, one for the directive
        // and one for the dynamically created component. Event emitters are forwarded from the dyn. component,
        // so there's no need to trigger the onTempChange again for the directive
        if (!this._valueEquals(this._tempValue, newValue) && this._inst === UNDEFINED) {
          this._hook<IPickerTempChangeEvent>('onTempChange', { value: newValue });
        }
      });
    }

    if (s.headerText !== prevS.headerText) {
      this._setHeader();
    }

    if (isOpen && !this._isOpen) {
      if (!this._tempValueSet || this._live) {
        const tempValue = this._get(this._tempValueRep);
        const parsedValue = this._get(this._valueRep);
        this._tempValueRep = this._copy(this._valueRep);
        this._tempValueText = this._format(this._tempValueRep); // this._valueText;
        this._tempValue = tempValue;
        this._setHeader();
        if (!this._valueEquals(tempValue, parsedValue)) {
          setTimeout(() => {
            // we cannot make a hook in render
            this._hook<IPickerTempChangeEvent>('onTempChange', { value: parsedValue });
          });
        }
      }
      this._onOpen();
    }

    this._allowTyping = s.inputTyping && !touchUi && !this._touchUi;
    this._anchorAlign = s.anchorAlign || (this._touchUi ? 'center' : 'start');
    this._cssClass = 'mbsc-picker ' + (s.cssClass || '');
    this._isOpen = isOpen;
    this._maxWidth = s.maxWidth;
    this._valueTextChange = this._valueTextChange || this._oldValueText !== this._valueText;
    this._oldValueText = this._valueText;
    this._value = value;
    this._shouldInitInput =
      this._shouldInitInput ||
      prevS.display === UNDEFINED ||
      (s.display === 'inline' && prevS.display !== 'inline') ||
      (s.display !== 'inline' && prevS.display === 'inline') ||
      s.element !== prevS.element;
  }

  protected _updated() {
    const s = this.s;
    const input = this._input;

    // we should initialize the components on the given input only, the directives should initialize the inputs
    // otherwise a single input will have the event handlers twice
    // (once for the directive and once for the dyn. created component)
    if (this._shouldInitInput && !this._inst) {
      this._unlisten();
      if (this._wrapper && s.display === 'inline') {
        this._observer = resizeObserver(this._wrapper, this._onWrapperResize, this._zone);
      }
      getNativeElement(s.element || this._el, (el) => {
        this._el = el;

        if (s.display !== 'inline') {
          this._resetEl = initPickerElement(el, this as any, this._onInputChange);
        }

        if (matches(el, 'input,select')) {
          this._input = el as HTMLInputElement;
          // Write the value (needs to happen after the event listeners were added)
          this._write(el as HTMLInputElement);
        }
      });
    }
    // Write the value to the input
    if (this._valueTextChange && input) {
      this._write(input);
    }
    // Needed for the responsive options to kick in on init
    setTimeout(() => {
      if (s.responsive && s.display !== 'inline' && win && this.state.width === UNDEFINED) {
        this._onResize({ windowWidth: win.innerWidth });
      }
    });
    this._shouldInitInput = false;
    this._valueTextChange = false;
    this._anchor = s.anchor || this._focusElm || s.element || this._el;
  }

  /**
   * Writes the value to the element and returns if the value was changed
   * @param elm The HTML element the value should be written to
   * @param text The value text that's written into the element
   */
  protected _writeValue(elm: HTMLInputElement, text: string, value?: ValueType): boolean {
    const oldValue = elm.value;
    elm.value = text;
    return oldValue !== text;
  }

  protected _destroy() {
    this._unlisten();
    this._shouldInitInput = true; // to work in React strict mode
  }

  protected _setHeader() {
    const headerText = this.s.headerText;
    this._headerText = headerText ? headerText.replace(/\{value\}/i, this._tempValueText || '&nbsp;') : UNDEFINED;
  }

  protected _setOrUpdate(preventChange?: boolean) {
    const value = this._get(this._tempValueRep);
    this._tempValue = value;
    this._tempValueText = this._format(this._tempValueRep);
    this._setHeader();
    if (!preventChange) {
      this._hook<IPickerTempChangeEvent>('onTempChange', { value });
    }
    if (this._live) {
      this.set();
    } else {
      this.forceUpdate();
    }
  }

  // tslint:disable variable-name

  /**
   * Returns a copy of the value representation.
   * Is used to copy the temporary value to the final value and vice versa.
   * @param value The value to copy.
   */
  protected _copy(value: ValueRepType): ValueRepType {
    return value;
  }

  /**
   * Formats the value representation into a string to display the selection.
   * @param value The value to format.
   */
  protected _format(value: ValueRepType): string {
    return value as any as string;
  }

  /**
   * Transforms the value representation into the actual value.
   * E.g. in case of date scroller the value is represented as an array like [5, 28, 2020],
   * this function will transform it into a date object.
   * @param value The value to transform.
   */
  protected _get(value: ValueRepType): ValueType {
    return value as any as ValueType;
  }

  /**
   * Parses a string or actual value into the value representation.
   * E.g. in case of the date scroller the '05/28/2020' string should be parsed into [5, 28, 2020].
   * @param valueText The value to parse.
   */
  protected _parse(valueText: string | ValueType, fromInput?: boolean): ValueRepType {
    return valueText as any as ValueRepType;
  }

  // tslint:disable-next-line: no-empty
  protected _validate() {}

  // tslint:disable-next-line: no-empty
  protected _onClosed() {}

  // tslint:disable-next-line: no-empty
  protected _onOpen() {}

  // tslint:disable-next-line: no-empty
  protected _onParse() {}

  /**
   * Default behavior for the enter key in a picker to set the selection and close the picker
   * @param args
   */
  protected _onEnterKey(args: any) {
    this.set();
    this.close();
  }

  // tslint:enable variable-name

  private _valueChange(value: ValueType | null) {
    if (this.s.value === UNDEFINED) {
      this.setState({ value });
    }
    this._change(value);

    this._hook<IPickerChangeEvent>('onChange', {
      value,
      valueText: this._tempValueText,
    });
  }

  private _readValue(value: string | ValueType, fromInput?: boolean) {
    this._tempValueRep = this._parse(value, fromInput);
    this._onParse();
    this._validate();
    this._tempValueText = this._format(this._tempValueRep);
    this._valueRep = this._copy(this._tempValueRep);
    this._valueText = !isEmpty(value) ? this._tempValueText : '';
  }

  private _unlisten() {
    if (this._resetEl) {
      this._resetEl();
      this._resetEl = UNDEFINED;
    }
    if (this._observer) {
      this._observer.detach();
      this._observer = UNDEFINED;
    }
  }

  private _write(input: HTMLInputElement) {
    const value = this._value;
    const changed = this._writeValue(input, this._valueText || '', value);
    if (changed) {
      setTimeout(() => {
        this._preventChange = true;
        trigger(input, INPUT);
        trigger(input, CHANGE);
      });
    }
    // In case of jquery/js mobiscroll input, pass pickerMap and pickerValue to the input, needed for tags
    const mbscInput = (input as any).__mbscFormInst;
    if (mbscInput) {
      mbscInput.setOptions({ pickerMap: this.s.valueMap, pickerValue: value });
    }
  }
}
