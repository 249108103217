/** @jsxRuntime classic */
/** @jsx createElement */
import { createElement } from '../../../react/renderer';
import { IScrollviewBaseProps, ScrollviewBase } from './scroll-view';

export function template(s: IScrollviewBaseProps, inst: ScrollviewBase, content: any) {
  let content3d: any;

  if (s.itemRenderer) {
    content = inst.visibleItems.map((item) => s.itemRenderer(item, inst._offset));
    if (s.scroll3d) {
      content3d = inst.visible3dItems.map((item) => s.itemRenderer(item, inst._offset, true));
    }
  }
  // TODO: forward other props as well
  return (
    <div ref={inst._setEl} className={inst._cssClass} style={s.styles}>
      <div ref={inst._setInnerEl} className={s.innerClass} style={s.innerStyles}>
        <div ref={inst._setScrollEl} className={'mbsc-scrollview-scroll' + inst._rtl}>
          {content}
        </div>
      </div>
      {s.scroll3d && (
        <div ref={inst._setScrollEl3d} style={{ height: s.itemSize + 'px' }} className={'mbsc-scroller-items-3d'}>
          {content3d}
        </div>
      )}
      <div
        ref={inst._setScrollbarContEl}
        className={
          'mbsc-scroller-bar-cont ' +
          inst._rtl +
          (!s.scrollBar || inst._barSize === inst._barContSize ? ' mbsc-scroller-bar-hidden' : '') +
          (inst._started ? ' mbsc-scroller-bar-started' : '')
        }
      >
        <div className={'mbsc-scroller-bar' + inst._theme} ref={inst._setScrollbarEl} style={{ height: inst._barSize + 'px' }} />
      </div>
    </div>
  );
}

export class Scrollview extends ScrollviewBase {
  protected _template(s: IScrollviewBaseProps): any {
    return template(s, this, s.children);
  }
}
