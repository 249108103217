import { FC, useEffect } from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { useAuth } from "../../auth";

const Error404: FC = () => {
  const { currentUser } = useAuth();

  const handleReturnHome = () => {
      const currentSubdomain = window.location.hostname.split(".")[0];
      const currentURL = window.location.href;
      let newURL = currentURL.replace(`${currentSubdomain}.`, '');
      newURL = newURL.replace('/error/404', '');

      const homeLinkPath =
        currentUser?.data?.user?.subdomain
          ? "/"
          : localStorage.getItem('isRedirectOnMainDomain') == 'true'
          ? newURL + "/auth"
          : '/auth';

      localStorage.setItem('isRedirectOnMainDomain','');
      window.location.href = homeLinkPath;
  };


  const disableSplashScreen = () => {
    const splashScreen = document.getElementById("splash-screen");
    if (splashScreen) {
      splashScreen.style.setProperty("display", "none");
    }
  };

  useEffect(() => {
    disableSplashScreen()
  }, [])


  return (
    <div className="d-flex flex-column flex-root">
      <div className="d-flex flex-column flex-center flex-column-fluid p-10">
        {/* begin::Illustration */}
        <img
          src={toAbsoluteUrl("/media/illustrations/sketchy-1/18.png")}
          alt=""
          className="mw-100 mb-10 h-lg-450px"
        />
        {/* end::Illustration */}
        {/* begin::Message */}
        <h1 className="fw-bold mb-10" style={{ color: "#A3A3C7" }}>
          Seems there is nothing here
        </h1>
        {/* end::Message */}
        {/* begin::Link */}
        {/* <button className="btn btn-primary" onClick={handleReturnHome}>
          Return Home
        </button> */}
        {/* end::Link */}
      </div>
    </div>
  );
};

export { Error404 };
