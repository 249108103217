/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useEffect } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { Registration } from "./components/Registration";
import { ForgotPassword } from "./components/ForgotPassword";
import { SetupPassword } from "./components/SetupPassword";
import { Login } from "./components/Login";
import {RedirectMSFT} from "./components/RedirectMSFT";
import { LoggedOut } from './components/LoggedOut';
import { KTSVG, toAbsoluteUrl } from "../../../_metronic/helpers";
import { ResetPassword } from "./components/ResetPassword";
const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add("bg-body");
    return () => {
      document.body.classList.remove("bg-body");
    };
  }, []);

  return (
    <div className="d-flex flex-column flex-root login" id="kt_app_root">
      <div className="d-flex flex-column flex-lg-row flex-column-fluid">
        <div
          className="d-flex align-items-end justify-content-center w-40 bgi-size-cover p-10 p-sm-15 loginLeft position-relative"
          style={{
            backgroundImage: `url(${toAbsoluteUrl("/media/misc/login-bg.jpg")})`,
          }}
        >
          {/* <div className="logoName text-white fs-1">Proxuma</div> */}
          <div className="position-absolute top-50 start-50 translate-middle">        
            <img
                alt='Proxuma'
                className='h-auto logo w-250px'
                src={toAbsoluteUrl('/media/logos/horizontal-dark-bg.svg')}
            />
          </div>

          <div className="position-absolute start-0 bottom-0 fs-16 text-white ms-4 ps-4 mb-10 pb-10 fw-bold">{process.env.REACT_APP_RELEASE_VERSION}</div>

        </div>

        <div className="d-flex flex-column flex-grow-1 justify-content-md-center flex-lg-row-fluid w-60 p-10 pt-sm-15 pb-sm-15 loginForm">
          <div className="d-flex flex-center flex-column flex-lg-row-fluid">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path="login" element={<Login />} />
      <Route path="registration" element={<Registration />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="setup-password" element={<SetupPassword />} />
      <Route path="reset_password" element={<ResetPassword />} />
      <Route path="redirectmsft/" element={<RedirectMSFT />} />
      <Route path="loggedout" element={<LoggedOut />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
);

export { AuthPage };