/** @jsxRuntime classic */
/** @jsx createElement */
import { Icon } from '../../../../react/components/icon';
import {
  createElement,
  Fragment,
  isPreact,
  isVue,
  ON_CONTEXT_MENU,
  ON_DOUBLE_CLICK,
  ON_MOUSE_LEAVE,
  ON_MOUSE_MOVE,
} from '../../../../react/renderer';
import { ScheduleEvent } from '../../../../react/shared/schedule-event';
import { TimeIndicator } from '../../../../react/shared/time-indicator';
import { MbscCalendarEventData, MbscResource } from '../../../shared/calendar-view/calendar-view.types';
import { computeEventResize } from '../../../shared/calendar-view/calendar-view.util';
import { checkDateRangeOverlap, ONE_DAY } from '../../../util/datetime';
import { isString, UNDEFINED } from '../../../util/misc';
import { MbscSlotData } from '../eventcalendar.types';
import { IDailyData, IDayData } from '../shared/schedule-timeline-base.types';
import { DEF_ID, getCellDate } from '../shared/schedule-timeline-base.util';
import { ITimelineOptions, ITimelineState, TimelineBase } from './timeline';

import '../shared/schedule-timeline-base.scss';
import './timeline.scss';

export function template(s: ITimelineOptions, state: ITimelineState, inst: TimelineBase): any {
  const dragData = state.dragData;
  const draggedEventId = dragData && dragData.draggedEvent && dragData.draggedEvent.id;
  const hasSlots = inst._hasSlots;
  const hb = inst._hb;
  const rtl = inst._rtl;
  const times = inst._times;
  const theme = inst._theme;
  const startTime = inst._startTime;
  const endTime = inst._endTime;
  const stepLabel = inst._stepLabel;
  const slots = inst._slots;
  const source = 'timeline';
  const isListing = s.eventList;
  const isMonthView = s.type === 'month';
  const isHourly = inst._stepCell < ONE_DAY;
  const startCellStyle = inst._startCellStyle;
  const endCellStyle = inst._endCellStyle;
  const daysBatch = inst._daysBatch;
  const headerHeight = { height: state.headerHeight + 'px' };
  const footerHeight = { height: state.footerHeight + 'px' };
  const days = inst._days;
  const daysNr = inst._daysNr;
  const dayIndex = state.dayIndex || 0;
  const isDailyResolution = inst._isDailyResolution;
  const hasResY = inst._hasResY;
  const hasResources = inst._hasResources;
  const hasFooter =
    s.renderHourFooter || s.renderDayFooter || s.renderQuarterFooter || s.renderWeekFooter || s.renderMonthFooter || s.renderYearFooter;
  const hasRows = inst._hasRows;
  const colClass = inst._colClass;
  const dragCol = inst._dragCol;
  const svgProps = { [isVue ? 'class' : 'className']: 'mbsc-connections' + theme };
  const handlers = {
    [ON_MOUSE_MOVE]: inst._onMouseMove,
    [ON_MOUSE_LEAVE]: inst._onMouseLeave,
  };

  const renderSlot = (args: MbscSlotData) => {
    const slot = args.slot;
    let content = slot.name;
    let html: any;
    if (s.renderSlot) {
      content = s.renderSlot(args);
      if (isString(content)) {
        html = inst._safeHtml(content);
        inst._shouldEnhance = true;
      }
    }
    // eslint-disable-next-line react/no-danger-with-children
    return (
      <div key={slot.id} className="mbsc-timeline-slot-title" dangerouslySetInnerHTML={isVue ? UNDEFINED : html}>
        {content}
      </div>
    );
  };

  const renderHeaderResolution = (day: IDayData) => {
    switch (s.resolution) {
      case 'week':
        if (s.renderWeek) {
          return renderWeek(day, false);
        }
        break;
      case 'month':
        if (s.renderMonth) {
          return renderMonth(day, false);
        }
        break;
      case 'quarter':
        if (s.renderQuarter) {
          return renderQuarter(day);
        }
        break;
      case 'year':
        if (s.renderYear) {
          return renderYear(day);
        }
        break;
    }

    return day.columnTitle;
  };

  const renderFooterResolution = (day: IDayData) => {
    switch (s.resolution) {
      case 'week':
        if (s.renderWeekFooter) {
          return renderWeekFooter(day);
        }
        break;
      case 'month':
        if (s.renderMonthFooter) {
          return renderMonthFooter(day);
        }
        break;
      case 'quarter':
        if (s.renderQuarterFooter) {
          return renderQuarterFooter(day);
        }
        break;
      case 'year':
        if (s.renderYearFooter) {
          return renderYearFooter(day);
        }
        break;
    }
    return;
  };

  const renderHour = (hour: IDayData, timestamp: number) => {
    let content: any;
    let html: any;

    if (inst._displayTime && inst._timeLabels[timestamp]) {
      if (s.renderHour) {
        const ms = +hour.date + timestamp;
        content = s.renderHour({
          date: new Date(ms),
          events: hour.eventMap[ms] || [],
          isActive: hour.isActive,
        });
        if (isString(content)) {
          html = inst._safeHtml(content);
          inst._shouldEnhance = true;
        }
      } else {
        content = inst._timeLabels[timestamp];
      }
    }

    return (
      <div
        key={timestamp}
        aria-hidden="true"
        className={'mbsc-timeline-header-time mbsc-flex-1-1' + theme}
        dangerouslySetInnerHTML={isVue ? UNDEFINED : html}
      >
        {content}
      </div>
    );
  };

  const renderHourFooter = (hour: IDayData, timestamp: number) => {
    let content: any;
    let html: any;

    if (s.renderHourFooter && inst._displayTime && inst._timeLabels[timestamp]) {
      const ms = +hour.date + timestamp;
      content = s.renderHourFooter({
        date: new Date(ms),
        events: hour.eventMap[ms] || [],
        isActive: hour.isActive,
      });
      if (isString(content)) {
        html = inst._safeHtml(content);
        inst._shouldEnhance = true;
      }
    }

    return (
      <div
        key={timestamp}
        className={'mbsc-timeline-footer-time mbsc-flex-1-1 ' + theme}
        dangerouslySetInnerHTML={isVue ? UNDEFINED : html}
      >
        {content}
      </div>
    );
  };

  const renderDay = (day: IDayData, sticky?: boolean) => {
    let content: any;
    let html: any;

    if (s.renderDay) {
      content = s.renderDay({
        date: day.date,
        events: day.eventMap.all,
        isActive: day.isActive,
      });
      if (isString(content)) {
        html = inst._safeHtml(content);
        inst._shouldEnhance = true;
      }
    } else {
      content = day.dateText;
    }

    return (
      <div
        ref={sticky ? inst._setStickyDay : UNDEFINED}
        aria-hidden="true"
        dangerouslySetInnerHTML={isVue ? UNDEFINED : html}
        className={
          (sticky ? 'mbsc-timeline-header-text' : '') +
          (day.isActive && !s.renderDay ? ' mbsc-timeline-header-active' : '') +
          (s.renderDay ? ' mbsc-timeline-header-date-cont' : ' mbsc-timeline-header-date-text') +
          theme
        }
      >
        {content}
      </div>
    );
  };

  const renderDayFooter = (day: IDayData) => {
    let content: any;
    let html: any;

    if (s.renderDayFooter) {
      content = s.renderDayFooter({
        date: day.date,
        events: day.eventMap.all,
      });
      if (isString(content)) {
        html = inst._safeHtml(content);
        inst._shouldEnhance = true;
      }
    }

    return (
      <div className="mbsc-timeline-footer-date-cont" dangerouslySetInnerHTML={isVue ? UNDEFINED : html}>
        {content}
      </div>
    );
  };

  const renderWeek = (week: IDayData, sticky?: boolean) => {
    let content: any;
    let html: any;

    if (s.renderWeek) {
      content = s.renderWeek({
        date: week.date,
        endDate: week.endDate,
        events: week.eventMap[week.timestamp] || [],
        isActive: week.isActive,
        startDate: week.date,
        weekNr: week.weekNr,
      });
      if (isString(content)) {
        html = inst._safeHtml(content);
        inst._shouldEnhance = true;
      }
    } else {
      content = week.weekText;
    }

    return (
      <div
        ref={sticky ? inst._setStickyWeek : UNDEFINED}
        aria-hidden="true"
        dangerouslySetInnerHTML={isVue ? UNDEFINED : html}
        className={
          (sticky ? 'mbsc-timeline-header-text' : '') +
          (s.renderWeek ? ' mbsc-timeline-header-week-cont' : ' mbsc-timeline-header-week-text') +
          (week.lastOfWeek ? '  mbsc-timeline-header-week-text-last' : '') +
          theme
        }
      >
        {content}
      </div>
    );
  };

  const renderWeekFooter = (week: IDayData) => {
    let content: any;
    let html: any;

    if (s.renderWeekFooter) {
      content = s.renderWeekFooter({
        date: week.date,
        endDate: week.endDate,
        events: week.eventMap[week.timestamp] || [],
        isActive: week.isActive,
        startDate: week.date,
        weekNr: week.weekNr,
      });
      if (isString(content)) {
        html = inst._safeHtml(content);
        inst._shouldEnhance = true;
      }
    }

    return (
      <div dangerouslySetInnerHTML={isVue ? UNDEFINED : html} className="mbsc-timeline-footer-week-cont">
        {content}
      </div>
    );
  };

  const renderMonth = (month: IDayData, sticky?: boolean) => {
    let content: any;
    let html: any;

    if (s.renderMonth) {
      content = s.renderMonth({
        date: month.date,
        events: month.eventMap[month.timestamp] || [],
        isActive: month.isActive,
      });
      if (isString(content)) {
        html = inst._safeHtml(content);
        inst._shouldEnhance = true;
      }
    } else {
      content = month.monthText;
    }

    return (
      <div
        ref={sticky ? inst._setStickyMonth : UNDEFINED}
        aria-hidden="true"
        dangerouslySetInnerHTML={isVue ? UNDEFINED : html}
        className={
          (sticky ? 'mbsc-timeline-header-text' : '') +
          (s.renderMonth ? ' mbsc-timeline-header-month-cont' : ' mbsc-timeline-header-month-text') +
          (month.lastOfMonth ? ' mbsc-timeline-header-month-text-last' : '') +
          theme
        }
      >
        {content}
      </div>
    );
  };

  const renderMonthFooter = (month: IDayData) => {
    let content: any;
    let html: any;

    if (s.renderMonthFooter) {
      content = s.renderMonthFooter({
        date: month.date,
        events: month.eventMap[month.timestamp] || [],
        isActive: month.isActive,
      });
      if (isString(content)) {
        html = inst._safeHtml(content);
        inst._shouldEnhance = true;
      }
    }

    return (
      <div dangerouslySetInnerHTML={isVue ? UNDEFINED : html} className="mbsc-timeline-footer-month-cont">
        {content}
      </div>
    );
  };

  const renderQuarter = (quarter: IDayData) => {
    let content: any;
    let html: any;
    if (s.renderQuarter) {
      content = s.renderQuarter({
        date: quarter.date,
        events: quarter.eventMap[quarter.timestamp] || [],
        isActive: quarter.isActive,
      });
      if (isString(content)) {
        html = inst._safeHtml(content);
        inst._shouldEnhance = true;
      }
    }

    return (
      <div
        aria-hidden="true"
        dangerouslySetInnerHTML={isVue ? UNDEFINED : html}
        className={(s.renderQuarter ? ' mbsc-timeline-header-month-cont' : ' mbsc-timeline-header-month-text') + theme}
      >
        {content}
      </div>
    );
  };

  const renderQuarterFooter = (quarter: IDayData) => {
    let content: any;
    let html: any;
    if (s.renderQuarterFooter) {
      content = s.renderQuarterFooter({
        date: quarter.date,
        events: quarter.eventMap[quarter.timestamp] || [],
        isActive: quarter.isActive,
      });
      if (isString(content)) {
        html = inst._safeHtml(content);
        inst._shouldEnhance = true;
      }
    }

    return (
      <div dangerouslySetInnerHTML={isVue ? UNDEFINED : html} className="mbsc-timeline-footer-month-cont">
        {content}
      </div>
    );
  };

  const renderYear = (year: IDayData) => {
    let content: any;
    let html: any;

    if (s.renderYear) {
      content = s.renderYear({
        date: year.date,
        events: year.eventMap[year.timestamp] || [],
        isActive: year.isActive,
      });
      if (isString(content)) {
        html = inst._safeHtml(content);
        inst._shouldEnhance = true;
      }
    } else {
      content = year.columnTitle;
    }

    return (
      <div
        aria-hidden="true"
        dangerouslySetInnerHTML={isVue ? UNDEFINED : html}
        className={
          (year.isActive && !s.renderYear ? ' mbsc-timeline-header-active' : '') +
          (s.renderYear ? ' mbsc-timeline-header-year-cont' : ' mbsc-timeline-header-year-text') +
          theme
        }
      >
        {content}
      </div>
    );
  };

  const renderYearFooter = (year: IDayData) => {
    let content: any;
    let html: any;

    if (s.renderYearFooter) {
      content = s.renderYearFooter({
        date: year.date,
        events: year.eventMap[year.timestamp] || [],
        isActive: year.isActive,
      });
      if (isString(content)) {
        html = inst._safeHtml(content);
        inst._shouldEnhance = true;
      }
    }

    return (
      <div dangerouslySetInnerHTML={isVue ? UNDEFINED : html} className="mbsc-timeline-footer-year-cont">
        {content}
      </div>
    );
  };

  const renderResourceHeader = () => {
    let content: any;
    let html: any;
    if (s.renderResourceHeader) {
      content = s.renderResourceHeader();
      if (isString(content)) {
        html = inst._safeHtml(content);
        inst._shouldEnhance = true;
      }
    }
    return (
      <div className="mbsc-timeline-resource-header" dangerouslySetInnerHTML={isVue ? UNDEFINED : html}>
        {content}
      </div>
    );
  };

  const renderResourceFooter = () => {
    let content: any;
    let html: any;
    if (s.renderResourceFooter) {
      content = s.renderResourceFooter();
      if (isString(content)) {
        html = inst._safeHtml(content);
        inst._shouldEnhance = true;
      }
    }
    return (
      <div className="mbsc-timeline-resource-footer" dangerouslySetInnerHTML={isVue ? UNDEFINED : html}>
        {content}
      </div>
    );
  };

  const renderSidebarHeader = () => {
    let content: any;
    let html: any;
    if (s.renderSidebarHeader) {
      content = s.renderSidebarHeader();
      if (isString(content)) {
        html = inst._safeHtml(content);
        inst._shouldEnhance = true;
      }
    }
    return (
      <div className="mbsc-timeline-sidebar-header" dangerouslySetInnerHTML={isVue ? UNDEFINED : html}>
        {content}
      </div>
    );
  };

  const renderSidebar = (resource: MbscResource, dateKey?: string) => {
    const isParent = resource.isParent;
    const key = (dateKey ? dateKey + '-' : '') + resource.id;
    const fixed = resource.fixed && !hasResY;
    const style = {
      background: resource.background,
      minHeight: inst._rowHeights[key],
      top: fixed ? inst._fixedResourceTops[key] : UNDEFINED,
    };

    let content: any;
    let html: any;
    if (s.renderSidebar) {
      content = s.renderSidebar(resource);
      if (isString(content)) {
        html = inst._safeHtml(content);
        inst._shouldEnhance = true;
      }
    }

    return (
      key !== inst._dragRow && (
        <div
          key={key}
          className={
            'mbsc-timeline-sidebar-resource mbsc-timeline-row mbsc-flex-1-0' +
            (isParent ? ' mbsc-timeline-parent mbsc-flex' : '') +
            (fixed ? '  mbsc-timeline-row-fixed' : '') +
            (resource.cssClass ? ' ' + resource.cssClass : '') +
            theme +
            rtl +
            hb
          }
          style={style}
        >
          <div className="mbsc-timeline-sidebar-resource-title" dangerouslySetInnerHTML={isVue ? UNDEFINED : html}>
            {content}
          </div>
        </div>
      )
    );
  };

  const renderSidebarFooter = () => {
    let content: any;
    let html: any;
    if (s.renderSidebarFooter) {
      content = s.renderSidebarFooter();
      if (isString(content)) {
        html = inst._safeHtml(content);
        inst._shouldEnhance = true;
      }
    }
    return (
      <div className="mbsc-timeline-sidebar-footer" dangerouslySetInnerHTML={isVue ? UNDEFINED : html}>
        {content}
      </div>
    );
  };

  const renderResource = (resource: MbscResource, dateKey?: string) => {
    const isParent = resource.isParent;
    const padding = inst._hasHierarchy ? resource.depth! * 1.75 + 'em' : UNDEFINED;
    const key = (dateKey ? dateKey + '-' : '') + resource.id;
    const fixed = resource.fixed && !hasResY;
    const style = {
      background: resource.background,
      minHeight: inst._rowHeights[key],
      paddingLeft: s.rtl ? UNDEFINED : padding,
      paddingRight: s.rtl ? padding : UNDEFINED,
      top: fixed ? inst._fixedResourceTops[key] + 'px' : UNDEFINED,
    };

    let content = resource.name;
    let html: any;
    if (s.renderResource) {
      content = s.renderResource(resource);
      if (isString(content)) {
        html = inst._safeHtml(content);
        inst._shouldEnhance = true;
      }
    }

    return (
      key !== inst._dragRow && (
        <div
          key={key}
          className={
            'mbsc-timeline-resource mbsc-timeline-row mbsc-flex-1-0' +
            (isParent ? ' mbsc-timeline-parent mbsc-flex' : '') +
            (fixed ? ' mbsc-timeline-row-fixed' : '') +
            (resource.cssClass ? ' ' + resource.cssClass : '') +
            theme +
            rtl +
            hb
          }
          style={style}
        >
          {isParent && (
            <Icon
              className={'mbsc-timeline-resource-icon' + rtl + hb + `${!resource.collapsed && " openArrow"}`}
              svg={resource.collapsed ? (s.rtl ? s.nextIconRtl : s.nextIcon) : s.downIcon}
              theme={s.theme}
              // tslint:disable-next-line jsx-no-lambda
              onClick={(ev: any) => inst._onParentClick(ev, resource)}
            />
          )}
          {/* eslint-disable-next-line react/no-danger-with-children */}
          <div
            className={'mbsc-timeline-resource-title' + (isParent ? ' mbsc-flex-1-1' : '')}
            dangerouslySetInnerHTML={isVue ? UNDEFINED : html}
          >
            {content}
          </div>
        </div>
      )
    );
  };

  const renderData = (
    data: { [key: string]: { [key: string]: { [key: string]: IDailyData } } },
    resource: string | number,
    renderFunc: any,
    checkDrag?: boolean,
    dateKey?: string,
  ) => {
    const rangeData = data[resource][DEF_ID][dateKey || 'all'];
    const dataForBatch = [];
    if (rangeData) {
      for (const item of rangeData.data) {
        if (
          (checkDrag && draggedEventId === item.id) ||
          // We use <= to work with inclusive end dates as well
          (inst._batchStart <= item.endDate && inst._batchEnd > item.startDate)
        ) {
          dataForBatch.push(item);
        }
      }
    }
    return renderFunc(dataForBatch, dateKey || 'all', resource, DEF_ID);
  };

  const renderColors = (colors: MbscCalendarEventData[]) => {
    return colors.map((color, i) => {
      return (
        <div key={i} className={'mbsc-schedule-color mbsc-timeline-color' + color.cssClass + theme} style={color.position}>
          <div className="mbsc-schedule-color-text">{color.title}</div>
        </div>
      );
    });
  };

  const renderInvalids = (invalids: MbscCalendarEventData[]) => {
    return invalids.map((invalid, i) => {
      return (
        invalid.position && (
          <div key={i} className={'mbsc-schedule-invalid mbsc-timeline-invalid' + invalid.cssClass + theme} style={invalid.position}>
            <div className="mbsc-schedule-invalid-text">{invalid.title}</div>
          </div>
        )
      );
    });
  };

  const renderEvents = (events: MbscCalendarEventData[], dateKey: string, resource: string | number, slot: string | number) => {
    const resourceResize = inst._resourcesMap[resource].eventResize;
    const dragKey = resource + '__' + slot + '__' + dateKey;
    const dragResize = computeEventResize(
      dragData && dragData.draggedEvent && dragData.draggedEvent.original!.resize,
      s.dragToResize,
      resourceResize,
    );
    const eventProps = {
      displayTimezone: s.displayTimezone,
      drag: s.dragToMove || s.externalDrag,
      endDay: s.endDay,
      eventHeight: inst._setRowHeight ? inst._eventHeight : UNDEFINED,
      exclusiveEndDates: s.exclusiveEndDates,
      gridEndTime: endTime,
      gridStartTime: startTime,
      hasResY,
      isListing,
      isTimeline: true,
      lastDay: +inst._lastDay,
      render: s.renderEvent,
      renderBufferAfter: s.renderBufferAfter,
      renderBufferBefore: s.renderBufferBefore,
      renderContent: s.renderEventContent,
      resource,
      rtl: s.rtl,
      slot,
      startDay: s.startDay,
      theme: s.theme,
      timezonePlugin: s.timezonePlugin,
    };

    return (
      <Fragment>
        {events.map((event) => {
          return (
            event.position && (
              <ScheduleEvent
                {...eventProps}
                event={event}
                inactive={draggedEventId === event.id}
                key={event.uid}
                resize={computeEventResize(event.original!.resize, s.dragToResize, resourceResize)}
                selected={!!(s.selectedEventsMap[event.uid!] || s.selectedEventsMap[event.id])}
                onClick={inst._onEventClick}
                onDoubleClick={s.onEventDoubleClick}
                onRightClick={s.onEventRightClick}
                onHoverIn={s.onEventHoverIn}
                onHoverOut={s.onEventHoverOut}
                onDelete={s.onEventDelete}
                onDragStart={inst._onEventDragStart}
                onDragMove={inst._onEventDragMove}
                onDragEnd={inst._onEventDragEnd}
                onDragModeOn={inst._onEventDragModeOn}
                onDragModeOff={inst._onEventDragModeOff}
              />
            )
          );
        })}
        {dragData && dragData.originDates && dragData.originDates[dragKey] && (
          <ScheduleEvent
            {...eventProps}
            event={dragData.originDates[dragKey]}
            hidden={dragData && !!dragData.draggedDates}
            isDrag={true}
            resize={dragResize}
            onDragStart={inst._onEventDragStart}
            onDragMove={inst._onEventDragMove}
            onDragEnd={inst._onEventDragEnd}
            onDragModeOff={inst._onEventDragModeOff}
          />
        )}
        {dragData && dragData.draggedDates && dragData.draggedDates[dragKey] && (
          <ScheduleEvent {...eventProps} event={dragData.draggedDates[dragKey]} isDrag={true} resize={dragResize} />
        )}
      </Fragment>
    );
  };

  const renderRow = (res: MbscResource, dKey?: string) => {
    const resource = res.id;
    const key = (dKey ? dKey + '-' : '') + resource;
    const fixed = res.fixed && !hasResY;
    return (
      <div
        key={key}
        className={
          'mbsc-timeline-row mbsc-flex mbsc-flex-1-0' +
          (res.isParent ? ' mbsc-timeline-parent' : '') +
          (fixed ? ' mbsc-timeline-row-fixed' : '') +
          (key === inst._dragRow ? ' mbsc-timeline-hidden' : '') +
          (res.cssClass ? ' ' + res.cssClass : '') +
          theme +
          hb
        }
        style={{
          background: res.background,
          minHeight: inst._rowHeights[key],
          top: fixed ? inst._fixedResourceTops[key] + 'px' : UNDEFINED,
        }}
      >
        {!hasSlots && (
          <Fragment>
            <div className="mbsc-timeline-events">{renderData(inst._events, resource, renderEvents, true, dKey)}</div>
            {renderData(inst._invalids, resource, renderInvalids, undefined, dKey)}
            {renderData(inst._colors, resource, renderColors, undefined, dKey)}
          </Fragment>
        )}
        <div style={{ width: inst._placeholderSizeX + 'px' }} className="mbsc-flex-none" />
        {daysBatch.map((dayData) => {
          const timestamp = dayData.timestamp;
          const dateKey = dKey || dayData.dateKey;
          if (isDailyResolution) {
            return (
              <div
                key={timestamp}
                className={
                  'mbsc-timeline-day mbsc-flex' +
                  theme +
                  rtl +
                  hb +
                  (dateKey === dragCol ? ' mbsc-timeline-hidden' : '') +
                  (dayData.dateIndex < daysNr - 1 && (isHourly || dayData.lastOfMonth) ? ' mbsc-timeline-day-border' : '') +
                  (state.hasScrollX ? ' mbsc-flex-none' : ' mbsc-flex-1-0-0') +
                  (isMonthView || inst._isMulti ? ' mbsc-timeline-day-month' : '')
                }
              >
                {slots.map((sl) => {
                  const slot = sl.id;
                  const dayEvents = inst._events[resource][slot][dateKey];
                  const dayColors = inst._colors[resource][slot][dateKey];
                  const dayInvalids = inst._invalids[resource][slot][dateKey];
                  return (
                    <div key={slot} className={'mbsc-flex mbsc-flex-1-1' + (hasSlots ? ' mbsc-timeline-slot' : '')}>
                      {hasSlots && (
                        <Fragment>
                          <div className="mbsc-timeline-events">
                            {renderEvents(dayEvents ? dayEvents.data : [], dateKey, resource, slot)}
                          </div>
                          {dayInvalids && renderInvalids(dayInvalids.data)}
                          {dayColors && renderColors(dayColors.data)}
                        </Fragment>
                      )}
                      {times.map((v, k) => {
                        const date = getCellDate(timestamp, v);
                        const first = !k;
                        const last = k === times.length - 1;
                        const cellHandlers = {
                          [ON_DOUBLE_CLICK]: (domEvent: any) => s.onCellDoubleClick({ date, domEvent, resource, slot, source }),
                          [ON_CONTEXT_MENU]: (domEvent: any) => s.onCellRightClick({ date, domEvent, resource, slot, source }),
                        };
                        return (
                          <div
                            key={k}
                            className={
                              'mbsc-timeline-column mbsc-flex-1-1' +
                              theme +
                              rtl +
                              hb +
                              ((first && !last && startCellStyle) || (last && !first && endCellStyle) ? ' mbsc-flex-none' : '')
                            }
                            // tslint:disable-next-line: jsx-no-lambda
                            onClick={(domEvent: any) => s.onCellClick({ date, domEvent, resource, slot, source })}
                            style={first && !last ? startCellStyle : last && !first ? endCellStyle : UNDEFINED}
                            {...cellHandlers}
                          />
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            );
          } else {
            const date = dayData.date;
            const cellHandlers = {
              [ON_DOUBLE_CLICK]: (domEvent: any) => s.onCellDoubleClick({ date, domEvent, resource, source }),
              [ON_CONTEXT_MENU]: (domEvent: any) => s.onCellRightClick({ date, domEvent, resource, source }),
            };
            return (
              <div
                key={timestamp}
                className={
                  'mbsc-timeline-day mbsc-timeline-column' +
                  theme +
                  rtl +
                  hb +
                  (dateKey === dragCol ? ' mbsc-timeline-hidden' : '') +
                  (state.hasScrollX ? ' mbsc-flex-none' : ' mbsc-flex-1-0-0')
                }
                // tslint:disable-next-line: jsx-no-lambda
                onClick={(domEvent: any) => s.onCellClick({ date, domEvent, resource, source })}
                {...cellHandlers}
              />
            );
          }
        })}
      </div>
    );
  };

  return (
    <div
      ref={inst._setEl}
      className={
        'mbsc-timeline mbsc-flex-1-1 mbsc-flex-col' +
        (state.cellWidth ? '' : ' mbsc-hidden') +
        (inst._hasSticky ? ' mbsc-has-sticky' : '') +
        (hasRows ? '' : ' mbsc-timeline-no-rows') +
        (hasResources ? '' : ' mbsc-timeline-no-resource') +
        theme +
        rtl
      }
    >
      {/* TRIAL */}
      {/* Header sticky labels */}
      <div ref={inst._setStickyHeader} className={'mbsc-timeline-header-sticky mbsc-flex' + theme}>
        {hasRows && (
          <div className={'mbsc-timeline-resource-header-cont ' + colClass + theme + rtl + hb} style={headerHeight}>
            {renderResourceHeader()}
          </div>
        )}
        {isDailyResolution && (
          <div className="mbsc-flex-1-1">
            {!hasResY && (
              <Fragment>
                {inst._isMulti && (
                  <div className={'mbsc-timeline-header-month mbsc-flex' + theme + rtl + hb}>
                    {renderMonth(days[dayIndex] || days[0], true)}
                  </div>
                )}
                {s.weekNumbers && (
                  <div className={'mbsc-timeline-header-week mbsc-flex' + theme + rtl + hb}>
                    {renderWeek(days[dayIndex] || days[0], true)}
                  </div>
                )}
                {(hasSlots || isHourly) && (
                  <div className={'mbsc-timeline-header-date mbsc-flex' + theme + rtl + hb}>
                    {renderDay(days[dayIndex] || days[0], true)}
                  </div>
                )}
              </Fragment>
            )}
          </div>
        )}
        {hasRows && s.renderSidebar && (
          <div className={'mbsc-timeline-sidebar-header-cont mbsc-timeline-sidebar-col' + theme + rtl + hb} style={headerHeight}>
            {renderSidebarHeader()}
          </div>
        )}
        {state.hasScrollY && <div className="mbsc-schedule-fake-scroll-y" />}
      </div>

      {/* Footer sticky  */}
      {hasFooter && (
        <div ref={inst._setStickyFooter} className={'mbsc-timeline-footer-sticky mbsc-flex' + theme}>
          {hasRows && (
            <div className={'mbsc-timeline-resource-footer-cont ' + colClass + theme + rtl + hb} style={footerHeight}>
              {renderResourceFooter()}
            </div>
          )}
          {isDailyResolution && <div className="mbsc-flex-1-1" />}
          {hasRows && s.renderSidebar && (
            <div className={'mbsc-timeline-sidebar-footer-cont mbsc-timeline-sidebar-col' + theme + rtl + hb} style={footerHeight}>
              {renderSidebarFooter()}
            </div>
          )}
          {state.hasScrollY && <div className="mbsc-schedule-fake-scroll-y" />}
        </div>
      )}

      <div
        ref={inst._setCont}
        className={'mbsc-timeline-grid-scroll mbsc-flex-col mbsc-flex-1-1' + theme + rtl + hb}
        onScroll={inst._onScroll}
      >
        <div className="mbsc-flex-none" style={inst._hasSticky ? UNDEFINED : headerHeight} />
        {/* Header */}
        <div className={'mbsc-timeline-header mbsc-flex' + theme + rtl + hb} ref={inst._setHeaderCont}>
          {hasRows && <div className={'mbsc-timeline-resource-header-cont ' + colClass + theme + rtl + hb} />}
          <div className={'mbsc-timeline-header-bg mbsc-flex-1-0 mbsc-flex' + theme}>
            {/* Time indicator */}
            <div
              className="mbsc-timeline-time-indicator-cont"
              style={{
                height: (state.scrollContHeight || 0) - (state.headerHeight || 0) + 'px',
                width: state.hasScrollX ? inst._gridWidth + 'px' : UNDEFINED,
              }}
            >
              {inst._showTimeIndicator && (
                <TimeIndicator
                  amText={s.amText}
                  displayedTime={inst._time}
                  displayedDays={daysNr}
                  displayTimezone={s.displayTimezone}
                  endDay={s.endDay}
                  firstDay={inst._firstDayTz}
                  orientation="y"
                  pmText={s.pmText}
                  rtl={s.rtl}
                  startDay={s.startDay}
                  startTime={startTime}
                  theme={s.theme}
                  timeFormat={s.timeFormat}
                  timezonePlugin={s.timezonePlugin}
                  hasResY={hasResY}
                />
              )}
              {inst._showCursorTime && (
                <div ref={inst._setCursorTimeCont} className={'mbsc-schedule-cursor-time mbsc-schedule-cursor-time-y' + theme} />
              )}
            </div>
            <div className="mbsc-flex-none" style={{ width: inst._placeholderSizeX + 'px' }} />
            <div className={state.hasScrollX ? 'mbsc-flex-none' : 'mbsc-flex-1-1'}>
              {isDailyResolution ? (
                <Fragment>
                  {inst._isMulti && !hasResY && (
                    <div className="mbsc-flex">
                      {daysBatch.map((d) => {
                        const last = d.lastOfMonth;
                        if (d.dateKey === dragCol) {
                          return UNDEFINED;
                        }
                        return (
                          <div
                            key={d.timestamp}
                            className={
                              'mbsc-timeline-month mbsc-flex-1-0-0' +
                              theme +
                              rtl +
                              hb +
                              (d.dateIndex < daysNr - 1 && last ? ' mbsc-timeline-day mbsc-timeline-day-border' : '')
                            }
                          >
                            <div
                              className={'mbsc-timeline-header-month' + theme + rtl + hb + (last ? ' mbsc-timeline-header-month-last' : '')}
                            >
                              {d.monthTitle && renderMonth(d, false)}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {s.weekNumbers && (
                    <div className="mbsc-flex">
                      {daysBatch.map((d) => {
                        const last = d.lastOfWeek;
                        if (d.dateKey === dragCol) {
                          return UNDEFINED;
                        }
                        return (
                          <div
                            key={d.timestamp}
                            className={
                              'mbsc-timeline-month mbsc-flex-1-0-0' +
                              theme +
                              rtl +
                              hb +
                              (d.dateIndex < daysNr - 1 && last && (isHourly || d.lastOfMonth)
                                ? ' mbsc-timeline-day mbsc-timeline-day-border'
                                : '')
                            }
                          >
                            <div
                              className={'mbsc-timeline-header-week' + theme + rtl + hb + (last ? ' mbsc-timeline-header-week-last' : '')}
                            >
                              {d.weekTitle && renderWeek(d, false)}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  <div className="mbsc-flex">
                    {daysBatch.map((d) => {
                      if (d.dateKey === dragCol) {
                        return UNDEFINED;
                      }
                      return (
                        <div
                          key={d.timestamp}
                          className={
                            'mbsc-timeline-day mbsc-flex-1-0-0' +
                            theme +
                            rtl +
                            hb +
                            (d.dateIndex < daysNr - 1 && (isHourly || d.lastOfMonth) ? ' mbsc-timeline-day-border' : '') +
                            (isMonthView || inst._isMulti ? ' mbsc-timeline-day-month' : '')
                          }
                        >
                          {!hasResY && (
                            <div className={'mbsc-timeline-header-date' + theme + rtl + hb}>
                              {renderDay(d)}
                              {d.label && <div className="mbsc-hidden-content">{d.label}</div>}
                            </div>
                          )}
                          {hasSlots && (
                            <div className={'mbsc-flex mbsc-timeline-slots' + theme}>
                              {slots.map((slot) => {
                                return (
                                  <div key={slot.id} className={'mbsc-timeline-slot mbsc-timeline-slot-header mbsc-flex-1-1' + rtl + theme}>
                                    {slot.name && renderSlot({ slot, date: d.date })}
                                  </div>
                                );
                              })}
                            </div>
                          )}
                          <div aria-hidden="true" className="mbsc-flex">
                            {times.map((t, j) => {
                              const first = !j;
                              const last = j === times.length - 1;
                              return (
                                <div
                                  key={j}
                                  style={first && !last ? startCellStyle : last && !first ? endCellStyle : UNDEFINED}
                                  className={
                                    'mbsc-flex mbsc-flex-1-1 mbsc-timeline-header-column' +
                                    theme +
                                    rtl +
                                    hb +
                                    (!inst._displayTime || hasSlots ? ' mbsc-timeline-no-height' : '') +
                                    (stepLabel > inst._stepCell && times[j + 1] % stepLabel ? ' mbsc-timeline-no-border' : '') +
                                    ((first && startCellStyle) || (last && endCellStyle) ? ' mbsc-flex-none' : '')
                                  }
                                >
                                  {renderHour(d, t)}
                                  {inst._timesBetween.map((tb, k) => {
                                    const ms = t + (k + 1) * stepLabel;
                                    return ms > startTime && ms < endTime && renderHour(d, ms);
                                  })}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Fragment>
              ) : (
                <div className="mbsc-flex">
                  {daysBatch.map((d) => {
                    if (d.dateKey === dragCol) {
                      return UNDEFINED;
                    }
                    return (
                      <div key={d.timestamp} className={'mbsc-timeline-day mbsc-flex-1-0-0' + theme + rtl + hb}>
                        <div className={'mbsc-timeline-header-week mbsc-timeline-header-week-last' + theme + rtl + hb}>
                          <div
                            className={
                              'mbsc-timeline-header-week-text mbsc-timeline-header-week-text-last' +
                              (d.isActive && !(s.renderWeek || s.renderMonth || s.renderQuarter || s.renderYear)
                                ? ' mbsc-timeline-header-active'
                                : '') +
                              theme
                            }
                          >
                            {renderHeaderResolution(d)}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
          {hasRows && s.renderSidebar && (
            <div className={'mbsc-timeline-sidebar-header-cont mbsc-timeline-sidebar-col' + theme + rtl + hb} />
          )}
        </div>

        {/* Resources column and time grid */}
        <div className="mbsc-flex mbsc-flex-1-1">
          {/* The extra flex div is needed for the div height to expand correctly */}
          <div className="mbsc-flex mbsc-flex-1-1">
            {hasRows && (
              <div className={'mbsc-timeline-resources mbsc-flex-col ' + colClass + theme + rtl} ref={inst._setResCont}>
                <div className="mbsc-flex-none" style={inst._hasSideSticky ? UNDEFINED : headerHeight} />
                <div
                  className={
                    'mbsc-timeline-resource-bg mbsc-flex-1-1' + (inst._hasHierarchy || state.hasScrollY ? '' : ' mbsc-flex-col') + theme
                  }
                >
                  <div style={{ height: inst._placeholderSizeY + 'px' }} className="mbsc-flex-none" />
                  {inst._rowBatch.map((rowGroup) => {
                    const day = rowGroup.day;
                    const dateKey = day ? day.dateKey : '';
                    return !rowGroup.hidden && day ? (
                      hasResources ? (
                        <div key={dateKey} className={'mbsc-timeline-row-group mbsc-flex mbsc-flex-1-0' + theme + hb}>
                          <div className={'mbsc-timeline-row-date mbsc-timeline-row-date-col mbsc-flex-none' + rtl + theme + hb}>
                            {renderDay(day)}
                          </div>
                          <div className="mbsc-timeline-row-resource-col mbsc-flex-1-1 mbsc-flex-col">
                            {rowGroup.rows.map((r) => renderResource(r, dateKey))}
                          </div>
                        </div>
                      ) : (
                        <div
                          key={dateKey}
                          className={'mbsc-timeline-row-date mbsc-flex-1-0' + rtl + theme + hb}
                          style={{
                            minHeight: inst._rowHeights[dateKey + '-' + DEF_ID],
                          }}
                        >
                          {renderDay(day)}
                        </div>
                      )
                    ) : (
                      rowGroup.rows.map((r) => renderResource(r, dateKey))
                    );
                  })}
                </div>
                <div className="mbsc-flex-none" style={inst._hasSideSticky ? UNDEFINED : footerHeight} />
              </div>
            )}
            {hasRows && <div className={inst._hasSideSticky ? '' : colClass} />}

            <div className="mbsc-timeline-hidden">
              <div className={'mbsc-timeline-row mbsc-timeline-empty-row' + theme} />
              <div className={'mbsc-timeline-row mbsc-timeline-parent mbsc-timeline-empty-parent' + theme} />
              <div className={'mbsc-timeline-row-gutter' + theme} />
            </div>

            <div
              className={'mbsc-timeline-grid mbsc-flex-1-0' + (inst._hasHierarchy || state.hasScrollY ? '' : ' mbsc-flex-col')}
              ref={inst._setGridCont}
              style={{
                height: state.hasScrollY ? inst._gridHeight + 'px' : UNDEFINED,
                width: state.hasScrollX ? inst._gridWidth + 'px' : UNDEFINED,
              }}
              {...handlers}
            >
              <div style={{ height: inst._placeholderSizeY + 'px' }} className="mbsc-flex-none" />
              {inst._rowBatch.map((rowGroup) => {
                const day = rowGroup.day;
                const dateKey = day ? day.dateKey : '';
                return day && hasResources ? (
                  <div key={dateKey} className={'mbsc-timeline-row-group mbsc-flex-col mbsc-flex-1-0' + theme + hb}>
                    {rowGroup.rows.map((r) => renderRow(r, dateKey))}
                  </div>
                ) : (
                  <Fragment key={dateKey}>{rowGroup.rows.map((r) => renderRow(r, dateKey))}</Fragment>
                );
              })}

              {inst._connections && (
                <svg viewBox="0 0 100 100" preserveAspectRatio="none" {...svgProps}>
                  {inst._connections.map((c) => {
                    const props = {
                      [isVue ? 'class' : 'className']: 'mbsc-connection ' + c.cssClass + theme,
                      d: c.pathD,
                      style: { stroke: c.color, fill: c.fill },
                      [isPreact || isVue ? 'vector-effect' : 'vectorEffect']: 'non-scaling-stroke',
                    };
                    return (
                      checkDateRangeOverlap(inst._batchStart, inst._batchEnd, c.startDate, c.endDate, true) && (
                        <path key={c.id} {...props} />
                      )
                    );
                  })}
                </svg>
              )}
            </div>

            {/* Sidebar column and time grid */}
            {hasRows && s.renderSidebar && (
              <div className={'mbsc-timeline-sidebar mbsc-timeline-sidebar-col mbsc-flex-col' + theme + rtl} ref={inst._setSidebarCont}>
                <div className="mbsc-flex-none" style={inst._hasSideSticky ? UNDEFINED : headerHeight} />
                <div
                  className={
                    'mbsc-timeline-resource-bg mbsc-flex-1-1' + (inst._hasHierarchy || state.hasScrollY ? '' : ' mbsc-flex-col') + theme
                  }
                >
                  <div style={{ height: inst._placeholderSizeY + 'px' }} className="mbsc-flex-none" />
                  {inst._rowBatch.map((rowGroup) => {
                    const day = rowGroup.day;
                    const dateKey = day ? day.dateKey : '';
                    return day && hasResources ? (
                      <div key={dateKey} className={'mbsc-timeline-row-group mbsc-flex-col mbsc-flex-1-0' + theme + hb}>
                        {rowGroup.rows.map((r) => renderSidebar(r, dateKey))}
                      </div>
                    ) : (
                      rowGroup.rows.map((r) => renderSidebar(r, dateKey))
                    );
                  })}
                </div>
                <div className="mbsc-flex-none" style={inst._hasSideSticky ? UNDEFINED : footerHeight} />
              </div>
            )}
            {hasRows && s.renderSidebar && <div className={inst._hasSideSticky ? '' : 'mbsc-timeline-sidebar-col'} />}
          </div>
        </div>

        {/* Footer */}
        {hasFooter && (
          <Fragment>
            <div className="mbsc-flex-none" style={inst._hasSticky ? UNDEFINED : footerHeight} />
            <div className={'mbsc-timeline-footer mbsc-flex' + theme + rtl + hb} ref={inst._setFooterCont}>
              {hasRows && <div className={'mbsc-timeline-resource-footer-cont ' + colClass + theme + rtl + hb} />}
              <div className={'mbsc-timeline-footer-bg mbsc-flex-1-0 mbsc-flex' + theme}>
                <div className="mbsc-flex-none" style={{ width: inst._placeholderSizeX + 'px' }} />
                <div className={state.hasScrollX ? 'mbsc-flex-none' : 'mbsc-flex-1-1'}>
                  <div className="mbsc-flex">
                    {daysBatch.map((d) => {
                      if (d.dateKey === dragCol) {
                        return UNDEFINED;
                      }
                      return isDailyResolution ? (
                        <div
                          key={d.timestamp}
                          className={
                            'mbsc-timeline-day mbsc-flex-1-0-0' +
                            theme +
                            rtl +
                            hb +
                            (d.dateIndex < daysNr - 1 && (isHourly || d.lastOfMonth) ? ' mbsc-timeline-day-border' : '') +
                            (isMonthView || inst._isMulti ? ' mbsc-timeline-day-month' : '')
                          }
                        >
                          <div className="mbsc-flex">
                            {times.map((t, j) => {
                              const first = !j;
                              const last = j === times.length - 1;
                              return (
                                <div
                                  key={j}
                                  style={first && !last ? startCellStyle : last && !first ? endCellStyle : UNDEFINED}
                                  className={
                                    'mbsc-flex mbsc-flex-1-1 mbsc-timeline-column mbsc-timeline-footer-column' +
                                    theme +
                                    rtl +
                                    hb +
                                    (!inst._displayTime || hasSlots ? ' mbsc-timeline-no-height' : '') +
                                    (stepLabel > inst._stepCell && times[j + 1] % stepLabel ? 'mbsc-timeline-no-border' : '') +
                                    ((first && !last && startCellStyle) || (last && !first && endCellStyle) ? ' mbsc-flex-none' : '')
                                  }
                                >
                                  {renderHourFooter(d, t)}
                                  {inst._timesBetween.map((tb, k) => {
                                    const ms = t + (k + 1) * stepLabel;
                                    return ms > startTime && ms < endTime && renderHourFooter(d, ms);
                                  })}
                                </div>
                              );
                            })}
                          </div>
                          {s.renderDayFooter && <div className={'mbsc-timeline-footer-date' + theme + rtl + hb}>{renderDayFooter(d)}</div>}
                          {hasSlots && (
                            <div className="mbsc-flex">
                              {slots.map((slot) => {
                                return <div key={slot.id} className={'mbsc-timeline-slot mbsc-flex-1-1' + rtl + theme} />;
                              })}
                            </div>
                          )}
                        </div>
                      ) : (
                        <div key={d.timestamp} className={'mbsc-timeline-day mbsc-flex-1-0-0' + theme + rtl + hb}>
                          <div className={'mbsc-timeline-footer-week mbsc-timeline-footer-week-last' + theme + rtl + hb}>
                            <div className={'mbsc-timeline-footer-week-text' + theme}>{renderFooterResolution(d)}</div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              {hasRows && s.renderSidebar && (
                <div className={'mbsc-timeline-sidebar-footer-cont mbsc-timeline-sidebar-col' + theme + rtl + hb} />
              )}
            </div>
          </Fragment>
        )}
      </div>
      {dragData && !state.isTouchDrag && <div className="mbsc-calendar-dragging" />}
    </div>
  );
}

export class Timeline extends TimelineBase {
  protected _template(s: ITimelineOptions, state: ITimelineState): any {
    return template(s, state, this);
  }
}
