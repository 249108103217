/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import { useState } from "react";
import { useAuth } from "../../../../app/modules/auth/core/AuthCognito";
import { CreateAppModal } from "../../../partials";
import { useLayout } from "../../core";
import { DefaultTitle } from "../header/page-title/DefaultTitle";

const Toolbar1 = () => {
  const { classes } = useLayout();
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false);
  const { currentUser, logout } = useAuth();

  return (
    <>
      <div className="toolbar" id={currentUser?.data?.type == 'superadmin' ? "kt_toolbar":"kt_toolbar"}>
        {/* begin::Container */}
        <div
          id="kt_toolbar_container"
          className={clsx(
            classes.toolbarContainer.join(" "),
            "d-flex flex-stack"
          )}
        >
          <DefaultTitle />

          {/* begin::Actions */}
          <div className="d-flex align-items-center py-1">
            {/* begin::Wrapper */}
            <div className="me-4">
              {/* begin::Menu */}

              {/* end::Menu */}
            </div>
            {/* end::Wrapper */}

            {/* begin::Button */}
            {/* end::Button */}
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Container */}
      </div>
      <CreateAppModal
        show={showCreateAppModal}
        handleClose={() => setShowCreateAppModal(false)}
      />
    </>
  );
};

export { Toolbar1 };
