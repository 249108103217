/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { Outlet, Route, Routes, useLocation } from "react-router-dom";
import { Registration } from "./superadminComponents/Registration";
import { ForgotPassword } from "./superadminComponents/ForgotPassword";
import { Login } from "./superadminComponents/Login";
import { toAbsoluteUrl, toApiAbsoluteUrl } from "../../../_metronic/helpers";
import { ResetPassword } from "./superadminComponents/ResetPassword";
import { CreatePassword } from "./superadminComponents/CreatePassword";
import { useQuery } from "react-query";
import { getGeneralSetting2 } from "../superadmin/general-settings/core/_requests";

const SuperadminAuthLayout = () => {
  let location: any = useLocation();
  const parsedUrl = new URL(window.location.href);
  const {
    refetch,
    data: mediaSetting } = useQuery(
      `media-setting`,
      async () => {
        return await getGeneralSetting2('');
      },
      {
        cacheTime: 0,
        onError: (err) => {
          console.error(err);
        },
      }
    );

  useEffect(() => {
    let host = parsedUrl.host;
    host = host.replace("www.", "");
    host = host.replace(/^https?:\/\//, ""); 
    let envhost = process.env.REACT_APP_HOST_URL?.replace("www.", "");
    if(host != envhost){
      window.location.href  = 'https://'+process.env.REACT_APP_HOST_URL+'/superadmin/'
    }
    document.body.classList.add("bg-body");
    return () => {
      document.body.classList.remove("bg-body");
    };
    
  }, []);

  return (
    <div className="d-flex flex-column flex-root login" id="kt_app_root">
      <div className="d-flex flex-column flex-lg-row flex-column-fluid">
        <div
          className="d-flex align-items-end justify-content-center w-lg-40 bgi-size-cover bgi-position-center p-10 p-sm-15"
          style={{
            backgroundImage:
            location.pathname == '/superadmin/auth' && mediaSetting?.admin_background ?
            `url(${(`${mediaSetting?.admin_background}`)})`
            :
            `url(${toAbsoluteUrl("/media/misc/login-bg.jpg")})`
          }}
        >
          <div className="position-absolute start-0 bottom-0 fs-16 text-white ms-4 ps-4 mb-10 pb-10 fw-bold">{process.env.REACT_APP_RELEASE_VERSION}</div>
          <div className="logoName text-white fs-1">PLANNING TOOL</div>
        </div>

        <div className="d-flex flex-column flex-grow-1 justify-content-md-center flex-lg-row-fluid w-lg-60 p-10 pt-sm-15 pb-sm-15 loginForm">
          <div className="d-flex flex-center flex-column flex-lg-row-fluid">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

const SuperadminAuthPage = () => (
  <Routes>
    <Route element={<SuperadminAuthLayout />}>
      <Route path="login" element={<Login />} />
      <Route path="registration" element={<Registration />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="reset_password" element={<ResetPassword />} />
      <Route path="setup-password" element={<CreatePassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
);

export { SuperadminAuthPage };
