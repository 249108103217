/* eslint-disable jsx-a11y/anchor-is-valid */
import { SegmentedGroup, SegmentedItem } from '@mobiscroll/react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { FC, useEffect, useRef } from 'react'
import { getCSS, getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import { KTSVG } from '../../../../_metronic/helpers'
import { useThemeMode } from '../../../../_metronic/partials'
import { OverlayTrigger, Popover } from 'react-bootstrap'

type Props = {
    plannedTask: any;
    freeTask: any;
    capacityTask: any;
    axisCategory: any;
    handleweek: any;
    // handlecapacity: any;
    // handleavailability: any;
    handleday: any;
    isgraphShown: any;
    todoclassName: any;
    viewclassName: any;
    closeCapacityGraph: any;
    overbookedTask: any;
    capacityLabel: any;
    confirmLabel: any;
    confirmIcon: any;
    capacityIcon: any;
    availableCapacityLabel: any;
    overbookLabel: any;
    availableCapacityIcon: any;
    overbookIcon: any;
    isCapacitydailytype: any;
    // capacity_filter_type: any;
    availability: any;
    filterCapacityArray: any;
    // isfilterApply:any;
    handlemonth: any;
    timelineView: any;
    istodoShown:any;
    monthYearDataResponse: any
}

const PlanningCapacityGraph: FC<Props> = ({istodoShown, plannedTask, freeTask, isgraphShown, capacityTask, overbookedTask, axisCategory, handleweek, handleday, todoclassName, viewclassName, closeCapacityGraph, confirmLabel, capacityLabel, confirmIcon, capacityIcon, availableCapacityLabel, overbookLabel, availableCapacityIcon, overbookIcon, isCapacitydailytype, availability, filterCapacityArray, handlemonth, timelineView, monthYearDataResponse}) => {
    const chartRef = useRef<HTMLDivElement | null>(null)
    const { mode } = useThemeMode()
    const refreshChart = () => {
        if (!chartRef.current) {
            return
        }
        const height = parseInt(getCSS(chartRef.current, 'height'))
        const width = parseInt(getCSS(chartRef.current, 'width'))
        const barplannedWorkload = plannedTask;
        const barfreeWorkload = freeTask;
        const barcapacity = capacityTask;
        const baroverbooked = overbookedTask;
        const axisdata = axisCategory
        const barborderRadius = timelineView == 'day' || timelineView == 'week' ? [4] : 0;
        // capacity_filter_type
        const chart = new ApexCharts(chartRef.current, getChartOptions(height, width, barplannedWorkload, barfreeWorkload, barcapacity, axisdata, baroverbooked, barborderRadius))
        if (chart) {
            chart.render()
        }

        return chart
    }

    useEffect(() => {
        const chart = refreshChart();

        return () => {
            if (chart) {
                chart.destroy()
            }
        }
    }, [chartRef, mode, axisCategory,istodoShown, monthYearDataResponse])

    useEffect(() => {
        window.dispatchEvent(new Event('resize'))
    },[])

    const renderCapacityGraphTooltip = (
        <Popover id="renderAddUserTooltip">
          <div className="p-5 bg-white card">
            <div>
            The capacity graph is dynamic to the filters that you apply on the planning screen.
              <a className="text-decoration-underline ms-2"
                href="https://knowledgebase.proxuma.io/article/what-is-the-capacity-graph"
                target="_blank"
              >
                Learn more.
              </a>
            </div>
          </div>
        </Popover>
    )
    return (
             (
                <div className={`card capacityCard rounded-0 mx-0 flex-fill ${isgraphShown} ${todoclassName} ${viewclassName}`} id='capacityCard'>
                    {/* begin::Header */}
                    <div className='card-header py-2 px-3 min-h-1px rounded-0 align-items-center bg-gray-new border-bottom-0'>
                        <div className='d-flex align-items-center flex-wrap flex-1 row-gap-5'>
                            {/* <button
                                // onClick={changeHeight}
                                className='btn p-0 pe-3'
                                data-kt-toggle="true"
                                data-kt-toggle-state="active"
                                data-kt-toggle-target="#capacityCard"
                                data-kt-toggle-name="expandDiv"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/extra/expand.svg"
                                    className="d-inline-block m-0"
                                    svgClassName="w-18px h-18px w-ls-auto h-ls-auto"
                                />
                            </button> */}
                            {/* <FilterCapacity filterCapacityArray={filterCapacityArray} /> */}
                        </div>
        
                        {/* /* begin::Toolbar */}
                        <div className='card-toolbar m-0' data-kt-buttons='true'>
                            <div className='row mb-0'>
                                <div className='d-flex align-items-center'>
                                    <div className='me-5'>
                                        <SegmentedGroup className='p-0 m-0 rounded-pill bg-transparent border border-primary'
                                            value={isCapacitydailytype} >
                                            {
                                                timelineView != 'year'
                                                    ?
                                                    <SegmentedItem
                                                        value="day" onChange={handleday}>
                                                        Daily
                                                    </SegmentedItem>
                                                    : ''
                                            }
                                            <SegmentedItem value="week" onChange={handleweek}>
                                                Weekly
                                            </SegmentedItem>
                                            <SegmentedItem value="month" onChange={handlemonth}>
                                                Monthly
                                            </SegmentedItem>
                                        </SegmentedGroup>
                                    </div>
                                    {/* <div>
                                        <SegmentedGroup className=''p-0 m-0 rounded-pill bg-transparent border border-primary'
                                            value={capacity_filter_type}>
                                            <SegmentedItem
                                                value="capacity" onChange={handlecapacity}>
                                                Capacity
                                            </SegmentedItem>
                                            <SegmentedItem value="availability" onChange={handleavailability}>
                                                Availability
                                            </SegmentedItem>
                                        </SegmentedGroup>
                                    </div> */}
                                    <button
                                        onClick={closeCapacityGraph}
                                        className='btn p-0 w-32px h-32px border border-primary rounded-circle'
                                    >
                                        <KTSVG
                                            path="/media/icons/duotune/new-icons/close.svg"
                                            className="d-inline-block m-0 text-primary"
                                            svgClassName="w-18px h-18px"
                                        />
                                    </button>
                                </div>
                            </div>
                            {/* end::Toolbar */}
                        </div>
                    </div>
                    {/* end::Header */}
        
                    {/* begin::Body */}
                    <div className='card-body p-0 position-relative'>
                        {/* begin::Card */}
                        {availability?.allResource?.length !== 0 ?
                                <div className='d-flex flex-wrap flex-md-nowrap overflow-hidden h-100' id='capacity-graph'>
                                    <div className='w-lg-auto min-w-md-200px order-2 order-md-1'>
                                        <div className="card h-100 d-flex flex-sm-row flex-md-column">
        
                                            {/* <div className="card-body px-6 pt-0 pt-sm-6 col-md-12 flex-fill-none">
                                                {isfilterApply==true && 
                                                    <><div className="row-lg-3">
                                                        <a
                                                            href="#"
                                                            className="text-gray-900 text-hover-primary fs-16 fw-bold"
                                                        >
                                                            No Filters Selected
                                                        </a>
                                                    </div><div>
                                                            <span className="row-lg-3 fs-16 fw-normal" style={{ color: "#5C5C71" }}>
                                                                Displaying data for all people
                                                            </span>
                                                        </div></>
                                                }
                                                </div> */}
        
                                            <div className="card-body px-6 pt-0 pt-sm-3 col-md-12 flex-fill-none" style={{ marginTop: '150px' }}>
                                                <div className="h-24px d-flex align-items-center">
                                                    <OverlayTrigger
                                                        trigger="click"
                                                        rootClose
                                                        placement="top"
                                                        overlay={renderCapacityGraphTooltip}
                                                    >
                                                        <button className="btn p-0 text-center rounded-3px border lh-1 border-primary text-primary fs-6 w-10px color-white infoBtn ms-0.5 mb-3" type="button">i</button>
                                                    </OverlayTrigger>
                                                </div>
                                                <div className='d-flex align-items-center fs-16 fw-semibold mb-3 text-dark lh-1 emptyDiv'>
                                                    {confirmIcon &&
                                                        <KTSVG
                                                            path={confirmIcon}
                                                            className="me-4"
                                                            svgClassName="w-12px h-auto"
                                                        />
                                                    }
                                                    {confirmLabel}
                                                </div>
                                                <div className='d-flex align-items-center fs-16 fw-semibold mb-3 text-dark lh-1 emptyDiv'>
                                                    {availableCapacityIcon &&
                                                        <KTSVG
                                                            path={availableCapacityIcon}
                                                            className="me-4"
                                                            svgClassName="w-12px h-auto"
                                                        />
                                                    }
                                                    {availableCapacityLabel}
                                                </div>
        
                                                <div className='d-flex align-items-center fs-16 fw-semibold mb-3 text-dark lh-1 emptyDiv'>
                                                    {overbookIcon &&
                                                        <KTSVG
                                                            path={overbookIcon}
                                                            className="me-4"
                                                            svgClassName="w-12px h-auto"
                                                        />
                                                    }
                                                    {overbookLabel}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* end::Card */}
                                    {/* begin::Chart */}
                                    <div className="flex-fill min-h-200px order-1 order-md-2" ref={chartRef}></div>
                                </div>
                                :
                                <span style={{ fontWeight: "500", color: "red", fontSize: "18px" }} className="position-absolute top-50 start-50 translate-middle">There are no items to display.</span>
                        }
                        {/* end::Chart */}
                    </div>
                    {/* end::Body */}
                </div>
            )
    )
}

export default PlanningCapacityGraph
// , capacity_filter_type: any
function getChartOptions(height: number, width: number, plannedTask: any, freeTask: any, capacityTask: any, axisCategory: any, overbookedTask: any, barborderRadius: any): ApexOptions {
    // const labelColor = getCSSVariableValue('--kt-gray-500')
    // const borderColor = getCSSVariableValue('--kt-gray-200')
    const labelColor = getCSSVariableValue('--kt-gray-900')
    const borderColor = getCSSVariableValue('#DADCE0')

    /*  const baseColor = getCSSVariableValue('--kt-primary')
        const baseLightColor = getCSSVariableValue('--kt-primary-light')
        const secondaryColor = getCSSVariableValue('--kt-info') */
    const plannedColor = '#336FC9' //dark purple --confirmplanned
    const overbookedcolor = '#D97872'
    const leftCapacitycolor = '#72B4D9'
    let series: any = [];
    let color = [];
    // if (capacity_filter_type == 'capacity') {
    //     series = [
    //         {
    //             name: 'Confirm workload',
    //             type: 'bar',
    //             data: plannedTask,
    //         },
    //         {
    //             name: 'Capacity',
    //             type: 'area',
    //             data: capacityTask,
    //         }
    //     ]
    //     color = [baseColor, baseLightColor]
    // } else {
    //     series = [

    //         {
    //             name: 'Available capacity',
    //             type: 'bar',
    //             data: freeTask,
    //         },
    //         {
    //             name: 'Overbooked',
    //             type: 'bar',
    //             data: overbookedTask,

    //         }
    //     ]
    //     color = [secondaryColor, redcolor]
    // }
    series = [
        // {
        //     name: 'Total capacity',
        //     type: 'area',
        //     data: capacityTask,
        // },
        {
            name: 'Planned capacity',
            type: 'bar',
            data: plannedTask,
        },

        {
            name: 'Left capacity',
            type: 'bar',
            data: freeTask,
        },

        {
            name: 'Total overbooked',
            type: 'bar',
            data: overbookedTask,

        }
    ]
    color = [plannedColor, leftCapacitycolor, overbookedcolor]
    return {
        series: series,
        chart: {
            background: '#ffffff',
            fontFamily: 'inherit',
            stacked: true,
            height: '100%',
            // zoom:true,
            toolbar: {
                show: true,
                tools: {
                    download: false,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false
                }
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: barborderRadius,
                columnWidth: '20%',
            },
        },
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'smooth',
            show: true,
            width: 2,
            colors: ['transparent'],
        },
        xaxis: {
            categories: axisCategory,
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                rotate: 0,
                style: {
                    colors: labelColor,
                    fontSize: '16px',
                },
            },
            tooltip: {
                enabled: false
            }
        },
        yaxis: {
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: '16px',
                },
                formatter: function (value) {
                    var val: any = value;
                    if (typeof val !== "undefined" && isNaN(val) === false && val !== 0 && val !== null) {
                        // val = (val).toFixed(0);
                        // val = Number((Math.round(val * 100) / 100).toFixed(0));
                        val = Number((Math.round(val)));
                        return val
                    }
                    return null
                },
            },
            // min: (min) => {
            //     min = Math.min(...series[0].data);
            //     return min;
            // },
            // max: (max) => {
            //     const planned1: any = [];
            //     const planned2: any = [];
            //     const newArr: any = [];
            //     const p1 = Math.max(...series[0].data)
            //     const p2 = Math.max(...series[1].data)
            //     const p3 = Math.max(...series[2].data)
            //     planned1.push(p1,p3);
            //     planned2.push(p1,p2);

            //     // max = Math.max(...planned);
            //     const maxsum1 = planned1.reduce(function(prev: number, current: { score: string | number }) {
            //         return prev + +current
            //     }, 0);
            //     const maxsum2 = planned2.reduce(function(prev: number, current: { score: string | number }) {
            //         return prev + +current
            //     }, 0);
            //     newArr.push(maxsum1,maxsum2);
            //     max = Math.max(...newArr);
            //     return max;
            // },
            min: 0,
            forceNiceScale: true,
            tickAmount: 7
        },
        fill: {
            opacity: 1,
        },
        states: {
            normal: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            hover: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
        },
        tooltip: {
            style: {
                fontSize: '16px',
            },
            x: {
                formatter: function (val: any) {
                    /*  if (val === undefined) {
                         val = 0
                         return val;
 
                     }
                     if (isNaN(val)) {
                         val = 0
                         return val;
 
                     }
                     if (val !== null) {
                         return val;
                     }
                     return null; */
                    if (typeof val !== "undefined" && isNaN(val) === false && val !== 0 && val !== null && val > 0) {
                        return val;
                    }
                    return null;
                },
            },
            y: {
                formatter: function (val: any) {
                    if (typeof val !== "undefined" && isNaN(val) === false && val !== 0 && val !== null && val > 0) {
                        return val;
                    }
                }
            },
            shared: true,
        },
        colors: color,
        grid: {
            borderColor: borderColor,
            strokeDashArray: 4,
            yaxis: {
                lines: {
                    show: true,
                },
            },
            padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            },
        },
    }
}
