import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { I18nProvider } from "../_metronic/i18n/i18nProvider";
import { LayoutProvider, LayoutSplashScreen } from "../_metronic/layout/core";
import { MasterInit } from "../_metronic/layout/MasterInit";
import { AuthInit } from "./modules/auth/core/AuthCognito";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
// import  msalInstance  from './msalConfig';
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { SubdomainProvider } from "./context/SubdomainContext";
import SubdomainChecker from "./context/SubdomainChecker";
import { WebsocketProvider } from "./context/WebSocketContext";

// const getSubdomain = () => {
//   const hostname = window.location.hostname;
//   const parts = hostname.split('.');
//   return parts.length > 2 ? parts[0] : null;
// };

// const getSubdomain = () => {
//   const hostname = window.location.hostname;
//   const parts = hostname.split('.');

//   // Check for local development environment (like 'subdomain.localhost')
//   if (parts.length === 2 && parts[1] === 'localhost') {
//     return parts[0];
//   }

//   // Check if the hostname is an IP address or localhost
//   if (parts.length === 1 || /^[0-9]+$/.test(hostname.replace(/\./g, ''))) {
//     return null;
//   }

//   // Assuming the last two parts are the domain and TLD for a standard domain (like 'subdomain.example.com')
//   if (parts.length > 2) {
//     return parts[0];
//   }

//   return null;
// };

const App = () => {
  const [msalInstance, setMsalInstance] = useState();
  const [subdomainExists, setSubdomainExists] = useState(true);
  const navigate = useNavigate();

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <WebsocketProvider>
              <Outlet />
              <MasterInit />
            </WebsocketProvider>
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  );
};

export { App };
