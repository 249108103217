
import { BaseComponent } from '../../base';
import { getSelectedIndex, setRadio } from '../../shared/radio-helper';
import { closest, forEach, getOffset } from '../../util/dom';
import { gestureListener } from '../../util/gesture';
import { UNDEFINED } from '../../util/misc';
import { MbscSegmentedGroupOptions } from './segmented.types.public';

// tslint:disable no-non-null-assertion
// tslint:disable no-inferrable-types
// tslint:disable directive-class-suffix
// tslint:disable directive-selector

let guid = 1;

/** @hidden */

export class SegmentedGroupBase extends BaseComponent<MbscSegmentedGroupOptions, any> {
  // tslint:disable variable-name
  public static defaults: MbscSegmentedGroupOptions = {
    select: 'single',
  };

  protected static _name = 'SegmentedGroup';

  public _groupClass?: string;
  public _groupOpt: any;
  public _name!: string;

  private _unlisten?: null | (() => void);

  private _id = 'mbsc-segmented-group' + guid++;

  public _onChange = (ev: any, val: any) => {
    const s = this.s;
    let value = s.modelValue !== UNDEFINED ? s.modelValue : this.value;
    if (s.select === 'multiple') {
      if (value !== UNDEFINED) {
        value = value || [];
        const index = value.indexOf(val);
        if (index !== -1) {
          value.splice(index, 1);
        } else {
          value.push(val);
        }
        this.value = [...value];
      }
    } else {
      this.value = val;
    }
    this._change(this.value);
    if (s.onChange) {
      s.onChange(ev);
    }
  };

  // tslint:disable-next-line no-empty
  public _change(value: any) {}

  protected _render(s: MbscSegmentedGroupOptions) {
    this._name = s.name === UNDEFINED ? this._id : s.name;
    this._groupClass =
      'mbsc-segmented mbsc-flex ' +
      this._className +
      this._theme +
      this._rtl +
      (s.color ? ' mbsc-segmented-' + s.color : '') +
      (this.state.dragging ? ' mbsc-segmented-dragging' : '');

    this._groupOpt = {
      color: s.color,
      disabled: s.disabled,
      name: this._name,
      onChange: this._onChange,
      select: s.select,
      value: s.modelValue !== UNDEFINED ? s.modelValue : s.value,
    };
  }

  protected _updated() {
    // we need to setup the dragging based on the `drag` option (theme specific default), which can change
    if (this.s.drag && this.s.select !== 'multiple') {
      if (!this._unlisten) {
        this._setupDrag();
      }
    } else {
      this._cleanupDrag();
    }
  }

  protected _destroy() {
    this._cleanupDrag();
  }

  private _setupDrag() {
    let disabledArray: boolean[] = [];
    let widthArray: number[] = [];
    let wrapperWidth: number;
    let wrapperLeft: number;
    let isDragging: boolean;
    let selectedIndex: number | undefined;
    let oldIndex: number | undefined;
    let name: string;

    this._unlisten = gestureListener(this._el, {
      onEnd: () => {
        if (isDragging && selectedIndex !== oldIndex && !disabledArray[selectedIndex!]) {
          const inputElement: HTMLInputElement = this._el.querySelectorAll('.mbsc-segmented-input')[selectedIndex!] as HTMLInputElement;
          inputElement.click();
        }
        isDragging = false;
        this.setState({ dragging: false });
      },
      onMove: (ev) => {
        if (isDragging) {
          // mouse x constrained to the group left and right side
          const relativeLeft = Math.min(Math.max(ev.endX - wrapperLeft, 0), wrapperWidth);
          let newIndex = 0;
          let beforeSum = widthArray[0];
          while (relativeLeft > beforeSum && widthArray.length > newIndex + 1) {
            newIndex++;
            beforeSum += widthArray[newIndex];
          }
          newIndex = this.s.rtl ? widthArray.length - 1 - newIndex : newIndex;
          // const newIndex = Math.floor(relativeLeft / this._itemWidth);
          if (newIndex !== selectedIndex && !disabledArray[newIndex]) {
            selectedIndex = newIndex;
            // this.forceUpdate();
            setRadio(name, UNDEFINED, selectedIndex);
          }
        }
      },
      onStart: (ev) => {
        // go into dragging state - handle or not
        const item = closest(ev.domEvent.target as HTMLElement, '.mbsc-segmented-item', this._el);

        if (!item) {
          // Gesture was started outside of an item
          return;
        }

        const input = item.querySelector('.mbsc-segmented-input') as HTMLInputElement;
        const classList = input.classList;

        if (classList.contains('mbsc-selected')) {
          // update disabled array
          disabledArray = [];
          forEach(this._el.querySelectorAll('.mbsc-segmented-button'), (button: HTMLElement) => {
            disabledArray.push(button.classList.contains('mbsc-disabled'));
          });

          widthArray = [];
          forEach(this._el.querySelectorAll('.mbsc-segmented-item'), (el: HTMLElement) => {
            widthArray.push(el.clientWidth);
          });

          const padding = 15; // (12 + 3) on each side
          wrapperWidth = this._el.clientWidth - padding * 2;
          wrapperLeft = getOffset(this._el).left + padding;

          name = input.name;
          selectedIndex = getSelectedIndex(name);
          oldIndex = selectedIndex;

          // We don't always have select multiple specified for the group,
          // so we additionally check if it's a radio input
          if (widthArray.length && input.type === 'radio') {
            isDragging = true;
            this.setState({ dragging: true });
          }
        }
      },
    });
  }

  private _cleanupDrag() {
    if (this._unlisten) {
      this._unlisten();
      this._unlisten = null;
    }
  }
}
