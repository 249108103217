import React, { FC, useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { KTSVG } from "../../../../../_metronic/helpers";
import { DropDown } from "./DropDown";
type Props = {
  filterArray?: any;
  view?: any;
  openDropdown?:any;
  setOpenDropdown?:any;
  toggleDropdown?:any;
  setPayload?:any;
  payload?:any;
  general_settings?:any
  taskListType?:any
};
const CommonFilter: FC<Props> = ({ filterArray, view, openDropdown,toggleDropdown, setPayload, payload,general_settings, taskListType}) => {
  const [currentClick, setcurrentClick] = useState("");
  const [showproject, setshowproject] = useState("d-none");
  const [showrole, setshowrole] = useState("d-none");
  const [showpeople, setshowpeople] = useState("d-none");
  const [showdepartment, setshowdepartment] = useState("d-none");
  const [showlocation, setshowlocation] = useState("d-none");
  const [showskill, setshowskill] = useState("d-none");
  const [showqueues, setshowqueues] = useState("d-none");
  const [showlineOfBusiness, setshowlineOfBusiness] = useState("d-none");
  const [Projectid, setProjectid] = useState<any>([]);
  const [Roleid, setRoleid] = useState<any>([]);
  const [ProjectName, setProjectName] = useState<any>([]);
  const [RoleName, setRoleName] = useState<any>([]);
  const [Peopletid, setPeopletid] = useState<any>([]);
  const [PeopletName, setPeopletName] = useState<any>([]);
  const [Departmentid, setDepartmentid] = useState<any>([]);
  const [DepartmentName, setDepartmentName] = useState<any>([]);
  const [projectinputText, setprojectinputText] = useState("");
  const [roleinputText, setroleinputText] = useState("");
  const [peopleinputText, setpeopleinputText] = useState("");
  const [departmentinputText, setdepartmentinputText] = useState("");
  const [queuesinputText, setqueuesinputText] = useState("");
  const [skillinputText, setskillinputText] = useState("");
  const [lineOfBusinessinputText, setlineOfBusinessinputText] = useState("");
  const [Locationid, setLocationid] = useState<any>([]);
  const [Skillid, setSkillid] = useState<any>([]);
  const [queuesid, setqueuesid] = useState<any>([]);
  const [lineOfBusinessid, setlineOfBusinessid] = useState<any>([]);
  const [SkillName, setSkillName] = useState<any>([]);
  const [queuesName, setqueuesName] = useState<any>([]);
  const [lineOfBusinessName, setlineOfBusinessName] = useState<any>([]);

  //project lead
  const [showprojectlead, setshowprojectlead] = useState('d-none');
  const [projectleadinputText, setprojectleadinputText] = useState("");
  const [projectleadid, setprojectleadid] = useState<any>([]);
  const [projectleadName, setprojectleadName] = useState<any>([]);
  //priority
  const [showpriorities, setshowpriorities] = useState('d-none');
  const [prioritiesinputText, setprioritiesinputText] = useState("");
  const [prioritiesid, setprioritiesid] = useState<any>([]);
  const [prioritiesName, setprioritiesName] = useState<any>([]);
  //slas
  const [showslas, setshowslas] = useState('d-none');
  const [slasinputText, setslasinputText] = useState("");
  const [slasid, setslasid] = useState<any>([]);
  const [slasName, setslasName] = useState<any>([]);
  //ticketcategory
  const [showticketcategory, setshowticketcategory] = useState('d-none');
  const [ticketcategoryinputText, setticketcategoryinputText] = useState("");
  const [ticketcategoryid, setticketcategoryid] = useState<any>([]);
  const [ticketcategoryName, setticketcategoryName] = useState<any>([]);
  //tickettype
  const [showtickettype, setshowtickettype] = useState('d-none');
  const [tickettypeinputText, settickettypeinputText] = useState("");
  const [tickettypeid, settickettypeid] = useState<any>([]);
  const [tickettypeName, settickettypeName] = useState<any>([]);
  //taskcategory
  const [showtaskcategory, setshowtaskcategory] = useState('d-none');
  const [taskcategoryinputText, settaskcategoryinputText] = useState("");
  const [taskcategoryid, settaskcategoryid] = useState<any>([]);
  const [taskcategoryName, settaskcategoryName] = useState<any>([]);
  //contractcategory
  const [showcontractcategory, setshowcontractcategory] = useState('d-none');
  const [contractcategoryinputText, setcontractcategoryinputText] = useState("");
  const [contractcategoryid, setcontractcategoryid] = useState<any>([]);
  const [contractcategoryName, setcontractcategoryName] = useState<any>([]);

  //task/ticket status
  const [showtaskticketstatus, setshowtaskticketstatus] = useState('d-none');
  const [taskticketstatusinputText, settaskticketstatusinputText] = useState("");
  const [taskticketstatusid, settaskticketstatusid] = useState<any>([]);
  const [taskticketstatusName, settaskticketstatusName] = useState<any>([]);

  const clearAllFilter = () => {
    let ar: any = [];
    setProjectid([]);
    setProjectName([]);
    setshowproject("d-none");
    setprojectinputText("");

    setRoleid([]);
    setRoleName([]);
    setshowrole("d-none");
    setroleinputText("");

    setshowpeople("d-none");
    setPeopletid(ar);
    setPeopletName(ar);
    setpeopleinputText("");

    setDepartmentid(ar);
    setDepartmentName(ar);
    setshowdepartment("d-none");
    setdepartmentinputText("");

    setSkillid(ar);
    setSkillName(ar);
    setshowskill("d-none");
    setskillinputText("");

    setshowqueues("d-none");
    setqueuesid(ar);
    setqueuesName(ar);
    setqueuesinputText("");

    setlineOfBusinessid(ar);
    setlineOfBusinessName(ar);
    setshowlineOfBusiness("d-none");
    setlineOfBusinessinputText("");

    setprojectleadid(ar);
    setprojectleadName(ar)
    setshowprojectlead('d-none');
    setprojectleadinputText("");

    setprioritiesid(ar);
    setprioritiesName(ar)
    setshowpriorities('d-none');
    setprioritiesinputText("");

    setticketcategoryid(ar);
    setticketcategoryName(ar)
    setshowticketcategory('d-none');
    setticketcategoryinputText("");

    settickettypeid(ar);
    settickettypeName(ar)
    setshowtickettype('d-none');
    settickettypeinputText("");

    settaskcategoryid(ar);
    settaskcategoryName(ar)
    setshowtaskcategory('d-none');
    settaskcategoryinputText("");

    setslasid(ar);
    setslasName(ar)
    setshowslas('d-none');
    setslasinputText("");

    setcontractcategoryid(ar);
    setcontractcategoryName(ar)
    setshowcontractcategory('d-none');
    setcontractcategoryinputText("");

    settaskticketstatusid(ar);
    settaskticketstatusName(ar)
    setshowtaskticketstatus('d-none');
    settaskticketstatusinputText("");
  };

  const hideSelection = (e: any) => {
    const ar: any[] = [];
    const latestpayload = JSON.parse(JSON.stringify(payload));
  
    switch (e.target.value) {
      case "Project":
        setProjectid([]);
        setProjectName([]);
        setshowproject("d-none");
        setprojectinputText("");
        latestpayload.projects = [];
        break;
  
      case "Role":
        setRoleid([]);
        setRoleName([]);
        setshowrole("d-none");
        setroleinputText("");
        latestpayload.roles = [];
        break;
  
      case "People":
        setshowpeople("d-none");
        setPeopletid(ar);
        setPeopletName(ar);
        setpeopleinputText("");
        latestpayload.resources = [];
        break;
  
      case "Department":
        setDepartmentid(ar);
        setDepartmentName(ar);
        setshowdepartment("d-none");
        setdepartmentinputText("");
        latestpayload.departments = [];
        break;
  
      case "Queues":
        setshowqueues("d-none");
        setqueuesid(ar);
        setqueuesName(ar);
        setqueuesinputText("");
        latestpayload.queues = [];
        break;
  
      case "Skills":
        setshowskill("d-none");
        setSkillid(ar);
        setSkillName(ar);
        setskillinputText("");
        latestpayload.skills = [];
        break;
  
      case "LineOfBusiness":
        setshowlineOfBusiness("d-none");
        setlineOfBusinessid(ar);
        setlineOfBusinessName(ar);
        setlineOfBusinessinputText("");
        latestpayload.lineOfBusiness = [];
        break;
  
      case 'projectlead':
        setprojectleadid(ar);
        setprojectleadName(ar);
        setshowprojectlead('d-none');
        setprojectleadinputText("");
        latestpayload.project_leads = [];
        break;
  
      case 'Priorities':
        setprioritiesid(ar);
        setprioritiesName(ar);
        setshowpriorities('d-none');
        setprioritiesinputText("");
        latestpayload.priority = [];
        break;
  
      case 'SLAs':
        setslasid(ar);
        setslasName(ar);
        setshowslas('d-none');
        setslasinputText("");
        latestpayload.sla = [];
        break;
  
      case 'Ticket category':
        setticketcategoryid(ar);
        setticketcategoryName(ar);
        setshowticketcategory('d-none');
        setticketcategoryinputText("");
        latestpayload.ticket_categories = [];
        break;
  
      case 'Ticket type':
        settickettypeid(ar);
        settickettypeName(ar);
        setshowtickettype('d-none');
        settickettypeinputText("");
        latestpayload.ticket_types = [];
        break;
  
      case 'Task category':
        settaskcategoryid(ar);
        settaskcategoryName(ar);
        setshowtaskcategory('d-none');
        settaskcategoryinputText("");
        latestpayload.lineOfBusiness = [];
        break;
  
      case 'Contract category':
        setcontractcategoryid(ar);
        setcontractcategoryName(ar);
        setshowcontractcategory('d-none');
        setcontractcategoryinputText("");
        latestpayload.lineOfBusiness = [];
        break;
  
      case 'Statuses':
        settaskticketstatusid(ar);
        settaskticketstatusName(ar);
        setshowtaskticketstatus('d-none');
        settaskticketstatusinputText("");
        latestpayload.statuses = [];
        break;
  
      default:
        clearAllFilter();
        latestpayload.projects = [];
        latestpayload.roles = [];
        latestpayload.resources = [];
        latestpayload.departments = [];
        latestpayload.queues = [];
        latestpayload.skills = [];
        latestpayload.lineOfBusiness = [];
        latestpayload.statuses = [];
        break;
    }
  
    setPayload(latestpayload);
  };

  const ChangeFilter = (e: any) => {
    const dataName = e.target.getAttribute("data-name");
    switch (dataName) {
      case "project":
        setshowproject("");
        setcurrentClick("Project");
        toggleDropdown("common-Project");
        break;
  
      case "role":
        setshowrole("");
        setcurrentClick("Role");
        toggleDropdown("common-Role");
        break;
  
      case "people":
        setshowpeople("");
        setcurrentClick("People");
        toggleDropdown("common-People");
        break;
  
      case "department":
        setshowdepartment("");
        setcurrentClick("Department");
        toggleDropdown("common-Department");
        break;
  
      case "location":
        setshowlocation("");
        setcurrentClick("Location");
        toggleDropdown("common-Location");
        break;
  
      case "Skills":
        setshowskill("");
        setcurrentClick("Skills");
        toggleDropdown("common-Skills");
        break;
  
      case "queues":
        setshowqueues("");
        setcurrentClick("Queues");
        toggleDropdown("common-Queues");
        break;
  
      case "LineOfBusiness":
        setshowlineOfBusiness("");
        setcurrentClick("LineOfBusiness");
        toggleDropdown("common-LineOfBusiness");
        break;
  
      case 'projectlead':
        setshowprojectlead('');
        setcurrentClick('projectlead');
        toggleDropdown("common-projectlead");
        break;
  
      case 'priorities':
        setshowpriorities('');
        setcurrentClick('Priorities');
        toggleDropdown("common-Priorities");
        break;
  
      case 'tickettype':
        setshowtickettype('');
        setcurrentClick('Ticket type');
        toggleDropdown("common-Ticket type");
        break;
  
      case 'ticketcategory':
        setshowticketcategory('');
        setcurrentClick('Ticket category');
        toggleDropdown("common-Ticket category");
        break;
  
      case 'taskcategory':
        setshowtaskcategory('');
        setcurrentClick('Task category');
        toggleDropdown("common-Task category");
        break;
  
      case 'slas':
        setshowslas('');
        setcurrentClick('SLAs');
        toggleDropdown("common-SLAs");
        break;
  
      case 'contractcategory':
        setshowcontractcategory('');
        setcurrentClick('Contract category');
        toggleDropdown("common-Contract category");
        break;
  
      case 'statuses':
        setshowtaskticketstatus('');
        setcurrentClick('Statuses');
        toggleDropdown("common-Statuses");
        break;
  
      default:
        break;
    }
  };
  

  const storeFilterValue = (value: any) => {
    let latestpayload = JSON.parse(JSON.stringify(payload));
  
    switch (value) {
      case "Project":
      case "all":
        latestpayload.projects = Projectid;
        break;
  
      case "Role":
      case "all":
        latestpayload.roles = Roleid;
        break;
  
      case "People":
      case "all":
        latestpayload.resources = Peopletid;
        break;
  
      case "Department":
      case "all":
        latestpayload.departments = Departmentid;
        break;
  
      case "Skills":
      case "all":
        latestpayload.skills = Skillid;
        break;
  
      case "Queues":
      case "all":
        latestpayload.queues = queuesid;
        break;
  
      case "LineOfBusiness":
      case "all":
        latestpayload.lineOfBusiness = lineOfBusinessid;
        break;
  
      case 'projectlead':
      case "all":
        latestpayload.project_leads = projectleadid;
        break;
  
      case 'Priorities':
      case "all":
        latestpayload.priority = prioritiesid;
        break;
  
      case 'Ticket type':
      case "all":
        latestpayload.ticket_types = tickettypeid;
        break;
  
      case 'Ticket category':
      case "all":
        latestpayload.ticket_categories = ticketcategoryid;
        break;
  
      case 'Task category':
      case "all":
        latestpayload.task_categories = taskcategoryid;
        break;
  
      case 'SLAs':
      case "all":
        latestpayload.sla = slasid;
        break;
  
      case 'Contract category':
      case "all":
        latestpayload.contract_category = contractcategoryid;
        break;
  
      case 'Statuses':
      case "all":
        latestpayload.statuses = taskticketstatusid;
        break;
  
      default:
        break;
    }
  
    setPayload(latestpayload);
  };
  

  const resetSelectedItems = (value: any) => {
      let resetArray = ["Project", "Role" , "People", "Department", "Location", "Skills", "Queues", "LineOfBusiness", 'projectlead', 'Priorities', 'Ticket type', 'Ticket category', 'Task category', 'SLAs', 'Contract category','Statuses'];
      if(resetArray.includes("Project")){
        setProjectid(payload.projects)
      }
      if(resetArray.includes("Role")){
        setRoleid(payload.roles)
      }
      if(resetArray.includes("People")){
        setPeopletid(payload.resources)
      }
      if(resetArray.includes("Department")){
        setDepartmentid(payload.departments)
      }
      if(resetArray.includes("Skills")){
        setSkillid(payload.skills)
      }
      if(resetArray.includes("Queues")){
        setqueuesid(payload.queues)
      }
      if(resetArray.includes("LineOfBusiness")){
        setlineOfBusinessid(payload.lineOfBusiness)
      }
      if(resetArray.includes('projectlead')){
        setprojectleadid(payload.project_leads)
      }
      if(resetArray.includes('Priorities')){
        setprioritiesid(payload.priority)
      }
      if(resetArray.includes('Ticket type')){
        settickettypeid(payload.ticket_types)
      }
      if(resetArray.includes('Ticket category')){
        setticketcategoryid(payload.ticket_categories)
      }
      if(resetArray.includes('Task category')){
        settaskcategoryid(payload.task_categories)
      }
      if(resetArray.includes('SLAs')){
        setslasid(payload.sla)
      }
      if(resetArray.includes('Contract category')){
        setcontractcategoryid(payload.contract_category)
      }
      if(resetArray.includes('Contract category')){
        setcontractcategoryid(payload.contract_category)
      }
      if(resetArray.includes('Statuses')){
        settaskticketstatusid(payload.statuses)
      }
  };

  const handleChange = (e: any) => {
    if (e.target.getAttribute("data-id") === "Project") {
      if (e.target.checked) {
        setProjectid((Projectid: any) => [
          ...Projectid,
          parseInt(e.target.value),
        ]);
      } else {
        setProjectid(
          Projectid.filter((item: any) => item !== parseInt(e.target.value))
        );
      }
    } else if (e.target.getAttribute("data-id") === "Role") {
      if (e.target.checked) {
        setRoleid((Roleid: any) => [...Roleid, parseInt(e.target.value)]);
      } else {
        setRoleid(
          Roleid.filter((item: any) => item !== parseInt(e.target.value))
        );
      }
    } else if (e.target.getAttribute("data-id") === "People") {
      if (e.target.checked) {
        setPeopletid((Peopletid: any) => [
          ...Peopletid,
          parseInt(e.target.value),
        ]);
      } else {
        setPeopletid(
          Peopletid.filter((item: any) => item !== parseInt(e.target.value))
        );
      }
    } else if (e.target.getAttribute("data-id") === "Department") {
      if (e.target.checked) {
        setDepartmentid((Departmentid: any) => [
          ...Departmentid,
          parseInt(e.target.value),
        ]);
      } else {
        setDepartmentid(
          Departmentid.filter((item: any) => item !== parseInt(e.target.value))
        );
      }
    } else if (e.target.getAttribute("data-id") === "Location") {
      if (e.target.checked) {
        setLocationid((Locationid: any) => [
          ...Locationid,
          parseInt(e.target.value),
        ]);
      } else {
        setLocationid(
          Locationid.filter((item: any) => item !== parseInt(e.target.value))
        );
      }
    } else if (e.target.getAttribute("data-id") === "Skills") {
      if (e.target.checked) {
        setSkillid((Skillid: any) => [...Skillid, parseInt(e.target.value)]);
      } else {
        setSkillid(
          Skillid.filter((item: any) => item !== parseInt(e.target.value))
        );
      }
    } else if (e.target.getAttribute("data-id") === "Queues") {
      if (e.target.checked) {
        setqueuesid((queuesid: any) => [...queuesid, parseInt(e.target.value)]);
      } else {
        setqueuesid(
          queuesid.filter((item: any) => item !== parseInt(e.target.value))
        );
      }
    } else if (e.target.getAttribute("data-id") === "LineOfBusiness") {
      if (e.target.checked) {
        setlineOfBusinessid((lineOfBusinessid: any) => [
          ...lineOfBusinessid,
          parseInt(e.target.value),
        ]);
      } else {
        setlineOfBusinessid(
          lineOfBusinessid.filter(
            (item: any) => item !== parseInt(e.target.value)
          )
        );
      }
    } else if (e.target.getAttribute('data-id') === 'projectlead') {
      if (e.target.checked) {
        setprojectleadid((projectleadid: any) => [...projectleadid, parseInt(e.target.value)]);
      } else {
        setprojectleadid(projectleadid.filter((item: any) => item !== parseInt(e.target.value)));
      }
    } else if (e.target.getAttribute('data-id') === 'Priorities') {
      if (e.target.checked) {
        setprioritiesid((prioritiesid: any) => [...prioritiesid, parseInt(e.target.value)]);
      } else {
        setprioritiesid(prioritiesid.filter((item: any) => item !== parseInt(e.target.value)));
      }
    } else if (e.target.getAttribute('data-id') === 'Ticket type') {
      if (e.target.checked) {
        settickettypeid((tickettypeid: any) => [...tickettypeid, parseInt(e.target.value)]);
      } else {
        settickettypeid(tickettypeid.filter((item: any) => item !== parseInt(e.target.value)));
      }
    } else if (e.target.getAttribute('data-id') === 'Ticket category') {
      if (e.target.checked) {
        setticketcategoryid((ticketcategoryid: any) => [...ticketcategoryid, parseInt(e.target.value)]);
      } else {
        setticketcategoryid(ticketcategoryid.filter((item: any) => item !== parseInt(e.target.value)));
      }
    } else if (e.target.getAttribute('data-id') === 'Task category') {
      if (e.target.checked) {
        settaskcategoryid((taskcategoryid: any) => [...taskcategoryid, parseInt(e.target.value)]);
      } else {
        settaskcategoryid(taskcategoryid.filter((item: any) => item !== parseInt(e.target.value)));
      }
    } else if (e.target.getAttribute('data-id') === 'SLAs') {
      if (e.target.checked) {
        setslasid((slasid: any) => [...slasid, parseInt(e.target.value)]);
      } else {
        setslasid(slasid.filter((item: any) => item !== parseInt(e.target.value)));
      }
    } else if (e.target.getAttribute('data-id') === 'Contract category') {
      if (e.target.checked) {
        setcontractcategoryid((contractcategoryid: any) => [...contractcategoryid, parseInt(e.target.value)]);
      } else {
        setcontractcategoryid(contractcategoryid.filter((item: any) => item !== parseInt(e.target.value)));
      }
    } else if (e.target.getAttribute('data-id') === 'Statuses') {
      if (e.target.checked) {
        settaskticketstatusid((taskticketstatusid: any) => [...taskticketstatusid, parseInt(e.target.value)]);
      } else {
        settaskticketstatusid(taskticketstatusid.filter((item: any) => item !== parseInt(e.target.value)));
      }
    }
    
  };
  let inputHandler = (e: any) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    if (e.target.id === "People-search") {
      setpeopleinputText(lowerCase);
    } else if (e.target.id === "Department-search") {
      setdepartmentinputText(lowerCase);
    } else if (e.target.id === "Project-search") {
      setprojectinputText(lowerCase);
    } else if (e.target.id === "Role-search") {
      setroleinputText(lowerCase);
    } else if (e.target.id === "Skills-search") {
      setskillinputText(lowerCase);
    } else if (e.target.id === "Queues-search") {
      setqueuesinputText(lowerCase);
    } else if (e.target.id === "LineOfBusiness-search") {
      setlineOfBusinessinputText(lowerCase);
    } else if (e.target.id === 'projectlead-search') {
      setprojectleadinputText(lowerCase);
    } else if (e.target.id === 'Priorities-search') {
      setprioritiesinputText(lowerCase);
    } else if (e.target.id === 'Ticket type-search') {
      settickettypeinputText(lowerCase);
    } else if (e.target.id === 'Ticket category-search') {
      setticketcategoryinputText(lowerCase);
    } else if (e.target.id === 'Task category-search') {
      settaskcategoryinputText(lowerCase);
    } else if (e.target.id === 'SLAs-search') {
      setslasinputText(lowerCase);
    } else if (e.target.id === 'Contract category-search') {
      setcontractcategoryinputText(lowerCase);
    } else if (e.target.id === 'Skills-search') {
      setskillinputText(lowerCase);
    } else if (e.target.id === 'Statuses-search') {
      settaskticketstatusinputText(lowerCase);
    }
  };

  const selectAll = (e: any) => {
    if (e.target.id === "Project") {
      if (e.target.checked) {
        const selectallid = filterArray?.projects
          ?.filter(
            (twork: any) => twork.isActive === true && twork.isCompleted === false
          )
          ?.map((x: any) => parseInt(x.id));
        setProjectid(selectallid);
      } else {
        setProjectid([]);
      }
    } else if (e.target.id === "Role") {
      if (e.target.checked) {
        const selectallid = filterArray?.roles
          ?.filter((twork: any) => twork.isActive === true)
          ?.map((x: any) => parseInt(x.id));
        setRoleid(selectallid);
      } else {
        setRoleid([]);
      }
    } else if (e.target.id === "People") {
      if (e.target.checked) {
        const selectallid = filterArray?.peoples
          ?.filter((twork: any) => twork.isActive === true)
          ?.map((x: any) => parseInt(x.id));
        setPeopletid(selectallid);
      } else {
        setPeopletid([]);
      }
    } else if (e.target.id === "Department") {
      if (e.target.checked) {
        const selectallid = filterArray?.departments?.items?.map((x: any) =>
          parseInt(x.id)
        );
        setDepartmentid(selectallid);
      } else {
        setDepartmentid([]);
      }
    } else if (e.target.id === "Location") {
      if (e.target.checked) {
        const selectallid = filterArray?.locations?.data
          ?.filter((twork: any) => twork.isActive === true)
          ?.map((x: any) => parseInt(x.id));
        setLocationid(selectallid);
      } else {
        setLocationid([]);
      }
    } else if (e.target.id === "Skills") {
      if (e.target.checked) {
        const selectallid = filterArray?.skills?.data
          ?.filter((twork: any) => twork.isActive === true)
          .map((x: any) => parseInt(x.id));
        setSkillid(selectallid);
      } else {
        setSkillid([]);
      }
    } else if (e.target.id === "Queues") {
      if (e.target.checked) {
        const selectallid = filterArray?.queues?.data
          ?.filter((twork: any) => twork.isActive === true)
          ?.map((x: any) => parseInt(x.id));
        setqueuesid(selectallid);
      } else {
        setqueuesid([]);
      }
    } else if (e.target.id === "LineOfBusiness") {
      if (e.target.checked) {
        const selectallid = filterArray?.lineOfBusiness?.data
          ?.filter((twork: any) => twork.isActive === true)
          .map((x: any) => parseInt(x.id));
        setlineOfBusinessid(selectallid);
      } else {
        setlineOfBusinessid([]);
      }
    } else if (e.target.id === 'projectlead') {
      
      if (e.target.checked) {
        const selectallid = filterArray?.peoples?.filter((twork: any) => (twork.isActive === true))?.map((x: any) => parseInt(x.id));
        setprojectleadid(selectallid);
      } else {
        setprojectleadid([]);
      }
    } else if (e.target.id === 'Priorities') {
      if (e.target.checked) {
        const selectallid = filterArray?.priorities?.data?.filter((twork: any) => (twork.isActive === true))?.map((x: any) => parseInt(x.id));
        setprioritiesid(selectallid);
      } else {
        setprioritiesid([]);
      }
    } else if (e.target.id === 'Ticket type') {
      if (e.target.checked) {
        const selectallid = filterArray?.ticketType?.data?.filter((twork: any) => (twork.isActive === true))?.map((x: any) => parseInt(x.id));
        settickettypeid(selectallid);
      } else {
        settickettypeid([]);
      }
    } else if (e.target.id === 'Ticket category') {
      if (e.target.checked) {
        const selectallid = filterArray?.ticketCategory?.data?.filter((twork: any) => (twork.isActive === true))?.map((x: any) => parseInt(x.id));
        setticketcategoryid(selectallid);
      } else {
        setticketcategoryid([]);
      }
    } else if (e.target.id === 'Task category') {
      if (e.target.checked) {
        const selectallid = filterArray?.taskCategory?.data?.filter((twork: any) => (twork.isActive === true))?.map((x: any) => parseInt(x.id));
        settaskcategoryid(selectallid);
      } else {
        settaskcategoryid([]);
      }
    } else if (e.target.id === 'SLAs') {
      if (e.target.checked) {
        const selectallid = filterArray?.slas?.data?.filter((twork: any) => (twork.isActive === true))?.map((x: any) => parseInt(x.id));
        setslasid(selectallid);
      } else {
        setslasid([]);
      }
    } else if (e.target.id === 'Contract category') {
      if (e.target.checked) {
        const selectallid = filterArray?.contractCategory?.data?.filter((twork: any) => (twork.isActive === true))?.map((x: any) => parseInt(x.id));
        setcontractcategoryid(selectallid);
      } else {
        setcontractcategoryid([]);
      }
    } else if (e.target.id === 'Statuses') {
      if (e.target.checked) {
        const selectallid = filterArray?.taskticketstatus?.filter((twork: any) => (twork.isActive === true))?.map((x: any) => parseInt(x.id));
        settaskticketstatusid(selectallid);
      } else {
        settaskticketstatusid([]);
      }
    }
  };

  useEffect(() => {
    if (!filterArray?.isfetchlayoutandfilters && !filterArray?.isPeopleFetch && !filterArray?.isDepartmentFetch && !filterArray?.isCompanyFetch && !filterArray?.isProjectFetch && !filterArray?.isSkillFetch && !filterArray?.isRoleFetch && !filterArray?.isQueuesFetch && !filterArray?.isTicketCategoryFetch && !filterArray?.isTicketCategoryFetch && !filterArray?.isTicketTypeFetch && !filterArray?.isTaskCategoryFetch && !filterArray?.isTaskCategoryFetch && !filterArray?.isSlasFetch && !filterArray?.isContractCategoryFetch && !filterArray?.isfetchstatus) {
      
      let data = JSON.parse(JSON.stringify(payload));
      if (data?.resources?.length > 0) {
        let ids2 = filterArray?.peoples
          ?.filter((twork: any) => twork.isActive === true)
          ?.map((item1: any) => parseInt(item1?.id));
        const filteredresource = data?.resources?.filter((id: any) =>
          ids2?.includes(id)
        );
        setPeopletid(filteredresource);
        setshowpeople("");
      }
      if (data?.departments?.length > 0) {
        let ids3 = filterArray?.departments?.items
        ?.map((item1: any) => parseInt(item1?.id)); // Convert to integers, if they aren't already  
        const filtereddepartments = data?.departments?.filter((id:any)=>{
            return ids3?.includes(id); 
        });
        setDepartmentid(filtereddepartments);
        setshowdepartment("");
      }
      if (data?.projects?.length > 0) {
    
        let ids4 = filterArray?.projects
          ?.filter((twork: any) => twork.isActive === true)
          ?.map((item1: any) => parseInt(item1?.id));
        const filteredprojects = data?.projects?.filter((id: any) =>
          ids4?.includes(id)
        );
      
        setProjectid(filteredprojects);
        setshowproject("");
        setcurrentClick("Project");
      }
      if (data?.roles?.length > 0) {
        let ids5 = filterArray?.roles
          ?.filter((twork: any) => twork.isActive === true)
          ?.map((item1: any) => parseInt(item1?.id));
        const filteredroles = data?.roles?.filter((id: any) =>
          ids5?.includes(id)
        );
        setRoleid(filteredroles);
        setshowrole("");
      }
      if (data?.queues?.length > 0) {
        let ids6 = filterArray?.queues?.data
          ?.filter((twork: any) => twork.isActive === true)
          ?.map((item1: any) => parseInt(item1?.id));
        const filteredqueues_ids = data?.queues?.filter((id: any) =>
          ids6?.includes(id)
        );
        setqueuesid(filteredqueues_ids);
        setshowqueues("");
      }
      if (data?.skills?.length > 0) {
        let ids8 = filterArray?.skills?.data
          ?.filter((twork: any) => twork.isActive === true)
          ?.map((item1: any) => parseInt(item1?.id));
        const filteredskills = data?.skills?.filter((id: any) =>
          ids8?.includes(id)
        );
        setSkillid(filteredskills);
        setshowskill("");
      }
      if (data?.lineOfBusiness?.length > 0) {
        setlineOfBusinessid(data?.lineOfBusiness);
        setshowlineOfBusiness("");
      }
      if(data?.contract_category?.length >0){
        let ids9 = filterArray?.contractCategory?.data?.filter((twork: any) => (twork.isActive === true))?.map((item1:any) => parseInt(item1?.id));
        const filteredcontract_category = data?.contract_category?.filter((id:any) => ids9?.includes(id));
        setcontractcategoryid(filteredcontract_category)
        setshowcontractcategory('');
      }
      if(data?.priority?.length >0){
        let ids10 = filterArray?.priorities?.data?.filter((twork: any) => (twork.isActive === true))?.map((item1:any) => parseInt(item1?.id));
        const filteredpriority = data?.priority?.filter((id:any) => ids10?.includes(id));
        setprioritiesid(filteredpriority)
        setshowpriorities('');
      }
      if(data?.project_leads?.length >0){
        let ids11 = filterArray?.peoples?.filter((twork: any) => (twork.isActive === true))?.map((item1:any) => parseInt(item1?.id));
        const filteredproject_leads = data?.project_leads?.filter((id:any) => ids11?.includes(id));
        setprojectleadid(filteredproject_leads)
        setshowprojectlead('');
      }
      if(data?.sla?.length >0){
        let ids12 = filterArray?.slas?.data?.filter((twork: any) => (twork.isActive === true))?.map((item1:any) => parseInt(item1?.id));
        const filteredsla = data?.sla?.filter((id:any) => ids12?.includes(id));
        setslasid(filteredsla)
        setshowslas('');
      }
      if(data?.task_categories?.length >0){
        let ids13 = filterArray?.taskCategory?.data?.filter((twork: any) => (twork.isActive === true))?.map((item1:any) => parseInt(item1?.id));
        const filteredtask_categories = data?.task_categories?.filter((id:any) => ids13?.includes(id));
        settaskcategoryid(filteredtask_categories)
        setshowtaskcategory('');
      }
      if(data?.ticket_categories?.length >0){
        let ids14 = filterArray?.ticketCategory?.data?.filter((twork: any) => (twork.isActive === true))?.map((item1:any) => parseInt(item1?.id));
        const filteredticket_categories = data?.ticket_categories?.filter((id:any) => ids14?.includes(id));
        setticketcategoryid(filteredticket_categories)
        setshowticketcategory('');
      }
      if(data?.ticket_types?.length >0){
        let ids15 = filterArray?.ticketType?.data?.filter((twork: any) => (twork.isActive === true))?.map((item1:any) => parseInt(item1?.id));
        const filteredticket_types = data?.ticket_types?.filter((id:any) => ids15?.includes(id));
        settickettypeid(filteredticket_types)
        setshowtickettype('');
      }
      if(data?.statuses?.length >0){
        let ids15 = filterArray?.taskticketstatus?.filter((twork: any) => (twork.isActive === true))?.map((item1:any) => parseInt(item1?.id));
        const filteredticket_types = data?.statuses?.filter((id:any) => ids15?.includes(id));
        settaskticketstatusid(filteredticket_types)
        setshowtaskticketstatus('');
      }
    }
  }, [
    payload,
    filterArray?.isfetchlayoutandfilters,filterArray?.isPeopleFetch,filterArray?.isDepartmentFetch, filterArray?.isCompanyFetch, filterArray?.isProjectFetch,filterArray?.isSkillFetch, filterArray?.isRoleFetch, filterArray?.isQueuesFetch, filterArray?.isTicketCategoryFetch,filterArray?.isTicketCategoryFetch, filterArray?.isTicketTypeFetch, filterArray?.isTaskCategoryFetch, filterArray?.isTaskCategoryFetch,filterArray?.isSlasFetch,filterArray?.isContractCategoryFetch,filterArray?.isfetchstatus
  ]);
  
  useEffect(() => {
    resetSelectedItems(openDropdown)
  },[openDropdown])


  useEffect(() => {
    if (!filterArray?.isPeopleFetch && !filterArray?.isDepartmentFetch && !filterArray?.isProjectFetch && !filterArray?.isSkillFetch && !filterArray?.isRoleFetch && !filterArray?.isQueuesFetch && !filterArray?.isTicketCategoryFetch && !filterArray?.isSlasFetch && !filterArray?.isfetchstatus) {
        const resourceNameArray = filterArray.peoples
            .filter((item: { id: any; }) => Peopletid.includes(item.id))
            .map((item: { name: any; }) => item.name)
        setPeopletName(resourceNameArray) 

        const departMentNameArray = filterArray.departments.items
            .filter((item: { id: any; }) => Departmentid.includes(item.id))
            .map((item: { name: any; }) => item.name)
        setDepartmentName(departMentNameArray)
        
        const projectNameArray = filterArray.projects
            .filter((item: { id: any; }) => Projectid.includes(item.id))
            .map((item: { name: any; }) => item.name)
        setProjectName(projectNameArray)

        const roleNameArray = filterArray.roles
            .filter((item: { id: any; }) => Roleid.includes(item.id))
            .map((item: { name: any; }) => item.name)
        setRoleName(roleNameArray)

        const queueNameArray = filterArray.queues.data
            .filter((item: { id: any; }) => queuesid.includes(item.id))
            .map((item: { name: any; }) => item.name)
        setqueuesName(queueNameArray)
      
        const skillNameArray = filterArray.skills.data
            .filter((item: { id: any; }) => Skillid.includes(item.id))
            .map((item: { name: any; }) => item.name)
        setSkillName(skillNameArray)

        const lineNameArray = filterArray.lineOfBusiness.data
            .filter((item: { id: any; }) => lineOfBusinessid.includes(item.id))
            .map((item: { name: any; }) => item.name)
        setlineOfBusinessName(lineNameArray)

        const contractcategoryNameArray = filterArray?.contractCategory?.data
          .filter((item: { id: any; }) => contractcategoryid.includes(item.id))
          .map((item: { name: any; }) => item.name)
        setcontractcategoryName(contractcategoryNameArray)

        const priorityNameArray = filterArray?.priorities?.data
            .filter((item: { id: any; }) => prioritiesid.includes(item.id))
            .map((item: { name: any; }) => item.name)
        setprioritiesName(priorityNameArray)

        const project_leadsNameArray = filterArray?.peoples?.filter((item: { id: any; }) => projectleadid.includes(item.id)).map((item: { name: any; }) => item.name)
        setprojectleadName(project_leadsNameArray)

        const slasNameArray = filterArray?.slas?.data?.filter((item: { id: any; }) => slasid.includes(item.id)).map((item: { name: any; }) => item.name)
        setslasName(slasNameArray)

        const task_categoriesNameArray = filterArray?.taskCategory?.data?.filter((item: { id: any; }) => taskcategoryid.includes(item.id)).map((item: { name: any; }) => item.name)
        settaskcategoryName(task_categoriesNameArray)
    
        const ticket_categoriesNameArray = filterArray?.ticketCategory?.data?.filter((item: { id: any; }) => ticketcategoryid.includes(item.id)).map((item: { name: any; }) => item.name)
        setticketcategoryName(ticket_categoriesNameArray)

        const ticket_typesNameArray = filterArray?.ticketType?.data?.filter((item: { id: any; }) => tickettypeid.includes(item.id)).map((item: { name: any; }) => item.name)
        settickettypeName(ticket_typesNameArray)

        const statusArray = filterArray?.taskticketstatus?.filter((item: { id: any; }) => taskticketstatusid.includes(item.id)).map((item: { name: any; }) => item.name)
        settickettypeName(statusArray)
      }
  }, [
    Peopletid, Departmentid, Projectid, Roleid, queuesid, Skillid, lineOfBusinessid, contractcategoryid, prioritiesid,projectleadid,slasid,taskcategoryid ,ticketcategoryid, tickettypeid, taskticketstatusid
  ]);
  return (
    <>
      <div className="dropDown d-flex align-items-center">
        <button
          onClick={()=> toggleDropdown("common-filter")}
          className="btn btn-outline btn-outline-primary fw-normal border-primary rounded-pill px-4 py-0 h-32px fs-16 d-flex align-items-center"
          type="button"
          data-kt-menu-trigger="click"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-start"
          data-kt-menu-flip="bottom"
        >
          <KTSVG
            path="/media/icons/duotune/new-icons/filter.svg"
            className="me-2"
            stylecode={{ color: "inherit" }}
            svgClassName="w-16px h-14px"
          />
          Filter
        </button>

        {(showproject === "" ||
          showrole === "" ||
          showpeople === "" ||
          showdepartment === "" ||
          showlocation === "" ||
          showskill === "" ||
          showqueues === "" ||
          showprojectlead === "" ||
          showcontractcategory === "" ||
          showtaskcategory === "" ||
          showticketcategory === "" ||
          showpriorities === "" ||
          showtickettype === "" ||
          showlineOfBusiness === "" || showtaskticketstatus === "") && (
          <OverlayTrigger
            key="copy-to-clipboard"
            trigger={["hover", "focus"]}
            placement="bottom"
            overlay={
              <Tooltip
                id="tooltip-copy-to-clipboard"
                className="position-absulute"
              >
                <span className="pe-2">Clear all</span>
              </Tooltip>
            }
          >
            <span>
              <button
                value="All"
                onClick={hideSelection}
                className="p-0 bg-transparent border-0 w-auto h-auto ms-3 me-5"
              >
                <KTSVG
                  path="/media/icons/duotune/extra/close.svg"
                  className="text-primary pe-none"
                  svgClassName="w-10px h-10px"
                />
              </button>
            </span>
          </OverlayTrigger>
        )}
        {openDropdown === "common-filter" &&
          <div
            className="menu menu-sub menu-sub-dropdown bg-white px-3 py-5 min-w-130px fs-14 show position-absolute top-100 start-0 mh-240px overflow-auto"
            data-kt-menu="true"
          >
            <a
              onClick={ChangeFilter}
              data-name="people"
              className="d-flex align-items-center text-black fw-semibold text-hover-primary text-nowrap"
              href="#"
            >
              <KTSVG
                path="/media/icons/duotune/new-icons/resources.svg"
                stylecode={{ color: "#164387" }}
                className="w-20px me-1"
                svgClassName="w-16px h-auto"
              />
              Resources
            </a>
            <a
              onClick={ChangeFilter}
              data-name="department"
              className="d-flex align-items-center text-black fw-semibold text-hover-primary text-nowrap"
              href="#"
            >
              <KTSVG
                path="/media/icons/duotune/extra/mouse-square.svg"
                stylecode={{ color: "#164387" }}
                className="w-20px me-1"
                svgClassName="w-16px h-auto"
              />
              Department
            </a>
            <a
              className={`d-flex align-items-center text-black fw-semibold text-hover-primary text-nowrap`}
              data-name="project"
              onClick={ChangeFilter}
              href="#"
            >
              <KTSVG
                path="/media/icons/duotune/new-icons/project.svg"
                stylecode={{ color: "#164387" }}
                className="w-20px me-1"
                svgClassName="w-16px h-auto"
              />
              Project
            </a>
            <a
              className="d-flex align-items-center text-black fw-semibold text-hover-primary text-nowrap"
              data-name="role"
              onClick={ChangeFilter}
              href="#"
            >
              <KTSVG
                path="/media/icons/duotune/new-icons/role.svg"
                stylecode={{ color: "#164387" }}
                className="w-20px me-1"
                svgClassName="w-16px h-auto"
              />
              Role
            </a>
            <a
              className={`d-flex align-items-center text-black fw-semibold text-hover-primary text-nowrap`}
              data-name="projectlead"
              onClick={ChangeFilter}
              href="#"
            >
              <KTSVG
                path="/media/icons/duotune/new-icons/project-leads.svg"
                stylecode={{ color: "#164387" }}
                className="w-20px me-1"
                svgClassName="w-16px h-auto"
              />
              Project Leads
            </a>
            {taskListType === "Ticket" &&
            <a
              className={`d-flex align-items-center text-black fw-semibold text-hover-primary text-nowrap`}
              data-name="queues"
              onClick={ChangeFilter}
              href="#"
            >
              <KTSVG
                path="/media/icons/duotune/new-icons/queues.svg"
                stylecode={{ color: "#164387" }}
                className="w-20px me-1"
                svgClassName="w-16px h-auto"
              />
              Queues
            </a>
            }

            {general_settings?.show_resource_skills === true && (
              <a
                className="d-flex align-items-center text-black fw-semibold text-hover-primary text-nowrap"
                data-name="Skills"
                onClick={ChangeFilter}
                href="#"
              >
                <KTSVG
                  path="/media/icons/duotune/new-icons/skills.svg"
                  stylecode={{ color: "#164387" }}
                  className="w-20px me-1"
                  svgClassName="w-16px h-auto"
                />
                Skills
              </a>
            )}
            <a
              className={`d-flex align-items-center text-black fw-semibold text-hover-primary text-nowrap`}
              data-name="LineOfBusiness"
              onClick={ChangeFilter}
              href="#"
            >
              <KTSVG
                path="/media/icons/duotune/extra/note.svg"
                stylecode={{ color: "#164387" }}
                className="w-20px me-1"
                svgClassName="w-16px h-auto"
              />
              Line of business
            </a>
            {taskListType === "Ticket" &&
            <><a className="d-flex align-items-center text-black fw-semibold text-hover-primary text-nowrap" data-name="priorities" onClick={ChangeFilter} href="#">
            <KTSVG path="/media/icons/duotune/new-icons/priorities.svg" className='w-20px me-1 text-primary' svgClassName="w-16px h-auto" />Priorities</a>
            <a className="d-flex align-items-center text-black fw-semibold text-hover-primary text-nowrap" data-name="tickettype" onClick={ChangeFilter} href="#">
              <KTSVG path="/media/icons/duotune/new-icons/ticket-type.svg" className='w-20px me-1 text-primary' svgClassName="w-16px h-auto" />Ticket type</a>
            <a className="d-flex align-items-center text-black fw-semibold text-hover-primary text-nowrap" data-name="ticketcategory" onClick={ChangeFilter} href="#">
              <KTSVG path="/media/icons/duotune/new-icons/ticket-category.svg" className='w-20px me-1 text-primary' svgClassName="w-16px h-auto" />Ticket category</a>
            <a className="d-flex align-items-center text-black fw-semibold text-hover-primary text-nowrap" data-name="slas" onClick={ChangeFilter} href="#">
              <KTSVG path="/media/icons/duotune/new-icons/slas.svg" className='w-20px me-1 text-primary' svgClassName="w-16px h-auto" />SLAs</a>
            <a className="d-flex align-items-center text-black fw-semibold text-hover-primary text-nowrap" data-name="contractcategory" onClick={ChangeFilter} href="#">
              <KTSVG path="/media/icons/duotune/new-icons/contract-category.svg" className='w-20px me-1 text-primary' svgClassName="w-16px h-auto" />Contract category</a></>
            }
            {taskListType === "Task" &&
            <a className="d-flex align-items-center text-black fw-semibold text-hover-primary text-nowrap" data-name="taskcategory" onClick={ChangeFilter} href="#">
              <KTSVG path="/media/icons/duotune/new-icons/task-category.svg" className='w-20px me-1 text-primary' svgClassName="w-16px h-auto" />Task category</a>
            }
            <a className="d-flex align-items-center text-black fw-semibold text-hover-primary text-nowrap" data-name="statuses" onClick={ChangeFilter} href="#">
            <KTSVG path="/media/icons/duotune/new-icons/task-category.svg" className='w-20px me-1 text-primary' svgClassName="w-16px h-auto" />Task/Ticket status</a>
          </div>
        }
      </div>
      {showpeople === "" &&
        <DropDown
          imgpath="/media/icons/duotune/extra/profile-user-2.svg"
          inputHandler={inputHandler}
          inputText={peopleinputText}
          currentClick={currentClick}
          checked_ids={Peopletid}
          title="Resources"
          id="People"
          data={filterArray?.peoples?.filter(
            (twork: any) => twork.isActive === true
          )}
          isLoading={filterArray?.isPeopleFetch}
          showdropdown={showpeople}
          hideSelection={hideSelection}
          storeFilterValue={storeFilterValue}
          handleChange={handleChange}
          selectedName={PeopletName}
          selectAll={selectAll}
          tooltipTitle="Filter resources on name."
          isOpen={openDropdown === 'common-People'}
          toggleDropdown={toggleDropdown}
          filterType={"common"}
        ></DropDown>
      }
      {showdepartment === "" &&
      <DropDown
        imgpath="/media/icons/duotune/extra/mouse-square.svg"
        inputHandler={inputHandler}
        inputText={departmentinputText}
        currentClick={currentClick}
        checked_ids={Departmentid}
        title="Department"
        id="Department"
        data={filterArray?.departments?.items}
        isLoading={filterArray?.isDepartmentFetch}
        showdropdown={showdepartment}
        hideSelection={hideSelection}
        storeFilterValue={storeFilterValue}
        handleChange={handleChange}
        selectedName={DepartmentName}
        selectAll={selectAll}
        tooltipTitle="Filter resources on their default department."
        isOpen={openDropdown === 'common-Department'}
        toggleDropdown={toggleDropdown}
        filterType={"common"}
      ></DropDown>
      }
      {showproject === "" &&
      <DropDown
        imgpath="/media/icons/duotune/extra/note.svg"
        inputHandler={inputHandler}
        inputText={projectinputText}
        currentClick={currentClick}
        checked_ids={Projectid}
        title="Project"
        id="Project"
        data={filterArray?.projects?.filter(
          (twork: any) => twork.isActive === true && twork.isCompleted === false && payload.companies.includes(twork.companyID)
        )}
        isLoading={filterArray?.isProjectFetching}
        showdropdown={showproject}
        hideSelection={hideSelection}
        storeFilterValue={storeFilterValue}
        handleChange={handleChange}
        selectedName={ProjectName}
        selectAll={selectAll}
        view={view}
        tooltipTitle="Filter resources on their project."
        isOpen={openDropdown === 'common-Project'}
        toggleDropdown={toggleDropdown}
        filterType={"common"}
        company={payload?.companies}
      ></DropDown>
      }
      {showrole === "" &&
      <DropDown
        imgpath="/media/icons/duotune/extra/note.svg"
        inputHandler={inputHandler}
        inputText={roleinputText}
        currentClick={currentClick}
        checked_ids={Roleid}
        title="Role"
        id="Role"
        data={filterArray?.roles?.filter(
          (twork: any) => twork.isActive === true
        )}
        isLoading={filterArray?.isRoleFetch}
        showdropdown={showrole}
        hideSelection={hideSelection}
        storeFilterValue={storeFilterValue}
        handleChange={handleChange}
        selectedName={RoleName}
        selectAll={selectAll}
        tooltipTitle="Filter resources on their roles."
        isOpen={openDropdown === 'common-Role'}
        toggleDropdown={toggleDropdown}
        filterType={"common"}
      ></DropDown>
      }
      {showqueues === "" &&
      <DropDown
        imgpath="/media/icons/duotune/extra/note.svg"
        inputHandler={inputHandler}
        inputText={queuesinputText}
        currentClick={currentClick}
        checked_ids={queuesid}
        title="Queues"
        id="Queues"
        data={filterArray?.queues?.data?.filter(
          (twork: any) => twork.isActive === true
        )}
        isLoading={filterArray?.isQueuesFetch}
        showdropdown={showqueues}
        hideSelection={hideSelection}
        storeFilterValue={storeFilterValue}
        handleChange={handleChange}
        selectedName={queuesName}
        selectAll={selectAll}
        view={view}
        tooltipTitle="Filter resources on their queues."
        isOpen={openDropdown === 'common-Queues'}
        toggleDropdown={toggleDropdown}
        filterType={"common"}
      ></DropDown>
      }
      {showskill === "" &&
      <DropDown
        imgpath="/media/icons/duotune/extra/note.svg"
        inputHandler={inputHandler}
        inputText={skillinputText}
        currentClick={currentClick}
        checked_ids={Skillid}
        title="Skills"
        id="Skills"
        data={filterArray?.skills?.data?.filter(
          (twork: any) => twork.isActive === true
        )}
        isLoading={filterArray?.isSkillFetch}
        showdropdown={showskill}
        hideSelection={hideSelection}
        storeFilterValue={storeFilterValue}
        handleChange={handleChange}
        selectedName={SkillName}
        selectAll={selectAll}
        tooltipTitle="Filter data on their skills."
        isOpen={openDropdown === 'common-Skills'}
        toggleDropdown={toggleDropdown}
        filterType={"common"}
      ></DropDown>
      }
      {showlineOfBusiness === "" &&
      <DropDown
        imgpath="/media/icons/duotune/extra/note.svg"
        inputHandler={inputHandler}
        inputText={lineOfBusinessinputText}
        currentClick={currentClick}
        checked_ids={lineOfBusinessid}
        title="Line of business"
        id="LineOfBusiness"
        data={filterArray?.lineOfBusiness?.data?.filter(
          (twork: any) => twork.isActive === true
        )}
        isLoading={filterArray?.islineOfBusinessFetch}
        showdropdown={showlineOfBusiness}
        hideSelection={hideSelection}
        storeFilterValue={storeFilterValue}
        handleChange={handleChange}
        selectedName={lineOfBusinessName}
        selectAll={selectAll}
        view={view}
        tooltipTitle="Filter data on their line of business."
        isOpen={openDropdown === 'common-LineOfBusiness'}
        toggleDropdown={toggleDropdown}
        filterType={"common"}
      ></DropDown>
      }
      
      {showprojectlead === "" &&
      <DropDown imgpath="/media/icons/duotune/new-icons/project-leads.svg" inputHandler={inputHandler} inputText={projectleadinputText} currentClick={currentClick} checked_ids={projectleadid} title="projectlead" data={filterArray?.peoples?.filter((twork: any) => (twork.isActive === true))} isLoading={filterArray?.isPeopleFetch} showdropdown={showprojectlead} hideSelection={hideSelection} storeFilterValue={storeFilterValue} handleChange={handleChange} selectedName={projectleadName} selectAll={selectAll} tooltipTitle='Filter data on their project leads.' isOpen={openDropdown === 'common-projectlead'} toggleDropdown={toggleDropdown} id="projectlead" filterType={"common"}></DropDown>
      }

      {showpriorities  === "" &&
        <DropDown imgpath="/media/icons/duotune/new-icons/priorities.svg" inputHandler={inputHandler} inputText={prioritiesinputText} currentClick={currentClick} checked_ids={prioritiesid} title="Priorities" data={filterArray?.priorities?.data?.filter((twork: any) => (twork.isActive === true))} isLoading={filterArray?.isPrioritiesFetch} showdropdown={showpriorities} hideSelection={hideSelection} storeFilterValue={storeFilterValue} handleChange={handleChange} selectedName={prioritiesName} selectAll={selectAll} tooltipTitle='Filter data on their priorities.' isOpen={openDropdown === 'common-Priorities'} toggleDropdown={toggleDropdown} id="Priorities" filterType={"common"}></DropDown>
      }
      {showtickettype === "" &&
        <DropDown imgpath="/media/icons/duotune/new-icons/ticket-type.svg" inputHandler={inputHandler} inputText={tickettypeinputText} currentClick={currentClick} checked_ids={tickettypeid} title="Ticket type" data={filterArray?.ticketType?.data?.filter((twork: any) => (twork.isActive === true))} isLoading={filterArray?.isTicketTypeFetch} showdropdown={showtickettype} hideSelection={hideSelection} storeFilterValue={storeFilterValue} handleChange={handleChange} selectedName={tickettypeName} selectAll={selectAll} tooltipTitle='Filter data on their ticket type.' isOpen={openDropdown === 'common-Ticket type'} toggleDropdown={toggleDropdown} id="Ticket type" filterType={"common"}></DropDown>
      }
      {showticketcategory === "" &&
        <DropDown imgpath="/media/icons/duotune/new-icons/ticket-category.svg" inputHandler={inputHandler} inputText={ticketcategoryinputText} currentClick={currentClick} checked_ids={ticketcategoryid} title="Ticket category" data={filterArray?.ticketCategory?.data?.filter((twork: any) => (twork.isActive === true))} isLoading={filterArray?.isTicketCategoryFetch} showdropdown={showticketcategory} hideSelection={hideSelection} storeFilterValue={storeFilterValue} handleChange={handleChange} selectedName={ticketcategoryName} selectAll={selectAll} tooltipTitle='Filter data on their ticket category.' isOpen={openDropdown === 'common-Ticket category'} toggleDropdown={toggleDropdown} id="Ticket category" filterType={"common"}></DropDown>
      }
      {showtaskcategory === "" && 
        <DropDown imgpath="/media/icons/duotune/new-icons/task-category.svg" inputHandler={inputHandler} inputText={taskcategoryinputText} currentClick={currentClick} checked_ids={taskcategoryid} title="Task category" data={filterArray?.taskCategory?.data?.filter((twork: any) => (twork.isActive === true))} isLoading={filterArray?.isTaskCategoryFetch} showdropdown={showtaskcategory} hideSelection={hideSelection} storeFilterValue={storeFilterValue} handleChange={handleChange} selectedName={taskcategoryName} selectAll={selectAll} tooltipTitle='Filter data on their task category.' isOpen={openDropdown === 'common-Task category'} toggleDropdown={toggleDropdown} id="Task category" filterType={"common"}></DropDown>
      }
      {showslas === "" &&
        <DropDown imgpath="/media/icons/duotune/new-icons/slas.svg" inputHandler={inputHandler} inputText={slasinputText} currentClick={currentClick} checked_ids={slasid} title="SLAs" data={filterArray?.slas?.data?.filter((twork: any) => (twork.isActive === true))} isLoading={filterArray?.isSlasFetch} showdropdown={showslas} hideSelection={hideSelection} storeFilterValue={storeFilterValue} handleChange={handleChange} selectedName={slasName} selectAll={selectAll} tooltipTitle='Filter data on their slas.' isOpen={openDropdown === 'common-SLAs'} toggleDropdown={toggleDropdown} id="SLAs" filterType={"common"}></DropDown>
      }
      {showcontractcategory === "" && 
        <DropDown imgpath="/media/icons/duotune/new-icons/contract-category.svg" inputHandler={inputHandler} inputText={contractcategoryinputText} currentClick={currentClick} checked_ids={contractcategoryid} title="Contract category" data={filterArray?.contractCategory?.data?.filter((twork: any) => (twork.isActive === true))} isLoading={filterArray?.isContractCategoryFetch} showdropdown={showcontractcategory} hideSelection={hideSelection} storeFilterValue={storeFilterValue} handleChange={handleChange} selectedName={contractcategoryName} selectAll={selectAll} tooltipTitle='Filter data on their contract category.' isOpen={openDropdown === 'common-Contract category'} toggleDropdown={toggleDropdown}  id="Contract category" filterType={"common"}></DropDown>
      }
      {showtaskticketstatus === "" && 
        <DropDown imgpath="/media/icons/duotune/new-icons/contract-category.svg" inputHandler={inputHandler} inputText={taskticketstatusinputText} currentClick={currentClick} checked_ids={taskticketstatusid} title="Task/Ticket status" data={filterArray?.taskticketstatus?.filter((twork: any) => (twork.isActive === true))} isLoading={filterArray?.iafetchstatus} showdropdown={showtaskticketstatus} hideSelection={hideSelection} storeFilterValue={storeFilterValue} handleChange={handleChange} selectedName={taskticketstatusName} selectAll={selectAll} tooltipTitle='Filter data on their statuses.' isOpen={openDropdown === 'common-Statuses'} toggleDropdown={toggleDropdown}  id="Statuses" filterType={"common"}></DropDown>
      }
    </>
  );
};

export default CommonFilter;
