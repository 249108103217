import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import { setInitialSecretKey, setInitialSyncState } from '../../../InitialSyncToastSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { getOrganisationById } from '../../superadmin/organisations/organisation-list/core/_requests';
import { webSocketContext } from '../../../context/WebSocketContext';

const ToastNotification = () => {

  const dispatch = useDispatch();
  const { initialSecretKey, organizationId ,initialSyncState} = useSelector((state: RootState) => state.initialSyncToast);
  const { socketMessage }: any = useContext(webSocketContext);
  const hasWebSocketRun = useRef(false);


  const fetchOrganisation = async () => {
    try {
      const data = await getOrganisationById(organizationId);
      dispatch(setInitialSyncState(data.initial_sync_completed))
      if (data.secret !== undefined) {
        dispatch(setInitialSecretKey(true));
      }
    } catch (e) {
      console.log("e", e);
    }
  };

  const websocketCallback = useCallback((event: any) => {
    let eData = JSON.parse(event.data);
    
    if (eData.organizationId == organizationId && eData.message == "All data has been synced successfully") {
      dispatch(setInitialSyncState(true));
    } 
    
  }, [organizationId]);

  useEffect(() => {
    if (socketMessage && !hasWebSocketRun.current && initialSyncState) {
      websocketCallback(socketMessage);
      hasWebSocketRun.current = true;
    }
  }, [socketMessage, websocketCallback ]);

  useEffect(() => {
    if (organizationId !== null) {
      fetchOrganisation();
    }
  }, [organizationId,fetchOrganisation]);

  return (
    initialSecretKey && !initialSyncState ?
      <div className="alert alert-success p-2 px-3 notifyAlert">
        <div className="alert-text font-weight-bold fs-14">
          {"Initial Sync in progress, based on your company size this can take up to 48 hours. When finished you will receive a confirmation and you can continue to setup Proxuma."}
        </div>
      </div>
      : ''
  );
};

export default ToastNotification;
