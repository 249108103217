import React from "react";
import { FC, useCallback, useState } from "react";
import moment from "moment";
import { Draggable, confirm } from "@mobiscroll/react";
import { KTSVG } from "../../../../../_metronic/helpers";
import { itemTitle } from "../../../../helpers/global";
import { SERVICECALL_COLOR, TASK_COLOR, TICKET_COLOR } from "../../constant/Constant";

const BucketTask: FC<any> = ({
  data,
  planBucketSettings,
  hasShowMore,
  setHasShowMore,
  general_settings,
  linkIcon,
  setIsReadMore,
  isReadMore,
  onEventClick,
  onLinkClick,
  removeServicecall,
  projectList,
  prioritylist,
  queuesList
}) => {
  if (data?.start === null) {
    data.start = data.end;
  }
  data.entity_type = data?.type;
  data.project_name = data.projectId ? projectList?.filter((p: any) => parseInt(p.id) == parseInt(data.projectId) )?.[0]?.name : "";
  data.priority_name = data.priority ? prioritylist?.filter((p: any) => parseInt(p.id) == parseInt(data.priority) )?.[0]?.name : "";
  data.queue_name = data.queueID ? queuesList?.filter((p: any) => parseInt(p.id) == parseInt(data.queueID) )?.[0]?.name : "";
  const isLocation = general_settings?.isLocation ?? true;
  data.department_name = data?.department;
  let color: any;
  if (data.type == "task") {
    color = TASK_COLOR;
  }
  if (data.type == "ticket") {
    color = TICKET_COLOR;
  }
  if (data.type == "servicecall" || data.type == "serviceCall") {
    color = SERVICECALL_COLOR;
    data.type = "servicecall";
  }
  const [draggable, setDraggable] = useState();
  const setDragElm = useCallback((elm: any) => {
    setDraggable(elm);
  }, []);

  const hasFieldVisibleInPlanBucket = (field: string) => {
    if(field !== "title" || field !== "type"){
      if (planBucketSettings?.includes(field) || hasShowMore) {
        return "";
      } else {
        return "d-none";
      }
    }
  };

  const handleShowMoreLess = (e: any) => {
    const planDetailElem = e.target.closest(
      ".md-tooltip-detail"
    ) as HTMLElement;
    //clicked "TO PLAN" detail toggle
    if (planDetailElem) {
      const dropdownToggle = planDetailElem.querySelector(
        ".toggleDropdown"
      ) as HTMLElement;
      hideShowPlanBucket(planDetailElem);
      if (dropdownToggle.classList.contains("rotateButton")) {
        dropdownToggle.classList.remove("rotateButton");
      } else {
        dropdownToggle.classList.add("rotateButton");
      }
    } else {
      //all "TO PLAN" detail toggle
      setHasShowMore(!hasShowMore);
    }
  };

  const hideShowPlanBucket = (planDetailElem: any) => {
    const planBucketsFields = planDetailElem.querySelectorAll(".bktcls");
    const planBucketSettingsClone = planBucketSettings?.map((item: any) => {
      return `${item}_bktcls`;
    });
    planBucketsFields?.forEach((element: any) => {
      const elementCls = element?.className
        ?.split(" ")
        ?.find((item: string) => item.indexOf("_bktcls") != -1);
      if (!planBucketSettingsClone?.includes(elementCls)) {
        if (element.classList.contains("d-none")) {
          element.classList.remove("d-none");
        } else {
          element.classList.add("d-none");
        }
      }
    });
  };

  const toggleReadMore = (e: any) => {
    e.stopPropagation();
    setIsReadMore(!isReadMore);
  };

  const handleLinkClick = (e: any, data: any) => {
    const url = data.type == "servicecall" ? data?.related_task_ticket_url : data?.url;
    return !data?.isOffline ? onLinkClick(e, url) : null;
  }

  return (
    <>
      <div
        ref={setDragElm}
        className={`cusCard dynamically-color-and-invalidate-task m-0 mb-4 position-relative border-0 p-0 commonMidpopup rounded-4px planDiv ${
          data?.isOffline ? "isoffline" : ""
        }`}
        id={`avail_task_${data.id}`}
        title={`${
          data?.isOffline == true
            ? "This " +
              data?.type +
              " will be disabled until it refreshes the data from Autotask."
            : ""
        }`}
      >
        <div className="md-tooltip-detail fs-16 rounded-4px overflow-hidden fontIner text-dark">
          <div
            className="md-tooltip-header position-relative p-0"
            style={{ zIndex: "1" }}
          >
            <div
              className="position-absolute start-0 top-0 w-100 h-100 opacity-100"
              style={{ backgroundColor: `${color}`, zIndex: "-1" }}
            ></div>
            <div className="d-flex align-items-center justify-content-between paddLR24">
              <a
                href="javascript:void(0)"
                onClick={(e) => {
                  !data?.isOffline && onLinkClick(e, data?.url);
                }}
                className="fs-18 fw-bold text-dark lh-1 d-flex align-items-center min-h-48px py-2 mw-75"
                style={{
                  pointerEvents: `${data?.isOffline ? "none" : "auto"}`,
                }}
              >
                {(data?.status == "Complete" || data?.status == "complete") && (
                  <span
                    className="text-success me-2 fa fa-check bg-white rounded-circle w-32px h-32px min-w-32px min-h-32px d-inline-flex align-items-center justify-content-center customCheck"
                    style={{
                      color: "green !important",
                      fontWeight: "bolder",
                      fontSize: "18px",
                    }}
                  ></span>
                )}
                <span
                  className="d-block w-100 lh-sm"
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  {itemTitle(general_settings,data)}
                </span>
              </a>
              <div className="d-flex align-items-center">
                <a
                  href="javascript:void(0)"
                  onClick={(e) => {
                    !data?.isOffline && onEventClick(e, data);
                  }}
                  className={`d-inline-flex ${
                    data?.isOffline ? "isoffline" : ""
                  }`}
                >
                  <KTSVG
                    path={linkIcon}
                    className="ms-auto"
                    stylecode={{ color: "#5C5C71" }}
                    svgClassName="w-auto h-auto"
                  />
                </a>

                {data?.serviceCallId != "" &&
                  (data?.type === "serviceCall" ||
                    data?.type === "servicecall") && (
                    <button
                      className={`btn min-h-24px text-dark fw-normal rounded-pill border-primary d-inline-flex align-items-center justify-content-center ms-4 p-2 px-0 ${
                        data?.isOffline ? "isdisable" : ""
                      }`}
                      onClick={(e: any) => {
                        {
                          !data?.isOffline &&
                            confirm({
                              title:
                                "Are you sure you want to delete this plan item?",
                              okText: "Yes",
                              cancelText: "No",
                              callback: function (result) {
                                if (result) {
                                  removeServicecall(e, data);
                                }
                              },
                            });
                        }
                      }}
                    >
                      <KTSVG
                        path="/media/icons/duotune/new-icons/trash.svg"
                        className="d-inline-block m-0 text-primary valignTop"
                        svgClassName="w-24px h-auto"
                      />
                    </button>
                  )}

                <button
                  className={`min-h-32px ms-2 min-w-32px border-primary bg-white rounded-circle d-inline-flex align-items-center justify-content-center toggleDropdown ${
                    hasShowMore && "rotateButton"
                  } ${data?.isOffline ? "isdisable" : ""}`}
                  style={{ borderWidth: "1px" }}
                  data-bs-toggle="collapse"
                  data-bs-target={`#kt_accordion_1_body_${data.id}`}
                  aria-expanded="false"
                  aria-controls={`kt_accordion_1_body_${data.id}`}
                  onClick={handleShowMoreLess}
                >
                  <KTSVG
                    path="/media/icons/duotune/new-icons/down-arrow-trans.svg"
                    className="d-inline-block m-0 text-primary valignTop"
                    svgClassName="w-12px h-auto"
                  />
                </button>
              </div>
            </div>
          </div>

          {/* Tooltip info start */}
          {!data?.isOffline && (
            <>
              <div className={`md-tooltip-info paddLR24 py-0 bg-gray-new ${(planBucketSettings?.includes("title") && planBucketSettings?.length ==1) && "d-none"}`}>
                <div className="lh-1 d-flex justify-content-between align-items-start">
                  <span
                    className={`d-flex align-items-center h-32px bktcls type_bktcls ${hasFieldVisibleInPlanBucket(
                      "type"
                    )}`}
                  >
                    <small className="fs-100 w-250px">Type:</small>{" "}
                    <strong className="fw-normal" style={{textTransform:"capitalize"}}>
                      {data?.type}
                    </strong>
                  </span>
                  <div>
                    {data?.item_number && (
                      <>
                        <a
                            href="javascript:void(0)"
                            onClick={(e) => { handleLinkClick(e, data) }}
                            className={`fs-16 fw-normal text-white h-32px p-0 px-5 d-inline-flex align-items-center justify-content-center rounded-1 btn position-relative btn btn-primary mw-250px bktcls type_bktcls ${hasFieldVisibleInPlanBucket(
                              "type"
                            )}`}
                            >
                              <span className={`text-ellipsis `}>{data?.item_number}</span>
                            </a>
                        <br />
                      </>
                    )}
                    {data?.status &&
                    <a
                    
                      href=""
                      className={`fs-8 rounded-4px h-auto p-1 px-2 d-flex align-items-center justify-content-center btn btn-primary position-relative pe-none mt-2 bktcls status_bktcls ${hasFieldVisibleInPlanBucket(
                        "status"
                      )}`}
                    >
                      {data?.status}
                    </a>
                    }
                  </div>
                </div>

                <div
                  className={`lh-1 description_bktcls bktcls ${hasFieldVisibleInPlanBucket(
                    "description"
                  )}`}
                >
                  <span className="d-flex">
                    <small className="fs-100 w-250px">Description:</small>
                    <strong className="fw-normal w-370px text-justify lh-sm">
                      {isReadMore && data?.description
                        ? data?.description.slice(0, 150)
                        : data?.description
                        ? data?.description
                        : "-"}
                      {/* {data?.description && */}
                      <span onClick={toggleReadMore} className="read-or-hide">
                        {data?.description && data?.description.length > 150 && (
                          <a
                            href="javascript:void(0)"
                            className="fw-bold text-primary"
                          >
                            {isReadMore ? "...see more" : " see less"}
                          </a>
                        )}
                      </span>
                      {/* } id={data?.id} */}
                    </strong>
                  </span>
                </div>

                <div
                  className={`lh-1 start_end_date_estimated_hours_bktcls bktcls ${hasFieldVisibleInPlanBucket(
                    "start_end_date_estimated_hours"
                  )}`}
                >
                  <span className="d-flex align-items-center">
                    <small className="fs-100 w-250px">
                      Start / End date / Estimated hours:
                    </small>
                    <strong className="fw-normal d-flex align-items-center text-nowrap w-370px">
                      <small className="fs-100 min-w-1px rounded-pill bg-graydarkBg paddLR8 min-h-24px d-inline-flex align-items-center mar-e-8px">
                        {data?.startDateTime
                          ? moment(data?.startDateTime)
                              .tz(general_settings?.timezone)
                              .format("DD-MM-YYYY")
                          : "-"}
                      </small>
                      {data?.startDateTime && (
                        <small className="fs-100 min-w-1px me-2">
                          {data?.startDateTime
                            ? moment(data?.startDateTime)
                                .tz(general_settings?.timezone)
                                .format(general_settings?.time_format)
                            : ""}
                        </small>
                      )}
                      /
                      <small className="fs-100 min-w-1px rounded-pill bg-graydarkBg paddLR8 min-h-24px d-inline-flex align-items-center mar-e-8px ms-2">
                        {data?.endDateTime
                          ? moment(data?.endDateTime)
                              .tz(general_settings?.timezone)
                              .format("DD-MM-YYYY")
                          : ""}
                      </small>
                      <small className="fs-100 min-w-1px me-2">
                        {data?.endDateTime
                          ? moment(data?.endDateTime)
                              .tz(general_settings?.timezone)
                              .format(general_settings?.time_format)
                          : "-"}
                      </small>
                      /
                      <small className="fs-100 fw-bold min-w-1px ms-2">
                        {Number(
                          (Math.round(data?.autotask_duration * 100) / 100).toFixed(2)
                        )}
                      </small>
                    </strong>
                  </span>
                </div>

                <div
                  className={`lh-1 company_project_bktcls bktcls ${hasFieldVisibleInPlanBucket(
                    "company_project"
                  )}`}
                >
                  <span className="d-flex align-items-center">
                    <small className="fs-100 w-250px">Company / Project:</small>
                    <strong className="fw-normal w-370px">
                      {data?.companyName || "-"} /{" "}
                      {data?.projectName || data?.project_name ? (
                        <a
                          href={data?.project_url}
                          target="_blank"
                          className="fw-light text-decoration-underline text-break"
                          rel="noreferrer"
                        >
                          {data?.project_name || "-"}
                        </a>
                      ) : (
                        "-"
                      )}
                    </strong>
                  </span>
                </div>

                <div
                  className={`lh-1 primary_secondary_resource_bktcls bktcls ${hasFieldVisibleInPlanBucket(
                    "primary_secondary_resource"
                  )}`}
                >
                  <span className="d-flex align-items-center">
                    <small className="fs-100 w-250px">
                      Primary / Secondary resource:
                    </small>
                    <strong className="fw-normal w-370px">
                      {data?.resource_name || ""}
                    </strong>
                  </span>
                </div>

                {data?.type == "servicecall" && (
                  <div
                    className={`lh-1 related_task_ticket_bktcls bktcls ${hasFieldVisibleInPlanBucket(
                      "related_task_ticket"
                    )}`}
                  >
                    <span className="d-flex align-items-center">
                      <small className="fs-100 w-250px">
                        Related Task / Ticket:
                      </small>
                      <strong className="fw-normal w-370px lh-sm">
                        {data?.related_task_tickets || "-"}
                      </strong>
                    </span>
                  </div>
                )}

                {(data?.type == "task" || data?.type == "ticket") && (data?.related_serviceCalls != 0 && (
                    <div
                      className={`lh-1 d-flex related_servicecall_bktcls bktcls ${hasFieldVisibleInPlanBucket(
                        "related_servicecall"
                      )}`}
                    >
                      <span>
                        <small className="fs-100 w-250px">
                          Related Servicecall:{" "}
                        </small>
                        <strong className="fw-normal w-370px text-justify lh-sm">
                          {data?.related_serviceCalls}
                        </strong>
                      </span>
                    </div>
                ))}
                
                {( data?.type == "ticket") && (data?.queueId != 0 && (
                    <div
                      className={`lh-1 d-flex queues_bktcls bktcls ${hasFieldVisibleInPlanBucket(
                        "queues"
                      )}`}
                    >
                      <span>
                        <small className="fs-100 w-250px">
                          Queue:{" "}
                        </small>
                        <strong className="fw-normal w-370px text-justify lh-sm">
                          {data?.queue_name || "-"}
                        </strong>
                      </span>
                    </div>
                  ))}

                {(data?.type == "task" || data?.type == "ticket") && (data?.priority && (
                    <div
                      className={`lh-1 d-flex priority_bktcls bktcls ${hasFieldVisibleInPlanBucket(
                        "priority"
                      )}`}
                    >
                      <span>
                        <small className="fs-100 w-250px">
                        Priority:{" "}
                        </small>
                        <strong className="fw-normal w-370px text-justify lh-sm">
                          {data?.priority_name || "-"}
                        </strong>
                      </span>
                    </div>
                  ))}
                  { isLocation && (
                  <div
                    className={`lh-1 d-flex location_bktcls bktcls ${hasFieldVisibleInPlanBucket(
                      "location"
                    )}`}
                  >
                    <span>
                      <small className="fs-100 w-250px">
                      Location:{" "}
                      </small>
                      <strong className="fw-normal w-370px text-justify lh-sm">
                        {data?.locationIsActive === true ? data?.location || "-" : "-"}
                      </strong>
                    </span>
                  </div>)}

                  {(data?.type === "ticket") && (
                    <div
                      className={`lh-1 d-flex contact_information_bktcls bktcls ${hasFieldVisibleInPlanBucket(
                        "contact_information"
                      )}`}
                    >
                      <span>
                        <small className="fs-100 w-250px">
                        Contact information:{" "}
                        </small>
                        <strong className="fw-normal w-370px text-justify lh-sm text-break_all">
                          {data?.contactIsActive === 1 ? data?.contact || "-" : "-"}
                        </strong>
                      </span>
                    </div>
                  )}
              </div>
            </>
          )}
          {/* Tooltip info end */}
        </div>
        {!data?.isOffline && <Draggable dragData={data} element={draggable} />}
      </div>
    </>
  );
};

export default BucketTask;
