/** @jsxRuntime classic */
/** @jsx createElement */
import { createElement } from '../../../react/renderer';
import { ListItemBase, MbscListItemOptions } from './list-item';

export function template(s: MbscListItemOptions, inst: ListItemBase, content: any): any {
  const {
    actionable,
    children,
    className,
    drag,
    event,
    eventData,
    ripple,
    rtl,
    theme,
    themeVariant,
    onHoverIn,
    onHoverOut,
    onDragEnd,
    onDragMove,
    onDragStart,
    onDragModeOn,
    onDragModeOff,
    onDelete,
    onClick,
    ...other
  } = inst.props;
  return (
    <div tabIndex={0} ref={inst._setEl} onClick={inst._onClick} className={inst._cssClass} {...other}>
      {/* TRIAL */}
      {content}
      <div className={'mbsc-list-item-background mbsc-' + theme} />
    </div>
  );
}

/**
 * The ListItem component
 */
export class ListItem extends ListItemBase {
  protected _template(s: MbscListItemOptions): any {
    return template(s, this, s.children);
  }
}
