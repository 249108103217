import React, { useState,FC } from 'react';
import CreditCardForm from './CreditCardForm';
import { Elements } from '@stripe/react-stripe-js';
import * as Yup from "yup";
import { loadStripe } from '@stripe/stripe-js';
import { useFormik } from "formik";
import clsx from "clsx";
import { checkValidCredentials } from "../../requests/GeneralSettingRequest";
import { useAuth } from '../../../app/modules/auth/core/AuthCognito';
import { useDispatch } from 'react-redux';
import { setInitialSyncState } from '../../../app/InitialSyncToastSlice';

const STRIPEKEY = (process.env.REACT_APP_STRIPE_MODE == 'live' ? process.env.REACT_APP_STRIPELIVEKEY : process.env.REACT_APP_STRIPETESTKEY) || "";
const stripePromise = loadStripe(STRIPEKEY);

const apiCredentialsSchema = Yup.object().shape({
  secret: Yup.string()
    .required("Secret is required")
    .matches(/^(\S+$)/g, 'Secret cannot contain blankspaces'),
    user_name: Yup.string()
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 50 characters")
    .required("Client id is required")
    .matches(/^(\S+$)/g, 'Client id cannot contain blankspaces'),
});

const initialValues = {
  secret: "",
  user_name: "",
};

const CreditCardMain: FC = () => {
    
    const { currentUser } = useAuth();
    const dispatch = useDispatch();
    const [show, setshow] = useState((currentUser?.data?.user?.role_label === 'company_admin' && currentUser?.data?.user?.iscreditExist && !currentUser?.data?.user?.isclientIdExist) ? 'show' : '');
    const [showcreditcard, setshowcreditcard] = useState((currentUser?.data?.user?.role_label === 'company_admin' && !currentUser?.data?.user?.iscreditExist) ? 'show' : '');
    const [success, setSuccess] = useState('');

    const formik = useFormik({
        initialValues,
        validationSchema: apiCredentialsSchema,   
        onSubmit: async (values, { setStatus, setSubmitting }) => {
          try {
            setStatus('');
            setSuccess('')
            const { data: auth } = await checkValidCredentials(values);
            setSuccess('Initial sync will start and company admin will be notified by email when sync is done.')
            setTimeout(() => {
              setshow('')
              dispatch(setInitialSyncState(true));
              window.location.reload()
            }, 7000);
          } catch (error:any) {
            let ErrorMsg = error?.response?.data?.message && error?.response?.data?.message != undefined ? ', ' +error?.response?.data?.message : '';
            let msg = 'API call was not successful'+ErrorMsg ?? "Something went wrong."
            setStatus(msg);
            setSubmitting(false);
          }
        },
      });
      const cancel = (withRefresh?: boolean) => {
        if (withRefresh) {
          formik.resetForm();
        }
      };

  return (
    <>
    <div className={`modal fade ${showcreditcard != '' ? 'show d-block':'d-none'}`} tab-index="-1" aria-labelledby="exampleModalSizeLg" role="dialog" style={{ backgroundColor : 'rgba(0,0,0,0.3)' }} >
          <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                  <div className="modal-header">
                      <h5 className="modal-title fs-18">Add creditcard details</h5>
                  </div>
                  {stripePromise && 
                    <div className="modal-body">
                      <Elements stripe={stripePromise}>
                          <CreditCardForm />
                      </Elements>
                    </div>
                  }
              </div>
          </div>
      </div>
    <div className={`modal fade ${show != '' ? 'show d-block':'d-none'}`} tab-index="-1" aria-labelledby="exampleModalSizeLg" role="dialog" style={{ backgroundColor : 'rgba(0,0,0,0.3)' }} >
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title fs-18">Add Autotask Credentials</h5>
                    {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <i aria-hidden="true" className="ki ki-close"></i>
                    </button> */}
                </div>
                <div className="modal-body">
                    <form
                      className="form w-100"
                      onSubmit={formik.handleSubmit}
                      noValidate
                      id="kt_login_signin_form"
                    >
                      <div className="mb-5">
                      <span className="fs-16">Please add your autotask API credentials below:</span>
                      </div>

                      {/* begin :: Message */}
                      {formik.status ? (
                        <div className="mb-8 alert alert-danger p-2 px-3">
                          <div className="alert-text font-weight-bold fs-14">{formik.status}</div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                      {/* End :: Message */}

                      {/* begin :: Message */}
                      {success ? (
                        <div className="mb-8 alert alert-success p-2 px-3">
                          <div className="alert-text font-weight-bold fs-14">{success}</div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                      {/* End :: Message */}

                      {/* begin::Form group */}
                      <div className="fv-row mb-8">
                        <label className="fs-16 text-dark fw-bold pb-1">Client ID:</label>
                        <input
                          {...formik.getFieldProps("user_name")}
                          className={clsx(
                            "form-control form-control-solid h-32px px-4 rounded-4px py-0 bg-white border-graybor fw-normal fs-16 text-dark",
                            { "is-invalid": formik.touched.user_name && formik.errors.user_name },
                            {
                              "is-valid": formik.touched.user_name && !formik.errors.user_name,
                            }
                          )}
                          type="password"
                          name="user_name"
                          autoComplete="off"
                        />
                        {formik.touched.user_name && formik.errors.user_name && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              <span role="alert" className="fs-16">{formik.errors.user_name}</span>
                            </div>
                          </div>
                        )}
                      </div>
                      {/* End::Form group */}

                      {/* begin::Form group */}
                      <div className="fv-row mb-8">
                        <label className="fs-16 text-dark fw-bold pb-1">Client Secret:</label>
                        <input
                          type="password"
                          autoComplete="off"
                          {...formik.getFieldProps("secret")}
                          className={clsx(
                            "form-control form-control-solid h-32px px-4 rounded-4px py-0 bg-white border-graybor fw-normal fs-16 text-dark",
                            {
                              "is-invalid": formik.touched.secret && formik.errors.secret,
                            },
                            {
                              "is-valid": formik.touched.secret && !formik.errors.secret,
                            }
                          )}
                          name="secret"
                        />
                        {formik.touched.secret && formik.errors.secret && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              <span role="alert" className="fs-16">{formik.errors.secret}</span>
                            </div>
                          </div>
                        )}
                      </div>
                      {/* end::Form group */}

                      <div className="">
                        {/* begin::Action */}
                        <button
                          type="submit"
                          id="kt_sign_in_submit"
                          className="btn btn-primary text-left h-32px p-0 px-5 me-3"   /* text-toggle-hover */
                          disabled={formik.isSubmitting}
                        >
                          <span className="indicator-label"><small className="fs-100">Save</small></span>
                        </button>&nbsp;
                        <button
                          type='reset'
                          onClick={() =>cancel(true)}
                          className='btn btn-secondary text-left h-32px p-0 px-5'
                          data-kt-role-modal-action='close'
                        >
                          Cancel
                        </button>
                        {/* end::Action */}
                      </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </>
  );
};

export default CreditCardMain;
