import axios, { AxiosResponse } from "axios";
import { ID, Response } from "../helpers";
import { ResourceSetting } from "../../app/modules/apps/settings/resource/core/_model";
import { Resource } from "../../app/modules/apps/planning/core/resourceModel";

const API_URL = process.env.REACT_APP_THEME_API_URL;

const API_URL_NODE = process.env.REACT_APP_API_URL;
const RESOURCESETTING_URL_NODE = `${API_URL_NODE}settings/resource`;
const RESOURCESETTING_Update_URL_NODE = `${API_URL_NODE}settings/update_resource_status`;

const getResourceSetting = async (query: string): Promise<any> => {
  return await axios
    .get(`${RESOURCESETTING_URL_NODE}?${query}`)
    .then((d: AxiosResponse<any>) => d.data);
};

const updateResourceSetting = (
  resource_setting: ResourceSetting,
  user: any
): Promise<ResourceSetting | undefined> => {
  return axios
    .post(`${RESOURCESETTING_URL_NODE}/${resource_setting.id}`, user)
    .then(
      (response: AxiosResponse<Response<ResourceSetting>>) => response?.data
    )
    .then((response: Response<ResourceSetting>) => response?.data);
};

// const updateActiveStatus = (updatedata: any): Promise<any | undefined> => {
//   return axios
//     .post(`${RESOURCESETTING_Update_URL_NODE}`, updatedata)
//     .then((response: AxiosResponse<Response<any>>) => response.data)
//     .then((response: Response<any>) => response.data);
// };

const updateActiveStatus = async (
  updatedata: any
): Promise<any | undefined> => {
  const response = await axios
    .post(`${RESOURCESETTING_Update_URL_NODE}`, updatedata)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return response;
};

const updateRoleLicense = (
  userData: any
): Promise<ResourceSetting | undefined> => {
  return axios
    .post(`${API_URL_NODE}users/update-role-license`, userData)
    .then(
      (response: AxiosResponse<Response<ResourceSetting>>) => response?.data
    )
    .then((response: Response<ResourceSetting>) => response?.data);
};

const updateActiveStatusUser = (userData: any): Promise<any | undefined> => {
  return axios
    .post(`${API_URL_NODE}users/update-user-status`, userData)
    .then((response: AxiosResponse<Response<any>>) => response?.data)
    .then((response: Response<any>) => response?.data);
};

export {
  getResourceSetting,
  updateResourceSetting,
  updateActiveStatus,
  updateRoleLicense,
  updateActiveStatusUser,
};
