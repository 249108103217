import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import '@fortawesome/fontawesome-free/css/all.min.css';
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import CustomButtonComponent from "./CustomButtonComponent";
import ImageRender from "./ImageRender";
import { useQuery } from "react-query";
import { getProjectBreakdown, projectCopyPhase, projectCopyTaskTicket } from "../../../../../_metronic/requests/PlanningRequest";
import { confirm } from "@mobiscroll/react";
import { Loading } from "../../../../components/loading/Loading";

// Registering the necessary modules
ModuleRegistry.registerModules([ClientSideRowModelModule, ColumnsToolPanelModule, MenuModule, RowGroupingModule]);
type Props = {
  projectId?: number;
  isRefetchTable?: Boolean;
  tasks?: any;
  phases?: any;
  setTasks?: any
  setPhases?: any,
  setIsCreatePhaseOpen?: any
  setIsCreateTaskOpen?: any
  setIsRefatchTable?: any
  setPhaseId?: any
  setDisabled?:any
}
const ProjectTable: FC<Props> = ({ setDisabled ,projectId, setIsRefatchTable, isRefetchTable,  setTasks, setPhases, setIsCreatePhaseOpen, setIsCreateTaskOpen, setPhaseId }) => {
  // enables pagination in the grid
  const pagination = true;
  // sets 10 rows per page (default is 100)
  const paginationPageSize = 10;
  // allows the user to select the page size from a predefined list of page sizes
  const paginationPageSizeSelector = [5, 10, 20, 50, 100];
  const { data: rowData, isLoading, isFetching, refetch } = useQuery(
    "project-breakdown",
    () => {
      return getProjectBreakdown(projectId);
    },
    { cacheTime: 0, keepPreviousData: false, refetchOnWindowFocus: false, refetchOnMount: true }
  );

  useEffect(() => {
    if (isRefetchTable) {
      refetch();
      setIsRefatchTable(false);
    }
  }, [isRefetchTable, refetch, setIsRefatchTable]);

    //set the inital data in the api 
      useEffect(() => {
        if (rowData?.data?.data) {
          const newTasks = Array.isArray(rowData.data.data)
            ? rowData.data.data.filter((item) => item.type === "task")
            : [];
          const newPhases = Array.isArray(rowData.data.data)
            ? rowData.data.data.filter((item) => item.type === "phase")
            : [];
          setTasks(newTasks);
          setPhases(newPhases);
        }
      }, [rowData, setTasks, setPhases]);

      useEffect(() => {
        if(isLoading){
          setDisabled(true)}
          else{
            setDisabled(false)
          }
      }, [isLoading])
  const CheckboxHeader = (props) => {
    const onCheckboxChange = (e) => {
      props.api.forEachNode(node => node.setSelected(e.target.checked));
    };

    return (
      <div className="custom-checkbox-header" style={{ display: 'flex', alignItems: 'center' }}>
        <i className="fa fa-bars" style={{ marginRight: '8px', cursor: 'move' }}></i>
        <input type="checkbox" style={{ verticalAlign: 'middle' }} onChange={onCheckboxChange} />
      </div>
    );
  };

  // Custom group row renderer to display additional data
  const CustomGroupRowRenderer = (params) => {
    return <span>{params.value}</span>;
  };

  const handleCopy = async (rowData, id, type, name) => {
    confirm({
      title: `Are you sure you want to copy this ${name}?`,
      okText: 'Yes',
      cancelText: 'No',
      callback: async function (result) {
        if (result) {
          try {
            if (type === 'task') {
              const response = await projectCopyTaskTicket(id);
              const { data: { data } } = response;
              setTasks((prevTasks) => [...prevTasks, data[0]]);
              setIsRefatchTable(true);
            } else if (type === 'phase' || rowData.type === 'subphase' || rowData.type === 'subphase1') {
              const response = await projectCopyPhase(id);
              const { data: { data } } = response;
              setTasks((prevTasks) => [...prevTasks, ...data.tasks]);
              setPhases((prevPhases) => [...prevPhases, ...data.phases]);
              setIsRefatchTable(true);
            } else {
              console.log('Copy action not applicable for this type.');
            }
          } catch (error) {
            console.error('Error copying task/ticket:', error);
          }
        }
      }
    });

  };

  const handleCreate = async (rowData, id, type) => {
    setPhaseId(id);
    if (type === 'phase') {
      setIsCreatePhaseOpen(true);
    }
    else if (type === 'subphase') {
      setIsCreateTaskOpen(true);
    }
  };
  const handleCreatePhase = (rowData) => {
    console.log('Delete function called with rowData:', rowData);
  };

  const [columnDefs] = useState([
    { field: 'filePath', rowGroup: true, hide: true },
    { field: 'category', headerName: 'Category' },
    { field: 'type', headerName: 'Type', hide: true },
    { field: 'task_type', headerName: 'Type' },
    { field: 'start_date', headerName: 'Start Date' },
    { field: 'end_date', headerName: 'End Date' },
    { field: 'priority', headerName: 'Priority' },
    { field: 'primary', headerName: 'Primary resource', cellRenderer: ImageRender, minWidth: 100, cellClass: "logoCell" },
    { field: 'secondary', headerName: 'Secondary resource', cellRenderer: ImageRender, minWidth: 100, cellClass: "logoCell" },
    { field: 'status', headerName: 'Status' },
    { field: 'work_type', headerName: 'Work Type' },
    { field: 'issue', headerName: 'Issue', hide: true },
    { field: 'id', headerName: 'ID', hide: true },
    { field: 'phaseID', headerName: 'Phase ID', hide: true },
    { field: '_id', headerName: 'Task ID', hide: true },
    {
      field: 'actions',
      headerName: 'Actions',
      maxWidth: 96,
      minWidth: 96,
      sortable: false,
      cellRenderer: CustomButtonComponent,
      pinned: 'right',
      suppressNavigable: true,
      suppressMenu: true,
      suppressMovable: true,
      cellClass: 'not-selectable-cell',
      cellRendererParams: {
        onCopy: handleCopy,
        onCreate: handleCreate,
        OnDelete: handleCreatePhase,
      },
    },
  ]);

  const defaultColDef = {
    flex: 1,
    sortable: true,
    editable: false,
    filter: true,
    floatingFilter: true,
    suppressMenu: true,
    resizable: false,
  };

  const autoGroupColumnDef = useMemo(() => {
    return {
      headerName: 'Phase / Task / Ticket',
      minWidth: 200,
      headerComponentFramework: CheckboxHeader,
      // checkboxSelection: true,
      // headerCheckboxSelection: true,
      pinned: 'left',
      width: 388,
      suppressMovable: true,
      cellClass: "suppress-movable-col",
      cellRendererParams: {
        suppressCount: true, // This hides the count in the default group cell renderer
        innerRenderer: CustomGroupRowRenderer,
      },
    };
  }, []);

  const getRowHeight = (params) => {
    return 48; // Default height
  };
  const gridOptions = {
    groupDisplayType: 'singleColumn',
    suppressCellFocus: true
  };

  const getRowId = (params) => {
    if (params.data && params.node) {
      return `${params.data.id || params.data._id}-${params.node.rowIndex}`;
    }
    // Fallback or default value, just in case
    return null;
  };

  const getRowClass = (params) => {
    return (params.data && params.data.type && params.data.type === "task") ? "row-task" : "row-phase";
  };

  const getDataPath = useCallback((data) => {
    return data.filePath || [`${data.type}-${data.id}`];
  }, []);

  return (
    <div className="agTable w-100">
      <div className="ag-theme-quartz w-100">
        {(isLoading || isFetching) ? (<h1><center>Loading...</center></h1>) :
          (!isLoading && rowData?.data?.data?.length > 0) ? (<AgGridReact
            rowData={rowData?.data?.data}
            treeData={true}
            groupDefaultExpanded={-1}
            getDataPath={getDataPath}
            columnDefs={columnDefs}
            domLayout={"autoHeight"}
            defaultColDef={defaultColDef}
            autoGroupColumnDef={autoGroupColumnDef}
            rowSelection={"multiple"}
            rowDragManaged={false}
            getRowHeight={getRowHeight}
            pagination={pagination}
            paginationPageSize={paginationPageSize}
            paginationPageSizeSelector={paginationPageSizeSelector}
            gridOptions={gridOptions}
            getRowId={getRowId}
            groupAggFiltering={true}
            getRowClass={getRowClass}  // Assign the getRowClass function here
          // onGridReady={onGridReady}
          />) :
            (<center><h4>No data is available for this project</h4></center>)}
      </div>
    </div>
  );
}

export default ProjectTable;
